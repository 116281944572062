<template>
  <div class="c-popup">
    <el-dialog
      @close="onClose"
      :title="title"
      v-model="visible"
      destroy-on-close
    >
      <slot name="content"></slot>
      <template #footer>
      <span
        class="dialog-footer"
        v-if="showAction"
      >
        <el-button @click="onClose">Cancel</el-button>
        <el-button
          :disabled="loading"
          type="primary"
          @click="onConfirm"
          v-loading="loading"
        >Confirm</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: this.showPopup
    }
  },

  methods: {
    onClose () {
      this.$emit('popup:close')
    },

    onConfirm () {
      this.$emit('action:confirm')
    }
  },

  props: {
    showPopup: {
      default: false,
      type: Boolean
    },

    title: {
      type: String
    },

    showAction: {
      default: true,
      type: Boolean
    },

    loading: {
      default: false,
      type: Boolean
    }
  },

  watch: {
    showPopup: {
      deep: true,
      handler (newValue) {
        this.visible = newValue
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.c-popup {
  .el-dialog__body {
    padding: 5px 20px;
  }
}

@media screen and (min-width: 200px) and (max-width:767px) {
  .c-popup .el-dialog{
    width:90% !important;
  }
}

@media screen and (min-width:768px) and (max-width:2500px) {
  .c-popup .el-dialog{
    width:32% !important;
  }
}

</style>
