<template>
  <router-view/>
</template>

<script>
</script>
<style>
#app, .app-wrapper, html, body {
    overflow: inherit !important;
  overflow-x: hidden !important;
  font-family: 'Ubuntu', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
</style>
