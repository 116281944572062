const educationFields = state => state.education_fields ? state.education_fields : ''

const educationLevels = state => state.education_levels ? state.education_levels : ''

const createdUser = state => state.created_user ? state.created_user : ''

const admin = state => localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')) : null

const user = () => localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

const users = state => state.users

const userToken = state => localStorage.getItem('USER_TOKEN')

export default {
  admin,
  educationFields,
  educationLevels,
  createdUser,
  user,
  users,
  userToken
}
