<template>
  <Page
    :title="'Safety Tips'"
    :showButton="false"
    class="c-admin-about-us"
  >
    <template #content>
      <PageFrom
        @page:created="onPageCreated"
        :pageValue="pageValue"
      />
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page'
import PageFrom from './PageForm'

import pageMixin from '@/mixins/page'

export default {
  components: {
    PageFrom,
    Page
  },

  created () {

  },

  data () {
    return {
      pageValue: null
    }
  },

  methods: {
    onPageCreated () {
      this.$message({
        message: 'Safety tips created successfully',
        type: 'success'
      })
    }
  },

  mixins: [pageMixin],

  name: 'AboutUs'
}
</script>
