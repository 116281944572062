<template>
  <div class="c-home">
    <el-row :gutter="15">
      <el-col :xs="12" :sm="12" :md="8" :lg="6">
      <el-card>
        <div class="card-box-main">
        <div class="card-left">
          <i class="icons el-icon-user"></i>
        </div>
        <div class="card-right">
          <div class="num-value">{{ totalUser }}</div>
          <div class="text-value">Total User</div>
        </div>
        </div>
      </el-card>
      </el-col>
      <el-col :xs="12" :sm="12" :md="8" :lg="6">
        <el-card class="text-center">
          <div class="card-box-main">
            <div class="card-left">
              <i class="icons el-icon-user"></i>
            </div>
            <div class="card-right">
              <div class="num-value">{{ activeUsers }}</div>
              <div class="text-value">Active User</div>
            </div>
          </div>
      </el-card>
      </el-col>
      <el-col :xs="12" :sm="12" :md="8" :lg="6">
        <el-card>
          <div class="card-box-main">
            <div class="card-left">
              <i class="icons el-icon-circle-check"></i>
            </div>
            <div class="card-right">
              <div class="num-value">{{ verifiedUsers }}</div>
              <div class="text-value">Verified User</div>
            </div>
          </div>
      </el-card>
      </el-col>
      <el-col :xs="12" :sm="12" :md="8" :lg="6">
        <el-card>
          <div class="card-box-main">
            <div class="card-left">
              <i class="icons el-icon-male"></i>
            </div>
            <div class="card-right">
              <div class="num-value">{{ maleUsers }}</div>
              <div class="text-value">Male User</div>
            </div>
          </div>
      </el-card>
      </el-col>
      <el-col :xs="12" :sm="12" :md="8" :lg="6">
        <el-card>
          <div class="card-box-main">
            <div class="card-left">
              <i class="icons el-icon-female"></i>
            </div>
            <div class="card-right">
              <div class="num-value">{{ femaleUsers }}</div>
              <div class="text-value">Female User</div>
            </div>
          </div>
      </el-card>
      </el-col>
      <el-col :xs="12" :sm="12" :md="8" :lg="6">
        <el-card>
          <div class="card-box-main">
            <div class="card-left">
              <i class="icons el-icon-circle-close"></i>
            </div>
            <div class="card-right">
              <div class="num-value">{{ nonVerifiedUsers }}</div>
              <div class="text-value">Non-Verified</div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="12">
        <el-card>
        <Chart
          :chartData="doughnutChart"
        />
        <Chart
          :chartData="lineChart"
        />
        </el-card>
      </el-col>
    <el-col :xs="24" :sm="24" :md="12" :lg="12">
      <el-card>
        <Chart
          :chartData="pieChart"
        />
        <Chart
          :chartData="barChart"
        />
      </el-card>
    </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="12">
          <el-card class="box-card dashboard-viewed">
            <template #header>
              <div class="card-header ">
                <span>Recently Added User</span>
              </div>
            </template>
            <div
              :key="index"
              class="dashboard-list"
              v-for="(user, index) in recentlyAddedUsers"
            >
              <div class="leftlist-img">
                <img
                  :src="user.image ? imageBasePath + user.image : require('@/assets/images/unknown1.png')"
                  :alt="user.image ? user.image : 'user'"
                >
              </div>
              <div class="right-listing">
                <div class="title-list">
                  <a href="">{{ user.name }}</a>
                </div>
                <div class="text-desc">
                  {{ user.email }}
                </div>
              </div>
              <div class="listing-border"></div>
            </div>
          </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12">
        <el-card class="box-card dashboard-viewed">
          <template #header>
            <div class="card-header ">
              <span>Most Viewed User</span>
            </div>
          </template>
          <div
            :key="index"
            class="dashboard-list"
            v-for="(user, index) in mostViewedUsers"
          >
            <div class="leftlist-img">
              <img
                :src="user.image ? imageBasePath + user.image : require('@/assets/images/unknown1.png')"
                :alt="user.image ? user.image : 'user'"
              >
            </div>
            <div class="right-listing">
              <div class="title-list">
                <a href="">{{ user.name }}</a>
              </div>
              <div class="text-desc">
                {{ user.email }}
              </div>
            </div>
            <div class="listing-border"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Chart from '@/components/Chart'

import userMixin from '@/mixins/user'

export default {
  components: {
    Chart
  },

  created () {
    this.getUsers()
    this.getMostViewedUsers().then((res) => {
      this.mostViewedUsers = res
    })
  },

  computed: {
    activeUsers () {
      const users = this.users && this.users.filter(user => {
        return user.status === 1 && user.email_verified_at !== null
      })

      return users.length ? users.length : 0
    },

    verifiedUsers () {
      const users = this.users && this.users.filter(user => {
        return (user.email_verified_at !== null || user.status === 1)
      })

      return users.length ? users.length : 0
    },

    recentlyAddedUsers () {
      return this.users && this.users.slice(0, 4)
    },

    maleUsers () {
      const users = this.users && this.users.filter(user => {
        if (user.personal_record) {
          return user.personal_record.gender === 1
        }
      })

      return users.length ? users.length : 0
    },

    femaleUsers () {
      const users = this.users && this.users.filter(user => {
        if (user.personal_record) {
          return user.personal_record.gender === 0
        }
      })

      return users.length ? users.length : 0
    },

    nonVerifiedUsers () {
      const users = this.users && this.users.filter(user => {
        return user.email_verified_at === null && user.status === 0
      })

      return users.length ? users.length : 0
    },

    totalUser () {
      return this.users && this.users.length
    },

    doughnutChart () {
      return {
        id: 'doughnut',
        type: 'doughnut',
        data: {
          labels: ['Verified Users', 'Unverified Users', 'Male Users', 'Female Users'],
          datasets: [
            {
              label: 'Doughnout Chart',
              backgroundColor: [
                '#41B883',
                '#E46651',
                '#00D8FF',
                '#DD1B16'
              ],
              data: [
                this.verifiedUsers ? this.verifiedUsers : 0,
                this.nonVerifiedUsers ? this.nonVerifiedUsers : 0,
                this.maleUsers ? this.maleUsers : 0,
                this.femaleUsers ? this.femaleUsers : 0
              ]
            }
          ]
        }
      }
    },

    pieChart () {
      return {
        id: 'bar',
        type: 'pie',
        data: {
          labels: [
            'Verified Users',
            'UnVerified Users',
            'Male Users',
            'Female Users'
          ],
          datasets: [{
            label: 'Pie chart',
            data: [
              this.verifiedUsers ? this.verifiedUsers : 0,
              this.nonVerifiedUsers ? this.nonVerifiedUsers : 0,
              this.maleUsers ? this.maleUsers : 0,
              this.femaleUsers ? this.femaleUsers : 0
            ],
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 162, 235)',
              'rgb(255, 205, 86)',
              'rgb(53, 46, 56)'
            ],
            hoverOffset: 4
          }]
        }
      }
    }
  },

  data () {
    return {
      mostViewedUsers: []
    }
  },

  setup () {
    const barChart = {
      id: 'bar',
      type: 'bar',
      data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [{
          label: 'Monthly Added Users Report',
          data: [65, 59, 80, 81, 56, 55, 40],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 205, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(201, 203, 207, 0.2)'
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ],
          borderWidth: 1
        }]
      }
    }

    const lineChart = {
      id: 'bar',
      type: 'line',
      data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [{
          label: 'Monthly added users Line chart',
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      }
    }

    return {
      barChart,
      lineChart
    }
  },

  mixins: [userMixin],

  name: 'Home'
}
</script>

<style lang="scss">

.main{
  padding-top:20px;
}
.card-box-main{
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 65px;
}
.card-left{
  width: 60px;
  float: left;
}
.card-right{
  float: right;
  text-align: right;
}
.card-left .icons{
  font-size: 60px;
  font-weight: 500;
  color: #dc143c;
}
.num-value{
  font-size: 40px;
  padding: 10px 0 0 0;
  color: #003895;
}

.text-value{
  font-size: 14px;
  padding: 10px 0 0 0;
}

.text-center{
  text-align: center;
}

.dashboard-viewed {
  margin: 0;
  padding: 0 0 40px 0;
}

.dashboard-list {
  margin: 0;
}

.listing-border{
  border-bottom: 1px solid #dddddd;
  clear:both;
  padding:2px 0;
  margin-bottom: 8px;
}

.dashboard-list .leftlist-img {
  margin: 0 10px 0 0;
  padding: 0;
  width: 12%;
  float: left;
}
.dashboard-list .leftlist-img img {
  margin: 0;
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.dashboard-list .right-listing {
  margin: 0;
  padding: 0;
  float: left;
  width: 80%;

}

.dashboard-list .title-list {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 15px;
}

.dashboard-list .title-list a {
  margin: 0;
}

.dashboard-list .text-desc {
  margin: 0;
  padding: 5px 0 0 0;
  font-size: 11px;
  line-height: 16px;
}

.c-home {
  padding:0 1rem;

  &__card {
    text-align: center;
    margin-bottom: 2rem;
    width: 12rem;
    height: 6rem;

    h2 {
      padding-bottom: 0.5rem;
    }
  }

  &__charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__chart1 {
    width: 20rem;
  }

  &__chart2 {
    width: 20rem;
  }

  &__chart3 {
    width: 40rem;
  }

  &__chart4 {
    width: 40rem;
  }

  &__user-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
</style>
