import Admin from '@/views/admin/Home.vue'
import Layout from '@/components/layout/Index'
import AddAdvertisement from '@/views/admin/advertisement/Add'
import EditAdvertisement from '@/views/admin/advertisement/Edit'
import AddBanner from '@/views/admin/banner/Add'
import EditBanner from '@/views/admin/banner/Edit'
import AddEvent from '@/views/admin/event/Add'
import EditEvent from '@/views/admin/event/Edit'
import Advertisement from '@/views/admin/advertisement/Index'
import Banner from '@/views/admin/banner/Index'
import Event from '@/views/admin/event/Index'
import AdminFeedback from '@/views/admin/Feedback'
import AdminFAQ from '@/views/admin/event/FAQ'

const meta = {
  redirectOnExpire: true,
  requiresAuth: true
}

const routes = [
  {
    children: [
      {
        component: Admin,
        name: 'admin-dashboard',
        path: '/admin_dashboard'
      },
      {
        component: Advertisement,
        name: 'admin-advertisement',
        path: '/admin-advertisement'
      },
      {
        component: AddAdvertisement,
        name: 'admin-add-advertisement',
        path: '/admin/add-advertisement'
      },
      {
        component: EditAdvertisement,
        name: 'admin-edit-advertisement',
        path: '/admin/edit-advertisement/:id'
      },
      {
        component: Banner,
        name: 'admin-banner',
        path: '/admin-banner'
      },
      {
        component: AddBanner,
        name: 'admin-add-banner',
        path: '/admin/add-banner'
      },
      {
        component: EditBanner,
        name: 'admin-edit-banner',
        path: '/admin/edit-banner/:id'
      },
      {
        component: Event,
        name: 'admin-event',
        path: '/admin-event'
      },
      {
        component: AddEvent,
        name: 'admin-add-event',
        path: '/admin/add-event'
      },
      {
        component: EditEvent,
        name: 'admin-edit-event',
        path: '/admin/edit-event/:id'
      },
      {
        component: AdminFeedback,
        name: 'admin-feedbacks',
        path: '/admin_feedbacks'
      },
      {
        component: AdminFAQ,
        name: 'admin-event-faq',
        path: '/admin-event-faqs/:id'
      }
    ],
    component: Layout,
    meta: meta,
    path: '/admin'
  }
]

export default routes
