<template>
  <div class="c-forget-password">
    <Header />

    <div class="c-forget-password__body">
      <div class="el-login-main">
        <div class="el-login">
          <el-form
            ref="form"
            v-loading="state.loading"
          >
            <el-form-item label="Password">
              <el-input
                placeholder="Password"
                v-model="state.password"
                class="input"
                type="password"
              >
              </el-input>
              <span
                class="c-user-login-form__error"
                v-if="v$.password.$error"
              >{{ v$.password.$errors[0].$message }}
                </span>
            </el-form-item>

            <el-form-item label="Confirm Password">
              <el-input
                placeholder="Confirm Password"
                v-model="state.confirm_password"
                class="input"
                type="password"
              >
              </el-input>
              <span
                class="c-user-login-form__error"
                v-if="v$.confirm_password.$error"
              >
                  {{ v$.confirm_password.$errors[0].$message }}
              </span>
              <span
                class="c-user-login-form__error"
                v-if="state.confirmPasswordError"
              >
                  Password does not match
              </span>
            </el-form-item>

            <div class="button-login">
              <el-button
                @click="onResetPassword"
                type="danger"
              >
                Submit</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { computed, reactive } from 'vue'
import { helpers, required, minLength } from '@vuelidate/validators'
import useValidate from '@vuelidate/core'

import userMixin from '@/mixins/user'

export default {
  components: {
    Header,
    Footer
  },

  setup () {
    const state = reactive({
      loading: false,
      password: '',
      confirm_password: '',
      confirmPasswordError: false
    })

    const rules = computed(() => {
      return {
        password: { required: helpers.withMessage('Password field is required', required), minLength: minLength(6) },
        confirm_password: {
          required: helpers.withMessage('Confirm Password field is required', required)
        }
      }
    })

    const v$ = useValidate(rules, state)

    return {
      state,
      v$
    }
  },

  methods: {
    onResetPassword () {
      this.v$.$validate()
      if (!this.v$.$error) {
        if (this.state.password !== this.state.confirm_password) {
          this.state.confirmPasswordError = true
        } else {
          this.state.confirmPasswordError = false

          this.loading = true

          this.resetPassword({
            token: this.$route.query.token,
            password: this.state.password
          }).then(() => {
            this.loading = false

            this.$message({
              message: 'Password changes successfully. Please login with new password.',
              type: 'success'
            })

            this.$router.push({ name: 'frontend' })
          }).catch((error) => {
            this.loading = false

            this.$message({
              message: error.response.data.error ? 'Invalid data' : error.response.data.message,
              type: 'warning'
            })
          })
        }
      }
    }
  },

  mixins: [userMixin],

  name: 'ForgetPassword'
}
</script>

<style lang="scss" scoped>
.c-user-login-form {
  &__error {
    color: darkred;
  }

  &__forget-password {
    text-align: center;
    margin: 1rem 0;
  }
}
.el-login-main{
  background: #f3f2ef;
  margin: 0;
  padding: 80px 0;
}

.el-login{
  margin: 0 auto;
  padding:30px 30px;
  width: 380px;
  background: #fff;
  border-radius: 10px;
}

.login-icon{
  background: #003895;
  text-align: center;
  border-radius: 50px;
  width: 80px;
  height: 80px;
  border: 1px solid #ccc;
  margin: 0 auto 5px;
  padding: 7px;
  position: relative;
  font-size: 52px;
  color: #fff;
}

.el-input__inner {
  background-color: #eee;
  border: none;
  padding: 15px 15px;
  margin: 8px 0;
  width: 100%;
}

.forgetpass{
  margin: 0;
  padding: 15px 0 15px 0;
  color: #000;
  font-size: 13px;
  text-align: center;
}

.button-login{
  text-align: center;
}

.el-form-item {
  margin-bottom: 0;
}
.alternate-login {
  margin: 0;
  padding: 15px 0 0 0;
  text-align: center;
}

.alternate-login img{
  margin: 10px 4px 0 4px;

}

.alternate-login .heading{
  margin: 0;
  padding: 15px 0 5px 0;
  text-align: center;
  font-style: italic;
}
</style>
