import Layout from '@/components/layout/Index'

const Login = () => import('@/views/user/Login.vue')
const AddUser = () => import('@/views/user/_components/Add.vue')
const EditUser = () => import('@/views/user/_components/Edit.vue')
const User = () => import('@/views/user/Index.vue')

const meta = {
  redirectOnExpire: true,
  requiresAuth: true
}

const routes = [
  {
    component: Login,
    name: 'admin-login',
    path: '/admin/login'
  },
  {
    children: [
      {
        component: AddUser,
        name: 'add-user',
        path: '/user/add'
      },
      {
        component: EditUser,
        name: 'edit-user',
        path: '/user/edit/:id'
      },
      {
        component: User,
        name: 'users',
        path: '/admin/users'
      }
    ],
    component: Layout,
    path: '/admin',
    meta: meta
  }
]

export default routes
