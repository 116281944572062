<template>
  <div>
    <div class="container">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="12" :lg="12">
          <el-card>
      <el-form ref="form" :model="form" class="contact-form">
        <el-form-item label="Your Name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="Phone">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="Email Address">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="Subject">
          <el-input v-model="form.subject"></el-input>
        </el-form-item>
        <el-form-item label="Message">
          <el-input type="textarea" v-model="form.message"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">Create</el-button>
          <el-button>Cancel</el-button>
        </el-form-item>
      </el-form>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12">
          <el-card>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7063.691015634036!2d85.31710982326618!3d27.722055928490885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1919f7dd0685%3A0xc59baa0caae9c83d!2sLazimpat%2C%20Kathmandu%2044600!5e0!3m2!1sen!2snp!4v1625479988405!5m2!1sen!2snp" width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </el-card>
        </el-col>
      </el-row>

  </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        subject: '',
        message: ''
      }
    }
  },
  methods: {
    onSubmit () {
    }
  }
}
</script>
<style>
.contact-form{
  padding: 10px;
}

.contact-form .el-form-item__label {
  line-height: 20px;
}

.contact-form .el-form-item {
  margin-bottom: 10px;
}

.contact-form .el-button {
  font-size: 14px;
}

</style>
