import api from '@/api/like_comment'

const getTotalLikes = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.getTotalLikes(id)
      .then((response) => {
        commit(
          'UPDATE_TOTAL_LIKES',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getTotalComments = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.getTotalComment(id)
      .then((response) => {
        commit(
          'UPDATE_TOTAL_COMMENTS',
          response.data
        )

        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getComments = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.getComments(id)
      .then((response) => {
        commit(
          'UPDATE_COMMENTS',
          response.data
        )

        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createLike = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createLike(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createComment = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createComment(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default {
  createComment,
  getComments,
  createLike,
  getTotalComments,
  getTotalLikes
}
