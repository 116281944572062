<template>
    <div class="sidebar">
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        text-color="#fff"
        active-text-color="#ffd04b">
        <router-link :to="{ name: 'admin-dashboard' }">
          <el-menu-item index="1">
            <i class="el-icon-s-home"></i>
            Dashboard
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'users' }">
          <el-menu-item index="2">
            <i class="el-icon-user"></i>
            Users
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'admin-advertisement' }">
          <el-menu-item index="3">
            <i class="el-icon-bangzhu"></i>
            Advertisement
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'admin-banner' }">
          <el-menu-item index="3">
            <i class="el-icon-timer"></i>
            Banner
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'admin-event' }">
          <el-menu-item index="3">
            <i class="el-icon-suitcase"></i>
            Event
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'admin-blogs' }">
          <el-menu-item index="4">
            <i class="el-icon-box"></i>
            Blogs
          </el-menu-item>
        </router-link>
        <el-submenu index="5">
          <template #title>
            <i class="el-icon-document"></i>
            Pages
          </template>
          <el-menu-item-group>
            <router-link :to="{ name: 'admin-about-us' }">
              <el-menu-item index="1-1">
                About Us
              </el-menu-item>
            </router-link>
            <router-link :to="{ name: 'admin-privacy-policy' }">
              <el-menu-item index="1-2">
                Privacy Policy
              </el-menu-item>
            </router-link>
            <router-link :to="{ name: 'admin-terms-conditions' }">
              <el-menu-item index="1-3">
                Terms & Conditions
              </el-menu-item>
            </router-link>
            <router-link :to="{ name: 'admin-faq' }">
              <el-menu-item index="1-4">
                FAQS
              </el-menu-item>
            </router-link>

            <router-link :to="{ name: 'admin-safety-center' }">
              <el-menu-item index="1-5">
                Safety Center
              </el-menu-item>
            </router-link>

            <router-link :to="{ name: 'admin-dating-in-corona' }">
              <el-menu-item index="1-6">
                Dating in corona
              </el-menu-item>
            </router-link>

            <router-link :to="{ name: 'admin-safety-tips' }">
              <el-menu-item index="1-7">
                Safety Tips
              </el-menu-item>
            </router-link>

            <router-link :to="{ name: 'admin-additional-resources' }">
              <el-menu-item index="1-8">
                Additional Resources
              </el-menu-item>
            </router-link>
          </el-menu-item-group>
        </el-submenu>
        <router-link :to="{ name: 'admin-feedbacks' }">
          <el-menu-item index="6">
            <i class="el-icon-paperclip"></i>
            Feedbacks
          </el-menu-item>
        </router-link>
        <router-link :to="{ name: 'configuration' }">
          <el-menu-item index="6">
            <i class="el-icon-setting"></i>
            Configuration
          </el-menu-item>
        </router-link>
      </el-menu>
    </div>
</template>

<script>
export default {
  name: 'AppSidebar'
}
</script>
<style>
.sidebar {
  background: #fff;
  min-height: 100%;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.sidebar .el-menu{
  border-right: none !important;
}

.el-menu li {
  border-bottom: 1px solid #ccc;
  font-size: 15px;
}

.el-submenu__title {
  color: #909399 !important;
}
.el-menu li a {
  font-size: 15px;
}
</style>
