<template>
  <Header />
  <Breadcrumb :title="'Mobile Push Notification'" :link="'mobile-push-notification'" />
  <div class="information-ul">
    <div class="container">
      <el-row :gutter="40" >
        <el-col :xs="24" :sm="8" :md="8" :lg="8">
          <div class="sidebar-bg">
            <div class="page-links">
              <ul>
                <li><a href="/#/blocked-profiles"> Blocked Profiles</a></li>
                <li><a href="/#/manage-subscription"> Manage Subscriptions</a></li>
                <li><a href="/#/email-notification"> Email Notifications</a></li>
                <li><a href="/#/site-notification" class="active"> Site Notification</a></li>
                <li><a href="/#/verify-account"> Verify your Account</a></li>
              </ul>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="14" :md="16" :lg="16">
          <div class="page-title">Site Notification</div>
          <div class="other-notifications">
            <div class="main-notification">
              <div class="notify-title">Messages
                <span class="switch-button"> <el-switch @click="onLikesChange" v-model="messages" ></el-switch></span>
              </div>
              <div class="notify-text">
                Be notified when you receive a new message
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import pageMixin from '@/mixins/page'
import Breadcrumb from '@/views/user/_components/Breadcrumb'

import userMixin from '@/mixins/user'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  created () {
    this.getPage({
      slug: 'likes'
    }).then((res) => {
      this.value = res
    })

    this.getMe().then((user) => {
      if (user) {
        this.messages = Boolean(user.setting.message_notification)
      }
    })
  },

  data () {
    return {
      value: null,
      activeStepBar: 0,
      tab: 1,
      messages: true
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    },

    onLikesChange () {
      this.updateUserSetting({
        user_id: this.user.id,
        message_notification: this.messages
      }).catch(() => {
        this.$message({
          message: 'Ops, Something went wrong',
          type: 'warning'
        })
      })
    }
  },

  mixins: [userMixin, pageMixin],

  name: 'SiteNotification'
}
</script>
<style scoped>
.information-ul {
  margin: 0;
  padding: 40px 0;
  background: #fff;
  border-top: 1px solid #ccc;
}
.information-ul .page-title {
  font-size: 24px !important;
  padding-top: 10px;
}

.information-ul p{
  padding: 10px 0;
  line-height: 24px;
}

.sidebar-bg{
  background: #fff;
  padding-bottom: 40px;
}

.page-links{
  margin: 0;
  padding:10px 0 0 0;
}

.page-links ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-links li{
  margin: 0 5px;
  padding: 20px 0 20px 20px;
  list-style: none;
  font-size: 17px;
  border-bottom: 1px solid #ccc;
}

.page-links li a{
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all 0.3s ease-in 0s;
}

.page-links li a:hover{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}

.page-links li a.active{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}

.other-notifications{
  padding: 10px 0 30px 0 ;
}

.main-notification{
  margin: 0;
  padding: 20px 0 20px 0;
}

.notify-title{
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.notify-text{
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 15px;
}

.switch-button{
  float: right;
}

</style>
