import { mapActions, mapGetters } from 'vuex'

const likeComments = {
  computed: {
    ...mapGetters(
      'likeComment',
      {
        totalLikes: 'totalLikes',
        totalComments: 'totalComments',
        comments: 'comments'
      }
    )
  },

  methods: {
    ...mapActions(
      'likeComment',
      [
        'getComments',
        'getTotalLikes',
        'getTotalComments',
        'createComment',
        'createLike'
      ]
    )
  }
}

export default likeComments
