<template>
  <div class="c-blog-form">
    <el-form
      ref="form"
      :model="state.blogData"
      label-width="140px"
    >
      <el-form-item label="Title">
        <el-input
          v-model="state.blogData.title"
        >
        </el-input>
        <span
          class="c-blog-form__error"
          v-if="v$.blogData.title.$error"
        >
          {{ v$.blogData.title.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Parent Blog">
        <el-select
          v-model="state.blogData.parent_id"
          placeholder="Select Parent Blog"
          class="form-control"
        >
          <el-option
            v-for="blog in blogOptions"
            :key="blog.value"
            :label="blog.label"
            :value="blog.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Description">
        <ckeditor
          class="c-blog-form__editor"
          :editor="state.editor"
          :config="state.editorConfig"
          v-model="state.blogData.description"
          tag-name="textarea"
          @input="onEditorInput"
        ></ckeditor>
      </el-form-item>

      <el-form-item label="Slug">
        <el-input
          v-model="state.blogData.slug"
        >
        </el-input>
        <span
          class="c-blog-form__error"
          v-if="v$.blogData.slug.$error"
        >
          {{ v$.blogData.slug.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Image">
        <input
          @input="onImageUpload"
          type="file"
        />
      </el-form-item>

      <el-form-item label="Meta Title">
        <el-input
          v-model="state.blogData.meta_title"
        >
        </el-input>
        <span
          class="c-blog-form__error"
          v-if="v$.blogData.meta_title.$error"
        >
          {{ v$.blogData.meta_title.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Meta Description">
        <el-input
          v-model="state.blogData.meta_description"
        >
        </el-input>
        <span
          class="c-blog-form__error"
          v-if="v$.blogData.meta_description.$error"
        >
          {{ v$.blogData.meta_description.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Meta Keyword">
        <el-input
          v-model="state.blogData.meta_keyword"
        >
        </el-input>
        <span
          class="c-blog-form__error"
          v-if="v$.blogData.meta_keyword.$error"
        >
          {{ v$.blogData.meta_keyword.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item>
        <el-button
          @click="onSubmit"
          type="primary"
          round
        >
          {{ state.blogData.id ? 'Update' : 'Submit' }}
        </el-button>
        <el-button @click="onClose" round>Cancel</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import { reactive, computed } from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import blogMixin from '@/mixins/blog'

export default {
  setup () {
    const state = reactive({
      editor: ClassicEditor,
      editorConfig: {
      },
      blogData: {
        title: '',
        slug: '',
        description: '',
        meta_title: '',
        meta_description: '',
        parent_id: '',
        meta_keyword: '',
        image: ''
      }
    })

    const rules = computed(() => {
      return {
        blogData: {
          title: { required: helpers.withMessage('Title field is required', required) },
          slug: { required: helpers.withMessage('Slug field is required', required) },
          description: { required: helpers.withMessage('Description field is required', required) },
          meta_title: { required: helpers.withMessage('Meta title field is required', required) },
          meta_description: { required: helpers.withMessage('Meta description field is required', required) },
          meta_keyword: { required: helpers.withMessage('Meta keyword field is required', required) }
        }
      }
    })

    const v$ = useValidate(rules, state)

    return {
      state,
      v$
    }
  },

  created () {
    this.getBlogs()
  },

  computed: {
    blogOptions () {
      return this.blogs && this.blogs.map(blog => {
        return {
          label: blog.title,
          value: blog.id
        }
      })
    }
  },

  methods: {
    onEditorInput (value) {
      this.state.blogData.description = value
    },

    onClose () {
      this.$router.push({ name: 'admin-blogs' })
    },

    onImageUpload (e) {
      const files = e.target.files || e.dataTransfer.files

      this.state.blogData.image = files[0]
    },

    onSubmit () {
      this.v$.$validate()
      if (!this.v$.$error) {
        if (this.state.blogData.id) {
          this.updateBlog(this.state.blogData).then(() => {
            this.$message({
              message: 'Blog updated successfully.',
              type: 'success'
            })

            this.$router.push({ name: 'admin-blogs' })
          }).catch(() => {
            this.$message({
              message: 'Oops! Something went wrong.',
              type: 'warning'
            })
          })
        } else {
          this.createBlog(this.state.blogData).then(() => {
            this.$message({
              message: 'Blog created successfully.',
              type: 'success'
            })

            this.$router.push({ name: 'admin-blogs' })
          }).catch(() => {
            this.$message({
              message: 'Oops! Something went wrong.',
              type: 'warning'
            })
          })
        }
      }
    }
  },

  mixins: [blogMixin],

  mounted () {
    if (this.blog) {
      this.state.blogData.id = this.blog.id
      this.state.blogData.title = this.blog.title
      this.state.blogData.slug = this.blog.slug
      this.state.blogData.description = this.blog.description
      this.state.blogData.meta_title = this.blog.meta_title
      this.state.blogData.parent_id = this.blog.parent_id
      this.state.blogData.meta_keyword = this.blog.meta_keyword
      this.state.blogData.image = this.blog.image
    }
  },

  name: 'BlogForm',

  props: {
    blog: {
      required: false
    }
  },

  watch: {
    blog: {
      deep: true,
      handler (newValue) {
        if (newValue) {
          this.state.blogData = newValue
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.c-blog-form {
  margin-top: 1rem;

  &__error {
    color: orangered;
  }

  .el-select {
    width: 100%;
  }

  &__editor {
  }

  .ck-editor__editable {
    min-height: 10rem !important;
  }
}
</style>
