<template>
  <div class="container">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="24">
        <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item> <router-link :to="'/'">Home</router-link></el-breadcrumb-item>
      <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    link: {
      type: String
    }
  }
}
</script>
<style>
.el-breadcrumb {
  padding: 16px 0 !important;
}

.el-breadcrumb .el-breadcrumb__item{
  font-size: 12px;
}
</style>
