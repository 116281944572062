import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  searchData: {
    country: null,
    gender: null,
    age: null,
    height: null,
    religion: null,
    location: null,
    city: null,
    cast: null,
    educational_level_id: null,
    occupation: null,
    name: '',
    education: '',
    username: ''
  }
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state
}
