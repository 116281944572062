<template>
  <Page
    @cta:click="onClick"
    :title="'Events'"
  >
    <template #content>
      <el-table
        :data="getEventsData"
        style="width: 100%"
      >
        <el-table-column
          label="ID"
          prop="id"
          width="100"
        >
        </el-table-column>

        <el-table-column
          label="Title"
          prop="title">
        </el-table-column>

        <el-table-column
          label="Description"
          prop="description">
          <template #default="scope">
            {{ scope.row.description.substring(0,20)+"..." }}
          </template>
        </el-table-column>

        <el-table-column
          label="City"
          prop="city">
        </el-table-column>

        <el-table-column
          label="Time"
          prop="time">
        </el-table-column>

        <el-table-column
          label="Price"
          prop="price">
        </el-table-column>

        <el-table-column
          label="Image"
          prop="image">
          <template #default="scope">
            <img
              :src="imageBaseUrl + scope.row.image"
              width="100"
              v-if="scope.row.image"
            >
          </template>
        </el-table-column>

        <el-table-column
          label="Status"
          prop="status">
          <template #default="scope">
            <el-tag v-if="scope.row.status" type="success">Active</el-tag>
            <el-tag v-else type="warning">Disabled</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          label="Total Visits"
          prop="total_visits">
        </el-table-column>

        <el-table-column
          align="right" width="200">
          <template #default="scope">
            <el-button
              @click="onEventFaqShow(scope.row.id)"
              size="mini"
              type="danger"><i class="el-icon-view"></i>
            </el-button>
            <el-button
              @click="onEdit(scope.row.id)"
              size="mini"><i class="el-icon-edit"></i>
            </el-button>
            <el-button
              @click="onDelete(scope.row.id)"
              size="mini"
              type="danger"><i class="el-icon-delete"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page'

import eventMixin from '@/mixins/event'

export default {
  components: {
    Page
  },

  created () {
    this.getEvents()
  },

  computed: {
    imageBaseUrl () {
      return process.env.VUE_APP_API_IMAGE_PATH
    },

    getEventsData () {
      return this.events && this.events.map(banner => {
        return {
          id: banner.id,
          image: banner.image,
          description: banner.description,
          title: banner.title,
          status: banner.status,
          price: banner.price,
          city: banner.city,
          date: banner.date,
          time: banner.time,
          total_visits: banner.total_visits
        }
      })
    }
  },

  methods: {
    onClick () {
      this.$router.push({ name: 'admin-add-event' })
    },

    onDelete (id) {
      this.$confirm('This will permanently delete the Event. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.deleteEvent(id).then(() => {
          this.getEvents()

          this.$message({
            type: 'success',
            message: 'Event deleted successfully.'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        })
      })
    },

    onEdit (id) {
      this.$router.push({ name: 'admin-edit-event', params: { id: id } })
    },

    onEventFaqShow (id) {
      this.$router.push({ name: 'admin-event-faq', params: { id: id } })
    }
  },

  mixins: [eventMixin],

  name: 'Banner'
}
</script>
