<template>
  <Page
    @cta:click="onClick"
    :title="'Edit Banner'"
    :ctaLabel="'Back'"
    :cta-class="'warning'"
  >
    <template #content>
      <Form
        :banner="banner"
        v-if="banner"
      />
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page'
import Form from './Form'

import bannerMixin from '@/mixins/banner'

export default {
  components: {
    Form,
    Page
  },

  created () {
    this.getBanner(this.$route.params.id).then((res) => {
      this.banner = res
    })
  },

  data () {
    return {
      banner: null
    }
  },

  methods: {
    onClick () {
      this.$router.push({ name: 'admin-banner' })
    }
  },

  mixins: [bannerMixin],

  name: 'EditBanner'
}
</script>
