import { mapActions, mapGetters } from 'vuex'

const users = {
  computed: {
    ...mapGetters(
      'user',
      {
        educationFields: 'educationFields',
        educationLevels: 'educationLevels',
        createdUser: 'createdUser',
        isLoggedIn: 'userToken',
        admin: 'admin',
        users: 'users'
      }
    ),

    user () {
      return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    },

    loggedInUser () {
      return this.user ? this.user : null
    }
  },

  methods: {
    ...mapActions(
      'user',
      [
        'createUserAbout',
        'createUserPersonalRecord',
        'createUserInformation',
        'createPhysicalAppAndLifeStyle',
        'createEducation',
        'createFamilyDetails',
        'getMe',
        'getEducationLevels',
        'getEducationFields',
        'getUser',
        'getUsers',
        'loginUser',
        'deleteUser',
        'logout',
        'toggleStatus',
        'updateProfileImage',
        'updateCoverImage',
        'updatePhysicalAppAndLifeStyle',
        'updateEducation',
        'updateFamilyDetail',
        'updateUserPersonalRecord',
        'connectUser',
        'acceptUser',
        'rejectUser',

        'forgetPassword',
        'resetPassword',

        'resetUserPassword',

        'updateUserViewedCount',
        'getMostViewedUsers',

        'deactivateUser',
        'createUserSummary',
        'updateProfileVisible',
        'addUserInterest',
        'changeEmail',
        'changeName',
        'changeSeekingAge',
        'changeSeekingDistance',
        'blockUser',
        'unblockUser',
        'updateUserSetting',
        'updateSearchHistory',
        'deleteUserGallery',
        'deleteUserInterest',

        'storeUsername'
      ]
    ),

    getEducationLevelById (id) {
      return this.educationLevels && this.educationLevels.find(educationLevel => {
        return educationLevel.id === id
      })
    },

    getEducationFieldById (id) {
      return this.educationFields && this.educationFields.find(educationField => {
        return educationField.id === id
      })
    }
  }
}

export default users
