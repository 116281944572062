<template>
  <Page
    @cta:click="onClick"
    :title="'Edit Event'"
    :ctaLabel="'Back'"
    :cta-class="'warning'"
  >
    <template #content>
      <Form
        :event="event"
        v-if="event"
      />
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page'
import Form from './Form'

import eventMixin from '@/mixins/event'

export default {
  components: {
    Form,
    Page
  },

  created () {
    this.getEvent(this.$route.params.id).then((res) => {
      this.event = res
    })
  },

  data () {
    return {
      event: null
    }
  },

  methods: {
    onClick () {
      this.$router.push({ name: 'admin-event' })
    }
  },

  mixins: [eventMixin],

  name: 'EditEvent'
}
</script>
