import request from '@/utils/request'

const getFeedbacks = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/feedbacks',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createFeedback = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('name', data.name)
    formData.set('email', data.email)
    formData.set('phone', data.phone)
    formData.set('message', data.message)
    formData.set('address', data.address)

    return request({
      url: '/feedback/store',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deleteFeedback = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/banner/delete/' + id,
      method: 'post'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

export default {
  getFeedbacks,
  createFeedback,
  deleteFeedback
}
