import { mapActions, mapGetters } from 'vuex'

const page = {
  computed: {
    ...mapGetters(
      'page',
      {
        page: 'page'
      }
    )
  },

  methods: {
    ...mapActions(
      'page',
      [
        'getPage',
        'createPage'
      ]
    )
  }
}

export default page
