<template>
  <Page
    @cta:click="onClick"
    :title="'Banner'"
  >
    <template #content>
      <el-table
        :data="getBannerData"
        style="width: 100%"
      >
        <el-table-column
          label="ID"
          prop="id"
          width="100"
        >
        </el-table-column>

        <el-table-column
          label="Title"
          prop="title">
        </el-table-column>

        <el-table-column
          label="Description"
          prop="description">
        </el-table-column>

        <el-table-column
          label="Link"
          prop="link">
        </el-table-column>

        <el-table-column
          label="Image"
          prop="image">
          <template #default="scope">
            <img
              :src="imageBaseUrl + scope.row.image"
              width="100"
              v-if="scope.row.image"
            >
          </template>
        </el-table-column>

        <el-table-column
          label="Type"
          prop="type">
          <template #default="scope">
            {{ scope.row.type === 1 ? 'Landing Page' : 'Event' }}
          </template>
        </el-table-column>

        <el-table-column
          label="Status"
          prop="status">
          <template #default="scope">
            <el-tag v-if="scope.row.status" type="success">Active</el-tag>
            <el-tag v-else type="warning">Disabled</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          align="right">
          <template #default="scope">
            <el-button
              @click="onEdit(scope.row.id)"
              size="mini">Edit
            </el-button>
            <el-button
              @click="onDelete(scope.row.id)"
              size="mini"
              type="danger">Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page'

import bannerMixin from '@/mixins/banner'

export default {
  components: {
    Page
  },

  created () {
    // Hits to Mixins
    this.getBanners()
  },

  computed: {
    imageBaseUrl () {
      return process.env.VUE_APP_API_IMAGE_PATH
    },

    getBannerData () {
      return this.banners && this.banners.map(banner => {
        return {
          id: banner.id,
          image: banner.image,
          description: banner.description,
          title: banner.title,
          status: banner.status,
          type: banner.type,
          link: banner.link
        }
      })
    }
  },

  methods: {
    onClick () {
      this.$router.push({ name: 'admin-add-banner' })
    },

    onDelete (id) {
      this.$confirm('This will permanently delete the Banner. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.deleteBanner(id).then(() => {
          this.getBanners()

          this.$message({
            type: 'success',
            message: 'Banner deleted successfully.'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        })
      })
    },

    onEdit (id) {
      this.$router.push({ name: 'admin-edit-banner', params: { id: id } })
    }
  },

  mixins: [bannerMixin],

  name: 'Banner'
}
</script>
