import api from '@/api/blog'

const getBlogs = ({ commit }) => {
  return new Promise((resolve, reject) => {
    api.getBlogs()
      .then((response) => {
        commit(
          'UPDATE_BLOGS',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getBlog = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.getBlog(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createBlog = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createBlog(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateBlog = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateBlog(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deleteBlog = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.deleteBlog(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default {
  getBlog,
  getBlogs,
  createBlog,
  deleteBlog,
  updateBlog
}
