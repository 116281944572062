<template>
  <div class="c-feedback">
    <Header />
    <Breadcrumb :title="'Feedback'" :link="'feedback'" />
    <div class="custom-page">
      <div class="container">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="2" :md="7" :lg="7"></el-col>
          <el-col :xs="24" :sm="12" :md="10" :lg="10">
            <div class="el-card is-always-shadow box-card">
              <div class="el-card__header form-header-bg">
                <div class="card-header">
                  <div class="form-title">Notifications</div>
                </div></div>
              <div class="el-card__body">
                <div class="text item">
                    <el-row :gutter="10">
                      <div class="notification-listing-scrol">
                      <div class="notification-listing">
                        <a
                          @click="onNotificationClick(notification)"
                          :key="index"
                          v-for="(notification, index) in notifications"
                        >
                          <div class="main-notify">
                            <div class="left-col">
                              <img
                                :src="notification.from_user && notification.from_user.image ? (imageBaseUrl + notification.from_user.image) : require('@/assets/images/default-user.png')"
                                width="50"
                                height="45"
                                alt="image"
                              >
                            </div>
                            <div class="right-col">
                              <p>{{ notification.message }}</p>
                              <div class="date-time">
                                {{ notification.created_at }}
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      </div>
                    </el-row>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Breadcrumb from '@/views/user/_components/Breadcrumb'
import Footer from '@/components/Footer'

import notificationMixin from '@/mixins/notification'
import userMixin from '@/mixins/user'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  computed: {
    imageBaseUrl () {
      return process.env.VUE_APP_API_IMAGE_PATH
    }
  },

  created () {
    this.getNotifications(this.user.id)
  },

  methods: {
    onNotificationClick (notification) {
      this.$router.push({ name: 'user-profile', params: { id: notification.from_user_id } })
    }
  },

  mixins: [notificationMixin, userMixin],

  name: 'Notifications'
}
</script>
<style lang="scss">
.c-feedback {
  &__error {
    color: darkred;
  }
}

.el-pages{
  margin: 0;
  padding: 40px 0 0 0;
}

.form-header-bg{
  padding: 30px 20px 10px 20px !important;
}

.form-input{
  padding-bottom: 15px;
}

.textareas{
  border: 2px solid #ccc !important;
}

.form-title{
  font-size: 22px !important;
  font-weight: 700;
  padding-bottom: 10px !important;
  color: #fff;
}

.form-header-bg P{
  font-size:15PX;
}

.fbutton{
  padding: 6px 12px !important;
  width: 130px;
  height: 40px;
}

.notification-listing{
  margin: 0 auto;
  padding: 8px 8px 5px 8px;
  width: 100%;
  background: #fff;
}

.notification-listing .heading{
  font-size: 18px;
  color: #003993;
  font-weight: 700;
  margin-bottom: 15px;
}

.main-notify{
  margin-top: 0px;
  padding: 8px 5px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  display: flex;
}

.main-notify:hover{
  background: #eaebed;
}

.notification-listing .left-col {
  margin: 0 15px 0 0;
  padding: 0;
  width: 42px;
  float: left;
}

.notification-listing .left-col i{
  font-size: 32px;
  background: #fff;
  border: 1px solid #ccc;
}

.notification-listing .right-col {
  margin: 0;
  padding: 0;
  float: right;
}

.notification-listung .right-col p{
  padding: 5px 0 0 0;
  color: #000;
  font-weight:600 !important;
  font-size: 14px;
}

.notification-listing .right-col .date-time{
  padding: 5px 0 0 0;
  color: #003993;
  font-weight:500;
  font-size: 14px;
}

.notification-listing .title {
  margin: 0;
  padding: 5px 0 0 0;
  font-size: 18px;
  font-weight: 500;

}
.notification-listing .description {
  margin: 0;
  padding: 5px 0 0 0;
  font-size: 11px;
  line-height: 16px;
  font-size: 14px;
}

.notification-listing-scrol{
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  height: 600px;
  width: 100%;
  overflow:hidden;
}

.notification-listing-scrol:hover{
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #ededed;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #ededed;
}

</style>
