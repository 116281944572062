// Vuex mutations are very similar to events: each mutation has a string type and a handler.
const UPDATE_EVENT = (state, event) => {
  state.event = event
}

const UPDATE_EVENTS = (state, events) => {
  state.events = events
}

export default {
  UPDATE_EVENT,
  UPDATE_EVENTS
}
