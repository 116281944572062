<template>
  <Header />
  <Breadcrumb :title="'Profile'" :link="'profile'" />
  <div class="profile-ul">
    <div class="container">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="8" :md="8" :lg="8">
          <div class="profile-ul-pro-box">
            <div class="profile-ui-image">
                <img src="@/assets/images/image3.jpg" alt="">
          </div>
            <div class="profile-ui-name">
              Mahesh Shahi
            </div>
            <div class="profile-ui-location">Bhairahawa - Nepal</div>
            <div class="profile-ui-gender">Gender - Male</div>
            <div class="profile-ui-like-count">
              <img src="@/assets/images/heart-active.png" alt=""> <span>8</span>
            </div>
            <div class="profile-ui-lcs-btn">
              <el-button class="lcs-button"><i class="fa fa-heart"></i> Like</el-button>
              <el-button class="lcs-button"><i class="fa fa-comment"></i> Comment</el-button>
              <el-button class="lcs-button"><i class="fa fa-comments"></i> Chat</el-button>
            </div>
            <div class="profile-ui-premium">
              <el-button class="pck-button"><i class="fa fa-certificate"></i> Get Premium</el-button>
            </div>
          </div>
          <div class="profile-gallery">
            <div class="profile-gal-heading">
              <div class="profile-gal-heading"><i class="el-icon-camera-solid"></i> 9 Upload Photos</div>
            </div>
            <div class="profile-gal-box">
              <el-row :gutter="15">
                <el-col :xs="12" :sm="8" :md="8" :lg="8">
              <div class="galbox">
                <img src="@/assets/images/image3.jpg" alt="">
                <ul class="icon">
                  <li><a href="#"><i class="fa fa-plus"></i></a></li>
                </ul>
              </div>
                </el-col>
                <el-col :xs="12" :sm="8" :md="8" :lg="8">
                  <div class="galbox">
                    <img src="@/assets/images/image3.jpg" alt="">
                    <ul class="icon">
                      <li><a href="#"><i class="fa fa-plus"></i></a></li>
                    </ul>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="8" :md="8" :lg="8">
                  <div class="galbox">
                    <img src="@/assets/images/image3.jpg" alt="">
                    <ul class="icon">
                      <li><a href="#"><i class="fa fa-plus"></i></a></li>
                    </ul>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="8" :md="8" :lg="8">
                  <div class="galbox">
                    <img src="@/assets/images/image3.jpg" alt="">
                    <ul class="icon">
                      <li><a href="#"><i class="fa fa-plus"></i></a></li>
                    </ul>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="8" :md="8" :lg="8">
                  <div class="galbox">
                    <img src="@/assets/images/image3.jpg" alt="">
                    <ul class="icon">
                      <li><a href="#"><i class="fa fa-plus"></i></a></li>
                    </ul>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="8" :md="8" :lg="8">
                  <div class="galbox">
                    <img src="@/assets/images/image3.jpg" alt="">
                    <ul class="icon">
                      <li><a href="#"><i class="fa fa-search"></i></a></li>
                    </ul>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="8" :md="8" :lg="8">
                  <div class="galbox">
                    <img src="@/assets/images/image3.jpg" alt="">
                    <ul class="icon">
                      <li><a href="#"><i class="fa fa-plus"></i></a></li>
                    </ul>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="8" :md="8" :lg="8">
                  <div class="galbox">
                    <img src="@/assets/images/image3.jpg" alt="">
                    <ul class="icon">
                      <li><a href="#"><i class="fa fa-plus"></i></a></li>
                    </ul>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="8" :md="8" :lg="8">
                  <div class="galbox">
                    <img src="@/assets/images/image3.jpg" alt="">
                    <ul class="icon">
                      <li><a href="#"><i class="fa fa-plus"></i></a></li>
                    </ul>
                  </div>
                </el-col>
              </el-row>
              <div class="gal-add-photo">
                <el-button class="el-button el-button--default add-button"><i class="el-icon-plus"></i> Add Photo</el-button>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="10" :md="10" :lg="10">
          <div class="info-box">
            <div class="infobox-heading">
              Apperance & Life Style
              <span class="infoplus"><a href=""><i class="fa fa-plus"></i></a></span>
            </div>
            <div class="info-list">
              <el-row :gutter="20">
                <el-col :xs="12" :sm="12" :md="12" :lg="12">
                  <div class="info-list-split">
                    <div class="info-list-icon">
                      <img src="@/assets/images/height-icon.png" alt="">
                    </div>
                    <div class="info-list-text">
                     4.7'
                    </div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12">
                  <div class="info-list-split">
                    <div class="info-list-icon">
                      <img src="@/assets/images/size-icon.png" alt="">
                    </div>
                    <div class="info-list-text">
                      Slim
                    </div>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12">
                  <div class="info-list-split">
                    <div class="info-list-icon">
                      <img src="@/assets/images/skin-icon.png" alt="">
                    </div>
                    <div class="info-list-text">
                      Wheatish
                    </div>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12">
                  <div class="info-list-split">
                    <div class="info-list-icon">
                      <img src="@/assets/images/food-icon.png" alt="">
                    </div>
                    <div class="info-list-text">
                      Non Veg
                    </div>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12">
                  <div class="info-list-split">
                    <div class="info-list-icon">
                      <img src="@/assets/images/smoking-icon.png" alt="">
                    </div>
                    <div class="info-list-text">
                      No
                    </div>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12">
                  <div class="info-list-split">
                    <div class="info-list-icon">
                      <img src="@/assets/images/drinking-icon.png" alt="">
                    </div>
                    <div class="info-list-text">
                      No
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="info-box">
            <div class="infobox-heading">
              Education and Profession
              <span class="infoplus"><a href=""><i class="fa fa-plus"></i></a></span>
            </div>
            <div class="info-list">
              <el-row :gutter="20">
                <el-col :xs="12" :sm="12" :md="12" :lg="12">
                  <div class="info-list-split">
                    <div class="info-list-icon">
                      <img src="@/assets/images/education.png" alt="">
                    </div>
                    <div class="info-list-text">
                      Bachelor
                    </div>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12">
                  <div class="info-list-split">
                    <div class="info-list-icon">
                      <img src="@/assets/images/faculty.png" alt="">
                    </div>
                    <div class="info-list-text">
                     Management
                    </div>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12">
                  <div class="info-list-split">
                    <div class="info-list-icon">
                      <img src="@/assets/images/job.png" alt="">
                    </div>
                    <div class="info-list-text">
                      Accountant
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="info-box">
            <div class="infobox-heading">
              Family Details
              <span class="infoplus"><a href=""><i class="fa fa-plus"></i></a></span>
            </div>
            <div class="info-list">
              <el-row :gutter="20">
                <el-col :xs="12" :sm="12" :md="12" :lg="12">
                  <div class="info-list-split">
                    <div class="info-list-icon">
                      <img src="@/assets/images/status.png" alt="">
                    </div>
                    <div class="info-list-text">
                     Married
                    </div>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12">
                  <div class="info-list-split">
                    <div class="info-list-icon">
                      <img src="@/assets/images/family-size.png" alt="">
                    </div>
                    <div class="info-list-text">
                      8+
                    </div>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12">
                  <div class="info-list-split">
                    <div class="info-list-icon">
                      <img src="@/assets/images/family-type.png" alt="">
                    </div>
                    <div class="info-list-text">
                      Joint
                    </div>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12">
                  <div class="info-list-split">
                    <div class="info-list-icon">
                      <img src="@/assets/images/country.png" alt="">
                    </div>
                    <div class="info-list-text">
                      Nepal
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="info-box">
            <div class="infobox-heading">
              Comments
              <span class="infoplus"><a href=""><i class="fa fa-plus"></i></a></span>
            </div>
            ASsa
          </div>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6">
          <div class="front-ui-connected">
            <div class="ui-header">
              <div class="tag"> People Connected With</div>
            </div>
            <div class="front-ui-conn-list">
              <div class="left-col">
                <img src="@/assets/images/girl.jpg" alt="">
              </div>
              <div class="right-col">
                <div class="title connection-wants-title">
                  <a href="#" class="">Dhruva Thakur</a></div>
                <div class="ui-bar-side">
                  <el-button class="connected"><i class="el-icon-check"></i> Connected</el-button>
                </div>
              </div>
            </div>
            <div class="front-ui-conn-list">
              <div class="left-col">
                <img src="@/assets/images/girl.jpg" alt="">
              </div>
              <div class="right-col">
                <div class="title connection-wants-title">
                  <a href="#" class="">Dhruva Thakur</a></div>
                <div class="ui-bar-side">
                  <el-button class="pending"><i class="el-icon-loading"></i> Pending</el-button>
                </div>
              </div>
            </div>
            <div class="front-ui-conn-list">
              <div class="left-col">
                <img src="@/assets/images/girl.jpg" alt="">
              </div>
              <div class="right-col">
                <div class="title connection-wants-title">
                  <a href="#" class="">Dhruva Thakur</a></div>
                <div class="ui-bar-side">
                  <el-button class="rejected"><i class="el-icon-close"></i> Rejected</el-button>
                </div>
              </div>
            </div>
            <div class="front-ui-conn-list">
              <div class="left-col">
                <img src="@/assets/images/girl.jpg" alt="">
              </div>
              <div class="right-col">
                <div class="title connection-wants-title">
                  <a href="#" class="">Dhruva Thakur</a></div>
                <div class="ui-bar-side">
                  <el-button class="ui-bar-button"><i class="el-icon-check"></i> Connected</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="front-ui-connected">
            <div class="ui-header">
              <div class="tag">
                People Who Wants To Connect
              </div>
            </div>
            <div class="hidden-scroller">
              <div class="front-ui-conn-list">
                <div class="left-col">
                  <img src="@/assets/images/girl.jpg" alt="">
                </div>
                <div class="right-col">
                  <div class="title connection-wants-title">
                    <a href="#" class="">Dhruva Thakur <span class="accepted-smv">Accepted</span></a></div>
                  <div class="ui-bar-side">
                    <el-button class="ui-bar-button"><i class="el-icon-check"></i> Accept</el-button>
                    <el-button class="ui-bar-button"><i class="el-icon-close"></i> Reject</el-button>
                  </div>
                </div>
              </div>
              <div class="front-ui-conn-list">
                <div class="left-col">
                  <img src="@/assets/images/image2.jpg" alt="">
                </div>
                <div class="right-col">
                  <div class="title connection-wants-title">
                    <a href="#" class="">Dhruva Thakur <span class="rejected-smv">Rejected</span></a></div>
                  <div class="ui-bar-side">
                    <el-button class="ui-bar-button"><i class="el-icon-check"></i> Accept</el-button>
                    <el-button class="ui-bar-button"><i class="el-icon-close"></i> Reject</el-button>
                  </div>
                </div>
              </div>
              <div class="front-ui-conn-list">
                <div class="left-col">
                  <img src="@/assets/images/image3.jpg" alt="">
                </div>
                <div class="right-col">
                  <div class="title connection-wants-title">
                    <a href="#" class="">Dhruva Thakur</a></div>
                  <div class="ui-bar-side">
                    <el-button class="ui-bar-button"><i class="el-icon-check"></i> Accept</el-button>
                    <el-button class="ui-bar-button"><i class="el-icon-close"></i> Reject</el-button>
                  </div>
                </div>
              </div>
              <div class="front-ui-conn-list">
                <div class="left-col">
                  <img src="@/assets/images/image3.jpg" alt="">
                </div>
                <div class="right-col">
                  <div class="title connection-wants-title">
                    <a href="#" class="">Dhruva Thakur</a></div>
                  <div class="ui-bar-side">
                    <el-button class="ui-bar-button"><i class="el-icon-check"></i> Accept</el-button>
                    <el-button class="ui-bar-button"><i class="el-icon-close"></i> Reject</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Breadcrumb from '@/views/user/_components/Breadcrumb'

import pageMixin from '@/mixins/page'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  created () {
    this.getPage({
      slug: 'newprofile'
    }).then((res) => {
      this.value = res
    })
  },

  data () {
    return {
      value: null,
      activeStepBar: 0,
      tab: 1
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    }
  },

  mixins: [pageMixin],

  name: 'Register'
}
</script>
<style scoped>
.profile-ul{
  margin: 0;
  padding: 40px 0;
  background: #f3f2ef;
}

.profile-ul-pro-box{
  background: #fff;
  border-radius: 5px;
  padding: 40px 20px 25px 20px;
  text-align: center;
}

.profile-ui-image{
  width: 220px;
  margin: 0 auto;
}

.profile-ui-image img{
  width:100%;
  border-radius: 50%;
  width: 220px;
  height: 220px;
}

.profile-ui-name{
  margin: 0;
  padding: 25px 0 0 0;
  font-size: 24px;
  color: #000;
  font-weight: 700;
}

.profile-ui-location{
  margin: 0;
  padding: 3px 0 0 0;
  font-size: 15px;
  color: #000;
  font-weight: 400;
}

.profile-ui-gender{
  margin: 0;
  padding: 3px 0 0 0;
  font-size: 15px;
  color: #000;
  font-weight: 400;
}

.profile-ui-like-count{
  margin: 0 auto;
  padding: 20px 0 0 0;
  font-size: 20px;
  color: #000;
  line-height: 28px;
  width: 60px;
  display: flex;
  font-weight: 400;
}

.profile-ui-like-count img{
width: 28px;
  margin-right: 8px;
}

.profile-ui-lcs-btn{
  margin: 20px 0 0 0;
  padding: 0;
}

.profile-ui-lcs-btn .lcs-button{
  margin: 0 5px 0 0 !important;
  padding: 0 12px;
  text-align: center;
  border: 1px solid #237eeb;
  color: #237eeb;
  border-radius: 20px;
  background: #ffffff;
  font-size: 15px;
  font-weight: 600;
  min-height: 35px !important;
  transition: all 0.3s ease-in 0s;
}

.profile-ui-lcs-btn .lcs-button:hover{
  background:#237eeb;
  color: #fff;
  border: 1px solid #237eeb;
  transition: all 0.3s ease-in 0s;
}

.profile-ui-premium{
  margin: 25px 0 0 0;
  padding: 0;
}

.profile-ui-premium .pck-button{
  margin: 0 5px 0 0 !important;
  padding: 0 25px;
  text-align: center;
  border: 1px solid #da298e;
  color: #fff;
  border-radius: 20px;
  background: #da298e;
  font-size: 18px;
  font-weight: 500;
  min-height: 45px !important;
  transition: all 0.3s ease-in 0s;
}

.profile-ui-premium .pck-button:hover{
  background:#da298e;
  color: #fff;
  border: 1px solid #da298e;
  transition: all 0.3s ease-in 0s;
}

.profile-gallery{
  background: #fff;
  border-radius: 5px;
  padding: 30px 20px 25px 20px;
  margin: 20px 0 0 0;
  text-align: center;
}

.profile-gal-heading{
  margin: 0;
  padding: 0 0 5px 0;
  font-size: 22px;
  text-align: center;
}

.galbox{
  background: #0a212f;
  font-family: 'Source Sans Pro', sans-serif;
  text-align: center;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  margin: 15px 0 0px 0;
}

.galbox img{
  width: 100%;
  height: auto;
  transition: all .5s ease 0.1s;

}
.galbox:hover img{
  opacity: 0.25;
  filter: blur(3px);
  transform: scale(1.1);

}

.galbox .icon{
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  top: 45%;
  left:50%;
  transition: all .5s ease-in-out;
}

.galbox:hover .icon{
  opacity: 1;
}

.galbox .icon li{
  margin: 0 3px;
  display: inline-block;
}

.galbox .icon li a{
  color: #fff;
  font-size: 14px;
  display: block;
  position: relative;
  transition: all .5s ease 0s;
}

.galbox .icon li a:hover{
  text-shadow: 0 0 7px rgba(0,0,0,0.9);
}

.gal-add-photo{
  margin: 0;
  padding: 20px 0 0 0;
}

.gal-add-photo .add-button{
  margin: 0 5px 0 0 !important;
  padding: 0 15px;
  text-align: center;
  border: 1px solid #da298e;
  color: #fff;
  border-radius: 20px;
  background: #da298e;
  font-size: 12px;
  font-weight: 600;
  min-height: 34px !important;
  transition: all 0.3s ease-in 0s;
}

.gal-add-photo .add-button:hover{
  background:#da298e;
  color: #fff;
  border: 1px solid #da298e;
  transition: all 0.3s ease-in 0s;
}

.info-box{
  margin: 0 0 20px 0;
  padding:20px;
  background: #fff;
  border-radius: 5px;
}

.infobox-heading{
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
}

.infoplus{
  float: right;
}

.info-list{
  padding: 25px 0 0 0;
}

.info-list-split{
  padding:8px 0 5px 0;
  display: flex;
}

.info-list-icon{
  padding: 0;
  width: 40px;
  text-align: center;
}

.info-list-text{
  padding:5px 0 0 10px;
  font-weight: 600;
}

.front-ui-conn-list {
  margin: 0 0 2px 0;
  padding: 12px 15px;
  clear: both;
  display: flex;
  background: #fff;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list:hover{
  box-shadow: 0px 10px 5px rgb(191 182 182 / 60%);
  -moz-box-shadow: 0px 5px 5px rgba(0,0,0,0.6);
  -webkit-box-shadow: 0px 4px 5px rgb(151 149 149 / 60%);
  -o-box-shadow: 0px 10px 5px rgba(0,0,0,0.6);
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .left-col {
  margin: 0 15px  0 0;
  padding: 0;
  width: 55 px;
  float: left;
}

.front-ui-conn-list .right-col {
  margin: 0;
  padding: 0;
  float: left;
}

.front-ui-conn-list .left-col img {
  margin: 0;
  padding: 0;
  width: 55px;
  height: 55px;
}

.front-ui-conn-list .title a {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.front-ui-conn-list .ui-bar-side{
  margin: 0;
  padding: 10px 0 0 0;
}

.front-ui-conn-list .ui-bar-button{
  margin: 0px 5px 0 0 !important;
  padding: 0 10px;
  text-align: center;
  border: 1px solid #237eeb;
  color: #237eeb;
  border-radius: 20px;
  background: #ffffff;
  font-size: 12px;
  font-weight: 600;
  min-height: 28px !important;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .ui-bar-button:hover{
  background:#237eeb;
  color: #fff;
  border: 1px solid #237eeb;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .connected{
  margin: 0 5px 0 0 !important;
  padding: 0 10px;
  text-align: center;
  border: 1px solid #0da749;
  color: #0da749;
  border-radius: 20px;
  background: #ffffff;
  font-size: 12px;
  font-weight: 600;
  min-height: 28px !important;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .connected:hover{
  background:#0da749;
  color: #fff;
  border: 1px solid #0da749;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .pending{
  margin: 0 5px 0 0 !important;
  padding: 0 10px;
  text-align: center;
  border: 1px solid #e66f0e;
  color: #ff5f18;
  border-radius: 20px;
  background: #ffffff;
  font-size: 12px;
  font-weight: 600;
  min-height: 28px !important;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .pending:hover{
  background:#e66f0e;
  color: #fff;
  border: 1px solid #e66f0e;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .rejected{
  margin: 0 5px 0 0 !important;
  padding: 0 10px;
  text-align: center;
  border: 1px solid #f00;
  color: #f00;
  border-radius: 20px;
  background: #ffffff;
  font-size: 12px;
  font-weight: 600;
  min-height: 28px !important;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .rejected:hover{
  background:#f00;
  color: #fff;
  border: 1px solid #f00;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .accepted-smv{
  padding: 2px 3px;
  border: 1px solid #0da749;
  color: #fff;
  border-radius: 20px;
  background: #0da749;
  font-size: 9px;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .rejected-smv{
  padding: 2px 3px;
  border: 1px solid #f00;
  color: #fff;
  border-radius: 20px;
  background: #f00;
  font-size: 9px;
  transition: all 0.3s ease-in 0s;
}

</style>
