const city = state => state.city ? state.city : ''

const advertisements = state => state.advertisements ? state.advertisements : ''

const cities = state => state.cities

const religion = state => state.religion ? state.religion : ''

const religions = state => state.religions

const cast = state => state.cast ? state.cast : ''

const casts = state => state.casts ? state.casts : ''

const education = state => state.education ? state.education : ''

const educations = state => state.educations ? state.educations : ''

const occupation = state => state.occupation ? state.occupation : ''

const occupations = state => state.occupations ? state.occupations : ''

const notifications = state => state.notifications ? state.notifications : ''

const userToken = state => localStorage.getItem('USER_TOKEN')

export default {
  advertisements,
  city,
  cities,
  cast,
  casts,
  religion,
  religions,
  education,
  educations,
  occupation,
  occupations,
  notifications,
  userToken
}
