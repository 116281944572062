<template>
  <Header />
  <Breadcrumb :title="'Package'" :link="'profile'" />
  <div class="package">
    <div class="container">
      <el-row :gutter="25">
        <el-col :xs="24" :sm="16" :md="17" :lg="17">
          <div class="package-box">
            <el-tabs v-model="activeTab">
              <el-tab-pane label="Free Package" name="first" first>
                <div class="subscription-benefit">
                  <el-row :gutter="20">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24">
                      <el-card>
                        <h4>
                          Currently this is free package
                        </h4>
                      </el-card>
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Summary" name="second" second>
                <div class="subscription-benefit">
                  <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="12" :lg="12">
                      <div class="title-left">A benefit of having a subscription</div>
                    </el-col>
                    <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      <div class="title-heading">Premium</div>
                    </el-col>
                    <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      <div class="title-heading">Standard</div>
                    </el-col>
                    <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      <div class="title-heading">Free</div>
                    </el-col>
                  </el-row>
                  <div class="sub-list">
                    <el-row :gutter="20">
                      <el-col :xs="12" :sm="12" :md="12" :lg="12">
                        <div class="sub-heading">Send unlimited likes</div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="sub-list">
                    <el-row :gutter="20">
                      <el-col :xs="12" :sm="12" :md="12" :lg="12">
                        <div class="sub-heading">Message your top picks</div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="sub-list">
                    <el-row :gutter="20">
                      <el-col :xs="12" :sm="12" :md="12" :lg="12">
                        <div class="sub-heading">Unlock conversations with matches</div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                    </el-row>
                  </div>
                  <div class="sub-list">
                    <el-row :gutter="20">
                      <el-col :xs="12" :sm="12" :md="12" :lg="12">
                        <div class="sub-heading">Unlimited Message</div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                    </el-row>
                  </div>
                  <div class="sub-list">
                    <el-row :gutter="20">
                      <el-col :xs="12" :sm="12" :md="12" :lg="12">
                        <div class="sub-heading">Anyone can reply to you</div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                    </el-row>
                  </div>
                  <div class="sub-list">
                    <el-row :gutter="20">
                      <el-col :xs="12" :sm="12" :md="12" :lg="12">
                        <div class="sub-heading">See who likes you</div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                    </el-row>
                  </div>
                  <div class="sub-list">
                    <el-row :gutter="20">
                      <el-col :xs="12" :sm="12" :md="12" :lg="12">
                        <div class="sub-heading">View read receipts</div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                    </el-row>
                  </div>
                  <div class="sub-list">
                    <el-row :gutter="20">
                      <el-col :xs="12" :sm="12" :md="12" :lg="12">
                        <div class="sub-heading">Get a monthly Boost</div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                    </el-row>
                  </div>
                  <div class="sub-list">
                    <el-row :gutter="20">
                      <el-col :xs="12" :sm="12" :md="12" :lg="12">
                        <div class="sub-heading">Enjoy unlimited Rewards</div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                    </el-row>
                  </div>
                  <div class="sub-list">
                    <el-row :gutter="20">
                      <el-col :xs="12" :sm="12" :md="12" :lg="12">
                        <div class="sub-heading">See who's viewed you</div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                    </el-row>
                  </div>
                  <div class="sub-list">
                    <el-row :gutter="20">
                      <el-col :xs="12" :sm="12" :md="12" :lg="12">
                        <div class="sub-heading">Weekly Super Likes</div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                        <div class="list-text"><i class="fa fa-check check"></i></div>
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                      <el-col :xs="4" :sm="4" :md="4" :lg="4">
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Premium Plan" name="second">
                <div class="content-bg">
                <div class="title-small">Choose a Premium Plan to see our flexible payment Option </div>
                <div class="package-price-option">
                  <div class="deals">Best Deal</div>
                  <div class="package-pricing-list">
                  <el-row :gutter="20">
                    <el-col :xs="8" :sm="8" :md="8" :lg="8">
                      <el-radio v-model="radio" label="1" class="radio-btn"><span class="pack-date">12 Months</span></el-radio>
                    </el-col>
                    <el-col :xs="8" :sm="8" :md="8" :lg="8">
                      <div class="packages--price"><span class="pack-old-price">$22.99</span> <span class="pack-new-price">$17.29</span></div>
                      <div class="pack-time">Per Month</div>
                    </el-col>
                    <el-col :xs="8" :sm="8" :md="8" :lg="8">
                     <div class="saving-per"> Save 73%</div>
                    </el-col>
                  </el-row>
                  </div>
                </div>
                <div class="package-price-option">
                  <div class="mostpopular">Most Popular</div>
                  <div class="package-pricing-list">
                    <el-row :gutter="20">
                      <el-col :xs="8" :sm="8" :md="8" :lg="8">
                        <el-radio v-model="radio" label="1" class="radio-btn"><span class="pack-date">6 Months</span></el-radio>
                      </el-col>
                      <el-col :xs="8" :sm="8" :md="8" :lg="8">
                        <div class="packages--price"><span class="pack-old-price">$24.99</span> <span class="pack-new-price">$18.74</span></div>
                        <div class="pack-time">Per Month</div>
                      </el-col>
                      <el-col :xs="8" :sm="8" :md="8" :lg="8">
                        <div class="saving-per"> Save 70%</div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div class="package-price-option">
                  <div class="package-pricing-list">
                    <el-row :gutter="20">
                      <el-col :xs="8" :sm="8" :md="8" :lg="8">
                        <el-radio v-model="radio" label="1" class="radio-btn"><span class="pack-date">3 Months</span></el-radio>
                      </el-col>
                      <el-col :xs="8" :sm="8" :md="8" :lg="8">
                        <div class="packages--price"><span class="pack-old-price">$37.99</span> <span class="pack-new-price">$28.49</span></div>
                        <div class="pack-time">Per Month</div>
                      </el-col>
                      <el-col :xs="8" :sm="8" :md="8" :lg="8">
                        <div class="saving-per"> Save 55%</div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div class="package-btn">
                  <el-button class="pack-btn">Continue</el-button>
                </div>
                <div class="package-plan-box">
                <div class="package-plan-title">Premium plans included these great features </div>
                  <ul>
                    <li><i class="el-icon-check"></i>  See who likes you </li>
                    <li><i class="el-icon-check"></i> Anyone can reply to you </li>
                    <li><i class="el-icon-check"></i> View read receipts </li>
                    <li><i class="el-icon-check"></i> Get a monthly Boost </li>
                    <li><i class="el-icon-check"></i> Enjoy Unlimited Rewards </li>
                    <li><i class="el-icon-check"></i> See who's viewed you </li>
                    <li><span class="includes">+Everything included instandard </span> </li>
                  </ul>
                </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Standard Plan" name="third">
                <div class="content-bg">
                <div class="title-small">Choose a Standard Plan to see our flexible payment Option </div>
                <div class="package-price-option">
                  <div class="deals">Best Deal</div>
                  <div class="package-pricing-list">
                    <el-row :gutter="20">
                      <el-col :xs="8" :sm="8" :md="8" :lg="8">
                        <el-radio v-model="radio" label="1" class="radio-btn"><span class="pack-date">12 Months</span></el-radio>
                      </el-col>
                      <el-col :xs="8" :sm="8" :md="8" :lg="8">
                        <div class="packages--price"><span class="pack-old-price">$20.99</span> <span class="pack-new-price">$15.29</span></div>
                        <div class="pack-time">Per Month</div>
                      </el-col>
                      <el-col :xs="8" :sm="8" :md="8" :lg="8">
                        <div class="saving-per"> Save 73%</div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div class="package-price-option">
                  <div class="mostpopular">Most Popular</div>
                  <div class="package-pricing-list">
                    <el-row :gutter="20">
                      <el-col :xs="8" :sm="8" :md="8" :lg="8">
                        <el-radio v-model="radio" label="1" class="radio-btn"><span class="pack-date">6 Months</span></el-radio>
                      </el-col>
                      <el-col :xs="8" :sm="8" :md="8" :lg="8">
                        <div class="packages--price"><span class="pack-old-price">$22.99</span> <span class="pack-new-price">$16.74</span></div>
                        <div class="pack-time">Per Month</div>
                      </el-col>
                      <el-col :xs="8" :sm="8" :md="8" :lg="8">
                        <div class="saving-per"> Save 70%</div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div class="package-price-option">
                  <div class="package-pricing-list">
                    <el-row :gutter="20">
                      <el-col :xs="8" :sm="8" :md="8" :lg="8">
                        <el-radio v-model="radio" label="1" class="radio-btn"><span class="pack-date">3 Months</span></el-radio>
                      </el-col>
                      <el-col :xs="8" :sm="8" :md="8" :lg="8">
                        <div class="packages--price"><span class="pack-old-price">$35.99</span> <span class="pack-new-price">$26.49</span></div>
                        <div class="pack-time">Per Month</div>
                      </el-col>
                      <el-col :xs="8" :sm="8" :md="8" :lg="8">
                        <div class="saving-per"> Save 55%</div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div class="package-btn">
                  <el-button class="pack-btn">Continue</el-button>
                </div>
                <div class="package-plan-box">
                  <div class="package-plan-title">Premium plans included these great features </div>
                  <ul>
                    <li><i class="el-icon-check"></i>  See who likes you </li>
                    <li><i class="el-icon-check"></i> Anyone can reply to you </li>
                    <li><i class="el-icon-check"></i> View read receipts </li>
                    <li><i class="el-icon-check"></i> Get a monthly Boost </li>
                    <li><i class="el-icon-check"></i> Enjoy Unlimited Rewards </li>
                    <li><i class="el-icon-check"></i> See who's viewed you </li>
                    <li><span class="includes">+Everything included instandard </span> </li>
                  </ul>
                </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
        <el-col :xs="24" :sm="6" :md="7" :lg="7">
           <div class="billing-info">
             <div class="headings"> Billing - Continious Service</div>
             <p>All subscriptions automatically renew until cancelled<br>
             <a href=""> Learn More</a></p>
              <p>Local Sales tax will be added  to your subscription</p>
             <p>*This is the Percentage saving when compared to the full
               monthly price of the shortest term package with similar Power Up </p>
             <p>View non-promotional rates<br>
               Have a promo code?</p>
             <p>Have a Match gift card?</p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Breadcrumb from '@/views/user/_components/Breadcrumb'

import pageMixin from '@/mixins/page'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  created () {
    this.getPage({
      slug: 'newprofile'
    }).then((res) => {
      this.value = res
    })
  },

  data () {
    return {
      value: null,
      activeStepBar: 0,
      tab: 1,
      activeTab: 'first'
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    }
  },

  mixins: [pageMixin],

  name: 'Register'
}
</script>
<style>
.package{
  margin: 0;
  padding: 40px 0;
  background: #f3f2ef;
}

.package .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 0px 30px;
  letter-spacing: -0.5px;
}

.content-bg{
  border: 1px solid #ccc;
  padding: 25px;
  background: #fff;
  border-radius: 10px;
}

.package-box .el-tabs__item {
  padding: 0 25px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 24px !important;
  font-weight: 500;
  position: relative;

}

.package-box .el-tabs__item.is-active {
}

.package-box{
  border-radius: 5px;
  padding: 0px;
}

.package-price-option{
  margin: 20px 0 20px 0;
  background:#fff;
  color: #000;
  border: 1px solid #ccc;
  transition: all 0.3s ease-in 0s;
  border-radius: 20px;
}

.title-small{
  padding: 10px 0;
  font-size: 15px;
}

.package-price-option:hover{
  background:#f2f7fc;
  border: 1px solid #2b3d9d;
  transition: all 0.3s ease-in 0s;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

}

.deals{
  background: #272f6e;
  padding: 6px 5px;
  position:relative;
  border-radius: 5px 5px 5px 0; ;
  font-weight: 800;
  width: 100px;
  text-align: center;
  color: #fff;
}

.mostpopular{
  background:#dc143c;
  padding: 6px 10px;
  position:relative;
  border-radius: 5px 5px 5px 0; ;
  font-weight: 800;
  width: 120px;
  text-align: center;
  color: #fff;

}

.package-pricing-list{
  padding: 30px 20px 20px 20px;
}

.pack-date{
  font-size: 28px;
  color: #33333d;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.package-pricing-list .el-radio__inner{
  border: 1px solid #68686a;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  background-color: #FFF;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 30px;
  margin-top: -7px;
}

.package-pricing-list  .el-radio__label {
  font-size: 14px;
  padding-left: 12px;
}

.packages--price{
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 20px;

}

.pack-old-price{
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 20px;
  text-decoration: line-through;
  color: #888faf;
  font-weight: 500;
}

.pack-new-price{
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 25px;
  color: #2a3b89;
}

.pack-time{
  margin: 0;
  padding: 5px 0 0 0;
  text-align: center;
  color: #4a5173;
}

.saving-per{
  margin: 0;
  padding: 8px 0 0 0;
  font-weight: 700;
  font-size: 25px;
  color: #4645a5;
  text-transform: uppercase;
  text-align: center;
}

.package-btn{
  margin:0;
  padding:10px 0 60px 0px;

}

.package-btn .pack-btn{
  float: right;
  margin:0;
  padding: 12px 40px;
  text-align: center;
  border: 1px solid #237eeb;
  color:#fff;
  border-radius: 20px;
  background:#237eeb;
  font-size: 15px;
  font-weight: 600;
  min-height: 35px;
  transition: all 0.3s ease 0s;
}

.package-btn .pack-btn:hover{
  border: 1px solid #237eeb;
  color:#fff;
  background:#237eeb;
  transition: all 0.3s ease 0s;
}

.package-plan-box{
  margin: 0;
  padding: 0 20px;
}

.package-plan-title{
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.package-plan-box ul{
  margin: 0;
  padding:10px 0;
}

.package-plan-box li{
  margin: 0;
  padding:5px 0 5px 0;
  font-size: 15px;
}

.package-plan-box li i{
  margin: 0;
  padding:0 5px 0 0;
  font-size: 18px;
  color: #3555bc;
  font-weight: 800;
}
.package-plan-box li .includes{
  padding-left: 22px;
}

.subscription-benefit{
  margin: 0;
  padding: 20px 0;
}

.subscription-benefit .title-heading{
  margin: 0;
  padding: 20px 0 10px 0;
  font-size: 17px;
  color: #2a3b89;
  text-align: center;
  font-weight: 600;
}

.subscription-benefit .title-left{
  margin: 0;
  padding: 20px 0 5px 0;
  font-size: 18px;
  color: #2a3b89;
  font-weight: 600;
}

.sub-heading{
  padding: 10px 10px;
  font-size: 15px;
}

.sub-list{
  margin: 1px 0 0 0;
  padding: 12px 10px;
  font-size: 14px;
  background: #fff;
}

.list-text{
  text-align: center;
  padding: 8px 0 0 0;
}

.sub-list .check{
  font-size: 18px;
  font-weight: 500;
  color: #003993;
  text-align: center;
}

.billing-info{
  margin: 0;
  padding: 45px 0;
}

.billing-info .headings{
  margin: 0;
  padding:0 0 10px 0;
  font-size: 18px;
  font-weight: 500;
}

.billing-info p{
  margin: 0;
  padding: 5px 0 15px 0;
  line-height: 24px;
  font-size: 15px;
  color: #605f5f;
}

.billing-info p a{
  margin: 0;
  padding: 5px 0 15px 0;
  line-height: 24px;
  font-size: 15px;
  color: #003993;
  font-style: italic;
}

</style>
