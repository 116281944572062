import { mapActions, mapGetters } from 'vuex'

const blogs = {
  computed: {
    ...mapGetters(
      'blog',
      {
        blogs: 'blogs'
      }
    )
  },

  methods: {
    ...mapActions(
      'blog',
      [
        'getBlogs',
        'getBlog',
        'createBlog',
        'deleteBlog',
        'updateBlog'
      ]
    )
  }
}

export default blogs
