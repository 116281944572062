import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'

import 'vue-notifyjs/themes/default.css'

import VueAxios from 'vue-axios'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import '@/assets/css/styles/style.scss'
import 'vue-select/dist/vue-select.css'
import 'vue-search-select/dist/VueSearchSelect.css'

import CKEditor from '@ckeditor/ckeditor5-vue'

import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas, faHeart, faThumbsUp } from '@fortawesome/free-solid-svg-icons'

import { fab } from '@fortawesome/free-brands-svg-icons'

import { far, faSmile } from '@fortawesome/free-regular-svg-icons'
library.add(far)
library.add(fas)
library.add(faHeart)
library.add(faThumbsUp)
library.add(fab)
library.add(faSmile)
dom.watch()

const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon)

app.use(VueAxios, axios)
app.use(ElementPlus)
app.use(store)
app.use(store)
app.use(router)
app.use(CKEditor)

router.isReady()
  .then(() => app.mount('#app'))
