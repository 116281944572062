import request from '@/utils/request'

const getAdvertisements = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/advertisements',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getAdvertisement = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/admin-ads/' + id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createAdvertisement = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('image', data.image)
    formData.set('title', data.title)
    formData.set('link', data.link)
    formData.set('description', data.description)
    formData.set('position', data.position)
    formData.set('from_date', data.from_date)
    formData.set('to_date', data.to_date)
    formData.set('status', data.status)

    return request({
      url: '/advertisement',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateAdvertisement = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('image', data.image)
    formData.set('title', data.title)
    formData.set('link', data.link)
    formData.set('description', data.description)
    formData.set('position', data.position)
    formData.set('from_date', data.from_date)
    formData.set('to_date', data.to_date)
    formData.set('status', data.status)

    return request({
      url: '/admin-ads/' + data.id,
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deleteAdvertisement = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/admin-ads/delete/' + id,
      method: 'post'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

export default {
  getAdvertisement,
  getAdvertisements,
  createAdvertisement,
  deleteAdvertisement,
  updateAdvertisement
}
