<template>
  <Page
    @cta:click="onClick"
    :title="'Blogs'"
  >
    <template #content>
      <el-table
        :data="getBlogData"
        style="width: 100%"
      >
        <el-table-column
          label="ID"
          prop="id"
          width="100"
        >
        </el-table-column>

        <el-table-column
          label="Title"
          prop="title">
        </el-table-column>

        <el-table-column
          label="Description"
          prop="description">
        </el-table-column>

        <el-table-column
          label="Slug"
          prop="slug">
        </el-table-column>

        <el-table-column
          label="Image"
          prop="image">
          <template #default="scope">
            <img
              :src="imageBaseUrl + scope.row.image"
              width="100"
              v-if="scope.row.image"
            >
          </template>
        </el-table-column>

        <el-table-column
          align="right">
          <template #default="scope">
            <el-button
              @click="onEdit(scope.row.id)"
              size="mini">Edit
            </el-button>
            <el-button
              @click="onDelete(scope.row.id)"
              size="mini"
              type="danger">Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page'

import blogMixin from '@/mixins/blog'

export default {
  components: {
    Page
  },

  created () {
    this.getBlogs()
  },

  computed: {
    imageBaseUrl () {
      return process.env.VUE_APP_API_IMAGE_PATH
    },

    getBlogData () {
      return this.blogs && this.blogs.map(blog => {
        return {
          id: blog.id,
          image: blog.image,
          slug: blog.slug,
          description: blog.description,
          title: blog.title
        }
      })
    }
  },

  methods: {
    onClick () {
      this.$router.push({ name: 'admin-add-blog' })
    },

    onDelete (id) {
      this.$confirm('This will permanently delete the Blog. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.deleteBlog(id).then(() => {
          this.getBlogs()

          this.$message({
            type: 'success',
            message: 'Blog deleted successfully.'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        })
      })
    },

    onEdit (id) {
      this.$router.push({ name: 'admin-edit-blog', params: { id: id } })
    }
  },

  mixins: [blogMixin],

  name: 'Blog'
}
</script>
