import api from '@/api/user'

const createUserInformation = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createUserInformation(data)
      .then((response) => {
        commit(
          'UPDATE_CREATED_USER',
          response.data
        )

        localStorage.setItem('createdUser', JSON.stringify(response.data))

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateSearchHistory = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateSearchHistory(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deleteUserGallery = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.deleteUserGallery(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
const deleteUserInterest = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.deleteUserInterest(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createUserPersonalRecord = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createUserPersonalRecord(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const blockUser = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.blockUser(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const unblockUser = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.unblockUser(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateUserPersonalRecord = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateUserPersonalRecord(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createUserAbout = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createUserAbout(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createUserSummary = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createUserSummary(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createPhysicalAppAndLifeStyle = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createPhysicalAppAndLifeStyle(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updatePhysicalAppAndLifeStyle = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updatePhysicalAppAndLifeStyle(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createEducation = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createEducation(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateEducation = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateEducation(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createFamilyDetails = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createFamilyDetails(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateFamilyDetail = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateFamilyDetail(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateProfileVisible = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateProfileVisible(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const addUserInterest = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.addUserInterest(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getMe = ({ commit }) => {
  return new Promise((resolve, reject) => {
    api.getMe()
      .then((response) => {
        commit(
          'UPDATE_USER',
          response.user
        )

        localStorage.setItem('user', JSON.stringify(response.user))
        resolve(response.user)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const forgetPassword = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.forgetPassword(data)
      .then((response) => {
        resolve(response.user)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const resetPassword = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.resetPassword(data)
      .then((response) => {
        resolve(response.user)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const resetUserPassword = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.resetUserPassword(data)
      .then((response) => {
        resolve(response.user)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getUser = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.getUser(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getEducationFields = ({ commit }) => {
  return new Promise((resolve, reject) => {
    api.getEducationFields()
      .then((response) => {
        commit(
          'UPDATE_EDUCATION_FIELDS',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getEducationLevels = ({ commit }) => {
  return new Promise((resolve, reject) => {
    api.getEducationLevels()
      .then((response) => {
        commit(
          'UPDATE_EDUCATION_LEVELS',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getUsers = ({ commit }) => {
  return new Promise((resolve, reject) => {
    api.getUsers()
      .then((response) => {
        commit(
          'UPDATE_USERS',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deleteUser = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.deleteUser(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getMostViewedUsers = ({ commit }) => {
  return new Promise((resolve, reject) => {
    api.getMostViewedUsers()
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const loginUser = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.loginUser(data)
      .then((response) => {
        commit(
          'UPDATE_USER',
          response.user
        )

        localStorage.removeItem('USER_TOKEN')
        localStorage.setItem('USER_TOKEN', response.tokens.access_token)

        if (response.user.position) {
          localStorage.setItem('admin', JSON.stringify(response.user))
          localStorage.removeItem('user')
        } else {
          localStorage.removeItem('admin')
          localStorage.setItem('user', JSON.stringify(response.user))
        }

        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateProfileImage = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateProfileImage(data)
      .then((response) => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateUserViewedCount = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateUserViewedCount(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateUserSetting = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateUserSetting(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateCoverImage = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateCoverImage(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const logout = ({ commit }, token) => {
  return api.logout()
    .then(() => {
      localStorage.removeItem('user')
      localStorage.removeItem('USER_TOKEN')
    })
}

const toggleStatus = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.toggleStatus(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deactivateUser = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.deactivateUser(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const connectUser = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.connectUser(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const acceptUser = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.acceptUser(data)
      .then((response) => {
        api.getMe().then(response => {
          localStorage.removeItem('user')
          localStorage.setItem('user', JSON.stringify(response.user))
        })

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const rejectUser = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.rejectUser(data)
      .then((response) => {
        api.getMe().then(response => {
          localStorage.setItem('user', JSON.stringify(response.user))
        })

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const changeEmail = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.changeEmail(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const storeUsername = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.storeUsername(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const changeName = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.changeName(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const changeSeekingAge = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.changeSeekingAge(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const changeSeekingDistance = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.changeSeekingDistance(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default {
  createUserAbout,
  createUserPersonalRecord,
  createUserInformation,
  createPhysicalAppAndLifeStyle,
  createEducation,
  createFamilyDetails,
  getMe,
  getEducationFields,
  getEducationLevels,
  getUser,
  getUsers,
  loginUser,
  deleteUser,
  logout,
  toggleStatus,
  updateProfileImage,
  updateCoverImage,
  updatePhysicalAppAndLifeStyle,
  updateEducation,
  updateFamilyDetail,
  updateUserPersonalRecord,
  connectUser,
  acceptUser,
  rejectUser,

  forgetPassword,
  resetPassword,

  resetUserPassword,

  updateUserViewedCount,
  getMostViewedUsers,

  deactivateUser,
  createUserSummary,

  updateProfileVisible,
  addUserInterest,
  changeEmail,
  changeName,
  changeSeekingAge,
  changeSeekingDistance,

  blockUser,
  unblockUser,
  updateUserSetting,
  updateSearchHistory,
  deleteUserGallery,
  deleteUserInterest,

  storeUsername
}
