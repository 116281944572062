<template>
  <div class="c-feedback">
    <Header />
    <Breadcrumb :title="'Feedback'" :link="'feedback'" />
    <div class="custom-page">
      <div class="container">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="2" :md="6" :lg="6"></el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12">
        <div class="el-card is-always-shadow box-card">
          <div class="el-card__header form-header-bg">
            <div class="card-header">
              <div class="form-title">Send us your Feedback!</div>
              <p>Do you have a suggestion or found some bugs?
                Please let us know in the field below.</p>
            </div></div>
          <div class="el-card__body">
            <div class="text item">
              <el-form
                ref="form"
                v-model="state.feedback"
                v-loading="state.loading"
              >
                <el-row :gutter="10">
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <p>Full Name</p>
                    <div class="el-input input form-input">
                      <input
                        class="el-input__inner"
                        type="text"
                        autocomplete="off"
                        v-model="state.feedback.name"
                      >
                      <span
                        class="c-feedback__error"
                        v-if="v$.feedback.name.$error"
                      >
                        {{ v$.feedback.name.$errors[0].$message }}
                      </span>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <p>Email Address</p>
                    <div class="el-input input form-input">
                      <input
                        class="el-input__inner"
                        type="text"
                        autocomplete="off"
                        v-model="state.feedback.email"
                      >
                      <span
                        class="c-feedback__error"
                        v-if="v$.feedback.email.$error"
                      >
                        {{ v$.feedback.email.$errors[0].$message }}
                      </span>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <p>Phone Number</p>
                    <div class="el-input input form-input">
                      <input
                        class="el-input__inner"
                        type="text"
                        autocomplete="off"
                        v-model="state.feedback.phone"
                      >
                      <span
                        class="c-feedback__error"
                        v-if="v$.feedback.phone.$error"
                      >
                        {{ v$.feedback.phone.$errors[0].$message }}
                      </span>
                    </div>
                  </el-col>

                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <p>Address</p>
                    <div class="el-input input form-input">
                      <input
                        class="el-input__inner"
                        type="text"
                        autocomplete="off"
                        v-model="state.feedback.address"
                      >
                      <span
                        class="c-feedback__error"
                        v-if="v$.feedback.address.$error"
                      >
                        {{ v$.feedback.address.$errors[0].$message }}
                      </span>
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24">
                    <p>Message</p>
                    <div class="el-input input form-input">
                     <textarea
                       class="el-textarea__inner textareas"
                       autocomplete="off"
                       style="min-height: 100px;"
                       v-model="state.feedback.message"
                     >
                       </textarea>
                      <span
                        class="c-feedback__error"
                        v-if="v$.feedback.message.$error"
                      >
                        {{ v$.feedback.message.$errors[0].$message }}
                      </span>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="el-input input form-input">
                      <button @click="onSubmit" class="el-button el-button--primary is-round fbutton">
                        <span> Send Feedback </span>
                      </button>
                    </div>
                  </el-col>
                </el-row>
              </el-form>
              </div>
          </div>
        </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { computed, reactive } from 'vue'
import { email, helpers, numeric, required } from '@vuelidate/validators'
import useValidate from '@vuelidate/core'

import Header from '@/components/Header'
import Breadcrumb from '@/views/user/_components/Breadcrumb'
import Footer from '@/components/Footer'

import feedbackMixin from '@/mixins/feedback'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  setup () {
    const state = reactive({
      loading: false,
      feedback: {
        name: '',
        email: '',
        phone: '',
        address: '',
        message: ''
      }
    })

    const rules = computed(() => {
      return {
        feedback: {
          name: { required: helpers.withMessage('Name field is required', required) },
          email: { required: helpers.withMessage('Email field is required', required), email },
          phone: { required: helpers.withMessage('Phone field is required', required), numeric },
          address: { required: helpers.withMessage('Address field is required', required) },
          message: { required: helpers.withMessage('Message field is required', required) }
        }
      }
    })

    const v$ = useValidate(rules, state)

    return {
      state,
      v$
    }
  },

  methods: {
    onSubmit () {
      this.v$.$validate()

      if (!this.v$.$error) {
        this.state.loading = true

        this.createFeedback(this.state.feedback).then((res) => {
          this.$message({
            message: 'Feedback submitted successfully.',
            type: 'success'
          })

          this.state.loading = false

          this.state.feedback = {
            name: '',
            email: '',
            phone: '',
            address: '',
            message: ''
          }
        }).catch((error) => {
          this.state.loading = false

          this.$message({
            message: error.response.data.error ? 'Invalid data' : error.response.data.message,
            type: 'warning'
          })
        })
      }
    }
  },

  mixins: [feedbackMixin],

  name: 'Feedback'
}
</script>
<style lang="scss">
.c-feedback {
  &__error {
    color: darkred;
  }
}

.el-pages{
  margin: 0;
  padding: 40px 0 0 0;
}
.form-header-bg{
  padding: 30px 20px 10px 20px !important;
}
.form-input{
  padding-bottom: 15px;
}
.textareas{
  border: 2px solid #ccc !important;
}
.form-title{
  font-size: 22px !important;
  font-weight: 700;
  padding-bottom: 10px !important;
  color: #fff;
}
.form-header-bg P{
  font-size:15PX;
}
.fbutton{
  padding: 6px 12px !important;
  width: 130px;
  height: 40px;
}
</style>
