<template>
  <Page
    :showButton="false"
    :title="'Feedback'"
    class="c-admin-feedback"
  >
    <template #content>
      <el-table
        :data="feedbacks.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
        style="width: 100%"
      >
        <el-table-column
          label="ID"
          prop="id"
          width="100"
        >
        </el-table-column>
        <el-table-column
          label="Name"
          prop="name">
        </el-table-column>

        <el-table-column
          label="Email"
          prop="email">
        </el-table-column>

        <el-table-column
          label="Phone"
          prop="phone">
        </el-table-column>

        <el-table-column
          label="Address"
          prop="address">
        </el-table-column>

        <el-table-column
          label="Message"
          prop="message">
        </el-table-column>

        <el-table-column
          align="right">
          <template #header>
            <el-input
              v-model="search"
              size="mini"
              placeholder="Type to search"/>
          </template>
          <template #default="scope">
            <el-button
              @click="onDelete(scope.row)"
              size="mini"
              type="danger">Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="c-configuration__pagination">
        <el-pagination
          @next-click="onNextPage"
          @prev-click="onPrevPage"
          @current-change="onPageClick"
          :total="feedbacks && feedbacks.length"
          :page-size="feedbackSize"
          :hide-on-single-page="false"
          background
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page'

import feedbackMixin from '@/mixins/feedback'

export default {
  created () {
    this.getFeedbacks()
  },

  components: {
    Page
  },

  computed: {
    paginatedFeedbacks () {
      const start = (this.state.feedbackPageNumber - 1) * this.state.feedbackSize
      const end = start + this.state.feedbackSize

      return this.feedbacks ? this.feedbacks.slice(start, end) : null
    }
  },

  data () {
    return {
      feedbackPageNumber: 1,
      feedbackSize: 10,
      search: ''
    }
  },

  methods: {
    onNextPage () {
      this.feedbackPageNumber++
    },

    onPrevPage () {
      this.feedbackPageNumber--
    },

    onPageClick (pageNumber) {
      this.feedbackPageNumber = pageNumber
    }
  },

  mixins: [feedbackMixin],

  name: 'AdminFeedback'
}
</script>
