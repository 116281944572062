// Vuex mutations are very similar to events: each mutation has a string type and a handler.
const UPDATE_TOTAL_LIKES = (state, totalLikes) => {
  state.totalLikes = totalLikes
}

const UPDATE_TOTAL_COMMENTS = (state, totalComments) => {
  state.totalComments = totalComments
}

const UPDATE_COMMENTS = (state, comments) => {
  state.comments = comments
}

export default {
  UPDATE_COMMENTS,
  UPDATE_TOTAL_LIKES,
  UPDATE_TOTAL_COMMENTS
}
