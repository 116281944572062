<template>
  <div class="c-physical-app-form">
    <el-form
      ref="form"
      :model="userData"
      v-loading="loading"
      label-width="120px"
    >
      <el-form-item label="Body Type">
        <el-select
          v-model="userData.body_type"
          placeholder="Select Body Type"
          class="form-control"
        >
          <el-option value="Slim/Slender" label="Slim/Slender"></el-option>
          <el-option value="Athletic/Fit" label="Athletic/Fit"></el-option>
          <el-option value="About Average" label="About Average"></el-option>
          <el-option value="Muscular" label="Muscular"></el-option>
          <el-option value="Curvy" label="Curvy"></el-option>
          <el-option value="A few Extra pounds" label="A few Extra pounds"></el-option>
          <el-option value="Big & Beautiful" label="Big & Beautiful"></el-option>
          <el-option value="Heavyset" label="Heavyset"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Skin Type">
        <el-select
          v-model="userData.skin_type"
          placeholder="Select Skin Type"
          class="form-control"
        >
          <el-option value="Fair" label="Fair"></el-option>
          <el-option value="Brown" label="Brown"></el-option>
          <el-option value="Wheatish" label="Wheatish"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Food">
        <el-radio-group v-model="userData.food" size="medium">
          <el-radio border :label="'Veg'">Veg</el-radio>
          <el-radio border :label="'Non Veg'">Non Veg</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="Smoking">
        <el-radio-group v-model="userData.smoking" size="medium">
          <el-radio border :label="'Yes'">Yes</el-radio>
          <el-radio border :label="'No'">No</el-radio>
          <el-radio border :label="'Occasionally'">Occasionally</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="Drinking">
        <el-radio-group v-model="userData.drinking" size="medium">
          <el-radio border :label="'Yes'">Yes</el-radio>
          <el-radio border :label="'No'">No</el-radio>
          <el-radio border :label="'Occasionally'">Occasionally</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button
          @click="onSubmit"
          type="primary"
          round
        >
          {{ userData.id ? 'Update' : 'Submit' }}
        </el-button>
        <el-button @click="onCancel" round>Cancel</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import userMixin from '@/mixins/user'

export default {
  data () {
    return {
      loading: false,
      userData: {
        body_type: '',
        skin_type: '',
        food: '',
        drinking: '',
        smoking: ''
      }
    }
  },

  methods: {
    onCancel () {
      this.$emit('form:close')
    },

    onSubmit () {
      if (this.userData.id) {
        this.loading = true

        this.updatePhysicalAppAndLifeStyle(
          Object.assign(this.userData, { user_id: this.createdUser ? this.createdUser.id : this.user.id })
        ).then((res) => {
          this.$message({
            message: 'Physical Appearance and life style updated successfully.',
            type: 'success'
          })

          this.loading = false

          if (this.user && this.user.id) {
            this.getMe()
          }
          this.$emit('data:update')
        }).catch(() => {
          this.loading = false

          this.$message({
            message: 'Invalid data',
            type: 'warning'
          })
        })
      } else {
        this.loading = true

        this.createPhysicalAppAndLifeStyle(
          Object.assign(this.userData, { user_id: this.createdUser ? this.createdUser.id : this.user.id })
        ).then((res) => {
          this.$message({
            message: 'Physical Appearance and life style created successfully.',
            type: 'success'
          })

          if (this.user && this.user.id) {
            this.getMe()
          }
          this.loading = false

          this.$emit('data:create')
        }).catch(() => {
          this.loading = false

          this.$message({
            message: 'Invalid data',
            type: 'warning'
          })
        })
      }
    }
  },

  mixins: [userMixin],

  mounted () {
    if (this.value) {
      this.userData = this.value
    }
  },

  name: 'PhysicalAppForm',

  props: {
    value: {
      required: false
    }
  }
}
</script>

<style lang="scss">
.c-physical-app-form {
  .el-select {
    width: 100%;
  }
}
</style>
