import request from '@/utils/request'

const getGallery = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/gallery/' + id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createGallery = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('image', data.image)
    formData.set('user_id', data.user_id)

    return request({
      url: '/gallery',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const makeProfile = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('gallery_id', data.gallery_id)
    formData.set('user_id', data.user_id)

    return request({
      url: '/gallery/makeProfile',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

export default {
  createGallery,
  getGallery,
  makeProfile
}
