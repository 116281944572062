import { mapActions, mapGetters } from 'vuex'

const users = {
  computed: {
    ...mapGetters(
      'configuration',
      {
        city: 'city',
        cities: 'cities',
        religion: 'religion',
        religions: 'religions',
        cast: 'cast',
        casts: 'casts',
        education: 'education',
        educations: 'educations',
        occupation: 'occupation',
        occupations: 'occupations'
      },
      {

      }
    )
  },

  methods: {
    ...mapActions(
      'configuration',
      [
        'getCities',
        'getCasts',
        'getReligions',
        'createCity',
        'updateCity',
        'deleteCity',
        'createCast',
        'updateCast',
        'deleteCast',
        'createReligion',
        'updateReligion',
        'deleteReligion',
        'getEducations',
        'createEducation',
        'updateEducation',
        'deleteEducation',
        'getOccupations',
        'createOccupation',
        'updateOccupation',
        'deleteOccupation'
      ]
    ),

    getCityById (id) {
      return this.cities && this.cities.find(city => {
        return city.id === id
      })
    },

    getReligionById (id) {
      return this.religions && this.religions.find(religion => {
        return religion.id === id
      })
    },

    getCastById (id) {
      return this.casts && this.casts.find(cast => {
        return cast.id === id
      })
    }
  }
}

export default users
