import request from '@/utils/request'

const getBanners = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/banners',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getBanner = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/admin-banner/' + id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createBanner = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('image', data.image)
    formData.set('title', data.title)
    formData.set('type', data.type)
    formData.set('description', data.description)
    formData.set('status', data.status)
    formData.set('link', data.link)

    return request({
      url: '/banner',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateBanner = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('image', data.image)
    formData.set('title', data.title)
    formData.set('type', data.type)
    formData.set('description', data.description)
    formData.set('status', data.status)
    formData.set('link', data.link)

    return request({
      url: '/banner/' + data.id,
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deleteBanner = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/banner/delete/' + id,
      method: 'post'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

export default {
  getBanner,
  getBanners,
  createBanner,
  deleteBanner,
  updateBanner
}
