<template>
    <Header />
    <Breadcrumb :title="'About Us'" :link="'about-us'" />
    <div class="custom-page">
      <div class="container">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>About Us</span>
            </div>
          </template>
          <div v-for="o in 1" :key="o" class="text item">
            <div v-html="aboutUs.description" v-if="aboutUs"></div>
          </div>
        </el-card>
      </div>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Breadcrumb from '@/views/user/_components/Breadcrumb'

import pageMixin from '@/mixins/page'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  created () {
    this.getPage({
      slug: 'about_us'
    }).then((res) => {
      this.aboutUs = res
    })
  },

  data () {
    return {
      aboutUs: null,
      activeStepBar: 0,
      tab: 1
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    }
  },

  mixins: [pageMixin],

  name: 'Register'
}
</script>
<style lang="scss">
.custom-page{
  padding: 30px 0 20px 0 !important;
  background: #f3f2ef;
}

.custom-page .card-header span{
  font-size: 18px;
}

.c-register {
  &__form {
    padding: 1rem 10px;
  }

  &__tabs {
    margin-bottom: 1rem;
  }
}
</style>
