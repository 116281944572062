<template>
  <Header />
  <Breadcrumb :title="pageValue" :link="'hindu'" />
  <div class="search-ul-bg">
    <div class="container">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24">
          <div class="search-filter">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="16" :md="12" :lg="12">
                <h4>
                  {{ name }}
                </h4>
              </el-col>
              <el-col :xs="24" :sm="8" :md="12" :lg="12">
                <div class="search-count">Showing {{ filteredUsers && filteredUsers.length }} results</div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24">
          <el-alert
            title="No users found."
            type="warning"
            center
            show-icon
            :closable="false"
            v-if="!filteredUsers.length"
          />
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" v-if="filteredUsers">
          <el-row :gutter="20">
            <el-col
              :xs="24" :sm="8" :md="6" :lg="6"
              :key="index"
              v-for="(user, index) in filteredUsers"
            >
              <div class="profile-user">
                <div class="profile-box">
                  <div class="var-online-part">
                    <div class="var-online">
                      <div class="var-icon">
                        <img src="@/assets/images/verified-icon.png" alt="" v-if="user && user.status">
                      </div>
                      <div class="var-line-icon">
                        <div class="user-online">
                          <div
                            :class="[user.is_logged_in ? 'user-online-show' : 'user-offline-show']"
                            :title="user.is_logged_in ? 'Online' : 'Offline'"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <figure>
                    <a @click="onUserView(user.id)">
                      <img
                        :src="user.image ? imageBasePath + user.image : require('@/assets/images/default-user.png')"
                        alt=""
                        border="0"
                        class="img-responsive"
                        style="max-height: 14.3rem;"
                      >
                    </a>
                  </figure>
                  <a @click="onUserView(user.id)">
                    <div class="main-profile">
                      <div class="profile-info-name">{{ user.username ? user.username : user.name }}</div>
                      <div class="profile-info">
                        <div class="profile-info-left">
                          {{ user.personal_record && user.personal_record.age ? user.personal_record.age : '0'}} -
                          {{ user.personal_record && user.personal_record.city_id ? getCityById(user.personal_record.city_id) ? getCityById(user.personal_record.city_id).name : '' : '' }},
                          {{ user.personal_record && user.personal_record.country ? getName(user.personal_record.country) : getName('NP') }}
                        </div>
                        <div class="profile-info-right">
                          <p class="cam-icon">
                            {{ user.gallery_count && user.gallery_count.length ? user.gallery_count.length : 0 }}
                            <i class="fa fa-camera"></i>
                          </p>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="profile-ux-btn">
                  <div class="profile-ux-btn-left">
                    <a @click="onCommentClick(user.id)">
                      <img src="@/assets/images/comment.png" alt="">
                    </a>
                  </div>
                  <div class="profile-ux-btn-mid">
                    <a
                      @click="onConnectUser(user)"
                      v-if="!checkIfUserExist(user.connect_from_users)"
                    >
                      <img src="@/assets/images/connect.png" alt="" title="Connect">
                    </a>
                    <a v-else-if="checkIfUserPending(user.connect_from_users)">
                      <img src="@/assets/images/connect-pending.png" alt="" title="Pending">
                    </a>
                    <a v-else-if="checkIfUserRejected(user.connect_from_users)">
                      <img src="@/assets/images/connect-rejected.png" alt="" title="Rejected">
                    </a>
                    <a v-else>
                      <img src="@/assets/images/connect-active.png" alt="" title="Connected">
                    </a>
                  </div>
                  <div class="profile-ux-btn-right">
                    <a
                      class="linke-btn"
                      v-if="checkIfUserLiked(user.liked_users)"
                    >
                      <img src="@/assets/images/heart-active.png" alt="">
                    </a>
                    <a
                      @click="onLikeClick(user)"
                      class="linke-btn"
                      v-else>
                      <img src="@/assets/images/heart.png" alt=""></a>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24">
        </el-col>
      </el-row>
  </div>
  </div>

  <CommentPopup
    @action:confirm="onCommentSubmit"
    @popup:close="closeCommentPopup"
    :showPopup="showCommentPopup"
    :title="'Comment'"
    v-if="showCommentPopup"
  >
    <template #content>
      <el-form>
        <el-form-item label="Write Comment">
          <el-input
            type="textarea"
            rows="4"
            v-model="commentData"
          ></el-input>
        </el-form-item>
      </el-form>
    </template>
  </CommentPopup>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Breadcrumb from '@/views/user/_components/Breadcrumb'
import Popup from '@/components/Popup'

import pageMixin from '@/mixins/page'
import userMixin from '@/mixins/user'
import likeCommentMixin from '@/mixins/like_comment'
import configurationMixin from '@/mixins/configuration'

const { getName } = require('country-list')

export default {
  components: {
    Header,
    Footer,
    Breadcrumb,
    CommentPopup: Popup
  },

  created () {
    if (!this.user) {
      this.$router.push({ name: 'user-login' })
    }

    this.pageValue = this.$route.params.value
    this.valueId = this.$route.params.id

    this.getReligions()
    this.getCasts()
    this.getCities()
    this.getUsers().then(() => {
      this.prepareComponent()
    })
  },

  computed: {
    imageBasePath () {
      return process.env.VUE_APP_API_IMAGE_PATH
    }
  },

  data () {
    return {
      pageValue: '',
      commentData: '',
      commentToUserId: '',
      showCommentPopup: false,
      valueId: '',
      filteredUsers: [],
      name: ''
    }
  },

  methods: {
    onCommentClick (commentToUserId) {
      this.showCommentPopup = true
      this.commentToUserId = commentToUserId
    },

    onCommentSubmit () {
      this.createComment({
        from_user_id: this.user.id,
        to_user_id: this.commentToUserId,
        comment: this.commentData
      }).then(() => {
        this.$message({
          message: 'User comment added successfully.',
          type: 'success'
        })

        this.commentData = ''
        this.commentToUserId = ''
        this.showCommentPopup = false
      }).catch(() => {
        this.$message({
          message: 'Ops! Something went wrong.',
          type: 'warning'
        })

        this.commentData = ''
        this.commentToUserId = ''

        this.showCommentPopup = false
      })
    },

    closeCommentPopup () {
      this.showCommentPopup = false
    },

    prepareComponent () {
      if (this.pageValue === 'Religion') {
        this.name = this.getReligionById(parseInt(this.valueId)) ? this.getReligionById(parseInt(this.valueId)).name : ''

        return this.users && this.users.filter(user => {
          if (user.personal_record && user.personal_record.religion_id === parseInt(this.valueId)) {
            this.filteredUsers.push(user)
          }
        })
      }

      if (this.pageValue === 'Cast') {
        this.name = this.getCastById(parseInt(this.valueId)) ? this.getCastById(parseInt(this.valueId)).name : ''

        return this.users && this.users.filter(user => {
          if (user.personal_record && user.personal_record.cast_id === parseInt(this.valueId)) {
            this.filteredUsers.push(user)
          }
        })
      }

      if (this.pageValue === 'City') {
        this.name = this.getCityById(parseInt(this.valueId)) ? this.getCityById(parseInt(this.valueId)).name : ''
        return this.users && this.users.filter(user => {
          if (user.personal_record && user.personal_record.city_id === parseInt(this.valueId)) {
            this.filteredUsers.push(user)
          }
        })
      }
    },

    onNextPage () {
      this.userPageNumber++
    },

    onPrevPage () {
      this.userPageNumber--
    },

    onPageClick (pageNumber) {
      this.userPageNumber = pageNumber
    },

    onSearchClick () {
      this.loading = true

      this.searchUser(this.searchData).then((data) => {
        this.data = data

        this.updateSearchHistory(Object.assign(this.searchData, { user_id: this.user.id }))

        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    onLikeClick (user) {
      this.$confirm(`Are you sure you want to like ${user.name}?`, 'Accept', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(() => {
        this.createLike({
          from_user_id: this.user.id,
          to_user_id: user.id
        }).then(() => {
          this.$message({
            message: 'User Liked Successfully',
            type: 'success'
          })

          this.$emit('user:update')
          this.filteredUsers = []
          this.getUsers().then(() => {
            this.prepareComponent()
          })
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      })
    },

    getName (code) {
      return code ? getName(code) : 'Nepal'
    },

    onConnectUser (user) {
      if (!this.user) {
        this.$router.push({ name: 'user-login' })
        return
      }

      this.$confirm(`Are you sure you want to connect ${user.name}?`, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.connectUser({
          from_id: this.user ? this.user.id : null,
          to_id: user.id
        }).then(() => {
          this.getUsers()
          this.getMe()

          this.$message({
            type: 'success',
            message: 'User connected successfully.'
          })

          this.$router.push({ name: 'user-profile', params: { id: user.id } })
        })
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: 'Opps, Something went wrong.'
        })
      })
    },

    onUserView (id) {
      this.$router.push({ name: 'user-profile', params: { id: id } })
    },

    checkIfUserConnected (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return existingUser && existingUser.pivot.status === 1
      }

      return false
    },

    checkIfUserExist (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return !!existingUser
      }

      return false
    },

    checkIfUserPending (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return existingUser && existingUser.pivot.status === 0
      }

      return false
    },

    checkIfUserRejected (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return existingUser && existingUser.pivot.status === 2
      }

      return false
    },

    checkIfUserLiked (users) {
      if (this.user) {
        const isLiked = users && users.find(user => {
          return user.id === this.user.id
        })

        return !!isLiked
      }

      return false
    }
  },

  mixins: [configurationMixin, likeCommentMixin, userMixin, pageMixin],

  name: 'BrowseByProfile',

  watch: {
    $route (to, from) {
      this.pageValue = this.$route.params.value
      this.valueId = this.$route.params.id

      this.getReligions()
      this.getCasts()
      this.getCities()

      this.filteredUsers = []
      this.getUsers().then(() => {
        this.prepareComponent()
      })
    }
  }
}
</script>
<style>

.ui-banner{
  margin: 0;
  padding: 0;
  width: 100%;
  background: url('~@/assets/images/app-bg.jpg') no-repeat;
  background-position: 100% 0;
  background-size: cover;
  min-height: 485px;
  background-attachment: fixed;
}

.ui-banner .title{
  margin-top: 28%;
  padding: 0;
  font-size:38px;
  color: #fff;
  font-weight: 800;

}

.ui-banner .Subtitle{
  margin: 0;
  padding: 0 0 20px 0;
  font-size:30px;
  color: #fff;
  font-weight: 800;
}

.ui-banner .frontregister{
  margin: 0;
  padding: 20px;
  background: #fff;
  min-height: 485px;
}

.ui-banner .frontregister .el-select {
  width: 100%;
}

.ui-banner .frontregister p{
  padding: 10px 0 0px 0;
}

.ui-banner .frontregister .heading{
  margin: 0;
  padding: 0px;
  font-size: 18px;
}

.ui-banner .frontregister .btns{
  padding: 12px 25px !important;
  font-size: 16px !important;
  margin-top:5px;
}

.ui-banner .frontregister .logwith{
  padding: 15px 0;
  color: #003895;
  font-size: 15px;
  font-weight: 500;
}

.ui-banner-after{
  margin: 0px 0 0px 0;
  padding: 0;
  width: 100%;
  background: url('~@/assets/images/after-login.jpg') no-repeat;
  background-position: 100% 0;
  background-size: cover;
  min-height: 220px;
  background-attachment: fixed;
}

.ui-banner-after img{
  width: 100%;
  margin-bottom: 12px;
}

.ui-banner-after .title{
  padding:30px 0 0 0;
  font-size:26px;
  color: #fff;
  font-weight: 800;

}

.ui-banner-after .Subtitle{
  margin: 0;
  padding: 0 0 20px 0;
  font-size:18px;
  color: #fff;
  font-weight: 400;
}

.front-ul-bg{
  margin: 0;
  padding: 30px 0;
  background: #f3f2ef;
  overflow: hidden;
}

.front-profile{
  margin: 0;
  padding: 0px 0 20px 0;
}

.front-ul-box{
  margin: 0;
  padding: 0;
  background:#fff;
  transition: all 0.3s ease-in 0s;
}

.front-ul-block{
  font-family: 'Raleway', sans-serif;
  overflow: hidden;
  position: relative;
}

.front-ul-block .box-content{
  color: #fff;
  text-align: center;
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 40%;
  left: 0;
  z-index: 2;
  transition: all 0.3s ease-out 0.1s;
}

.front-ul-block .title{
  font-size: 17px;
  font-weight: 700;
  margin: 0;
  line-height: 22px;
}

.front-ul-block .post{
  color: rgba(255,255,255,0.8);
  font-size: 13px;
  font-weight: 500;
  display: block;
}

.front-ul-block .icon{
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  bottom: 40%;
  z-index: 2;
  transition: all 0.3s ease-out 0.1s;
}

.front-ul-block .icon li{
  margin: 0 3px;
  display: inline-block;
}

.front-ul-block .icon li a{
  color: #444;
  background-color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 36px;
  height: 38px;
  width: 35px;
  border-radius: 10px;
  display: block;
  transition: all 0.3s ease 0s;
}

.front-ul-block .icon li a:hover{
  color: #fff;
  background: #12c2e9;
}

.front-ui-likes-btn{
  margin-top:-20px;
  padding: 0;
  text-align: center;
  z-index: 999;
  position: relative;
}

.front-ui-likes-btn img{
  width: 32px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.front-ui-likes-btn .linke-btn{
  margin: 0;
  padding: 8px 6px;
  background: #fff;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 22px;
  color: #fff;
  border-radius: 50%;
  line-height: 40px;
  box-shadow: 0px 10px 5px rgb(0 0 0 / 60%);
  -moz-box-shadow: 0px 10px 5px rgba(0,0,0,0.6);
  -webkit-box-shadow: 0px 4px 5px rgb(116 115 115 / 60%);
  -o-box-shadow: 0px 10px 5px rgba(0,0,0,0.6);
}

.front-ui-details{
  margin: 0;
  padding:20px 0;
  text-align: center;
}

.front-ui-name{
  margin: 0;
  padding:0;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  line-height: 24px;
}

.front-ui-adds{
  margin: 0;
  padding:0;
  font-size: 13px;
  color: #000;
}

.front-ui-cl-btn{
  margin:0;
  padding: 20px 0 20px 0;
  text-align: center;

}

.front-ui-cl-btn i{
  font-weight: 600;
}

.front-ui-cl-btn .view-btn{
  margin:0 5px 0 0 !important;
  padding: 0 15px;
  text-align: center;
  border: 1px solid #000;
  color:#000;
  border-radius: 20px;
  background:#ffffff;
  font-size: 12px;
  font-weight: 600;
  min-height: 35px;
  transition: all 0.3s ease 0s;
}

.front-ui-cl-btn .view-btn:hover{
  border: 1px solid #237eeb;
  color:#fff;
  background:#237eeb;
  transition: all 0.3s ease 0s;
}

.front-ui-cl-btn .connect-btn{
  margin:0 !important;
  padding: 0 15px;
  text-align: center;
  border: 1px solid #000;
  color:#000;
  border-radius: 20px;
  background:#ffffff;
  font-size: 12px;
  font-weight: 600;
  min-height: 35px;
  transition: all 0.3s ease 0s;
}

.front-ui-cl-btn .connected-btn{
  margin:0 !important;
  padding: 0 15px;
  text-align: center;
  border: 1px solid #237eeb;
  color:#fff;
  border-radius: 20px;
  background:#237eeb;
  font-size: 12px;
  font-weight: 600;
  min-height: 35px;
  transition: all 0.3s ease 0s;
}

.front-ui-cl-btn .connected-btn:hover{
  color:#fff;
  background:#237eeb;
  border: 1px solid #237eeb;
  transition: all 0.3s ease 0s;
}

.front-ui-cl-btn .connect-btn:hover{
  border: 1px solid #237eeb;
  color:#fff;
  background:#237eeb;
  transition: all 0.3s ease 0s;
}

.var-online{
  top: 5px;
  padding: 10px;
  z-index: 999;
  width:100%;
}

.var-online-part{
   margin:0;
  padding: 0;
  position: relative;
}

.var-icon{
  margin: 0;
  padding: 0;
  float: left;
}

.var-line-icon{
  margin: 0;
  padding: 0;
  float: right;
}

.user-online{
  float: right;
}

.user-online-show {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 4px solid #fff;
  padding: 7px;
  background: green;
}

.user-offline-show {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 4px solid #fff;
  padding: 7px;
  background:#c4c2c2;
}

.front-ui-connected{
  margin: 0 0 20px 0;
  padding: 0;
}

.front-ui-connected .ui-header{
  margin: 0 0 1px 0;
  padding:12px 0px;
  background: #fff;
  font-size: 16px;
}

.front-ui-connected .ui-header .tag {
  border-left:4px solid #fa6342;
  padding:4px 20px;
}

.front-ui-conn-list {
  margin: 0 0 2px 0;
  padding: 12px 15px;
  clear: both;
  display: flex;
  background: #fff;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list:hover{
  box-shadow: 0px 10px 5px rgb(191 182 182 / 60%);
  -moz-box-shadow: 0px 5px 5px rgba(0,0,0,0.6);
  -webkit-box-shadow: 0px 4px 5px rgb(151 149 149 / 60%);
  -o-box-shadow: 0px 10px 5px rgba(0,0,0,0.6);
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .left-col {
  margin: 0 15px  0 0;
  padding: 0;
  width: 55px;
  float: left;
}

.front-ui-conn-list .right-col {
  margin: 0;
  padding: 0;
  float: left;
}

.front-ui-conn-list .left-col img {
  margin: 0;
  padding: 0;
  width: 55px;
  height: 55px;
}

.front-ui-conn-list .title a {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.front-ui-conn-list .ui-bar-side{
  margin: 0;
  padding: 5px 0 0 0;
}

.front-ui-conn-list .ui-bar-button{
  margin: 0 5px 0 0 !important;
  padding: 0 10px;
  text-align: center;
  border: 1px solid #237eeb;
  color: #237eeb;
  border-radius: 20px;
  background: #ffffff;
  font-size: 12px;
  font-weight: 600;
  min-height: 28px !important;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .ui-bar-button:hover{
  background:#237eeb;
  color: #fff;
  border: 1px solid #237eeb;
  transition: all 0.3s ease-in 0s;
}

.front-ui-work{
  margin: 0px;
  padding: 50px 0px;
  text-align: center;
}

.front-ui-work-title {
  text-align: center;
}

.front-ui-work h2 {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 30px;
  text-align: center;
  color: #000a93;
}

.front-ui-work .work-block{
  margin: 0;
  padding: 30px 0 0 0;
}

.front-ui-work .work-block .box{
  margin: 0;
  padding: 20px;
  text-align: center;
  background: #fff;
  border-radius: 20px;
  text-align: center;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;

}

.front-ui-work .work-block .box .title{
  margin: 0;
  padding: 15px 0 5px 0;
  font-size: 22px;
  font-weight: 600;
}

.front-ui-work .work-block .icons{
  margin: 0;
  padding: 0;
  font-size: 52px;
  font-weight: 600;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  line-height: 100px;
  color: #ffffff;
  background:#dc143c;
}

.front-ui-work .work-block .box li{
  margin: 0;
  padding:0;
  line-height: 20px;
}

.front-ui-work .ui-work-btn{
  margin:20px 0 0 0 !important;
  padding: 0 15px;
  text-align: center;
  border: 1px solid #003993;
  color:#fff;
  border-radius: 20px;
  background:#003993;
  font-size: 12px;
  font-weight: 600;
  min-height: 35px;
}

.front-ui-work .ui-work-btn:hover{
  color:#fff;
  background:#003993;
  border: 1px solid #003993;
}

.front-ui-work .ui-work-btn:focus {
  color:#fff;
  background:#003993;
  border: 1px solid #003993;
}

.feedback-section{
  margin: 0;
  padding: 40px 0;
  background: #f3f2ef;
}

.feed-text{
  margin: 0;
  padding: 20px 0 0 0;
  background: #f3f2ef;
}

.feed-text h3{
  margin: 0;
  padding: 20px 0;
  text-align: right;
  font-size: 24px;
}

.feed-text p{
  margin: 0;
  padding: 20px 0;
  text-align: right;
  font-size: 18px;
}

.feedback-section .ui-feed-btn {
  margin: 20px 0 0 0 !important;
  padding: 0 15px;
  text-align: center;
  border: 1px solid #237eeb;
  color: #fff;
  border-radius: 20px;
  background: #237eeb;
  font-size: 12px;
  font-weight: 600;
  min-height: 35px;
}

.profile-box {
  color: #fff;
  margin: 0;
  padding: 0;
}

.profile-user{
  margin: 0;
  padding:0 0 35px 0;
}

.main-profile{
  position: relative;
  margin-top: -70px;
  background: linear-gradient(rgba(0,130,170,0),rgb(40 40 40));
  border-radius: 0 0 20px 20px;
  min-height: 70px;
}

.profile-user figure img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  width: 100%;
  object-fit: cover;
  height:240px;
}

.profile-user figure:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.profile-user figure {
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
  border-radius: 20px;
}

.profile-user figure:hover+span {
  opacity: 1;
  z-index:999;
  color: #fff;
  font-size:15px;
  font-family: 'Open Sans', sans-serif;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  font-weight:500;
}

.profile-user .profile-box span {
  position: relative;
  bottom:80px;
  display: block;
  margin: 0;
  padding: 5px 0 10px 6px;
  color: #fff;
  font-size:15px;
  text-decoration: none;
  -webkit-transition: .3s ease-in-out;
  font-family: 'Open Sans', sans-serif;
  transition: .3s ease-in-out;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  font-weight:500;
  background: linear-gradient(rgba(0,130,170,0),rgb(40 40 40));
  border-radius: 0 0 20px 20px;
  min-height: 80px;
}

.exprience-content-main p{
  font-size: 13px;
  text-align: justify;
  color: #fff;
  line-height: 18px;
  font-family: 'Open Sans', sans-serif;
}

.exprience-content p{
  font-size: 13px;
  text-align: justify;
  color: #000;
  line-height: 18px;
  font-family: 'Open Sans', sans-serif;
}

.profile-info-name{
  margin: 0;
  padding: 8px 8px 3px 8px;
  font-weight: 700;
  font-size: 18px;
}

.profile-info{
  margin: 0;
  padding:0px 8px;
}

.profile-info-left{
  margin: 0;
  padding: 0;
  width: 82%;
  float: left;
  letter-spacing: -0.4px;
  font-size: 14px;
}

.profile-info-right{
  margin: 0;
  padding: 0;
  float: right;
  text-align: right;
}

.cam-icon{
margin: 0;
  padding: 0 8px 0 0;
  font-size: 16px;
}

.profile-ux-btn{
  margin: 0 auto;
  padding: 12px 0 20px 0;
  width: 70%;
}

.profile-ux-btn-left{
  float: left;
  width: 33%;
  text-align: center;
}

.profile-ux-btn-mid{
  float: left;
  width: 33%;
  text-align: center;
}

.profile-ux-btn-right{
  float: left;
  width: 33%;
  text-align: center;
}

.profile-ux-btn-left img{
  width: 25px;

}

.profile-ux-btn-mid img{
  width: 35px;
}

.profile-ux-btn-right img{
  width: 25px;
}

.profile-ux-btn .fa{
  margin: 0;
  padding: 0px 20px;
}

.profile-ux-btn .unlike{
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 25px;
}

.profile-ux-btn .liked{
  margin: 0;
  padding: 0;
  color: #f00;
  font-size: 25px;
}

.profile-ux-btn .connect{
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 25px;
}

.boost-option{
  margin: 0 0 30px 0;
  padding: 0;
  border-radius: 20px;
  border: 2px solid #ccc;
  background: #fff;
  text-align: center;
  transition: all 0.3s ease-in 0s;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 240px;
}

.boost-option:hover{
  border: 2px solid #f22876;
  transition: all 0.3s ease-in 0s;
}

.boost-option-icon{
  margin: 0;
  padding: 40px 0 10px 0;
  font-size: 25px;
  font-weight: 600;
  transition: all 0.3s ease-in 0s;
}

.boost-option-icon:hover{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}

.boost-text-option{
  margin: 0;
  padding: 0;
  font-size: 24px;
  transition: all 0.3s ease-in 0s;
}

.boost-text-option:hover{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}

.boost-text-search{
  margin: 0;
  padding: 15px 40px;
  font-size: 16px;
  color: #000;
}

@media screen and (min-width: 481px) and (max-width:600px) {
  .profile-user figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    width: 100%;
    object-fit: cover;
    height: 320px;
  }
}

@media screen and (min-width: 601px) and (max-width:767px) {
  .profile-user figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    width: 100%;
    object-fit: cover;
    height: 380px;
  }
}

.el-carousel__item img{
  font-size: 18px;
  margin: 0;
  width: 100%;
  height: auto;
}

</style>
