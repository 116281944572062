import api from '@/api/gallery'

const getGallery = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.getGallery(id)
      .then((response) => {
        commit(
          'UPDATE_GALLERY',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createGallery = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createGallery(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const makeProfile = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.makeProfile(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default {
  getGallery,
  createGallery,
  makeProfile
}
