<template>
  <Header />
  <Breadcrumb :title="'Payment'" :link="'payment'" />
  <div class="payment-option">
    <div class="container">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="14" :md="14" :lg="14">
          <div class="payment-info-box">
            <el-tabs v-model="activeTab">
              <el-tab-pane label="Credit Card" name="first">
                  <div class="payment-title">Payment Information</div>
                  <div class="card-form">
                    <el-form>
                      <el-row :gutter="10">
                        <el-col :xs="24" :sm="24" :md="24" :lg="24">
                          <div class="el-input input form-input">
                            <label>Full Name</label>
                            <input
                              class="el-input__inner"
                              type="text"
                              autocomplete="off">
                          </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="24">
                          <div class="el-input input form-input">
                            <label>Credit Card Number</label>
                            <input
                              class="el-input__inner"
                              type="text"
                              autocomplete="off">
                          </div>
                        </el-col>
                        <el-col :xs="14" :sm="8" :md="8" :lg="8">
                          <div class="el-input input form-input">
                            <label>CVV*</label>
                            <input
                              class="el-input__inner"
                              type="text"
                              autocomplete="off">
                          </div>
                        </el-col>
                        <el-col :xs="14" :sm="14" :md="14" :lg="14">
                          <div class="el-input input form-input">
                          <label>Expiration Date</label>
                          <el-row :gutter="10">
                            <el-col :xs="24" :sm="16" :md="16" :lg="16">
                              <el-select clearable placeholder="Month">
                                <el-option
                                  v-for="item in options"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                                </el-option>
                              </el-select>
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8" :lg="8">
                              <el-select clearable placeholder="Year">
                                <el-option
                                  v-for="item in options"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                                </el-option>
                              </el-select>
                            </el-col>
                          </el-row>
                          </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="24">
                            <el-button class="card-pay-btn">Pay Now</el-button>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="24">
                          <div class="payment-terms">
                            <el-checkbox>By clicking on 'Pay Now' I agree to all terms and condoitions </el-checkbox>
                          </div>
                        </el-col>
                      </el-row>
                    </el-form>
                  </div>
              </el-tab-pane>
              <el-tab-pane label="Paypal" name="second">
                <div class="payment-title">Payment Information</div>
                <div class="card-form">
                  <el-form>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="24" :md="24" :lg="24">
                        <el-button class="card-pay-btn">Pay with Paypal</el-button>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="24" :lg="24">
                        <div class="payment-terms">
                          <el-checkbox>By clicking on 'Process Now' I agree to all terms and condoitions </el-checkbox>
                        </div>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
        <el-col :xs="24" :sm="10" :md="10" :lg="10">
          <div class="order-box">
          <div class="payment-title"> Order Information</div>
          <div class="order-list">
            <el-row :gutter="10">
              <el-col :xs="18" :sm="18" :md="18" :lg="18">
                <iv class="order-heading">Package</iv>
              </el-col>
              <el-col :xs="6" :sm="6" :md="6" :lg="6">
                <iv class="order-heading">Sub Total</iv>
              </el-col>
            </el-row>
          </div>
          <div class="order-list">
            <el-row :gutter="10">
              <el-col :xs="18" :sm="18" :md="18" :lg="18">
               <div class="order-item">12 Months - Subscription</div>
              </el-col>
              <el-col :xs="6" :sm="6" :md="6" :lg="6">
                <div class="order-item">$17.29</div>
              </el-col>
            </el-row>
          </div>
          <div class="order-list">
            <el-row :gutter="10">
              <el-col :xs="18" :sm="18" :md="18" :lg="18">
                <div class="order-item">Tax(13%)</div>
              </el-col>
              <el-col :xs="6" :sm="6" :md="6" :lg="6">
                <div class="order-item">$1.5</div>
              </el-col>
            </el-row>
          </div>
            <div class="order-list">
              <el-row :gutter="10">
                <el-col :xs="18" :sm="18" :md="18" :lg="18">
                  <div class="item-total">Total Amount</div>
                </el-col>
                <el-col :xs="6" :sm="6" :md="6" :lg="6">
                  <div class="item-total">$17.29</div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Breadcrumb from '@/views/user/_components/Breadcrumb'

import pageMixin from '@/mixins/page'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  created () {
    this.getPage({
      slug: 'newprofile'
    }).then((res) => {
      this.value = res
    })
  },

  data () {
    return {
      value: null,
      activeStepBar: 0,
      tab: 1,
      activeTab: 'first'
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    }
  },

  mixins: [pageMixin],

  name: 'Register'
}
</script>
<style>
.payment-option{
  margin: 0;
  padding: 40px 0;
  background: #eee;
}

.payment-info-box{
  background: #fff;
  padding: 30px 50px;
  min-height: 295px;
}

.package .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 0px 30px;
  letter-spacing: -0.5px;
}

.payment-title{
  margin: 0;
  padding: 15px 0 25px 0;
  font-size: 20px;
  font-weight: 500;
}

.card-form label{
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.card-form .el-input__inner{
  height: 45px;
  margin-top: 0 !important;
}

.card-form .card-pay-btn {
  margin: 10px 0px !important;
  padding: 14px 0 14px 0;
  text-align: center;
  border: 1px solid #237eeb;
  color: #fff;
  border-radius:5px;
  background: #237eeb;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}

.card-form .card-pay-btn:hover{
  color: #fff;
  background: #237eeb;
}

.payment-terms{
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 13px;
}

.card-form .el-checkbox__inner{
  border: 2px solid #ccc;
}
.order-box{
  background: #fff;
  padding: 25px;
}

.order-list{
  margin:1px 0 0 0;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.order-heading{
  margin: 0;
  padding: 0 0 20px 0;
  font-size: 18px;
  font-weight: 500;
}

.order-item{
  margin: 0;
  padding: 5px 0 0 0;
  font-size: 15px;
}

.item-total{
  margin: 0;
  padding: 5px 0 0 0;
  font-size: 16px;
  font-weight: 500;
}

</style>
