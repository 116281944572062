<template>
  <Header/>
  <Breadcrumb :title="'Search'" :link="'search'" />
  <div class="user-profile-list">
    <div class="container">
      <div class="Filter-view">
        <el-form class="filter-form">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24">
              <el-card class="box-card profile-viewed">
                <el-row :gutter="10">
                  <el-col :xs="24" :sm="8" :md="6" :lg="4">
                    <label>I'm looking for a</label>
                    <el-select
                      :placeholder="'Please select Gender'"
                      v-model="searchData.gender"
                      filterable
                    >
                      <el-option value="null" label="Please Select Gender">Please Select Gender</el-option>
                      <el-option label="Male" value="1"></el-option>
                      <el-option label="Female" value="0"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="8" :md="6" :lg="4">
                    <label>Age (in Years)</label>
                    <el-row :gutter="20">
                      <el-col :xs="24" :sm="24" :md="24" :lg="24">
                        <el-select
                          placeholder="Please select Age"
                          v-model="searchData.age"
                          filterable
                        >
                          <el-option value="null" label="Please Select Age">Please Select Age</el-option>
                          <el-option label="18" value="18"></el-option>
                          <el-option label="19" value="19"></el-option>
                          <el-option label="20" value="20"></el-option>
                          <el-option label="21" value="21"></el-option>
                          <el-option label="22" value="22"></el-option>
                          <el-option label="23" value="23"></el-option>
                          <el-option label="24" value="24"></el-option>
                          <el-option label="25" value="25"></el-option>
                          <el-option label="26" value="26"></el-option>
                          <el-option label="27" value="27"></el-option>
                          <el-option label="28" value="28"></el-option>
                          <el-option label="29" value="29"></el-option>
                          <el-option label="30" value="30"></el-option>
                          <el-option label="31" value="31"></el-option>
                          <el-option label="32" value="32"></el-option>
                          <el-option label="33" value="33"></el-option>
                          <el-option label="34" value="34"></el-option>
                          <el-option label="35" value="35"></el-option>
                          <el-option label="36" value="36"></el-option>
                          <el-option label="37" value="37"></el-option>
                          <el-option label="38" value="38"></el-option>
                          <el-option label="39" value="39"></el-option>
                          <el-option label="40" value="40"></el-option>
                          <el-option label="41" value="41"></el-option>
                          <el-option label="42" value="42"></el-option>
                          <el-option label="43" value="43"></el-option>
                          <el-option label="44" value="44"></el-option>
                          <el-option label="45" value="45"></el-option>
                          <el-option label="46" value="46"></el-option>
                          <el-option label="47" value="47"></el-option>
                          <el-option label="48" value="48"></el-option>
                          <el-option label="49" value="49"></el-option>
                          <el-option label="50" value="50"></el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :xs="24" :sm="8" :md="6" :lg="4">
                    <label>Height</label>
                    <el-row :gutter="20">
                      <el-col :xs="24" :sm="24" :md="24" :lg="24">
                        <el-select
                          placeholder="Please select Height"
                          v-model="searchData.height"
                          filterable
                        >
                          <el-option value="null" label="Please Select Height">Please Select Height</el-option>
                          <el-option label="below 4.5'" value="4.5"></el-option>
                          <el-option label="4.6'" value="4.6"></el-option>
                          <el-option label="4.7'" value="4.7"></el-option>
                          <el-option label="4.8'" value="4.8"></el-option>
                          <el-option label="4.9'" value="4.9"></el-option>
                          <el-option label="4.10'" value="4.10"></el-option>
                          <el-option label="4.11'" value="4.11"></el-option>
                          <el-option label="5'" value="5"></el-option>
                          <el-option label="5.1'" value="5.1"></el-option>
                          <el-option label="5.2'" value="5.2"></el-option>
                          <el-option label="5.3'" value="5.3"></el-option>
                          <el-option label="5.4'" value="5.4"></el-option>
                          <el-option label="5.5'" value="5.5"></el-option>
                          <el-option label="5.6'" value="5.6"></el-option>
                          <el-option label="5.7'" value="5.7"></el-option>
                          <el-option label="5.8'" value="5.8"></el-option>
                          <el-option label="5.9'" value="5.9"></el-option>
                          <el-option label="5.10'" value="5.10"></el-option>
                          <el-option label="5.11'" value="5.11"></el-option>
                          <el-option label="6'" value="6"></el-option>
                          <el-option label="6.1'" value="6.1"></el-option>
                          <el-option label="6.2'" value="6.2"></el-option>
                          <el-option label="6.3'" value="6.3"></el-option>
                          <el-option label="6.4'" value="6.4"></el-option>
                          <el-option label="6.5'" value="6.5"></el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :xs="24" :sm="8" :md="6" :lg="4">
                    <label>Religion</label>
                    <el-select
                      placeholder="Please Select Religion"
                      v-model="searchData.religion"
                      filterable
                    >
                      <el-option value="null" label="Please Select Religion">Please Select Religion</el-option>
                      <el-option
                        v-for="religion in religions"
                        :key="religion.id"
                        :label="religion.name"
                        :value="religion.id">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="8" :md="6" :lg="4">
                    <label>City</label>
                    <el-select
                      placeholder="Please Select City"
                      v-model="searchData.city"
                      filterable
                    >
                      <el-option value="null" label="Please Select City">Please Select City</el-option>
                      <el-option
                        v-for="city in cities"
                        :key="city.id"
                        :label="city.name"
                        :value="city.id">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="8" :md="6" :lg="4">
                    <label>Cast</label>
                    <el-select
                      placeholder="Please Select Cast"
                      v-model="searchData.cast"
                      filterable
                    >
                      <el-option value="null" label="Please Select Cast">Please Select Cast</el-option>
                      <el-option
                        v-for="cast in casts"
                        :key="cast.id"
                        :label="cast.name"
                        :value="cast.id">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="8" :md="6" :lg="4">
                    <label>Country</label>
                    <el-select
                      placeholder="Please Select Country"
                      v-model="searchData.country"
                      filterable
                    >
                      <el-option value="null" label="Please Select Occupation">Please Select Country</el-option>
                      <el-option
                        v-for="(country, index) in countryOption"
                        :key="index"
                        :label="country.label"
                        :value="country.value">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="8" :md="6" :lg="4">
                    <label>Location</label>
                    <el-input
                      placeholder="Enter location"
                      v-model="searchData.location"
                    ></el-input>
                  </el-col>

                  <el-col :xs="24" :sm="8" :md="6" :lg="4">
                    <label>Search By Keyword</label>
                    <el-input
                      placeholder="Enter keyword"
                      v-model="searchData.name"
                    ></el-input>
                  </el-col>

                  <el-col :xs="24" :sm="8" :md="6" :lg="4">
                    <label>Search By Education</label>
                    <el-select
                      placeholder="Please Select Education"
                      v-model="searchData.education"
                      filterable
                    >
                      <el-option value="null" label="Please Select Cast">Please Select Education</el-option>
                      <el-option
                        v-for="education in educationOptions"
                        :key="education.id"
                        :label="education.educational_level"
                        :value="education.educational_level"
                      >
                      </el-option>
                      <el-option
                        v-for="education in educationOptions"
                        :key="education.id"
                        :label="education.educational_field"
                        :value="education.educational_field"
                      >
                      </el-option>
                    </el-select>
                  </el-col>

                  <el-col :xs="24" :sm="8" :md="6" :lg="4">
                    <label>Search By Username</label>
                    <el-input
                      placeholder="Enter username"
                      v-model="searchData.username"
                    ></el-input>
                  </el-col>
                </el-row>
                <el-row :gutter="30">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12">
                    <el-button
                      @click="onSearchClick"
                      type="primary"
                      round
                    >
                      Search
                    </el-button>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-row :gutter="24">
        <el-col :xs="24" :sm="16" :md="16" :lg="24">
          <el-card
            class="box-card vp-head"
            v-if="!data"
          >
            <template #header>
              <div class="card-header">
                <span class="card-title">Searched Users</span>
              </div>
            </template>
            <el-alert
              title="Please select search fields to view users"
              type="warning"
              :closable="false"
            >
            </el-alert>
          </el-card>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="24">
              <el-alert
                title="No user found."
                type="warning"
                center
                show-icon
                :closable="false"
                v-if="paginatedUsers && !paginatedUsers.length"
              />
            </el-col>
          </el-row>
          <code-loader
            :speed="2"
            :animate="true"
            :primary-color="'red'"
            :primary-opacity="0.5"
            viewBox="0 0 300 150"
            v-if="loading"
          >
          </code-loader>
          <el-row :gutter="10" v-else>
            <el-col
              :xs="24"
              :sm="12"
              :md="6"
              :lg="6"
              :key="index"
              v-for="(user, index) in paginatedUsers"
            >
              <div class="boost-option" v-if="index === 3">
                <div class="boost-option-icon">
                  <div class="fa fa-bolt"></div>
                </div>
                <div class="boost-text-option"><router-link :to="{ name: 'newpackage' }">Boost me <i class="el-icon-right"></i></router-link></div>
                <div class="boost-text-search">Be the top profile in Search to get 4x more views.</div>
              </div>
              <div class="profile-user" v-else>
                <div class="profile-box">
                  <div class="var-online-part">
                    <div class="var-online">
                      <div class="var-icon">
                        <img src="@/assets/images/verified-icon.png" alt="" v-if="user.status">
                      </div>
                      <div class="var-line-icon">
                        <div class="user-online">
                          <div class="user-offline-show" title="Online"></div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <figure>
                    <a @click="onUserView(user.id)">
                      <img
                        :src="user.image ? imageBasePath + user.image : require('@/assets/images/default-user.png')"
                        alt=""
                        border="0"
                        class="img-responsive"
                        style="max-height: 14.3rem;"
                      >
                    </a>
                  </figure>
                  <a @click="onUserView(user.id)">
                    <div class="main-profile">
                      <div class="profile-info-name">{{ user.username ? user.username : user.name }}</div>
                      <div class="profile-info">
                        <div class="profile-info-left">
                          {{ user.personal_record && user.personal_record.age ? user.personal_record.age : '0'}} -
                          {{ user.personal_record && user.personal_record.city_id ? getCityById(user.personal_record.city_id) ? getCityById(user.personal_record.city_id).name : '' : '' }},
                          {{ user.personal_record && user.personal_record.country ? getName(user.personal_record.country) : getName('NP') }}
                        </div>
                        <div class="profile-info-right">
                          <p class="cam-icon">
                            {{ user.gallery_count && user.gallery_count.length ? user.gallery_count.length : 0 }}
                            <i class="fa fa-camera"></i>
                          </p>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="profile-ux-btn">
                  <div class="profile-ux-btn-left">
                    <a @click="onCommentClick(user.id)">
                      <img src="@/assets/images/comment.png" alt="">
                    </a>
                  </div>
                  <div class="profile-ux-btn-mid">
                    <a
                      @click="onConnectUser(user)"
                      v-if="!checkIfUserExist(user.connect_from_users)"
                    >
                      <img src="@/assets/images/connect.png" alt="" title="Connect">
                    </a>
                    <a v-else-if="checkIfUserPending(user.connect_from_users)">
                      <img src="@/assets/images/connect-pending.png" alt="" title="Pending">
                    </a>
                    <a v-else-if="checkIfUserRejected(user.connect_from_users)">
                      <img src="@/assets/images/connect-rejected.png" alt="" title="Rejected">
                    </a>
                    <a v-else>
                      <img src="@/assets/images/connect-active.png" alt="" title="Connected">
                    </a>
                  </div>
                  <div class="profile-ux-btn-right">
                    <a
                      class="linke-btn"
                      v-if="checkIfUserLiked(user.liked_users)"
                    >
                      <img src="@/assets/images/heart-active.png" alt="">
                    </a>
                    <a
                      @click="onLikeClick(user)"
                      class="linke-btn"
                      v-else>
                      <img src="@/assets/images/heart.png" alt=""></a>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </el-col>
          </el-row>

          <el-pagination
            @next-click="onNextPage"
            @prev-click="onPrevPage"
            @current-change="onPageClick"
            :total="data && data.length"
            :page-size="userSize"
            :hide-on-single-page="true"
            background
            layout="prev, pager, next"
          >
          </el-pagination>
        </el-col>
      </el-row>
    </div>
  </div>
  <Footer/>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Breadcrumb from '@/views/user/_components/Breadcrumb'
import axios from 'axios'

import userMixin from '@/mixins/user'
import searchMixin from '@/mixins/search'
import advertisementMixin from '@/mixins/advertisement'
import likeCommentMixin from '@/mixins/like_comment'

import { CodeLoader } from 'vue-content-loader'
import configurationMixin from '@/mixins/configuration'

const { getName, getData } = require('country-list')

export default {
  components: {
    Header,
    Footer,
    Breadcrumb,
    CodeLoader
  },

  created () {
    this.getReligions()
    this.getCasts()
    this.getCities()
    this.getEducationLevels()
    this.getOccupations()
    this.getAdvertisements()

    this.loading = true
    this.searchUser(this.searchData).then((data) => {
      this.data = data

      this.loading = false
    }).catch(() => {
      this.loading = false
    })

    axios.get(process.env.VUE_APP_API_BASE_URL + '/educationOptions').then((res) => {
      this.educationOptions = res.data
    })
  },

  computed: {
    countryOption () {
      return getData().map(country => {
        return {
          label: country.name,
          value: country.code
        }
      })
    },

    advertisementData () {
      return this.advertisements && this.advertisements.slice(0, 4).filter(advertisement => {
        return advertisement.status === 1 && advertisement.position === 2
      })
    },

    educationalLevelOptions () {
      return this.educationLevels && this.educationLevels.map(educationLevel => {
        return {
          id: educationLevel.id,
          name: educationLevel.name
        }
      })
    },

    imageBasePath () {
      return process.env.VUE_APP_API_IMAGE_PATH
    },

    paginatedUsers () {
      const start = (this.userPageNumber - 1) * this.userSize
      const end = start + this.userSize
      return this.data ? this.data.slice(start, end) : null
    }
  },

  data () {
    return {
      activeStepBar: 0,
      tab: 1,
      data: null,
      educationOptions: [],
      loading: false,
      fits: ['fill'],
      userPageNumber: 1,
      userSize: 30,
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    },

    onNextPage () {
      this.userPageNumber++
    },

    onPrevPage () {
      this.userPageNumber--
    },

    onPageClick (pageNumber) {
      this.userPageNumber = pageNumber
    },

    onSearchClick () {
      this.loading = true

      this.searchUser(this.searchData).then((data) => {
        this.data = data

        this.updateSearchHistory(Object.assign(this.searchData, { user_id: this.user.id }))

        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    onLikeClick (user) {
      this.$confirm(`Are you sure you want to like ${user.name}?`, 'Accept', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(() => {
        this.createLike({
          from_user_id: this.user.id,
          to_user_id: user.id
        }).then(() => {
          this.$message({
            message: 'User Liked Successfully',
            type: 'success'
          })

          this.$emit('user:update')
          this.getUsers()
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      })
    },

    getName (code) {
      return code ? getName(code) : 'Nepal'
    },

    onConnectUser (user) {
      if (!this.user) {
        this.$router.push({ name: 'user-login' })
        return
      }

      this.$confirm(`Are you sure you want to connect ${user.name}?`, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.connectUser({
          from_id: this.user ? this.user.id : null,
          to_id: user.id
        }).then(() => {
          this.getUsers()
          this.getMe()

          this.$message({
            type: 'success',
            message: 'User connected successfully.'
          })

          this.$router.push({ name: 'user-profile', params: { id: user.id } })
        })
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: 'Opps, Something went wrong.'
        })
      })
    },

    onUserView (id) {
      this.$router.push({ name: 'user-profile', params: { id: id } })
    },

    checkIfUserConnected (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return existingUser && existingUser.pivot.status === 1
      }

      return false
    },

    checkIfUserExist (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return !!existingUser
      }

      return false
    },

    checkIfUserPending (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return existingUser && existingUser.pivot.status === 0
      }

      return false
    },

    checkIfUserRejected (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return existingUser && existingUser.pivot.status === 2
      }

      return false
    },

    checkIfUserLiked (users) {
      if (this.user) {
        const isLiked = users && users.find(user => {
          return user.id === this.user.id
        })

        return !!isLiked
      }

      return false
    }
  },

  mixins: [advertisementMixin, configurationMixin, searchMixin, userMixin, likeCommentMixin],

  name: 'Register',

  watch: {
    searchData: {
      deep: true,
      handler (newValue) {
        this.onSearchClick()
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss">
.user-profile-list{
  margin: 0;
  padding: 20px 0 30px 0;
  background: #f3f2ef;
}

.background-profile{
  padding: 0;

}

.box-card-profile-list{
  padding:20px;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  background: #FFFFFF;
  margin-bottom: 20px;
}

.background-profile img{
  margin: 0;
  padding: 0;
}

.edit-background{
  margin: 0 15px 0 0;
  padding: 0;
  float: right;
  position: relative;
  z-index: 9999;
}
.el-icon-camera-solid{
  margin: 0;
  padding: 8px;
  background: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 20px;
  text-align: center;
}
.profile-userpic-list{
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 999;
  text-align: center;
}

.profile-userpic-list img {
  float: none;
  margin: 0 auto;
  width: 130px;
  height: 130px;
  -webkit-border-radius: 50%!important;
  -moz-border-radius: 50%!important;
  border-radius: 50%!important;
  border:5px solid #fff;
}

.profile-userpic-list .online{
  width: 12px;
  height: 12px;
  background: #008000;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  top: -30px;
  left: 38px;
  border: 5px solid #ffffff;
  padding: 8px;
}

.profile-userpic-list .offline{
  width: 12px;
  height: 12px;
  background: #c4c2c2;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  top: -30px;
  left: 38px;
  border: 5px solid #ffffff;
  padding: 8px;
}

.profile-name{
  margin: 0;
  padding: 5px 0 5px 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.profile-age-height{
  margin: 0;
  padding: 5px 0 0 0;
  font-size:16px;
  line-height: 20px;
  font-weight: 500;
}

.profile-post{
  margin: 0;
  padding: 3px 0 0 0;
  font-size: 16px;
  line-height: 20px;
}

.contact-info{
  margin: 0;
  padding: 3px 0 20px 0;
  font-size: 13px;
  line-height: 22px;
}

.profile-userpic-list .el-button{
  padding: 4px 8px !important;
  min-height: 30px !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  margin-top: 5px !important;
}

.contact-info a{
  color: #030848;
  font-weight: 600;
}

.edit-details{
  text-align: right;
}

.edit-details .el-icon-edit{
  font-size: 25px;
}

.profile-viewed{
  margin: 0;
  padding: 0 0 40px 0;
}

.profile-viewed span{
  font-size: 16px !important;
  font-weight: 600;
}

.Filter-view{
  margin: 0;
  padding: 10px 0 0 0;
}

.Filter-view .el-select{
  width: 100%;
}

.Filter-view .el-input__inner {
  height: 38px;
}

.Filter-view .to{
  padding: 20px 0 0 0;
  margin-left: -5px;
}

.el-card__header {
  padding: 15px 20px 8px 20px !important;
}

.el-card{
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 20px;
}

.add-info{
  float: right;
}

.add-info .icons{
  font-weight: 600;
  color: #000;
}

.add-info .icons:hover{
  font-weight: 600;
  color: #F56C6C;
}

.top-gap{
  padding-top: 30px;
}

label{
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
}

.tp-gap{
  padding-top: 30px;
}

.el-input__inner{
  margin: 5px 0 5px 0 !important;
  border: 2px solid #ccc !important;
}

.profile-viewed[data-v-0964933c] {
  margin: 0 0 20px 0;
  padding: 0 0 0 0;
}

.user-action {
  display: flex;
  justify-content: space-between;
}
</style>
