<template>
  <Header />
  <Breadcrumb :title="'Setting'" :link="'setting'" />
  <div class="information-ul">
    <div class="container">
      <el-row :gutter="40" >
        <el-col :xs="24" :sm="8" :md="8" :lg="8">
          <div class="sidebar-bg">
            <div class="page-links">
              <ul>
                <li><a href="/#/blocked-profiles" class="active"> Blocked Profiles</a></li>
                <li><a href="/#/removed-profiles"> Removed Profiles</a></li>
                <li><a href="/#/manage-subscription"> Manage Subscriptions</a></li>
                <li><a href="/#/email-notification"> Email Notifications</a></li>
                <li><a href="/#/site-notification"> Site Notification</a></li>
                <li><a href="/#/verify-account"> Verify your Account</a></li>
              </ul>
            </div>
          </div>
        </el-col>
        <slot name="content"></slot>
      </el-row>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import pageMixin from '@/mixins/page'
import Breadcrumb from '@/views/user/_components/Breadcrumb'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  created () {
  },

  data () {
    return {
      activeStepBar: 0,
      tab: 1
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    }
  },

  mixins: [pageMixin],

  name: 'SettingPage'
}
</script>
<style scoped>
.information-ul {
  margin: 0;
  padding: 40px 0;
  background: #fff;
  border-top: 1px solid #ccc;
}
.information-ul .page-title {
  font-size: 24px !important;
  padding-top: 10px;
}

.information-ul p{
  padding: 10px 0;
  line-height: 24px;
}

.sidebar-bg{
  background: #fff;
  padding-bottom: 40px;
}

.page-links{
  margin: 0;
  padding:10px 0 0 0;
}

.page-links ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-links li{
  margin: 0 5px;
  padding: 20px 0 20px 20px;
  list-style: none;
  font-size: 17px;
  border-bottom: 1px solid #ccc;
}

.page-links li a{
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all 0.3s ease-in 0s;
}

.page-links li a:hover{
  color: #237eeb;
  transition: all 0.3s ease-in 0s;
}

.page-links li a.active{
  color: #237eeb;
  transition: all 0.3s ease-in 0s;
}

</style>
