import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  comments: [],
  totalLikes: null,
  totalComments: null
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state
}
