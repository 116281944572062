import { mapActions, mapGetters } from 'vuex'

const gallery = {
  computed: {
    ...mapGetters(
      'gallery',
      {
        gallery: 'gallery'
      }
    )
  },

  methods: {
    ...mapActions(
      'gallery',
      [
        'createGallery',
        'getGallery',
        'makeProfile'
      ]
    )
  }
}

export default gallery
