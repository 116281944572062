<template>
  <div class="c-header">
    <div class="header-top">
      <div class="container">
        <el-row :gutter="20">
          <el-col :xs="4" :sm="8" :md="12" :lg="14">
            <div class="grid-content xs-hidden sm-hidden">Nepal's Best Matrimony Services</div>
            <a @click="showResponsiveMenu"><i class="el-icon-menu mob-menu-bar"></i></a>
          </el-col>
          <el-col :xs="20" :sm="16" :md="12" :lg="10">
            <div class="contacts">
              <i class="el-icon-phone"></i> +977-9800000000 <span> <i class="el-icon-message"></i> info@merojodi.com</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
      <div class="menu-bar-box md-lg-hidden" :class="[!isResponsive ? 'hide-md-lg-menu' : 'show-md-lg-menu']">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
        >
          <el-menu-item index="1">
            <router-link :to="{ name: 'faq' }">
              <i class="el-icon-s-home"></i>
              <span>Home</span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="2">
            <router-link :to="{ name: 'frontend' }">
              <i class="el-icon-s-home"></i>
              <span>FAQs</span>
            </router-link>
          </el-menu-item>
          <el-submenu index="3" v-if="isLoggedIn">
            <template #title>
              <i class="el-icon-search"></i>
              <span class="itemdropmain">Search</span>
            </template>
            <el-menu-item-group>
              <router-link :to="{ name: 'search' }">
                <el-menu-item index="2-1">
                  Search
                </el-menu-item>
              </router-link>
              <el-menu-item index="2-2">
                <router-link :to="{ name: 'custom_search' }">Custom Search</router-link>
              </el-menu-item>
              <el-menu-item index="2-3">
                <router-link :to="{ name: 'mutual-search' }">Mutual Search</router-link>
              </el-menu-item>
              <el-menu-item index="2-4">
                <router-link :to="{ name: 'reverse-search' }">Reverse Search</router-link>
              </el-menu-item>
              <el-menu-item index="2-5">
                <router-link :to="{ name: 'saved-search' }">Saved Search</router-link>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="5" v-if="isLoggedIn">
            <router-link :to="{ name: 'likes' }">
              <i class="fa fa-heart mr-3 likes-heart"></i>
              <span>Likes</span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="6" v-if="isLoggedIn">
            <router-link :to="{ name: 'matches' }">
              <i class="el-icon-connection"></i>
              <span>Matches</span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="7" v-if="isLoggedIn">
            <router-link :to="{ name: 'matchevents' }">
              <i class="el-icon-date"></i>
              <span>Events</span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="8">
            <router-link :to="{ name: 'contact' }">
              <i class="el-icon-message"></i>
              <span>Contact Us</span>
            </router-link>
          </el-menu-item>
        </el-menu>
    </div>
    <div class="header-main">
      <div class="container">
        <el-row :gutter="20">
          <el-col :xs="8" :sm="4" :md="4" :lg="4">
            <div class="grid-contents">
              <router-link :to="{ name: 'frontend' }">
                <img src="@/assets/images/logo.jpg" class="logo-responsive" alt="">
              </router-link>
            </div>
          </el-col>
          <el-col :xs="0" :sm="18" :md="14" :lg="14">
<!--            <el-menu class="el-menu-demo" :class="[!isResponsive ? 'hide-md-lg-menu' : 'show-md-lg-menu']" mode="horizontal">-->
              <el-menu class="el-menu-demo hide-md-lg-menu xs-hidden" mode="horizontal">
              <el-menu-item index="1">
                <router-link :to="{ name: 'frontend' }">
                  Home
                </router-link>
              </el-menu-item>
                <el-menu-item index="2">
                  <router-link :to="{ name: 'faq' }">
                    FAQs
                  </router-link>
                </el-menu-item>
                <el-menu-item index="3" v-if="user && user.id">
                  <a @click="openBrowseByProfile">Browse By Profile</a>
                </el-menu-item>
              <el-menu-item index="4" v-if="user && user.id">
                <el-dropdown style="margin-top: 0; font-size: 15px">
                  <span class="el-dropdown-link">
                    Search
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item>
                        <router-link :to="{ name: 'search' }">Search</router-link>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <router-link :to="{ name: 'custom_search' }">Custom Search</router-link>
                      </el-dropdown-item>
                      <el-dropdown-item><router-link :to="{ name: 'mutual-search' }">Mutual Search</router-link></el-dropdown-item>
                      <el-dropdown-item><router-link :to="{ name: 'reverse-search' }">Reverse Search</router-link></el-dropdown-item>
                      <el-dropdown-item><router-link :to="{ name: 'saved-search' }">Saved Search</router-link></el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </el-menu-item>
              <el-menu-item index="5" v-if="user && user.id">
                <el-dropdown style="margin-top: 0; font-size: 15px">
                  <span class="el-dropdown-link">
                    Likes
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item>
                        <router-link :to="{ name: 'likes' }">Likes</router-link>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </el-menu-item>
              <el-menu-item index="6" v-if="user && user.id">
                <router-link :to="{ name: 'matches' }">Matches</router-link>
              </el-menu-item>
              <el-menu-item index="7" v-if="user && user.id">
                <router-link :to="{ name: 'matchevents' }">Events</router-link>
              </el-menu-item>
            </el-menu>
          </el-col>
          <el-col :xs="16" :sm="8" :md="6" :lg="6">
            <div class="main-flex">
              <el-popover
                placement="bottom"
                :width="'auto'"
                trigger="click"
                v-if="user && user.id"
              >
                <div>
                  <div class="notification-list">
                    <div class="heading">Notifications</div>
                    <el-alert
                      v-if="notifications.length === 0"
                      :closable="false"
                      :type="'error'"
                    >No notifications</el-alert>
                    <a
                      @click="onNotificationClick(notification)"
                      :key="index"
                      v-for="(notification, index) in notifications"
                    >
                      <div class="main-notify" :class="[notification.is_seen ? 'seen-notification' : '']">
                      <div class="left-col">
                        <img
                          :src="notification.from_use && notification.from_user.imager ? imageBaseUrl + notification.from_user.image : require('@/assets/images/default-user.png')"
                          width="50"
                          height="45"
                          alt="image"
                        >
                        </div>
                      <div class="right-col">
                        <p>{{ notification.message }}</p>
                        <div class="date-time">
                          {{ notification.created_at }}
                        </div>
                      </div>
                      </div>
                    </a>
                  </div>
                  <div
                    class="more-notification"
                    v-if="notifications && notifications.length > 0"
                  >
                    <router-link :to="{ name : 'notification' }">
                      View More Notifications
                    </router-link>
                  </div>
                </div>
                <template #reference>
                  <div class="bell-icon">
                    <a><i class="el-icon-bell iconbell"></i></a>
                    <span>{{ notificationCount }}</span>
                  </div>
                </template>
              </el-popover>

              <el-popover
                placement="bottom"
                :width="'auto'"
                trigger="click"
                v-if="user && user.id"
              >
                <div>
                  <div class="chat-list">
                    <el-alert
                      v-if="allFromMessages.length === 0"
                      :closable="false"
                      :type="'error'"
                    >No messages</el-alert>
                    <a
                      @click="openChat(message[0])"
                      :key="index"
                      v-for="(message, index) in allFromMessages"
                    >
                      <div class="chat-user-list">
                        <div class="left-col">
                          <img
                            :src="message[0] && message[0].from_user.image ? imageBaseUrl + message[0].from_user.image : require('@/assets/images/default-user.png')"
                            alt=""
                          >
                          <div
                            :class="[message[0].from_user.is_logged_in ? 'online' : 'offline']"
                            title="Online"
                          >
                          </div>
                        </div><div class="right-col">
                        <div
                          class="title"
                          :class="[ message[0].seen_at ? '' : 'title-unread-msg']"
                        >
                          {{ message[0].from_user ? message[0].from_user.name : '' }}
                        </div>
                        <div
                          class="description"
                          :class="[ message[0].seen_at ? '' : 'description-msg-unread']"
                        >
                          {{ message[0].message }}
                        </div>
                      </div>
                      </div>
                    </a>
                  </div>
                </div>
                <template #reference>
                  <div class="bell-icon">
                    <a><i class="el-icon-chat-dot-square iconbell"></i></a>
                    <span>{{ messageUnseenCount }}</span>
                  </div>
                </template>
              </el-popover>
            <div class="user-menu-layout">
              <div v-if="user && user.id">
                <el-dropdown
                  placement="bottom"
                  trigger="click"
                  class="navbar-dropdown"
                >
                  <div class="navbar-dropdown-trigger">
                    <div class="user-profile-h">
                      <div class="user-img">
                <span>
                  <img
                    :key="updatedUser && updatedUser.image"
                    :src="user && user.image ? imageBaseUrl + user.image : require('@/assets/images/logo.jpg')"
                  ></span>
                      </div>
                      <div class="user-text">
                  <span
                    class="user-name"
                    v-if="user"
                  > {{ user.name ? user.name.split(' ')[0] : '' }}</span>
                      </div>
                    </div>
                  </div>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item command="dashboard">
                        <router-link :to="{ name: 'user-dashboard' }">
                          <i class="el-icon-user-solid"></i> My Profile
                        </router-link>
                      </el-dropdown-item>
                      <el-dropdown-item command="viewed-me">
                        <router-link :to="{ name: 'views' }"><i class="el-icon-view"></i> Who's Viewed Me</router-link>
                      </el-dropdown-item>
                      <el-dropdown-item command="boost">
                        <router-link :to="{ name: 'newpackage' }">
                          <i class="el-icon-view"></i> Profile Boost
                        </router-link>
                      </el-dropdown-item>
                      <el-dropdown-item command="safety-center">
                        <router-link :to="{ name: 'safetycenter' }"><i class="el-icon-view"></i> Safety Center</router-link>
                      </el-dropdown-item>
                      <el-dropdown-item command="setting">
                        <a href="/#/blocked-profiles">
                          <i class="el-icon-setting"></i> Setting
                        </a>
                      </el-dropdown-item>
                      <el-dropdown-item command="dashboard">
                        <a @click="openDeactivateUserPopup">
                          <i class="el-icon-turn-off"></i> Deactivate
                        </a>
                      </el-dropdown-item>
                      <el-dropdown-item
                        @click="onLogout"
                        command="signOut"
                      >
                        <i class="el-icon-switch-button"></i> LogOut
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
              <div v-else>
                <el-row>
                  <router-link :to="{ name: 'register' }">
                    <el-button type="danger"><i class="el-icon-user-solid"></i> Register</el-button>
                  </router-link>
                  <router-link
                    class="c-header__login-button"
                    :to="{ name: 'user-login' }"
                  >
                    <el-button type="primary"><i class="el-icon-lock"></i> Login</el-button>
                  </router-link>
                </el-row>
              </div>
            </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="chat-container" v-if="showChat">
      <div class="chatcard chatcard-bordered">
        <div class="chatcard-header">
          <h4 class="chatcard-title">
            <img
              :src="toUser && toUser.image ? imagePath + toUser.image : 'https://img.icons8.com/color/36/000000/administrator-male.png'"
              class="avatar"
              alt="..."
            >
            <div class="text-chat">
              {{ toUser && toUser.username ? toUser.username : toUser.name }}
              <a @click="onVideoChatClick(toUser)">
                <i class="el-icon-video-camera"></i>
              </a>
            </div>
          </h4> <a @click="closeChat" class="btn btn-xs btn-secondary" data-abc="true">X</a>
        </div>
        <div class="chat-content">
          <div class="ps-container ps-theme-default ps-active-y" id="chat-content" ref="chatContainer" style="overflow-y: scroll !important; height:400px !important;">
            <div
              :key="index"
              v-for="(message, index) in allMessages"
            >
              <div
                class="media media-chat"
                v-if="message.to_user_id === user.id"
              >
                <img
                  :src="toUser && toUser.image ? imagePath + toUser.image : 'https://img.icons8.com/color/36/000000/administrator-male.png'"
                  class="avatar"
                  alt="..."
                >
                  <div class="media-body">
                    <p v-html="message.message"></p>
                  <p class="meta"><time datetime="2018">{{ getTime(message.created_at) }}</time></p>
                </div>
              </div>
              <div
                class="media media-chat media-chat-reverse"
                v-else
              >
                <div class="media-body">
                  <p v-html="message.message"></p>
                  <p><time datetime="2018">{{ getTime(message.created_at) }}</time></p>
                </div>
              </div>
            </div>

            <div class="ps-scrollbar-x-rail" style="left: 0px; bottom: 0px;">
              <div class="ps-scrollbar-x" tabindex="0" style="left: 0px; width: 0px;"></div>
            </div>
            <div class="ps-scrollbar-y-rail" style="top: 0px; height: 0px; right: 2px;">
              <div class="ps-scrollbar-y" tabindex="0" style="top: 0px; height: 2px;"></div>
            </div>
          </div>
          <div class="publisher bt-1 border-light">
            <img
              :src="user && user.image ? imagePath + user.image : 'https://img.icons8.com/color/36/000000/administrator-male.png'"
              class="avatar avatar-xs"
              alt="..."
            >
            <textarea
              @keyup.enter="onSendMessage"
              class="publisher-input"
              type="text"
              placeholder="Write something"
              v-model="message"
            >
            </textarea>
            <a @click="openEmojiPicker">
              <i class="fa fa-smile"></i>
            </a>
            <a
              @click="onSendMessage"
              class="publisher-btn text-info"
              data-abc="true"
            >
              send
            </a>
          </div>

          <Picker
            :data="emojiIndex"
            :showPreview="false"
            :showSkinTones="false"
            @select="onSelectEmoji"
            v-if="showEmojiPicker"
            :style="{ position: 'absolute', bottom: '20px', right: '100px' }"
          />
        </div>
      </div>
    </div>
    <div class="poup-tabs">
    <Popup
      @popup:close="closeBrowseByProfile"
      :showPopup="showBrowseByProfile"
      :title="'Browse By Profile'"
      :showAction="false"
    >
      <template #content>
        <el-tabs v-model="activeTabName" class="browse-by-profile">
          <el-tab-pane label="Religion" name="first">
            <el-row :gutter="10" >
              <el-col
                :xs="12" :sm="8" :md="6" :lg="6"
                v-for="item in religionOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
               <div class="browse-list">
                 <a @click="onSubmit('religion', item.value)">{{ item.label }}</a>
               </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="Cast" name="second">
            <el-row :gutter="10" >
              <el-col
                :xs="12" :sm="8" :md="6" :lg="6"
                v-for="item in castOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <div class="browse-list">
                  <a @click="onSubmit('cast', item.value)">{{ item.label }}</a>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
       <el-tab-pane label="City" name="third">
          <el-row :gutter="10" >
            <el-col
              :xs="12" :sm="8" :md="6" :lg="6"
              v-for="item in cityOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <div class="browse-list">
                <a @click="onSubmit('city', item.value)">{{ item.label }}</a>
              </div>
            </el-col>
          </el-row>
          </el-tab-pane>
        </el-tabs>
      </template>
    </Popup>
</div>
  </div>
</template>
<script>
import Pusher from 'pusher-js'
import mitt from 'mitt'

import Popup from '@/components/Popup'

import userMixin from '@/mixins/user'
import configurationMixin from '@/mixins/configuration'
import searchMixin from '@/mixins/search'
import messageMixin from '@/mixins/messages'
import notificationMixin from '@/mixins/notification'

import 'vue-dock-menu/dist/vue-dock-menu.css'
import data from 'emoji-mart-vue-fast/data/all.json'
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast/src'

const emitter = mitt()

const intervals = [
  { label: 'year', seconds: 31536000 },
  { label: 'month', seconds: 2592000 },
  { label: 'day', seconds: 86400 },
  { label: 'hour', seconds: 3600 },
  { label: 'minute', seconds: 60 },
  { label: 'second', seconds: 1 }
]

Pusher.logToConsole = false

const emojiIndex = new EmojiIndex(data)

export default {
  created () {
    this.getCasts()
    this.getReligions()
    this.getCities()

    if (this.user && this.user.id) {
      this.fromMessages({
        id: this.user.id
      }).then((res) => {
        this.allFromMessages = res.data

        let count = 0

        this.allFromMessages && Object.values(this.allFromMessages).forEach(message => {
          if (!message[0].seen_at) {
            count += 1
          }
        })

        this.messageUnseenCount = count
      })

      this.getNotifications(this.user.id)

      this.subscribe()
    }
  },

  components: {
    Popup,
    Picker
  },

  computed: {
    notificationCount () {
      let count = 0

      this.notifications && this.notifications.map(notification => {
        if (!notification.is_seen) {
          count++
        }
      })

      return count
    },

    updatedUser () {
      return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    },

    imagePath () {
      return process.env.VUE_APP_API_IMAGE_PATH
    },

    religionOptions () {
      return this.religions && this.religions.map(religion => {
        return {
          value: religion.id,
          label: religion.name
        }
      })
    },

    castOptions () {
      return this.casts && this.casts.map(cast => {
        return {
          value: cast.id,
          label: cast.name
        }
      })
    },

    cityOptions () {
      return this.cities && this.cities.map(city => {
        return {
          value: city.id,
          label: city.name
        }
      })
    },

    browseProfileOptions () {
      return [
        {
          name: 'Browse By Profile',
          menu: [
            {
              name: 'Religions',
              menu: this.religions.map(religion => {
                return {
                  id: religion.id,
                  name: religion.name
                }
              })
            },
            {
              name: 'Cast',
              menu: this.casts.map(cast => {
                return {
                  id: cast.id,
                  name: cast.name
                }
              })
            },
            {
              name: 'City',
              menu: this.cities.map(city => {
                return {
                  id: city.id,
                  name: city.name
                }
              })
            }
          ]
        }
      ]
    },

    imageBaseUrl () {
      return process.env.VUE_APP_API_IMAGE_PATH
    }
  },

  data () {
    return {
      activeTabName: 'first',
      showBrowseByProfile: false,
      showEmojiPicker: false,
      emojiIndex: emojiIndex,
      search: '',
      message: '',
      allMessages: [],
      allFromMessages: [],
      showDeactivateUserPopup: false,
      messageUnseenCount: 0,
      drawer: false,
      showChat: false,
      toUser: '',
      religionSelected: '',
      citySelected: '',
      castSelected: '',
      isResponsive: false
    }
  },

  methods: {
    showResponsiveMenu () {
      this.isResponsive = !this.isResponsive
    },

    timeSince (date) {
      const seconds = Math.floor((Date.now() - date.getTime()) / 1000)
      const interval = intervals.find(i => i.seconds < seconds)
      const count = Math.floor(seconds / interval.seconds)
      return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`
    },

    onSelectEmoji (emoji) {
      this.message += emoji.native

      this.showEmojiPicker = !this.showEmojiPicker
    },

    openEmojiPicker () {
      this.showEmojiPicker = !this.showEmojiPicker
    },

    onVideoChatClick (user) {
      this.$router.push({ name: 'video-chat', params: { id: user.id } })
    },

    subscribe () {
      const pusher = new Pusher('4b1c61858ff8ed28adc6', {
        cluster: 'ap2',
        encrypted: false
      })
      pusher.subscribe('chat')
      pusher.bind('my-event', data => {
        this.allMessages.push(data.message)

        var container = this.$refs.chatContainer

        container.scrollTop = container.scrollHeight + 150

        let count = 1
        if (data.message.type === 2 && data.user.id === this.user.id && count === 1) {
          this.$confirm(`${data.fromUser.name} is calling you`, 'Accept', {
            confirmButtonText: 'Accept',
            cancelButtonText: 'Cancel',
            type: 'success'
          }).then(() => {
            count++
            window.location.href = data.message.link
          })
        }
      })
    },

    onSubmit (value, id) {
      if (value === 'religion') {
        this.$router.push({ name: 'browse-by-profile', params: { value: 'Religion', id: id } })
      }

      if (value === 'cast') {
        this.$router.push({ name: 'browse-by-profile', params: { value: 'Cast', id: id } })
      }

      if (value === 'city') {
        this.$router.push({ name: 'browse-by-profile', params: { value: 'City', id: id } })
      }

      this.closeBrowseByProfile()
    },

    openBrowseByProfile () {
      this.showBrowseByProfile = true
    },

    closeBrowseByProfile () {
      this.showBrowseByProfile = false
    },

    openChat (message) {
      this.showChat = true

      this.subscribe()

      this.getUser(message.from_user_id).then((res) => {
        this.toUser = res
      })

      this.getAllMessages(message.from_user_id)

      setTimeout(() => {
        this.seenMessage({
          id: message.id
        })
      }, 3000)
    },

    getAllMessages (toUserId) {
      this.getMessages({
        from_user_id: this.user.id,
        to_user_id: Number(toUserId)
      }).then((res) => {
        this.allMessages = res

        let count = 0

        this.allFromMessages && Object.values(this.allFromMessages).forEach(message => {
          if (!message[0].seen_at) {
            count += 1
          }
        })

        this.messageUnseenCount = count
      })
    },

    onSendMessage () {
      this.createMessage({
        from_user_id: this.user.id,
        to_user_id: this.toUser.id,
        message: this.message
      }).then(() => {
        this.getAllMessages(this.toUser.id)
        // eslint-disable-next-line
        var container = this.$refs.chatContainer

        container.scrollTop = container.scrollHeight

        this.message = ''
      })
    },

    closeChat () {
      this.showChat = false
    },

    getTime (dateTime) {
      const today = new Date(dateTime)
      return today.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    },

    onSelect (value) {
      const path = value.path

      const category = path.split('>')

      if (category[1] === 'religions') {
        const search = this.religions.find(religion => {
          return religion.name === value.name
        })

        this.searchData.religion = search.id
      }

      if (category[1] === 'cast') {
        const search = this.casts.find(cast => {
          return cast.name === value.name
        })

        this.searchData.cast = search.id
      }

      if (category[1] === 'city') {
        const search = this.cities.find(city => {
          return city.name === value.name
        })

        this.searchData.city = search.id
      }

      this.$router.push({ name: 'search' })
    },

    openDeactivateUserPopup () {
      this.$confirm('Are you sure you want to Deactivate Account, Once you deactivate your profile will be invisible to others. After you login it will be automatically activated.', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.deactivateUser(this.user ? this.user.id : '').then(() => {
          this.$message({
            type: 'success',
            message: 'User deactivated successfully.'
          })

          this.onLogout()
        })
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: 'Opps, Something went wrong.'
        })
      })
    },

    onNotificationClick (notification) {
      this.seenNotification(notification.id).then(() => {
        this.$router.push({ name: 'user-profile', params: { id: notification.from_user_id } })
      })
    },

    hideDeactivateUserPopup () {
      this.showDeactivateUserPopup = false
    },

    onLogout () {
      this.logout().then(() => {
        this.$router.push({ name: 'user-login' })
      })
    }
  },

  mixins: [
    configurationMixin,
    messageMixin,
    notificationMixin,
    searchMixin,
    userMixin
  ],

  mounted () {
    emitter.on('imageUpdated', () => {
    })
  },

  name: 'Header',

  watch: {
    isResponsive: {
      deep: true,
      handler (newValue) {
        this.isResponsive = newValue
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.header-top {
  background: #003993;
 /* background-image: linear-gradient(
      166deg, #f22876 0%, #942dd9 100%);*/
  padding: 10px 0 5px 0;
  color: #fff;
  font-size: 15px;
  line-height: 28px;
}

.header-top .el-icon-phone {
  color: #fff;
  font-size: 17px;

}

.media-chat {
  display: flex;
}

.media-chat.media-chat-reverse {
  padding-right: 12px;
  padding-left: 64px;
  text-align: -webkit-right;
}

.header-top .el-icon-message {
  color: #fff;
  font-size: 17px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.contacts {
  margin: 0;
  padding: 0;
  line-height: 30px;
  float: right;
}

.contacts span {
  padding-left: 20px;
}

.header-main {
  padding: 20px 0 0px 0;
  background: #fff;
  border-bottom: 1px solid #ccc;
}

.user-menu-layout{
  float: right;
}

.user-profile-h{
  margin: 3px 0 0 0;
  padding: 0;
  line-height: 30px;
  display: flex;

}

.user-img{
  width: 36px;
  margin-right: 6px;
}

.user-img img{
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
  border:1px solid #ccc;
  padding: 0px;
  background: #e8e8e8;
  cursor: pointer;
}

.user-text{
  float: left;
}

.el-menu--horizontal>.el-menu-item{
  line-height: 54px !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  color: #000 !important;
  z-index: 999;
}

.el-submenu__title {
  padding-left: 0 !important;
}
.el-menu--horizontal>.el-submenu .el-submenu__title {
  font-size: 15px !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}

.el-dropdown-menu__item {
  line-height: 36px;
  margin: 0 !important;
  color: #000 !important;
  outline: 0;
}

.el-dropdown-menu__item:hover{
  line-height: 36px;
  margin: 0;
  color: #003993 !important;
  outline: 0;
  background: #fff !important;
}

.el-menu li{
  border:none !important;
  color: #000 !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.menu-bar-item-container[data-v-d6123432].top, .menu-bar-item-container[data-v-d6123432].bottom {
  height: 100%;
  padding: 0 0 !important;
}

.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  border-bottom: none !important;
}

.el-tabs__active-bar {
  background-color: #f56c6c !important;
}

.custom-tab{
  min-height: 250px;
  padding: 20px 20px !important;
}

.el-tabs__item.is-active {
  color: #dc143c !important;
}

.el-tabs__item:hover {
  color: #dc143c !important;
  cursor: pointer;
}

.el-tabs__item{
  font-size: 22px !important;
}

.custom-menu{
  margin: 0;
  padding: 0;
}

.el-menu li a {
  font-size: 18px !important;
  color: #000 !important;
}

.el-dropdown {
  font-size: 18px !important;
}

.custom-menu ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.custom-menu ul li{
  margin: 0;
  padding: 0 10px 0 0;
  width: 33%;
  float: left;
}

.custom-menu ul li a{
  color: #000 !important;
}
.custom-menu ul li a:hover{
  color: #f56c6c;
}

.user-name {
  cursor: pointer;
  color: #000;
  font-size: 14px;
}

.el-dropdown {
  margin-top: 10px;
}

.menu-item{
  margin: 0px 0 0 0;
  padding: 0 0 0 10px;
  background: #fff;
  line-height: 28px;
}

.menu-item[data-v-9bcc0be2]:hover:not(.divider) {
  cursor: pointer;
  background: var(--background-color-hover);
  color: var(--text-hover-color);
  color: #fff !important;
}

.menu-wrapper[data-v-9bcc0be2] {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  min-width: 220px;
  padding: 15px 0 0 0;
  outline: none;
}

.menu-item[data-v-9bcc0be2] span.name {
  padding-left: 5%;
}

.el-button.is-round {
  border-radius: 5px !important;
  padding: 5px 10px !important;
  font-size: 13px !important;
  min-height: 36px !important;
}

.el-button--primary {
  color: #FFF;
  background-color: #003895 !important;
  border-color: #003895 !important;
}

.name-container[data-v-d6123432] {
  font-size: 18px !important;
  color: #000 !important;
}

.el-dropdown .el-dropdown-selfdefine {
  outline: 0 !important;
  color: #000 !important;
}

@media screen and (min-width:200px) and (max-width:480px) {
  .container {
    width: 100%;
    padding: 0 5px;
    margin: 0 auto;
  }

  .contacts {
    margin: 0;
    padding: 0;
    line-height: 30px;
    float: none !important;
    font-size: 11px;
  }

  .contacts .el-icon-phone{
    font-size: 11px;
  }
  .contacts .el-icon-message{
    font-size: 11px;
  }

  .el-dropdown-menu__item, .el-menu-item {
    font-size: 15px;
    padding: 0 0px !important;
  }

  .el-menu li a {
    font-size: 18px !important;
    color: #000 !important;
  }

  .header-main {
    padding: 5px 0 0px 0 !important;
  }

  .app-content-box{
    width: 100%;
  }

  .el-menu li a {
    font-size: 13px !important;
  }

  .name-container[data-v-d6123432] {
    font-size: 13px;
  }

  .el-carousel__container {
    height: 125px !important;
  }

  .user-menu-layout {
    padding-bottom: 10px;
    text-align: center;
  }
}

@media screen and (min-width: 481px) and (max-width:767px) {
  .container {
    width: 100%;
  }
  .app-content-box{
    width: 100%;
  }

  .el-carousel__container {
    height: 180px !important;
  }
}

@media only screen and (min-width: 600px) {
  .app-content-box{
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    width: 1024px;
  }
}
@media only screen and (min-width: 1200px) {
  .container {
    width: 1160px;
  }
}

@media screen and (min-width: 200px) and (max-width:768px) {
  .contacts {
    margin: 0;
    padding: 0;
    line-height: 30px;
    float: none !important;
    text-align:right;
  }

  .grid-content{
    text-align: center;
    padding-bottom: 5px;
  }

}

.c-header {
  &__login-button {
    margin-left: 1rem;
  }

  &__dock-menu {
  }

  .menu-bar-container {
    position: relative;
    width: auto;
    top: 0.6rem !important;
  }
}

@media(min-width:1000px) {
  .container {
    width: 750px
  }
}

  @media(min-width:992px) {
    .container {
      width: 970px
    }
  }

@media(min-width:1200px) {
  .container {
    width: 1170px
  }
}

.container {
  margin-right:auto;
  margin-left:auto
}

.user-menu-layout .el-button{
  padding-left: 12px;
  padding-right: 12px;
}

.main-flex{
  display: flex;
  justify-content: flex-end;
}

.bell-icon{
  margin:14px 3px 0 0;
  padding:0;
}

.bell-icon a{
  padding-right:12px;
}

.iconbell{
  font-size: 18px;
  background: #003993;
  padding: 8px;
  border-radius: 50%;
  font-weight: 800 !important;
  color: #fff;
  -webkit-transition: .2s;
  transition: .2s;
}

.iconbell:hover{
  background: #ee1c27;
  color: #fff;
  -webkit-transition: .2s;
  transition: .2s;
}

.bell-icon span{
  background-color: #10a64a;
  color: white;
  position: relative;
  width: 15px;
  height: 15px;
  line-height: 15px;
  left: auto;
  top: -18px;
  text-align: center;
  font-size: 11px;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  margin-left: -20px;
  padding: 2px 5px;
  font-weight: 700;
}

.chat-list{
  margin: 0 auto;
  padding: 10px;
  width: 300px;
  background: #fff;
}
.chat-user-list:hover{
  background: #e9e8e4;
  color: #000;
  border-radius: 5px;
}

.chat-user-list {
  margin: 0;
  padding:10px;
  display: flex;
  background: #fff;
}

.chat-user-list .left-col {
  margin: 0 15px 0 0;
  padding: 0;
  width: 55px;
  float: left;
}

.chat-user-list .right-col {
  margin: 0;
  padding: 0;
  float: left;
}

.chat-user-list .left-col img {
  margin: 0;
  padding: 0;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.chat-user-list .title {
  margin: 0;
  padding: 5px 0 0 0;
  font-size: 18px;
  font-weight: 500;

}
.chat-user-list .description {
  margin: 0;
  padding: 5px 0 0 0;
  font-size: 11px;
  line-height: 16px;
  font-size: 14px;
}

.chat-user-list .description-msg-unread {
  margin: 0;
  padding: 5px 0 0 0;
  font-size: 11px;
  line-height: 16px;
  font-size: 14px;
  color: #1975ff;
  font-weight: 600;
}

.chat-user-list .title a {
  margin: 0;
}

.chat-user-list .title-unread-msg {
  margin: 0;
  color: #000;
  font-weight: 600;
}

.chat-user-list .online {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  margin-top: -26px;
  left: 23px;
  border: 3px solid #fff;
  padding: 3px;
  background: #008000;
}

.chat-user-list .offline {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  margin-top: -26px;
  left: 23px;
  border: 3px solid #fff;
  padding: 3px;
  background: #c4c2c2;
}

.side-scrol{
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  height: 600px;
  width: 100%;
  overflow:hidden;
}

.side-scrol:hover{
  overflow-y: scroll;
}

.notification-list{
  margin: 0 auto;
  padding: 8px 8px 5px 8px;
  width: 300px;
  background: #fff;
}

.notification-list .heading{
  font-size: 18px;
  color: #003993;
  font-weight: 700;
  margin-bottom: 15px;
}

.main-notify{
  margin-top: 0px;
  padding: 8px 5px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  display: flex;
  margin-bottom: 0.5rem;
}

.seen-notification {
  background-color: #d6d6d6;
}

.main-notify:hover{
  background: #eaebed;
}

.notification-list .left-col {
  margin: 0 15px 0 0;
  padding: 0;
  width: 42px;
  float: left;
}

.notification-list .left-col i{
  font-size: 32px;
  background: #fff;
  border: 1px solid #ccc;
}

.notification-list .right-col {
  margin: 0;
  padding: 0;
  float: right;
}

.notification-list .right-col p{
  padding: 5px 0 0 0;
  color: #000;
  font-weight:600 !important;
  font-size: 14px;
}

.notification-list .right-col .date-time{
  padding: 5px 0 0 0;
  color: #003993;
  font-weight:500;
  font-size: 14px;
}

.notification-list .title {
  margin: 0;
  padding: 5px 0 0 0;
  font-size: 18px;
  font-weight: 500;
}

.notification-list .description {
  margin: 0;
  padding: 5px 0 0 0;
  font-size: 11px;
  line-height: 16px;
  font-size: 14px;
}

.el-card__header{
  background: #003993;
  color: #fff;
}

.more-notification{
  text-align: center;
  padding: 10px 0 0 0;
}

.more-notification a{
  text-align: center;
  color: #003993;
  font-weight: 600;
}

.page-title{
  font-size: 24px !important;
}

.faqs .el-collapse-item__header {
  font-size: 16px !important;
  font-weight: 500;
}

.el-switch.is-checked .el-switch__core {
  border-color: #003993 !important;
  background-color: #003993 !important;
}

@media screen and (min-width: 769px) and (max-width:2500px) {
  .hide-sm-menu{
    display: none;
  }

  .md-lg-hidden{
    display: none;
  }

  .mob-menu-bar{
   display: none !important;
  }
}

@media screen and (min-width: 300px) and (max-width:768px) {
  .hide-md-lg-menu{
    display: none;
  }

  .show-md-lg-menu{
    display: inherit;
  }

  .sm-hidden{
    display: none;
  }

  .mob-menu-bar{
    font-size: 30px;
  }

  .poup-tabs .c-popup .el-dialog{
    width: 90% !important;
  }
}

@media screen and (min-width: 280px) and (max-width:600px) {
  .xs-hidden{
    display: none;
  }

  .mob-menu-bar{
    font-size: 30px;
  }

  .contacts {
    margin: 0;
    padding: 0;
    line-height: 30px;
    float: right;
    font-size: 13px;
  }

  .logo-responsive{
    width: 70%;
  }

  .iconbell {
    font-size: 14px;
    background: #003993;
    padding: 5px;
    border-radius: 50%;
    font-weight: 800 !important;
    color: #fff;
    transition: .2s;
  }

  .user-img {
    width: 30px;
    margin-right: 5px;
  }

  .user-img img {
    width: 28px;
    height: 28px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #ccc;
    padding: 0px;
    background: #e8e8e8;
    cursor: pointer;
  }

  .user-name {
    cursor: pointer;
    color: #000;
    font-size: 10px;
  }
}

.menu-bar-box {
  background: #fff;
  padding: 20px 0 10px 0px;
  position: absolute;
  width: 260px;
  left: 0px;
  top:50px;
  z-index: 9999;
  -webkit-transition: .2s;
  transition: .2s;
}

.browse-profie{
  margin: 0;
  padding: 0;
}

.browse-profie .is-top{
  font-size: 15px !important;
}
.browse-profie .browse-text{
  padding-top:5px;
  padding-bottom:10px;
}

.likes-heart{
  margin-right: 10px !important;
  color: #909399 !important;
}

.itemdropmain{
  font-size: 18px !important;
  color: #000 !important;
}

.browse-list{
  margin: 0; padding: 18px 0 0 0;
}

.browse-list a{
  margin: 0; padding: 0px;
  font-size: 16px;
  color: #000;
}
.browse-list a:hover{
  color: #dc143c;
  display: block;
}
.poup-tabs .c-popup .el-dialog{
    width: 50% !important;
  }

.poup-tabs .c-popup .el-dialog .el-tabs__item {
  font-size: 20px !important;
}

.var-line-icon-new {
  position: relative !important;
  top: -8px;
  right: -2px;
  z-index: 999 !important;
}

.var-line-icon-main-profile{
  position: relative;
  top: 40px;
  right: 22px;
  z-index: 999 !important;
}

.front-ui-conn-list .left-col img {
position: absolute !important;
}
</style>
