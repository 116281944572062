<template>
  <div class="c-event-form">
    <el-form
      ref="form"
      :model="state.eventData"
      label-width="140px"
    >
      <el-form-item label="Title">
        <el-input
          v-model="state.eventData.title"
        >
        </el-input>
        <span
          class="c-banner-form__error"
          v-if="v$.eventData.title.$error"
        >
          {{ v$.eventData.title.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Description">
        <el-input
          v-model="state.eventData.description"
          type="textarea"
          rows="4"
        >
        </el-input>
        <span
          class="c-event-form__error"
          v-if="v$.eventData.description.$error"
        >
          {{ v$.eventData.description.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Image">
        <input
          @input="onImageUpload"
          type="file"
        />
      </el-form-item>

      <el-form-item label="Banner Image">
        <input
          @input="onBannerImageUpload"
          type="file"
        />
      </el-form-item>

      <el-form-item label="City">
        <el-input
          v-model="state.eventData.city"
        >
        </el-input>
        <span
          class="c-event-form__error"
          v-if="v$.eventData.city.$error"
        >
          {{ v$.eventData.city.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Price">
        <el-input
          v-model="state.eventData.price"
        >
        </el-input>
        <span
          class="c-event-form__error"
          v-if="v$.eventData.price.$error"
        >
          {{ v$.eventData.price.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Date">
        <el-input
          v-model="state.eventData.date"
          type="date"
        >
        </el-input>
        <span
          class="c-event-form__error"
          v-if="v$.eventData.date.$error"
        >
          {{ v$.eventData.date.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Time">
        <el-input
          v-model="state.eventData.time"
          type="time"
        >
        </el-input>
        <span
          class="c-event-form__error"
          v-if="v$.eventData.time.$error"
        >
          {{ v$.eventData.time.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Know Before You Go">
        <el-input
          v-model="state.eventData.know_before_go"
          type="textarea"
          rows="4"
        >
        </el-input>
        <span
          class="c-event-form__error"
          v-if="v$.eventData.description.$error"
        >
          {{ v$.eventData.description.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Status">
        <el-radio-group v-model="state.eventData.status" size="medium">
          <el-radio border :label="1">Active</el-radio>
          <el-radio border :label="0">Disable</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button
          @click="onSubmit"
          type="primary"
          round
        >
          {{ state.eventData.id ? 'Update' : 'Submit' }}
        </el-button>
        <el-button @click="onClose" round>Cancel</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import { reactive, computed } from 'vue'

import eventMixin from '@/mixins/event'

export default {
  setup () {
    const state = reactive({
      eventData: {
        title: '',
        description: '',
        image: '',
        city: '',
        price: '',
        date: '',
        time: '',
        status: 1,
        banner_image: '',
        know_before_go: ''
      }
    })

    const rules = computed(() => {
      return {
        eventData: {
          title: { required: helpers.withMessage('Title field is required', required) },
          description: { required: helpers.withMessage('Description field is required', required) },
          city: { required: helpers.withMessage('City field is required', required) },
          price: { required: helpers.withMessage('Price field is required', required) },
          date: { required: helpers.withMessage('Date field is required', required) },
          time: { required: helpers.withMessage('Time field is required', required) }
        }
      }
    })

    const v$ = useValidate(rules, state)

    return {
      state,
      v$
    }
  },

  methods: {
    onClose () {
      this.$router.push({ name: 'admin-event' })
    },

    onImageUpload (e) {
      const files = e.target.files || e.dataTransfer.files

      this.state.eventData.image = files[0]
    },

    onBannerImageUpload (e) {
      const files = e.target.files || e.dataTransfer.files

      this.state.eventData.banner_image = files[0]
    },

    onSubmit () {
      this.v$.$validate()
      if (!this.v$.$error) {
        if (this.state.eventData.id) {
          this.updateEvent(this.state.eventData).then(() => {
            this.$message({
              message: 'Event updated successfully.',
              type: 'success'
            })

            this.$router.push({ name: 'admin-event' })
          }).catch(() => {
            this.$message({
              message: 'Oops! Something went wrong.',
              type: 'warning'
            })
          })
        } else {
          this.createEvent(this.state.eventData).then(() => {
            this.$message({
              message: 'Event created successfully.',
              type: 'success'
            })

            this.$router.push({ name: 'admin-event' })
          }).catch(() => {
            this.$message({
              message: 'Oops! Something went wrong.',
              type: 'warning'
            })
          })
        }
      }
    }
  },

  mixins: [eventMixin],

  mounted () {
    if (this.event) {
      this.state.eventData = this.event
    }
  },

  name: 'EventForm',

  props: {
    event: {
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-event-form {
  margin-top: 1rem;

  &__error {
    color: orangered;
  }
}
</style>
