<template>
  <div class="c-chart">
    <vue3-chart-js
      :id="chartData.id"
      :type="chartData.type"
      :data="chartData.data"
    ></vue3-chart-js>
  </div>
</template>

<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
  components: {
    Vue3ChartJs
  },

  name: 'Chart',

  props: {
    chartData: {
      required: true
    }
  }
}
</script>

<style lang="scss">

</style>
