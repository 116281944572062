<template>
  <div class="c-banner-form">
    <el-form
      ref="form"
      :model="state.bannerData"
      label-width="140px"
    >
      <el-form-item label="Title">
        <el-input
          v-model="state.bannerData.title"
        >
        </el-input>
        <span
          class="c-banner-form__error"
          v-if="v$.bannerData.title.$error"
        >
          {{ v$.bannerData.title.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Description">
        <el-input
          v-model="state.bannerData.description"
          type="textarea"
          rows="4"
        >
        </el-input>
        <span
          class="c-banner-form__error"
          v-if="v$.bannerData.description.$error"
        >
          {{ v$.bannerData.description.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Link">
        <el-input
          v-model="state.bannerData.link"
          type="textarea"
          rows="4"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="Image">
        <input
          @input="onImageUpload"
          type="file"
        />
      </el-form-item>

      <el-form-item label="Type">
        <el-select
          placeholder="Please select type"
          v-model="state.bannerData.type"
        >
          <el-option value="null" label="Please Select Family Member">Please Select type</el-option>
          <el-option label="Landing Page" :value="1"></el-option>
          <el-option label="Event" :value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Status">
        <el-radio-group v-model="state.bannerData.status" size="medium">
          <el-radio border :label="1">Active</el-radio>
          <el-radio border :label="0">Disable</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button
          @click="onSubmit"
          type="primary"
          round
        >
          {{ state.bannerData.id ? 'Update' : 'Submit' }}
        </el-button>
        <el-button @click="onClose" round>Cancel</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import { reactive, computed } from 'vue'

import bannerMixin from '@/mixins/banner'

export default {
  setup () {
    const state = reactive({
      bannerData: {
        title: '',
        description: '',
        position: '',
        image: '',
        status: 1,
        type: '',
        link: ''
      }
    })

    const rules = computed(() => {
      return {
        bannerData: {
          title: { required: helpers.withMessage('Title field is required', required) },
          description: { required: helpers.withMessage('Description field is required', required) },
          type: { required: helpers.withMessage('Type field is required', required) }
        }
      }
    })

    const v$ = useValidate(rules, state)

    return {
      state,
      v$
    }
  },

  methods: {
    onClose () {
      this.$router.push({ name: 'admin-banner' })
    },

    onImageUpload (e) {
      const files = e.target.files || e.dataTransfer.files

      this.state.bannerData.image = files[0]
    },

    onSubmit () {
      this.v$.$validate()
      if (!this.v$.$error) {
        if (this.state.bannerData.id) {
          this.updateBanner(this.state.bannerData).then(() => {
            this.$message({
              message: 'Banner updated successfully.',
              type: 'success'
            })

            this.$router.push({ name: 'admin-banner' })
          }).catch(() => {
            this.$message({
              message: 'Oops! Something went wrong.',
              type: 'warning'
            })
          })
        } else {
          this.createBanner(this.state.bannerData).then(() => {
            this.$message({
              message: 'Banner created successfully.',
              type: 'success'
            })

            this.$router.push({ name: 'admin-banner' })
          }).catch(() => {
            this.$message({
              message: 'Oops! Something went wrong.',
              type: 'warning'
            })
          })
        }
      }
    }
  },

  mixins: [bannerMixin],

  mounted () {
    if (this.banner) {
      this.state.bannerData = this.banner
    }
  },

  name: 'BannerForm',

  props: {
    banner: {
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-banner-form {
  margin-top: 1rem;

  &__error {
    color: orangered;
  }
}
</style>
