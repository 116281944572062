<template>
  <div class="main">
    <router-view
      v-slot="{ Component }"
    >
      <transition
        :name="transitionName"
        mode="out-in"
      >
        <keep-alive :include="[]">
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'AppMain',

  props: {
    transitionName: {
      type: String,
      default: 'el-fade-in'
    }
  }
}
</script>
