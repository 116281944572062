<template>
  <div class="c-personal-record-form">
    <el-form
      ref="form"
      :model="state.userData"
      v-loading="state.loading"
      label-width="140px"
    >
      <el-form-item label="Nationality">
        <el-input
          v-model="state.userData.nationality"
        >
        </el-input>
        <span
          class="c-personal-record-form__error"
          v-if="v$.userData.nationality.$error"
        >
          {{ v$.userData.nationality.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Country">
        <el-select
          v-model="state.userData.country"
          placeholder="Select Country"
          class="form-control"
          filterable
        >
          <el-option
            v-for="country in countryOption"
            :key="country.value"
            :label="country.label"
            :value="country.value">
          </el-option>
        </el-select>
        <span
          class="c-personal-record-form__error"
          v-if="v$.userData.country.$error"
        >
          {{ v$.userData.country.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="City ">
        <el-select
          v-model="state.userData.city_id"
          placeholder="Select City"
          class="form-control"
          filterable
        >
          <el-option
            v-for="city in citiesOption"
            :key="city.value"
            :label="city.label"
            :value="city.value">
          </el-option>
        </el-select>
        <span
          class="c-personal-record-form__error"
          v-if="v$.userData.city_id.$error"
        >
          {{ v$.userData.city_id.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Cast ">
        <el-select
          v-model="state.userData.cast_id"
          placeholder="Select Cast"
          class="form-control"
          filterable
        >
          <el-option
            v-for="cast in castsOption"
            :key="cast.value"
            :label="cast.label"
            :value="cast.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Religion ">
        <el-select
          v-model="state.userData.religion_id"
          placeholder="Select Religion"
          class="form-control"
          filterable
        >
          <el-option
            v-for="religion in religionsOption"
            :key="religion.value"
            :label="religion.label"
            :value="religion.value">
          </el-option>
        </el-select>
        <span
          class="c-personal-record-form__error"
          v-if="v$.userData.religion_id.$error"
        >
          {{ v$.userData.religion_id.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Occupation ">
        <el-select
          v-model="state.userData.occupation_id"
          placeholder="Select Occupation"
          class="form-control"
          filterable
        >
          <el-option
            v-for="occupation in occupations"
            :key="occupation.id"
            :label="occupation.name"
            :value="occupation.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Date of Birth">
        <el-date-picker
          v-model="state.userData.dob"
          type="date"
          placeholder="Pick a day">
        </el-date-picker>
        <span
          class="c-personal-record-form__error"
          v-if="v$.userData.dob.$error"
        >
          {{ v$.userData.dob.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Height">
        <el-select
          placeholder="Please select Height"
          v-model="state.userData.height"
          filterable
        >
          <el-option value="null" label="Please Select Height">Please Select Height</el-option>
          <el-option label="3.1'" value="3.1"></el-option>
          <el-option label="3.2'" value="3.2"></el-option>
          <el-option label="3.3'" value="3.3"></el-option>
          <el-option label="3.4'" value="3.4"></el-option>
          <el-option label="3.5'" value="3.5"></el-option>
          <el-option label="3.6'" value="3.6"></el-option>
          <el-option label="3.7'" value="3.7"></el-option>
          <el-option label="3.8'" value="3.8"></el-option>
          <el-option label="3.9'" value="3.9"></el-option>
          <el-option label="3.10'" value="3.10"></el-option>
          <el-option label="3.11'" value="3.11"></el-option>
          <el-option label="4'" value="4"></el-option>
          <el-option label="4.1'" value="4.1"></el-option>
          <el-option label="4.2'" value="4.2"></el-option>
          <el-option label="4.3'" value="4.3"></el-option>
          <el-option label="4.4'" value="4.4"></el-option>
          <el-option label="4.5'" value="4.5"></el-option>
          <el-option label="4.6'" value="4.6"></el-option>
          <el-option label="4.7'" value="4.7"></el-option>
          <el-option label="4.8'" value="4.8"></el-option>
          <el-option label="4.9'" value="4.9"></el-option>
          <el-option label="4.10'" value="4.10"></el-option>
          <el-option label="4.11'" value="4.11"></el-option>
          <el-option label="5'" value="5"></el-option>
          <el-option label="5.1'" value="5.1"></el-option>
          <el-option label="5.2'" value="5.2"></el-option>
          <el-option label="5.3'" value="5.3"></el-option>
          <el-option label="5.4'" value="5.4"></el-option>
          <el-option label="5.5'" value="5.5"></el-option>
          <el-option label="5.6'" value="5.6"></el-option>
          <el-option label="5.7'" value="5.7"></el-option>
          <el-option label="5.8'" value="5.8"></el-option>
          <el-option label="5.9'" value="5.9"></el-option>
          <el-option label="5.10'" value="5.10"></el-option>
          <el-option label="5.11'" value="5.11"></el-option>
          <el-option label="6'" value="6"></el-option>
          <el-option label="6.1'" value="6.1"></el-option>
          <el-option label="6.2'" value="6.2"></el-option>
          <el-option label="6.3'" value="6.3"></el-option>
          <el-option label="6.4'" value="6.4"></el-option>
          <el-option label="6.5'" value="6.5"></el-option>
          <el-option label="6.6'" value="6.6"></el-option>
          <el-option label="6.7'" value="6.7"></el-option>
          <el-option label="6.8'" value="6.8"></el-option>
          <el-option label="6.9'" value="6.9"></el-option>
          <el-option label="6.10'" value="6.10"></el-option>
          <el-option label="6.11'" value="6.11"></el-option>
          <el-option label="7'" value="7"></el-option>
          <el-option label="7.1'" value="7.1"></el-option>
          <el-option label="7.2'" value="7.2"></el-option>
          <el-option label="7.3'" value="7.3"></el-option>
          <el-option label="7.4'" value="7.4"></el-option>
          <el-option label="7.5'" value="7.5"></el-option>
          <el-option label="7.6'" value="7.6"></el-option>
          <el-option label="7.7'" value="7.7"></el-option>
          <el-option label="7.8'" value="7.8"></el-option>
          <el-option label="7.9'" value="7.9"></el-option>
          <el-option label="7.10'" value="7.10"></el-option>
          <el-option label="7.11'" value="7.11"></el-option>
          <el-option label="8'" value="8"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Current Address">
        <el-input
          v-model="state.userData.current_address"
          type="email"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="Mother Tongue">
        <el-input
          v-model="state.userData.mother_tongue"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="Marital Status">
        <el-select
          v-model="state.userData.marital_status"
          placeholder="Select Marita Status"
          class="form-control"
        >
          <el-option :label="'Unmarried'" :value="0"></el-option>
          <el-option :label="'Married'" :value="1"></el-option>
          <el-option :label="'Separated'" :value="2"></el-option>
          <el-option :label="'Single Divorced'" :value="3"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Gender">
        <el-radio-group v-model="state.userData.gender" size="medium">
          <el-radio border :label="1">Male</el-radio>
          <el-radio border :label="0">Female</el-radio>
          <el-radio border :label="2">Unspecified</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item class="c-personal-record-form__cta-action">
        <el-button
          @click="onSubmit"
          type="primary"
          round
        >
          {{ state.userData.id ? 'Update' : 'Submit' }}
        </el-button>
        <el-button @click="onClose" round>Cancel</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import { reactive, computed } from 'vue'

import userMixin from '@/mixins/user'
import configurationMixin from '@/mixins/configuration'

const { getData } = require('country-list')

export default {
  created () {
    this.getCities()
    this.getCasts()
    this.getReligions()
    this.getOccupations()
  },

  computed: {
    countryOption () {
      return getData().map(country => {
        return {
          label: country.name,
          value: country.code
        }
      })
    },

    citiesOption () {
      return this.cities && this.cities.map(city => {
        return {
          value: city.id,
          label: city.name
        }
      })
    },

    religionsOption () {
      return this.religions && this.religions.map(religion => {
        return {
          value: religion.id,
          label: religion.name
        }
      })
    },

    castsOption () {
      return this.casts && this.casts.map(cast => {
        return {
          value: cast.id,
          label: cast.name
        }
      })
    }
  },

  setup () {
    const state = reactive({
      loading: false,
      userData: {
        country: 'NP',
        nationality: '',
        gender: 1,
        current_address: '',
        mother_tongue: '',
        city_id: '',
        cast_id: '',
        height: '',
        marital_status: 0,
        occupation_id: '',
        dob: new Date('Y-m-d'),
        educational_level_id: '',
        educational_field_id: '',
        religion_id: ''
      }
    })

    const rules = computed(() => {
      return {
        userData: {
          country: { required: helpers.withMessage('Country field is required', required) },
          nationality: { required: helpers.withMessage('Nationality field is required', required) },
          current_address: { required: helpers.withMessage('Current Address field is required', required) },
          dob: { required: helpers.withMessage('Date of birth field is required', required) },
          city_id: { required: helpers.withMessage('City field is required', required) },
          religion_id: { required: helpers.withMessage('Religion field is required', required) }
        }
      }
    })

    const v$ = useValidate(rules, state)

    return {
      state,
      v$
    }
  },

  methods: {
    onClose () {
      this.$emit('form:close')
    },

    onSubmit () {
      if (this.state.userData.id) {
        this.v$.$validate()
        if (!this.v$.$error) {
          this.state.loading = true

          this.updateUserPersonalRecord(Object.assign(this.state.userData, { user_id: this.createdUser ? this.createdUser.id : this.user.id })).then((res) => {
            this.$message({
              message: 'Personal record updated successfully.',
              type: 'success'
            })

            if (this.user && this.user.id) {
              this.getMe()
            }
            this.loading = false

            this.$emit('data:create')
          }).catch(() => {
            this.loading = false

            this.$message({
              message: 'Invalid data',
              type: 'warning'
            })
          })
        }
      } else {
        this.v$.$validate()
        if (!this.v$.$error) {
          this.state.loading = true

          this.createUserPersonalRecord(Object.assign(this.state.userData, { user_id: this.createdUser ? this.createdUser.id : this.user.id })).then((res) => {
            this.$message({
              message: 'Personal record created successfully.',
              type: 'success'
            })

            if (this.user && this.user.id) {
              this.getMe()
            }

            this.state.loading = false

            this.$emit('data:create')
          }).catch(() => {
            this.state.loading = false

            this.$message({
              message: 'Invalid data',
              type: 'warning'
            })
          })
        }
      }
    }
  },

  mixins: [userMixin, configurationMixin],

  mounted () {
    if (this.value) {
      this.state.userData = this.value
    }
  },

  name: 'UserForm',

  props: {
    value: {
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-personal-record-form {
  &__error {
    color: darkred;
  }

  &__cta-action {
    margin-top: 1rem;
  }

  .el-select {
    width: 100%;
  }
}
</style>
