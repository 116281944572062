import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  advertisements: [],
  city: null,
  cities: [],
  religion: null,
  religions: [],
  occupation: null,
  occupations: [],
  education: null,
  educations: [],
  cast: null,
  casts: [],
  notifications: []
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state
}
