import { mapActions, mapGetters } from 'vuex'

const advertisements = {
  computed: {
    ...mapGetters(
      'configuration',
      {
        advertisements: 'advertisements'
      }
    )
  },

  methods: {
    ...mapActions(
      'configuration',
      [
        'getAdvertisement',
        'getAdvertisements',
        'createAdvertisement',
        'deleteAdvertisement',
        'updateAdvertisement'
      ]
    )
  }
}

export default advertisements
