import Layout from '@/components/layout/Index'
import Blogs from '@/views/admin/blog/Index'
import AddBlog from '@/views/admin/blog/Add'
import EditBlog from '@/views/admin/blog/Edit'
import AboutUs from '@/views/admin/page/AboutUs'
import PrivacyPolicy from '@/views/admin/page/PrivacyPolicy'
import TermsConditions from '@/views/admin/page/TermsConditions'
import FAQ from '@/views/admin/page/FAQ'
import SafetyCenter from '@/views/admin/page/SafetyCenter'
import DatingInCorona from '@/views/admin/page/DatingInCorona'
import SafetyTips from '@/views/admin/page/SafetyTips'
import AdditionalResources from '@/views/admin/page/AdditionalResources'
const Configuration = () => import('@/views/admin/configuration/Index.vue')
const meta = {
  redirectOnExpire: true,
  requiresAuth: true
}

const routes = [
  {
    children: [
      {
        component: Configuration,
        name: 'configuration',
        path: '/configuration'
      },
      {
        component: Blogs,
        name: 'admin-blogs',
        path: '/admin-blogs'
      },
      {
        component: AboutUs,
        name: 'admin-about-us',
        path: '/admin_about_us',
        meta: {
          slug: 'about_us'
        }
      },
      {
        component: PrivacyPolicy,
        name: 'admin-privacy-policy',
        path: '/admin_privacy-policy',
        meta: {
          slug: 'privacy_policy'
        }
      },
      {
        component: TermsConditions,
        name: 'admin-terms-conditions',
        path: '/admin_terms-conditions',
        meta: {
          slug: 'terms_conditions'
        }
      },
      {
        component: FAQ,
        name: 'admin-faq',
        path: '/admin_faq',
        meta: {
          slug: 'faq'
        }
      },
      {
        component: SafetyCenter,
        name: 'admin-safety-center',
        path: '/admin_safety_center',
        meta: {
          slug: 'safety_center'
        }
      },
      {
        component: DatingInCorona,
        name: 'admin-dating-in-corona',
        path: '/admin_dating_in_corona',
        meta: {
          slug: 'dating_in_corona'
        }
      },
      {
        component: SafetyTips,
        name: 'admin-safety-tips',
        path: '/admin_safety_tips',
        meta: {
          slug: 'safety_tips'
        }
      },
      {
        component: AdditionalResources,
        name: 'admin-additional-resources',
        path: '/admin_additional_resources',
        meta: {
          slug: 'additional_resources'
        }
      },
      {
        component: AddBlog,
        name: 'admin-add-blog',
        path: '/admin/add-blog'
      },
      {
        component: EditBlog,
        name: 'admin-edit-blog',
        path: '/admin/edit-blog/:id'
      }
    ],
    component: Layout,
    path: '/admin',
    meta: meta
  }
]

export default routes
