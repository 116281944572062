<template>
  <Header />
  <Breadcrumb :title="'Mutual Search'" :link="'mutualsearch'" />
  <div class="search-ul-bg">
    <div class="container">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24">
          <div class="search-filter">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="16" :md="12" :lg="12">
              </el-col>
              <el-col :xs="24" :sm="8" :md="12" :lg="12">
                <div class="search-count">{{ filteredUsers ? filteredUsers.length : 0 }} Matches</div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24">
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24">
          <el-alert
            title="No users in mutual."
            type="warning"
            center
            show-icon
            :closable="false"
            v-if="!filteredUsers.length"
          />
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24">
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="12"
              :md="6"
              :lg="6"
              :key="index"
              v-for="(user, index) in filteredUsers"
            >
              <div class="boost-option" v-if="index === 2">
                <div class="boost-option-icon">
                  <div class="fa fa-bolt"></div>
                </div>
                <div class="boost-text-option"><router-link :to="{ name: 'newpackage' }">Boost me <i class="el-icon-right"></i></router-link></div>
                <div class="boost-text-search">Be the top profile in Search to get 4x more views.</div>
              </div>
              <div class="profile-user" v-else>
                <div class="profile-box">
                  <div class="var-online-part">
                    <div class="var-online">
                      <div class="var-icon">
                        <img src="@/assets/images/verified-icon.png" alt="">
                      </div>
                      <div class="var-line-icon">
                        <div class="user-online">
                          <div class="user-offline-show" title="Online"></div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <figure>
                    <a @click="onUserView(user.id)">
                      <img
                        :src="user.image ? imageBasePath + user.image : require('@/assets/images/default-user.png')"
                        alt=""
                        border="0"
                        class="img-responsive"
                        style="max-height: 14.3rem;"
                      >
                    </a>
                  </figure>
                  <a @click="onUserView(user.id)">
                    <div class="main-profile">
                      <div class="profile-info-name">{{ user.username ? user.username : user.name }}</div>
                      <div class="profile-info">
                        <div class="profile-info-left">
                          {{ user.personal_record && user.personal_record.age ? user.personal_record.age : '0'}} -
                          {{ user.personal_record && user.personal_record.city_id ? getCityById(user.personal_record.city_id) ? getCityById(user.personal_record.city_id).name : '' : '' }},
                          {{ user.personal_record && user.personal_record.country ? getName(user.personal_record.country) : getName('NP') }}
                        </div>
                        <div class="profile-info-right">
                          <p class="cam-icon">
                            {{ user.gallery_count && user.gallery_count.length ? user.gallery_count.length : 0 }}
                            <i class="fa fa-camera"></i>
                          </p>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="profile-ux-btn">
                  <div class="profile-ux-btn-left">
                    <a @click="onCommentClick(user.id)">
                      <img src="@/assets/images/comment.png" alt="">
                    </a>
                  </div>
                  <div class="profile-ux-btn-mid">
                    <a
                      @click="onConnectUser(user)"
                      v-if="!checkIfUserExist(user.connect_from_users)"
                    >
                      <img src="@/assets/images/connect.png" alt="" title="Connect">
                    </a>
                    <a v-else-if="checkIfUserPending(user.connect_from_users)">
                      <img src="@/assets/images/connect-pending.png" alt="" title="Pending">
                    </a>
                    <a v-else-if="checkIfUserRejected(user.connect_from_users)">
                      <img src="@/assets/images/connect-rejected.png" alt="" title="Rejected">
                    </a>
                    <a v-else>
                      <img src="@/assets/images/connect-active.png" alt="" title="Connected">
                    </a>
                  </div>
                  <div class="profile-ux-btn-right">
                    <a
                      class="linke-btn"
                      v-if="checkIfUserLiked(user.liked_users)"
                    >
                      <img src="@/assets/images/heart-active.png" alt="">
                    </a>
                    <a
                      @click="onLikeClick(user)"
                      class="linke-btn"
                      v-else>
                      <img src="@/assets/images/heart.png" alt=""></a>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Breadcrumb from '@/views/user/_components/Breadcrumb'

import configurationMixin from '@/mixins/configuration'
import likeCommentMixin from '@/mixins/like_comment'
import pageMixin from '@/mixins/page'
import userMixin from '@/mixins/user'

const { getName } = require('country-list')

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  created () {
    this.getUsers()
    this.onFilterUser()
  },

  computed: {
    getFilteredUser () {
      return this.users && this.users.filter(user => {
        console.log(user)
      })
    },

    imageBasePath () {
      return process.env.VUE_APP_API_IMAGE_PATH
    }
  },

  data () {
    return {
      showCommentPopup: false,
      value: null,
      activeStepBar: 0,
      fromAge: '',
      toAge: '',
      tab: 1,
      filteredUsers: []
    }
  },

  methods: {
    onCommentClick (commentToUserId) {
      this.showCommentPopup = true
      this.commentToUserId = commentToUserId
    },

    onCommentSubmit () {
      this.createComment({
        from_user_id: this.user.id,
        to_user_id: this.commentToUserId,
        comment: this.commentData
      }).then(() => {
        this.$message({
          message: 'User comment added successfully.',
          type: 'success'
        })

        this.commentData = ''
        this.commentToUserId = ''
        this.showCommentPopup = false
      }).catch(() => {
        this.$message({
          message: 'Ops! Something went wrong.',
          type: 'warning'
        })

        this.commentData = ''
        this.commentToUserId = ''

        this.showCommentPopup = false
      })
    },

    onLikeClick (user) {
      this.$confirm(`Are you sure you want to like ${user.name}?`, 'Accept', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(() => {
        this.createLike({
          from_user_id: this.user.id,
          to_user_id: user.id
        }).then(() => {
          this.$message({
            message: 'User Liked Successfully',
            type: 'success'
          })

          this.$emit('user:update')
          this.getUsers()
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      })
    },

    checkIfUserConnected (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return existingUser && existingUser.pivot.status === 1
      }

      return false
    },

    checkIfUserExist (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return !!existingUser
      }

      return false
    },

    checkIfUserPending (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return existingUser && existingUser.pivot.status === 0
      }

      return false
    },

    checkIfUserRejected (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return existingUser && existingUser.pivot.status === 2
      }

      return false
    },

    checkIfUserLiked (users) {
      if (this.user) {
        const isLiked = users && users.find(user => {
          return user.from_user_id === this.user.id
        })

        return !!isLiked
      }

      return false
    },

    onConnectUser (user) {
      if (!this.user) {
        this.$router.push({ name: 'user-login' })
        return
      }

      this.$confirm(`Are you sure you want to connect ${user.name}?`, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.connectUser({
          from_id: this.user ? this.user.id : null,
          to_id: user.id
        }).then(() => {
          this.getUsers()
          this.getMe()

          this.$message({
            type: 'success',
            message: 'User connected successfully.'
          })

          this.$router.push({ name: 'user-profile', params: { id: user.id } })
        })
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: 'Opps, Something went wrong.'
        })
      })
    },

    onCreate () {
      this.activeStepBar++
      this.tab++
    },

    onFilterUser () {
      return this.users && this.users.filter(user => {
        const connectToUsersIds = this.user && this.user.connect_to_users.map(newUser => {
          return newUser.id
        })

        if (connectToUsersIds.includes(user.id)) {
          if (!this.filteredUsers.find(filteredUser => { return filteredUser.id === user.id })) {
            this.filteredUsers.push(user)
          }
        }
        //
        // if (
        //   this.user &&
        //   this.user.personal_record &&
        //   this.user.personal_record.age &&
        //   user.personal_record && user.personal_record.age !== 'null' &&
        //   Math.abs(this.user.personal_record.age - user.personal_record.age) < 3
        // ) {
        //   if (!this.filteredUsers.find(filteredUser => { return filteredUser.id === user.id })) {
        //     this.filteredUsers.push(user)
        //   }
        // }
        //
        // if (
        //   this.user &&
        //   this.user.personal_record &&
        //   this.user.personal_record.height &&
        //   user.personal_record && user.personal_record.height !== 'null' &&
        //   Math.abs(this.user.personal_record.height - user.personal_record.height) < 0.3
        // ) {
        //   if (!this.filteredUsers.find(filteredUser => { return filteredUser.id === user.id })) {
        //     this.filteredUsers.push(user)
        //   }
        // }
        //
        // if (
        //   this.user &&
        //   this.user.personal_record &&
        //   this.user.personal_record.city_id &&
        //   user.personal_record && user.personal_record.city_id &&
        //   this.user.personal_record.city_id === user.personal_record.city_id
        // ) {
        //   if (!this.filteredUsers.find(filteredUser => { return filteredUser.id === user.id })) {
        //     this.filteredUsers.push(user)
        //   }
        // }
        //
        // if (
        //   this.user &&
        //   this.user.personal_record &&
        //   this.user.personal_record.cast_id &&
        //   user.personal_record && user.personal_record.cast_id &&
        //   this.user.personal_record.cast_id === user.personal_record.cast_id
        // ) {
        //   if (!this.filteredUsers.find(filteredUser => { return filteredUser.id === user.id })) {
        //     this.filteredUsers.push(user)
        //   }
        // }
        //
        // if (
        //   this.user &&
        //   this.user.personal_record &&
        //   this.user.personal_record.religion_id &&
        //   user.personal_record && user.personal_record.religion_id &&
        //   this.user.personal_record.religion_id === user.personal_record.religion_id
        // ) {
        //   if (!this.filteredUsers.find(filteredUser => { return filteredUser.id === user.id })) {
        //     this.filteredUsers.push(user)
        //   }
        // }
      })
    },

    getName (code) {
      return code ? getName(code) : 'Nepal'
    },

    onUserView (id) {
      this.$router.push({ name: 'user-profile', params: { id: id } })
    }
  },

  mixins: [configurationMixin, likeCommentMixin, pageMixin, userMixin],

  name: 'Custom Search'
}
</script>
<style lang="scss">
.custom-page{
  padding: 20px 0;
  background: #f3f2ef;
}

.custom-page .card-header span{
  font-size: 18px;
}

.c-register {
  &__form {
    padding: 1rem 10px;
  }

  &__tabs {
    margin-bottom: 1rem;
  }
}

.search-ul-bg{
  background: #f3f2ef;
  padding-bottom: 20px;
}

.search-count{
  font-size: 17px;
  padding:5px 10px 5px 15px;
  text-align: right;
  color: #003895;
  font-weight: 500;
}

.search-filter{
  margin: 20px 0 30px 0;
  padding: 15px 15px 15px 15px !important;
  font-size: 17px;
  font-weight: 500;
  color: #706e6e;
  border-bottom: 1px solid #ccc;
  background: #fff;
  border-radius: 5px;
}

.filter-form .input-text{
  width:12%;
  padding: 5px 5px;
  margin: 0 8px 0 2px !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  height: 28px;
  background: #fff;
  border-bottom: dashed 1px #706e6e !important;
  font-size: 15px;
  color: #706e6e;
}
</style>
