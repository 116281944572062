import { mapActions, mapGetters } from 'vuex'

const events = {
  computed: {
    ...mapGetters(
      'event',
      {
        events: 'events'
      }
    )
  },

  methods: {
    ...mapActions(
      'event',
      [
        'getEvent',
        'getEventList',
        'getEvents',
        'createEvent',
        'deleteEvent',
        'updateEvent',
        'getEventFaqById',
        'getEventFaqs',
        'updateEventFaq',
        'createEventFaq',
        'deleteEventFaq',
        'updateEventTotalVisit'
      ]
    )
  }
}

export default events
