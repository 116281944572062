import { mapActions, mapGetters } from 'vuex'

const feedbacks = {
  computed: {
    ...mapGetters(
      'feedback',
      {
        feedbacks: 'feedbacks'
      }
    )
  },

  methods: {
    ...mapActions(
      'feedback',
      [
        'getFeedbacks',
        'createFeedback',
        'deleteFeedback'
      ]
    )
  }
}

export default feedbacks
