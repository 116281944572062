import api from '@/api/banner'

const getBanners = ({ commit }) => {
  return new Promise((resolve, reject) => {
    api.getBanners()
      .then((response) => {
        commit(
          'UPDATE_BANNERS',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getBanner = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.getBanner(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createBanner = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createBanner(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateBanner = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateBanner(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deleteBanner = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.deleteBanner(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default {
  getBanner,
  getBanners,
  createBanner,
  deleteBanner,
  updateBanner
}
