<template>
  <Header />
  <div class="match-event-banner">
    <div class="container-fluid">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24">
          <div class="title">Experience Match in your city</div>
        </el-col>
      </el-row>
    </div>
  </div>
  <div class="container">
    <div class="matchevent-page">
      <el-tabs v-model="activeName" class="likes-area">
        <el-tab-pane class="tabs" label="Matched Events" name="first">
     <el-row :gutter="20" >
      <el-col :xs="24" :sm="24" :md="24" :lg="18">
        <div class="page-title" v-if="matchedEvents && matchedEvents.length">
          Match Events Near {{ getUserCity }}
        </div>
        <div class="page-title" v-else>
          No Event Matched near you city
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <el-input @keyup="onSearchEvent" placeholder="Search by keyword" v-model="searchEvent"></el-input>
      </el-col>
    </el-row>
      <el-row :gutter="20">
        <el-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="8"
          :key="index"
          v-for="(event, index) in matchedEvents"
        >
          <div class="events">
            <div class="events-box">
             <div class="events-info">
               <div class="events-dates">
                 <div class="date-option">
                   {{  new Date(event.date).toLocaleString('default', { month: 'short' }) }} <br> {{  new Date(event.date).getDate() }}
                 </div>
               </div>
             </div>
              <figure>
                <a @click="showEvent(event.id)">
                  <img
                    alt=""
                    border="0"
                    class="img-responsive"
                    :src="imageBaseUrl + event.image"
                    style="height: 14rem"
                  >
                </a>
              </figure>
            </div>
            <div class="event-title">
              {{ event.status ? 'Active' : 'Disabled' }}
              <span class="leftday">
                {{ Math.ceil(Math.abs(new Date(event.date) - new Date()) / (1000 * 60 * 60 * 24)) }} Days left
              </span>
            </div>
            <div class="event-subtitle"><a @click="showEvent(event.id)">{{ event.title }}</a></div>
            <div class="event-subtitle"><strong>{{ event.city }}</strong></div>
            <div class="event-date-time">{{  new Date(event.date).toDateString() }} {{ event.time }} </div>
            <div class="event-text">33% off for Premium Members </div>
          </div>
        </el-col>
      </el-row>
        </el-tab-pane>
        <el-tab-pane label="All Events" name="second">
          <el-row :gutter="20" >
            <el-col :xs="24" :sm="24" :md="24" :lg="18">
              <div class="page-title">Events</div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-input @keyup="onAllEventSearch" placeholder="Search by keyword" v-model="allEventSearch"></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="12"
              :md="8"
              :lg="8"
              :key="index"
              v-for="(event, index) in allEvents"
            >
              <div class="events">
                <div class="events-box">
                  <div class="events-info">
                    <div class="events-dates">
                      <div class="date-option">
                        {{  new Date(event.date).toLocaleString('default', { month: 'short' }) }} <br> {{  new Date(event.date).getDate() }}
                      </div>
                    </div>
                  </div>
                  <figure>
                    <a @click="showEvent(event.id)">
                      <img
                        alt=""
                        border="0"
                        class="img-responsive"
                        :src="imageBaseUrl + event.image"
                      >
                    </a>
                  </figure>
                </div>
                <div class="event-title">
                  {{ event.status ? 'Active' : 'Disabled' }}
                  <span class="leftday">
                {{ Math.ceil(Math.abs(new Date(event.date) - new Date()) / (1000 * 60 * 60 * 24)) }} Days left
              </span>
                </div>
                <div class="event-subtitle"><a @click="showEvent(event.id)">{{ event.title }}</a></div>
                <div class="event-subtitle"><strong>{{ event.city }}</strong></div>
                <div class="event-date-time">{{  new Date(event.date).toDateString() }} {{ event.time }} </div>
                <div class="event-text">33% off for Premium Members </div>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
  </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

import pageMixin from '@/mixins/page'
import userMixin from '@/mixins/user'
import eventMixin from '@/mixins/event'
import configurationMixin from '@/mixins/configuration'

export default {
  components: {
    Header,
    Footer
  },

  created () {
    this.getPage({
      slug: 'likes'
    }).then((res) => {
      this.value = res
    })

    this.getCities()
    this.getEventList().then((res) => {
      this.allEvents = res
      this.getMatchedEvents()
    })
  },

  computed: {
    getUserCity () {
      return this.user.personal_record && this.user.personal_record.city_id ? this.getCityById(this.user.personal_record.city_id) ? this.getCityById(this.user.personal_record.city_id).name : '' : ''
    },

    imageBaseUrl () {
      return process.env.VUE_APP_API_IMAGE_PATH
    }
  },

  data () {
    return {
      value: null,
      activeStepBar: 0,
      tab: 1,
      activeName: 'first',
      searchEvent: '',
      allEventSearch: '',
      matchedEvents: [],
      allEvents: []
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    },

    showEvent (id) {
      this.$router.push({ name: 'event-detail', params: { id: id } })
    },

    getMatchedEvents () {
      return this.events && this.events.filter(eventData => {
        if (this.getUserCity.toLowerCase() === eventData.city.toLowerCase()) {
          this.matchedEvents.push(eventData)
        }
      })
    },

    onSearchEvent () {
      if (this.searchEvent) {
        const filteredEvents = []
        this.matchedEvents && this.matchedEvents.map(event => {
          if (this.searchEvent && event.title.toLowerCase().indexOf(this.searchEvent.toLowerCase()) !== -1) {
            filteredEvents.push(event)
          }

          if (this.searchEvent && event.city.toLowerCase().indexOf(this.searchEvent.toLowerCase()) !== -1) {
            if (!filteredEvents.find(filterEvent => { return filterEvent.id === event.id })) {
              filteredEvents.push(event)
            }
          }
        })

        this.matchedEvents = filteredEvents
      } else {
        this.matchedEvents = []
        this.getMatchedEvents()
      }
    },

    onAllEventSearch () {
      if (this.allEventSearch) {
        const filteredEvents = []

        this.allEvents && this.allEvents.map(event => {
          if (this.allEventSearch && event.title.toLowerCase().indexOf(this.allEventSearch.toLowerCase()) !== -1) {
            filteredEvents.push(event)
          }

          if (this.allEventSearch && event.city.toLowerCase().indexOf(this.allEventSearch.toLowerCase()) !== -1) {
            if (!filteredEvents.find(filteredUser => { return filteredUser.id === event.id })) {
              filteredEvents.push(event)
            }
          }
        })

        this.allEvents = filteredEvents
      } else {
        this.allEvents = []
        this.allEvents = this.events
      }
    }
  },

  mixins: [configurationMixin, userMixin, eventMixin, pageMixin],
  name: 'Register'
}
</script>
<style scoped>
.match-event-banner{
  background-attachment: fixed;
  /*background-image: url(../images/matchevents-banner.jpg);*/
  background-image: url('~@/assets/images/matchevents-banner.jpg');
  background-repeat: repeat-y;
  background-position: 100% 0;
  background-size: cover;
  min-height: 340px;
  text-align: center;
  padding-top: 130px;
  color: #fff;
  width: 100%;
}

.match-event-banner .title{
  font-size: 45px;
  color: #fff;
  font-weight: 500;
}

.matchevent-page{
  padding: 30px 0;
}

.matchevent-page .page-title{
  padding: 15px 0 25PX 0;
  font-size: 24px;
}

.events{
  margin: 0;
  padding:0 0 35px 0;
}

.events figure img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  width: 100%;
}

.events figure:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.events figure {
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
  border-radius: 10px;
}

.events figure:hover+span {
  opacity: 1;
  z-index:999;
  color: #fff;
  font-size:15px;
  font-family: 'Open Sans', sans-serif;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  font-weight:500;
}

.events .events-box span {
  position: relative;
  bottom:80px;
  display: block;
  margin: 0;
  padding: 5px 0 10px 6px;
  color: #fff;
  font-size:15px;
  text-decoration: none;
  -webkit-transition: .3s ease-in-out;
  font-family: 'Open Sans', sans-serif;
  transition: .3s ease-in-out;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  font-weight:500;
  background: linear-gradient(rgba(0,130,170,0),rgb(40 40 40));
  border-radius: 0 0 20px 20px;
  min-height: 80px;
}

.events-info{
  top: 21px;
  padding: 10px;
  z-index: 999;
  position: absolute;
}

.events-dates{
  margin-left:15px;
  font-size: 14px;
  width: 70px;
}

.date-option{
  background: #fff;
  min-height: 80px;
  border-radius:10px;
  font-size: 20px;
  text-align: center;
  padding: 20px 0;
  font-weight: 700;
}

.event-title{
  margin: 0;
  padding: 10px 0 5px 5px;
  font-size:17px;
  color: #99999d;
  text-transform: uppercase;
}

.event-subtitle{
  margin: 0;
  padding: 2px 0 0 5px;
  font-size: 22px;
  color: #4b4b4c;
}

.event-date-time{
  margin: 0;
  padding: 8px 0 0 5px;
  font-size:15px;
  color: #99999d;
}

.event-text{
  margin: 0;
  padding: 5px 0 0 5px;
  font-size:15px;
  color: #99999d;
}

.leftday{
  background: #ee1c27;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 11px;
  letter-spacing:0.5px;
}

.travel-experience{
  padding: 40px 0 0 0;
}

.drop-view{
  float: right;
  padding-top: 25px;
  color: #003993;
  font-weight: 500;
}

.testimonials{
  padding: 40px 0 40px 0;
}

.testi-box{
  margin:0 0 20px 0;
  padding: 30px 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.testiname{
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.testititle{
  margin: 0;
  padding: 8px 0 0 0;
  font-size: 16px;
  font-weight: 500;
}

.testitext{
  margin: 0;
  padding: 20px 0 0 0;
  font-size: 14px;
  color: #838383;
  line-height: 20px;
}

</style>
