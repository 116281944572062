const totalLikes = state => state.totalLikes ? state.totalLikes : null

const comments = state => state.comments ? state.comments : null

const totalComments = state => state.totalComments ? state.totalComments : null

export default {
  comments,
  totalLikes,
  totalComments
}
