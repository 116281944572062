<template>
  <Page
    @cta:click="onClick"
    :title="'Advertisement'"
  >
    <template #content>
      <el-table
        :data="getAdvertisementData"
        style="width: 100%"
      >
        <el-table-column
          label="ID"
          prop="id"
          width="100"
        >
        </el-table-column>

        <el-table-column
          label="Title"
          prop="title">
        </el-table-column>

        <el-table-column
          label="Description"
          prop="description">
        </el-table-column>

        <el-table-column
          label="From Date"
          prop="from_date">
        </el-table-column>

        <el-table-column
          label="To Date"
          prop="to_date">
        </el-table-column>

        <el-table-column
          label="Position"
          prop="position">
          <template #default="scope">
            <span v-if="scope.row.position === 1">Top</span>
            <span v-if="scope.row.position === 2">Middle</span>
            <span v-if="scope.row.position === 3">Bottom</span>
          </template>
        </el-table-column>

        <el-table-column
          label="Image"
          prop="image">
          <template #default="scope">
            <img
              :src="imageBaseUrl + scope.row.image"
              width="100"
              v-if="scope.row.image"
            >
          </template>
        </el-table-column>

        <el-table-column
          label="Status"
          prop="status">
          <template #default="scope">
            <el-tag v-if="scope.row.status" type="success">Active</el-tag>
            <el-tag v-else type="warning">Disabled</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          align="right">
          <template #default="scope">
            <el-button
              @click="onEdit(scope.row.id)"
              size="mini">Edit
            </el-button>
            <el-button
              @click="onDelete(scope.row.id)"
              size="mini"
              type="danger">Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page'

import advertisementMixin from '@/mixins/advertisement'

export default {
  components: {
    Page
  },

  created () {
    this.getAdvertisements()
  },

  computed: {
    imageBaseUrl () {
      return process.env.VUE_APP_API_IMAGE_PATH
    },

    getAdvertisementData () {
      return this.advertisements && this.advertisements.map(advertisement => {
        return {
          id: advertisement.id,
          image: advertisement.image,
          description: advertisement.description,
          position: advertisement.position,
          title: advertisement.title,
          from_date: advertisement.from_date,
          to_date: advertisement.to_date,
          status: advertisement.status
        }
      })
    }
  },

  methods: {
    onClick () {
      this.$router.push({ name: 'admin-add-advertisement' })
    },

    onDelete (id) {
      this.$confirm('This will permanently delete the Advertisement. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.deleteAdvertisement(id).then(() => {
          this.getAdvertisements()

          this.$message({
            type: 'success',
            message: 'Advertisement deleted successfully.'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        })
      })
    },

    onEdit (id) {
      this.$router.push({ name: 'admin-edit-advertisement', params: { id: id } })
    }
  },

  mixins: [advertisementMixin],

  name: 'Advertisement'
}
</script>
