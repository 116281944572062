<template>
  <div>
    <el-form
      ref="form"
      label-width="140px"
    >
      <el-form-item label="Title">
        <el-input
          v-model="state.pageData.title"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="Description">
        <QuillEditor
          v-model:content="state.pageData.description"
          contentType="html"
          theme="snow"
        />
      </el-form-item>

      <el-form-item style="margin-top: 3rem">
        <el-button
          @click="onSubmit"
          type="primary"
          round
        >
          Submit
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import '@vueup/vue-quill/dist/vue-quill.bubble.css'

import pageMixin from '@/mixins/page'
import { computed, reactive } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import useValidate from '@vuelidate/core'

export default {
  setup () {
    const state = reactive({
      pageSlug: '',
      pageData: {
        title: '',
        description: ''
      }
    })

    const rules = computed(() => {
      return {
        pageData: {
          title: { required: helpers.withMessage('Title field is required', required) },
          description: { required: helpers.withMessage('Description field is required', required) }
        }
      }
    })

    const v$ = useValidate(rules, state)

    return {
      state,
      v$
    }
  },

  components: {
    QuillEditor
  },

  data () {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ['bold', 'italic', '|', 'link']
      }
    }
  },

  created () {
    this.state.pageSlug = this.$route.meta.slug
  },

  methods: {
    onEditorInput (value) {
      this.state.pageData.description = value.ops[0].insert
    },

    onSubmit () {
      this.loading = true

      this.createPage({
        title: this.state.pageData.title,
        description: this.state.pageData.description,
        slug: this.state.pageSlug
      }).then((res) => {
        this.$emit('page:created')

        this.loading = false
      }).catch(() => {
        this.loading = false

        this.$message({
          message: 'Ops, Something went wrong.',
          type: 'success'
        })
      })
    }
  },

  mixins: [pageMixin],

  mounted () {
    if (this.pageValue) {
      this.state.pageData.title = this.pageValue.title
      setTimeout(() => {
        this.state.pageData.description = this.pageValue.description
      }, 2000)
    }
  },

  name: 'AdminPage',

  props: {
    pageValue: {
      required: false
    }
  },

  watch: {
    pageValue: {
      deep: true,
      handler (newValue) {
        if (newValue) {
          this.state.pageData.title = newValue.title
          this.state.pageData.description = newValue.description
        }
      }
    }
  }
}
</script>

<style lang="scss">
.c-page {
  .ck-editor__editable {
    min-height: 20rem !important;
  }
}
</style>
