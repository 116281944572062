import api from '@/api/event'

const getEvents = ({ commit }) => {
  return new Promise((resolve, reject) => {
    api.getEvents()
      .then((response) => {
        commit(
          'UPDATE_EVENTS',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getEventList = ({ commit }) => {
  return new Promise((resolve, reject) => {
    api.getEventList()
      .then((response) => {
        commit(
          'UPDATE_EVENTS',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getEvent = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.getEvent(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createEvent = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createEvent(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateEvent = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateEvent(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deleteEvent = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.deleteEvent(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateEventTotalVisit = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.updateEventTotalVisit(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getEventFaqs = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.getEventFaqs(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getEventFaqById = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.getEventFaqById(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createEventFaq = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createEventFaq(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateEventFaq = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateEventFaq(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deleteEventFaq = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.deleteEventFaq(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default {
  getEvent,
  getEventList,
  getEvents,
  createEvent,
  deleteEvent,
  updateEvent,
  getEventFaqs,
  getEventFaqById,
  createEventFaq,
  updateEventFaq,
  deleteEventFaq,
  updateEventTotalVisit
}
