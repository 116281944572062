<template>
  <div></div>
</template>

<script>
export default {
  created () {
    const routerParams = this.$route.params.status

    if (routerParams) {
      this.$message({
        message: 'Congratulation, Your email has been verified. Please login',
        type: 'success'
      })

      this.$router.push({ name: 'user-login' })
    }
  }
}
</script>
