<template>
  <div class="c-advertisement-form">
    <el-form
      ref="form"
      :model="state.advertisementData"
      label-width="140px"
    >
      <el-form-item label="Title">
        <el-input
          v-model="state.advertisementData.title"
        >
        </el-input>
        <span
          class="c-advertisement-form__error"
          v-if="v$.advertisementData.title.$error"
        >
          {{ v$.advertisementData.title.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Description">
        <el-input
          v-model="state.advertisementData.description"
          type="textarea"
          rows="4"
        >
        </el-input>
        <span
          class="c-advertisement-form__error"
          v-if="v$.advertisementData.description.$error"
        >
          {{ v$.advertisementData.description.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Link">
        <el-input
          v-model="state.advertisementData.link"
          type="text"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="From Date">
        <el-date-picker
          v-model="state.advertisementData.from_date"
          type="date"
          placeholder="Select from date">
        </el-date-picker>
        <span
          class="c-advertisement-form__error"
          v-if="v$.advertisementData.from_date.$error"
        >
          {{ v$.advertisementData.from_date.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="To Date">
        <el-date-picker
          v-model="state.advertisementData.to_date"
          type="date"
          placeholder="Select to date">
        </el-date-picker>
        <span
          class="c-advertisement-form__error"
          v-if="v$.advertisementData.to_date.$error"
        >
          {{ v$.advertisementData.to_date.$errors[0].$message }}
        </span>
      </el-form-item>

      <el-form-item label="Image">
        <input
          @input="onImageUpload"
          type="file"
        />
      </el-form-item>

      <el-form-item label="Position">
        <el-select
          v-model="state.advertisementData.position"
          placeholder="Select Position"
          class="form-control"
        >
          <el-option
            label="Top"
            :value="1"
          ></el-option>
          <el-option
            label="Middle"
            :value="2"
          ></el-option>
          <el-option
            label="Bottom"
            :value="3"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Status">
        <el-radio-group v-model="state.advertisementData.status" size="medium">
          <el-radio border :label="1">Active</el-radio>
          <el-radio border :label="0">Disable</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button
          @click="onSubmit"
          type="primary"
          round
        >
          {{ state.advertisementData.id ? 'Update' : 'Submit' }}
        </el-button>
        <el-button @click="onClose" round>Cancel</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import { reactive, computed } from 'vue'

import advertisementMixin from '@/mixins/advertisement'

export default {
  setup () {
    const state = reactive({
      advertisementData: {
        title: '',
        description: '',
        position: 3,
        from_date: '',
        to_date: '',
        link: '',
        image: '',
        status: 1
      }
    })

    const rules = computed(() => {
      return {
        advertisementData: {
          title: { required: helpers.withMessage('Title field is required', required) },
          description: { required: helpers.withMessage('Description field is required', required) },
          from_date: { required: helpers.withMessage('From date field is required', required) },
          to_date: { required: helpers.withMessage('To date field is required', required) }
        }
      }
    })

    const v$ = useValidate(rules, state)

    return {
      state,
      v$
    }
  },

  methods: {
    onClose () {
      this.$router.push({ name: 'admin-advertisement' })
    },

    onImageUpload (e) {
      const files = e.target.files || e.dataTransfer.files

      this.state.advertisementData.image = files[0]
    },

    onSubmit () {
      this.v$.$validate()
      if (!this.v$.$error) {
        if (this.state.advertisementData.id) {
          this.updateAdvertisement(this.state.advertisementData).then(() => {
            this.$message({
              message: 'Advertisement updated successfully.',
              type: 'success'
            })

            this.$router.push({ name: 'admin-advertisement' })
          }).catch(() => {
            this.$message({
              message: 'Oops! Something went wrong.',
              type: 'warning'
            })
          })
        } else {
          this.createAdvertisement(this.state.advertisementData).then(() => {
            this.$message({
              message: 'Advertisement created successfully.',
              type: 'success'
            })

            this.$router.push({ name: 'admin-advertisement' })
          }).catch(() => {
            this.$message({
              message: 'Oops! Something went wrong.',
              type: 'warning'
            })
          })
        }
      }
    }
  },

  mixins: [advertisementMixin],

  mounted () {
    if (this.advertisement) {
      this.state.advertisementData = this.advertisement
    }
  },

  name: 'AdvertisementForm',

  props: {
    advertisement: {
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-advertisement-form {
  margin-top: 1rem;

  &__error {
    color: orangered;
  }
}
</style>
