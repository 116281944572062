import request from '@/utils/request'

const searchUser = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/search',
      method: 'post',
      data: {
        country: data.country,
        gender: data.gender,
        age: data.age,
        height: data.height,
        religion_id: data.religion,
        city_id: data.city,
        location: data.location,
        cast_id: data.cast,
        educational_level_id: data.educational_level_id,
        occupation_id: data.occupation,
        name: data.name,
        education: data.education,
        username: data.username
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

export default {
  searchUser
}
