import request from '@/utils/request'

const getPage = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/page/' + data.slug,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createPage = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/page/add',
      method: 'post',
      data: {
        title: data.title,
        description: data.description,
        slug: data.slug
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

export default {
  getPage,
  createPage
}
