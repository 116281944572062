import request from '@/utils/request'

const getTotalLikes = ($id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/totalLike/' + $id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getTotalComment = ($id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/totalComment/' + $id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getComments = ($id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user-comments/' + $id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createComment = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('from_user_id', data.from_user_id)
    formData.set('to_user_id', data.to_user_id)
    formData.set('comment', data.comment)
    if (data.parent_id) {
      formData.set('parent_id', data.parent_id)
    }

    return request({
      url: '/user-comment',
      method: 'post',
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createLike = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('from_user_id', data.from_user_id)
    formData.set('to_user_id', data.to_user_id)

    return request({
      url: '/user-like',
      method: 'post',
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

export default {
  getComments,
  getTotalComment,
  getTotalLikes,
  createComment,
  createLike
}
