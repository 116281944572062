<template>
  <UserDashboard />
</template>

<script>
import UserDashboard from '@/views/user/UserDashboard'
export default {
  beforeRouteEnter (to, from, next) {
    const user = localStorage.getItem('user')

    if (!user) {
      next('/user_login')
    } else {
      next()
    }
  },

  components: {
    UserDashboard
  },

  name: 'NextUser'
}
</script>
