import request from '@/utils/request'

const createUserPersonalRecord = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/personal-record/add',
      method: 'post',
      data: {
        country: data.country,
        nationality: data.nationality,
        gender: data.gender,
        city_id: data.city_id,
        user_id: data.user_id,
        cast_id: data.cast_id,
        current_address: data.current_address,
        mother_tongue: data.mother_tongue,
        marital_status: data.marital_status,
        dob: data.dob,
        religion_id: data.religion_id,
        occupation_id: data.occupation_id,
        height: data.height
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateSearchHistory = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/search-history/store',
      method: 'post',
      data: data
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deleteUserInterest = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/interest/delete/' + id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deleteUserGallery = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/gallery/delete/' + id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const blockUser = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/block-user',
      method: 'post',
      data: {
        from_user_id: data.from_user_id,
        to_user_id: data.to_user_id,
        type: data.type
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateUserSetting = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/update-user-setting',
      method: 'post',
      data: data
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const unblockUser = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/unblock-user',
      method: 'post',
      data: {
        from_user_id: data.from_user_id,
        to_user_id: data.to_user_id,
        type: data.type
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateUserPersonalRecord = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/personal-record/update',
      method: 'post',
      data: {
        country: data.country,
        nationality: data.nationality,
        gender: data.gender,
        city_id: data.city_id,
        cast_id: data.cast_id,
        user_id: data.user_id,
        current_address: data.current_address,
        mother_tongue: data.mother_tongue,
        marital_status: data.marital_status,
        occupation_id: data.occupation_id,
        dob: data.dob,
        religion_id: data.religion_id,
        height: data.height
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createUserInformation = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/user-information/add',
      method: 'post',
      data: {
        name: data.name,
        email: data.email,
        phone: data.phone,
        password: data.password
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createUserAbout = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/personal-record/about/add',
      method: 'post',
      data: {
        user_id: data.user_id,
        about: data
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createUserSummary = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/personal-record/summary/add',
      method: 'post',
      data: {
        user_id: data.user_id,
        summary: data
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createEducation = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/education/add',
      method: 'post',
      data: {
        user_id: data.user_id,
        educational_level: data.education_level,
        educational_field: data.education_field
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateEducation = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/education/update',
      method: 'post',
      data: {
        user_id: data.user_id,
        educational_level: data.education_level,
        educational_field: data.education_field
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createFamilyDetails = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/family-details/add',
      method: 'post',
      data: {
        user_id: data.user_id,
        family_status: data.family_status,
        family_number: data.family_number,
        family_type: data.family_type,
        country_living: data.country_living,
        country: data.country
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateFamilyDetail = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/family-details/update',
      method: 'post',
      data: {
        user_id: data.user_id,
        family_status: data.family_status,
        family_number: data.family_number,
        family_type: data.family_type,
        country_living: data.country_living,
        country: data.country
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createPhysicalAppAndLifeStyle = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/physical-app-lifestyle/add',
      method: 'post',
      data: {
        user_id: data.user_id,
        height: data.height,
        body_type: data.body_type,
        skin_type: data.skin_type,
        food: data.food,
        drinking: data.drinking,
        smoking: data.smoking
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updatePhysicalAppAndLifeStyle = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/physical-app-lifestyle/update',
      method: 'post',
      data: {
        user_id: data.user_id,
        height: data.height,
        body_type: data.body_type,
        skin_type: data.skin_type,
        food: data.food,
        drinking: data.drinking,
        smoking: data.smoking
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateProfileVisible = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/updateProfileVisible',
      method: 'post',
      data: {
        user_id: data.user_id,
        data: data.profile_visible
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const addUserInterest = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/interest/add',
      method: 'post',
      data: {
        user_id: data.user_id,
        interest: data.interest
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getMe = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/me',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const forgetPassword = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/auth/forget',
      method: 'post',
      data: {
        email: data.email
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const resetPassword = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/auth/reset',
      method: 'post',
      data: {
        token: data.token,
        password: data.password
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const resetUserPassword = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: 'change-password/user',
      method: 'post',
      data: {
        user_id: data.user_id,
        password: data.password
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getEducationFields = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/education-field-list',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getEducationLevels = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/education-level-list',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getUser = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/' + id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deactivateUser = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/deactivate/' + id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getMostViewedUsers = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/most-viewed-users',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getUsers = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/users',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const loginUser = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/login',
      method: 'post',
      data: {
        username: data.username,
        password: data.password
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateProfileImage = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('image', data.image)
    formData.set('userId', data.user_id)

    return request({
      url: '/user/update-profile-image',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateCoverImage = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('cover_image', data.cover_image)
    formData.set('userId', data.user_id)

    return request({
      url: '/user/update-cover-image',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const toggleStatus = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/toggleStatus/' + id,
      method: 'post'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const logout = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/logout',
      method: 'post'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deleteUser = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/user/delete/' + data.id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateUserViewedCount = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/updateUserViewedCount',
      method: 'post',
      data: {
        user_id: data.user_id,
        from_user_id: data.from_user_id
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const connectUser = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: 'connect_user',
      method: 'post',
      data: {
        from_id: data.from_id,
        to_id: data.to_id
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const acceptUser = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: 'accept_user',
      method: 'post',
      data: {
        from_id: data.from_id,
        to_id: data.to_id
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const rejectUser = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: 'reject_user',
      method: 'post',
      data: {
        from_id: data.from_id,
        to_id: data.to_id
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const changeEmail = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: 'user/email/change',
      method: 'post',
      data: {
        user_id: data.user_id,
        email: data.email
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const changeName = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: 'user/name/change',
      method: 'post',
      data: {
        user_id: data.user_id,
        name: data.name
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const changeSeekingAge = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: 'user/seekingAge/change',
      method: 'post',
      data: {
        user_id: data.user_id,
        seeking_age: data.seeking_age
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const changeSeekingDistance = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: 'user/seekingDistance/change',
      method: 'post',
      data: {
        user_id: data.user_id,
        seeking_distance: data.seeking_distance
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const storeUsername = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: 'storeUsername',
      method: 'post',
      data: {
        user_id: data.user_id,
        username: data.username
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

export default {
  createUserAbout,
  createUserPersonalRecord,
  createUserInformation,
  createPhysicalAppAndLifeStyle,
  createEducation,
  createFamilyDetails,
  getMe,
  getEducationFields,
  getEducationLevels,
  getUser,
  getUsers,
  loginUser,
  deleteUser,
  logout,
  toggleStatus,
  updateProfileImage,
  updateCoverImage,
  updatePhysicalAppAndLifeStyle,
  updateEducation,
  updateFamilyDetail,
  updateUserPersonalRecord,
  connectUser,
  acceptUser,
  rejectUser,

  forgetPassword,
  resetPassword,

  resetUserPassword,

  updateUserViewedCount,
  getMostViewedUsers,

  deactivateUser,
  createUserSummary,

  updateProfileVisible,
  addUserInterest,
  changeEmail,
  changeName,

  changeSeekingAge,
  changeSeekingDistance,

  blockUser,
  unblockUser,
  updateUserSetting,
  updateSearchHistory,
  deleteUserGallery,
  deleteUserInterest,

  storeUsername
}
