// Vuex mutations are very similar to events: each mutation has a string type and a handler.
const UPDATE_BANNER = (state, banner) => {
  state.banner = banner
}

const UPDATE_BANNERS = (state, banners) => {
  state.banners = banners
}

export default {
  UPDATE_BANNER,
  UPDATE_BANNERS
}
