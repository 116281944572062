<template>
  <Header />
  <Breadcrumb :title="'Blocked Profiles'" :link="'setting'" />
  <div class="information-ul">
    <div class="container">
      <el-row :gutter="40" >
        <el-col :xs="24" :sm="8" :md="8" :lg="8">
          <div class="sidebar-bg">
            <div class="page-links">
              <ul>
                <li><a href="/#/blocked-profiles" class="active"> Blocked Profiles</a></li>
                <li><a href="/#/manage-subscription"> Manage Subscriptions</a></li>
                <li><a href="/#/email-notification"> Email Notifications</a></li>
                <li><a href="/#/site-notification"> Site Notification</a></li>
                <li><a href="/#/verify-account"> Verify your Account</a></li>
              </ul>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="14" :md="16" :lg="16">
          <div class="page-title">Blocked Profiles</div>
          <p>Once you block someone, that person can no longer see things you post on your profile,
             invite you to events or groups, start a conversation with you, or add you as a friend.</p>
          <div class="block-profile-form">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="Block Users">
                <el-input @keyup="onUserInput"  placeholder="Type a Name" class="input-forms" v-model="blockedEmail"></el-input>
              </el-form-item>

              <div class="searched-users" style="margin: 10px">
                <div
                  class="searched-user"
                  v-for="user in userList"
                  :key="user.id"
                  style="margin: 0"
                >
                  <div class="content">
                    <img class="right floated mini ui image" style="max-height: 5.3rem" :src="user.image ? imageBasePath + user.image : require('@/assets/images/default-user.png')" width="100" />
                    <div class="header">{{ user.name }}</div>
                  </div>
                  <el-form-item>
                    <el-button @click="onBlockUser(user)" type="primary">Block</el-button>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        <div class="blocked-profile-list">
          <ul>
            <li
              :key="index"
              v-for="(user, index) in blockedUsers"
            >{{ user.name }} <a @click="onUnblockUser(user)">Unblock</a></li>
          </ul>
        </div>
          <div class="not-blocking" v-if="blockedUsers && !blockedUsers.length">
            You are not blocking any profile
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Breadcrumb from '@/views/user/_components/Breadcrumb'

import pageMixin from '@/mixins/page'
import userMixin from '@/mixins/user'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  created () {
    this.getUsers()
    this.getMeData()
  },

  computed: {
    blockedUsers () {
      return this.userData && this.userData.blocked_users
    },

    imageBasePath () {
      return process.env.VUE_APP_API_IMAGE_PATH
    }
  },

  data () {
    return {
      value: null,
      activeStepBar: 0,
      tab: 1,
      userData: '',
      blockedEmail: '',
      userList: []
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    },

    getMeData () {
      this.getMe().then((user) => {
        this.userData = user
      })
    },

    onUserInput () {
      const connectToUsers = this.userData.connect_to_users

      const newUsers = connectToUsers.concat(this.userData.connect_from_users)

      this.userList = []

      return newUsers && newUsers.map(user => {
        if (user && this.blockedEmail && user.name.toLowerCase().indexOf(this.blockedEmail.toLowerCase()) !== -1) {
          this.userList.push(user)
        }
      })
    },

    onUnblockUser (user) {
      this.$confirm(`Are you sure you want to unblock ${user.name}?`, 'Unblock', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(() => {
        this.unblockUser({
          from_user_id: this.user.id,
          to_user_id: user.id,
          type: 1
        }).then(() => {
          this.$message({
            message: 'User unblocked Successfully',
            type: 'success'
          })

          this.getMeData()
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      })
    },

    onBlockUser (user) {
      this.$confirm(`Are you sure you want to block ${user.name}?`, 'Unblock', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(() => {
        this.blockUser({
          from_user_id: this.user.id,
          to_user_id: user.id,
          type: 1
        }).then(() => {
          this.$message({
            message: 'User blocked Successfully',
            type: 'success'
          })

          this.getMeData()
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      })
    }
  },

  mixins: [userMixin, pageMixin],

  name: 'Register'
}
</script>
<style scoped>
.information-ul {
  margin: 0;
  padding: 40px 0;
  background: #fff;
  border-top: 1px solid #ccc;
}
.information-ul .page-title {
  font-size: 24px !important;
  padding-top: 10px;
}

.information-ul p{
  padding: 10px 0;
  line-height: 24px;
}

.sidebar-bg{
  background: #fff;
  padding-bottom: 40px;
}

.searched-users {
  display: flex;
}

.searched-user {
  margin-left: 1rem !important;
}

.page-links{
  margin: 0;
  padding:10px 0 0 0;
}

.page-links ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-links li{
  margin: 0 5px;
  padding: 20px 0 20px 20px;
  list-style: none;
  font-size: 17px;
  border-bottom: 1px solid #ccc;
}

.page-links li a{
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all 0.3s ease-in 0s;
}

.page-links li a:hover{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}

.page-links li a.active{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}

.block-profile-form{
  padding: 30px 0 5px 0;
}

.block-profile-form button{
  margin-top: 5px;
}

.blocked-profile-list{
  margin: 0;
  padding: 0;
}

.blocked-profile-list ul{
  margin: 0;
  padding: 0 0 0 108px;
}

.blocked-profile-list li{
  margin: 0;
  padding: 0px 0 15px 0;
  font-size: 15px;
  list-style-type: disc !important;
}

.blocked-profile-list li a{
  margin: 0;
  padding:0;
  color: #003993;
}

.not-blocking{
  margin: 30px 0 0 0;
  padding: 20px;
  font-size: 18px;
  color: #003993;
  background: #f6f8f9;
  border-radius: 5px;
}

</style>
