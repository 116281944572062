import request from '@/utils/request'

const getEducations = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/educations',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getEducation = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/admin-education/' + id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createEducation = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/education',
      method: 'post',
      data: data
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateEducation = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/admin-education/' + data.id,
      method: 'post',
      data: data
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deleteEducation = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/admin-education/delete/' + data.id,
      method: 'post'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

export default {
  getEducation,
  getEducations,
  createEducation,
  deleteEducation,
  updateEducation
}
