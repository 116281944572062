<template>
  <Header />
  <div class="event-single-banner">
    <div class="banner-content">
      <div class="title">{{ eventData.title }}</div>
    </div>
    <img
      :src="eventData.banner_image ? (imageBasePath + eventData.banner_image) : require('@/assets/images/matchevents-banner.jpg')"
      alt="">
  </div>
<!--  <div class="match-event-banner">
    <div class="container-fluid">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24">
          <div class="title">{{ eventData.title }}</div>
        </el-col>
      </el-row>
    </div>
  </div>-->
  <div class="events-detail-page">
  <div class="container">
    <div class="events-detail-page">
      <el-row :gutter="40" >
        <el-col :xs="24" :sm="15" :md="15" :lg="15">
          <div class="backto">
            <router-link :to="{ name: 'matchevents' }"><i class="el-icon-back"></i>
              Back to  all events
            </router-link>
          </div>
          <div class="event-description">{{ eventData.title }}</div>
          <div class="event-date"><i class="elicon el-icon-date"></i> {{  new Date(eventData.date).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }} </div>
          <div class="event-time"><i class="elicon el-icon-stopwatch"></i> {{ eventData.time }} </div>
          <div class="event-ticket"><i class="elicon el-icon-s-ticket"></i> {{ Math.ceil(Math.abs(new Date(eventData.date) - new Date()) / (1000 * 60 * 60 * 24)) }} Days to get Ticket </div>
          <div class="event-details">
            <div class="details-title">Event Details</div>
            <div class="events-info">{{ eventData.description }}
            </div>
          </div>
          <div class="know-beforego">
            <div class="title">Know Before you Go</div>
            <div class="knowbeforego">
              <div class="know-icon">
                <i class="el-icon-info"></i>
              </div>
              <div class="know-content">
                <p>{{ eventData.know_before_go }}</p>
              </div>
            </div>
          </div>
          <div class="faqs">
            <div class="title">FAQs</div>
            <el-collapse v-model="activeName">
              <el-collapse-item
                :key="index"
                :title="eventFaq.question"
                :name="index"
                v-for="(eventFaq, index) in eventData.faqs"
              >
                <div>{{ eventFaq.answer }}</div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-col>
        <el-col :xs="24" :sm="9" :md="9" :lg="9">
         <div class="event-sidebar">
           <img :src="imageBasePath + eventData.image" style="width: 100%" >
         </div>
        </el-col>
      </el-row>
      <div class="more-experience">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24">
        <div class="title">More Experiences to love</div>
          </el-col>
        </el-row>
      <el-row :gutter="20">
        <el-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="8"
          :key="index"
          v-for="(event, index) in events"
        >
          <div class="events">
            <div class="events-box">
              <div class="events-info">
                <div class="events-dates">
                  <div class="date-option">
                    {{  new Date(event.date).toLocaleString('default', { month: 'short' }) }} <br> {{  new Date(event.date).getDate() }}
                  </div>
                </div>
              </div>
              <figure>
                <a @click="showEvent(event.id)">
                  <img
                    alt=""
                    border="0"
                    class="img-responsive"
                    :src="imageBasePath + event.image"
                    style="height: 14rem"
                  >
                </a>
              </figure>
            </div>
            <div class="event-title">
              {{ event.status ? 'Active' : 'Disabled' }}
            </div>
            <div class="event-subtitle"><a @click="showEvent(event.id)">{{ event.title }}</a></div>
            <div class="event-date-time">{{  new Date(event.date).toDateString() }} {{ event.time }} </div>
          </div>
        </el-col>
      </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24">
          <div class="event-views">
            Total Visits: {{ eventData.total_visits }}
          </div>
          </el-col>
        </el-row>
    </div>
  </div>
  </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

import pageMixin from '@/mixins/page'
import eventMixin from '@/mixins/event'

export default {
  components: {
    Header,
    Footer
  },

  created () {
    this.getPage({
      slug: 'likes'
    }).then((res) => {
      this.value = res
    })

    this.getEvent(this.$route.params.id).then((res) => {
      this.eventData = res
    })

    this.getEventList()
  },

  computed: {
    imageBasePath () {
      return process.env.VUE_APP_API_IMAGE_PATH
    }
  },

  data () {
    return {
      activeName: 1,
      value: null,
      activeStepBar: 0,
      tab: 1,
      eventData: ''
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    },

    showEvent (id) {
      this.$router.push({ name: 'event-detail', params: { id: id } })
    }
  },

  mixins: [eventMixin, pageMixin],

  mounted () {
    const eventId = this.$route.params.id

    this.updateEventTotalVisit(eventId)
  },

  watch: {
    $route (to, from) {
      const eventId = to.params.id

      this.getEvent(eventId).then(newEvent => {
        this.eventData = newEvent
      })
    }
  },

  name: 'Register'
}
</script>
<style scoped>
.event-single-banner{
  background-attachment: fixed;
  background-repeat: repeat-y;
  background-position: 100% 0;
  background-size: cover;
  text-align: center;
  color: #fff;
  width: 100%;
  height: 340px;
  overflow: hidden;
}

.event-single-banner img{
  width: 100%;
  height: auto;
}

.banner-content{
  position: absolute;
  bottom: 20%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 15%;
  color: #fff;
  text-align: center;
  width: 100%;
}

.event-single-banner .title{
  font-size: 45px;
  color: #fff;
  font-weight: 500;
}

@media screen and (min-width: 200px) and (max-width:768px) {
  .event-single-banner{
    text-align: center;
    color: #fff;
    width: 100%;
    height: auto !important;
    overflow: hidden;
  }
}

.match-event-banner{
  background-attachment: fixed;
  /*background-image: url(../images/matchevents-banner.jpg);*/
  background-image: url('~@/assets/images/matchevents-banner.jpg');
  background-repeat: repeat-y;
  background-position: 100% 0;
  background-size: cover;
  min-height: 340px;
  text-align: center;
  padding-top: 130px;
  color: #fff;
  width: 100%;
}

.match-event-banner .title{
  font-size: 45px;
  color: #fff;
  font-weight: 500;
}

.backto{
  padding: 5px;
  color: #003993;
  font-size: 15px;
}

.events-detail-page{
  padding: 30px 0;
}

.events-detail-page .event-title{
  padding: 15px 0 10px 0;
  font-size: 28px;
}

.events-detail-page .event-description{
  padding: 10px 0 10px 0;
  font-size: 16px;
  line-height: 22px;
}

.events-detail-page .event-date{
  padding: 25px 0 0 0;
  font-size: 16px;
  line-height: 22px;
}

.events-detail-page .event-date .elicon{
  font-size: 20px ;
  color: #003993;
}

.events-detail-page .event-time{
  padding: 10px 0 0 0;
  font-size: 16px;
  line-height: 22px;
}

.events-detail-page .event-time .elicon{
  font-size: 22px ;
  color: #003993;
}

.events-detail-page .another-round{
  padding: 10px 0 0 0;
  font-size: 16px;
  line-height: 22px;
}

.events-detail-page .another-round .elicon{
  font-size: 22px ;
  color: #003993;
}

.events-detail-page .another-round a{
  color: #003993;
}

.events-detail-page .event-ticket{
  padding: 10px 0 0 0;
  font-size: 16px;
  line-height: 22px;
}

.events-detail-page .event-ticket .elicon{
  font-size: 22px ;
  color: #003993;
}

.ticket-includes{
  margin: 0;
  padding:50px 0 0 0;
}

.ticket-includes .include-title{
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.ticket-includes .listing{
  margin: 0;
  padding:10px 0;
}

.ticket-includes .listing ul{
  margin: 0;
  padding:0 0 0 35px;
}

.ticket-includes .listing li{
  margin: 0;
  padding:8px 0 0 0;
  list-style-type: disc !important;
  font-size: 16px;
}

.event-details{
  margin: 0;
  padding:50px 0 0 0;
}

.event-details .details-title{
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.event-details .events-info{
  padding: 15px 0 0 0;
  font-size: 16px;
  line-height: 22px;
}
.event-details .events-info a{
  color: #003993;
}

.know-beforego{
  margin: 0;
  padding:50px 0 0 0;
}

.know-beforego .title{
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.knowbeforego{
  margin: 0;
  padding: 20px 0 0 0;
  width: 100%;
}

.know-icon{
  float: left;
  width:60px;
  font-size: 60px;
  margin-right: 10px;
}

.know-content{
  padding: 0;

}

.know-content p{
  line-height: 22px;
  font-size: 16px;
}

.gmap{
  margin: 0;
  padding:80px 0 0 0;
}

.gmap .title{
  padding: 0 0 20px 0;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.locations-title{
  margin: 0;
  padding:15px 0 0 0;
  font-size: 18px;
}

.locations{
  margin: 0;
  padding: 5px 0 0 0;
  font-size: 16px;
}

.direction{
  margin: 0;
  padding: 8px 0 0 0;
  font-size: 14px;
  color: #237eeb;
}

.direction a{
  color: #003993;
}

.faqs{
  margin: 0;
  padding:80px 0 0 0;
}

.faqs .title{
  padding: 0 0 20px 0;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.more-experience{
  margin: 0;
  padding:40px 0 15px 0;
}

.more-experience .title{
  margin: 0;
  padding:30px 0 20px 0;
  font-size: 24px;
}

.more-experience .events figure img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  width: 100%;
}

.more-experience .events figure:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.more-experience .events figure {
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
  border-radius: 10px;
}

.more-experience .events figure:hover+span {
  opacity: 1;
  z-index:999;
  color: #fff;
  font-size:15px;
  font-family: 'Open Sans', sans-serif;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  font-weight:500;
}

.more-experience .events .events-box span {
  position: relative;
  bottom:80px;
  display: block;
  margin: 0;
  padding: 5px 0 10px 6px;
  color: #fff;
  font-size:15px;
  text-decoration: none;
  -webkit-transition: .3s ease-in-out;
  font-family: 'Open Sans', sans-serif;
  transition: .3s ease-in-out;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  font-weight:500;
  background: linear-gradient(rgba(0,130,170,0),rgb(40 40 40));
  border-radius: 0 0 20px 20px;
  min-height: 80px;
}

.more-experience .events-info{
  top: 21px;
  padding: 10px;
  z-index: 999;
  position: absolute;
}

.more-experience .events-dates{
  margin-left:15px;
  font-size: 14px;
  width: 70px;
}

.more-experience .date-option{
  background: #fff;
  min-height: 80px;
  border-radius:10px;
  font-size: 20px;
  text-align: center;
  padding: 20px 0;
  font-weight: 700;
}

.more-experience .event-title{
  margin: 0;
  padding: 10px 0 5px 5px;
  font-size:17px;
  color: #99999d;
  text-transform: uppercase;
}

.more-experience .event-subtitle{
  margin: 0;
  padding: 2px 0 0 5px;
  font-size: 22px;
  color: #4b4b4c;
}

.more-experience .event-date-time{
  margin: 0;
  padding: 8px 0 0 5px;
  font-size:15px;
  color: #99999d;
}

.more-experience .event-text{
  margin: 0;
  padding: 5px 0 0 5px;
  font-size:15px;
  color: #99999d;
}

.more-experience .leftday{
  background: #237eeb;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 11px;
  letter-spacing:0.5px;
}

.event-sidebar{
  margin: 0;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.spot-left{
  margin:0;
  padding: 5px 0 5px 0;
  width: auto;
}

.spot-left a{
  margin:0;
  padding: 8px 10px;
  font-size: 13px;
  background: #ee1c27;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  letter-spacing:-0.5px;
}

.p-member{
  margin:0;
  padding: 15px 0 0 0;
  font-size: 14px;
}

.p-pricing{
  margin:0;
  padding: 5px 0 0 0;
  font-size: 15px;
}

.p-pricing span{
  margin:0;
  padding: 0;
  font-weight: 600;
  font-size: 18px;
}

.savespot-btn-area{
  margin: 40px  auto;
  width: 100%;
}
.savespot-btn{
  margin: 20px 0 !important;
  padding: 15px 30px;
  text-align: center;
  border: 1px solid #003993;
  color: #fff;
  border-radius: 30px;
  background: #003993;
  font-size: 18px;
  font-weight: 600;
  min-height: 28px !important;
  transition: all 0.3s ease-in 0s;
}

.event-views{
  text-align: center !important;
  margin-top: 60px;
  font-size: 20px;
  font-weight: 600;
  color: #ed2532;
}

</style>
