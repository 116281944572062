<template>
    <Header />
    <Breadcrumb :title="'Video Chat'" :link="'video-chat'" />
    <div class="custom-page">
      <div class="container">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>Video Chat</span>
            </div>
          </template>
          <div class="video" v-loading="loading">
            <div id="meet" />
          </div>
        </el-card>
      </div>
    </div>
    <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Breadcrumb from '@/views/user/_components/Breadcrumb'

import pageMixin from '@/mixins/page'
import userMixin from '@/mixins/user'
import messageMixin from '@/mixins/messages'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  created () {
  },

  data () {
    return {
      toUser: '',
      loading: false
    }
  },

  methods: {
    startVideoChat () {
      if (parseInt(this.$route.params.id) !== parseInt(this.user.id)) {
        this.createMessage({
          from_user_id: this.user.id,
          to_user_id: this.$route.params.id,
          link: `https://merojodi.com/#/video-chat/${this.$route.params.id}`,
          type: 2,
          /* eslint-disable */
          message: this.user.name + ' has called you.'
        }).then(() => {
          /* eslint-disable */
          $(document).ready(() => {
            var domain = 'meet.jit.si'
            const options = {
              roomName: `chat_merojodi_${this.$route.params.id}`,
              width: 700,
              height: 700,
              parentNode: document.querySelector('#meet'),
              configOverwrite: {
                prejoinConfig: {
                  enabled: false
                }
              }
            };

            var api = new JitsiMeetExternalAPI(domain, options)

            this.loading = false
          })
        })
      } else {
        /* eslint-disable */
        $(document).ready(() => {
          var domain = 'meet.jit.si'
          const options = {
            roomName: `chat_merojodi_${this.$route.params.id}`,
            width: 700,
            height: 700,
            parentNode: document.querySelector('#meet'),
            configOverwrite: {
              prejoinConfig: {
                enabled: false
              }
            }
          };

          var api = new JitsiMeetExternalAPI(domain, options)

          this.loading = false
        })
      }
    }
  },

  mixins: [messageMixin, userMixin, pageMixin],

  mounted () {
    this.loading = true
    this.getUser(this.$route.params.id).then(res => {
      this.toUser = res

      this.loading = false
    })

    this.startVideoChat()
  },

  name: 'VideoChat'
}
</script>
<style lang="scss">
.custom-page{
  padding: 30px 0 20px 0 !important;
  background: #f3f2ef;
}

.video {
  display: flex;
  justify-content: center;
}
.custom-page .card-header span{
  font-size: 18px;
}

.c-register {
  &__form {
    padding: 1rem 10px;
  }

  &__tabs {
    margin-bottom: 1rem;
  }
}
</style>
