<template>
  <div class="c-education-profession-form">
    <el-form
      ref="form"
      :model="userData"
      v-loading="loading"
      label-width="140px"
    >
      <el-form-item label="Educational Level: ">
        <el-input type="text" v-model="userData.education_level"></el-input>
      </el-form-item>

      <el-form-item label="Educational Field: ">
        <el-input type="text" v-model="userData.education_field"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button
          @click="onSubmit"
          type="primary"
          round
        >
          {{ userData.id ? 'Update' : 'Submit' }}
        </el-button>
        <el-button @click="onClose" round>Cancel</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import userMixin from '@/mixins/user'

export default {
  created () {
    this.getEducationFields()
    this.getEducationLevels()
  },

  computed: {
    educationLevelOption () {
      return this.educationLevels && this.educationLevels.map(educationLevel => {
        return {
          value: educationLevel.id,
          label: educationLevel.name
        }
      })
    },

    educationFieldOption () {
      return this.educationFields && this.educationFields.map(educationField => {
        return {
          value: educationField.id,
          label: educationField.name
        }
      })
    }
  },

  data () {
    return {
      loading: false,
      userData: {
        education_level_id: null,
        education_field_id: null,
        educational_field: null,
        educational_level: null
      }
    }
  },

  methods: {
    onClose () {
      this.$emit('form:close')
    },

    onSubmit () {
      if (this.userData.id) {
        this.loading = true

        this.updateEducation(Object.assign(this.userData, { user_id: this.createdUser ? this.createdUser.id : this.user.id }))
          .then(() => {
            this.$message({
              message: 'Education data updated successfully.',
              type: 'success'
            })

            this.loading = false

            this.$emit('data:create')
          })
          .catch(() => {
            this.loading = false

            this.$message({
              message: 'Oops! Something went wrong.',
              type: 'warning'
            })
          })
      } else {
        this.loading = true

        this.createEducation(Object.assign(this.userData, { user_id: this.createdUser ? this.createdUser.id : this.user.id }))
          .then(() => {
            this.$message({
              message: 'Education data created successfully.',
              type: 'success'
            })

            this.loading = false

            this.$emit('data:create')
          })
          .catch(() => {
            this.loading = false

            this.$message({
              message: 'Oops! Something went wrong.',
              type: 'warning'
            })
          })
      }
    }
  },

  mixins: [userMixin],

  mounted () {
    if (this.value) {
      this.userData.education_level = this.value.educational_level
      this.userData.education_field = this.value.educational_field
      this.userData.id = this.value.id
    }
  },

  name: 'EducationAndProfessionForm',

  props: {
    value: {
      required: false
    }
  }
}
</script>

<style lang="scss">
.c-education-profession-form {
  .el-dropdown {
    cursor: pointer;
  }

  .el-select {
    width: 100%;
  }
}
</style>
