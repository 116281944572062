<template>
  <Page
    @cta:click="onClick"
    :title="'Event FAQ'"
    :ctaLabel="'Add'"
    :showButton="!showAddForm"
    :cta-class="'primary'"
    class="c-event-faq"
  >
    <template #content>
      <el-form
        ref="form"
        :model="state.eventFaqData"
        label-width="140px"
        v-if="showAddForm"
      >
        <el-form-item label="Title">
          <el-input
            v-model="state.eventFaqData.question"
          >
          </el-input>
          <span
            class="c-event-faq__error"
            v-if="v$.eventFaqData.question.$error"
          >
          {{ v$.eventFaqData.question.$errors[0].$message }}
        </span>
        </el-form-item>

        <el-form-item label="Description">
          <el-input
            v-model="state.eventFaqData.answer"
            type="textarea"
            rows="4"
          >
          </el-input>
          <span
            class="c-event-faq__error"
            v-if="v$.eventFaqData.answer.$error"
          >
          {{ v$.eventFaqData.answer.$errors[0].$message }}
        </span>
        </el-form-item>

        <el-form-item>
          <el-button
            @click="onSubmit"
            type="primary"
            round
          >
            Submit
          </el-button>
          <el-button @click="onClose" round>Cancel</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="eventFaqs"
        style="width: 100%"
        v-else
      >
        <el-table-column
          label="ID"
          prop="id"
          width="100"
        >
        </el-table-column>

        <el-table-column
          label="Question"
          prop="question">
        </el-table-column>

        <el-table-column
          label="Answer"
          prop="answer">
        </el-table-column>

        <el-table-column
          align="right">
          <template #default="scope">
            <el-button
              @click="onDelete(scope.row.id)"
              size="mini"
              type="danger"><i class="el-icon-delete"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page'

import eventMixin from '@/mixins/event'
import { computed, reactive } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import useValidate from '@vuelidate/core'

export default {
  components: {
    Page
  },

  created () {
    this.getEventFaqsData()
  },

  setup () {
    const state = reactive({
      eventFaqData: {
        question: '',
        answer: ''
      }
    })

    const rules = computed(() => {
      return {
        eventFaqData: {
          question: { required: helpers.withMessage('Question field is required', required) },
          answer: { required: helpers.withMessage('Answer field is required', required) }
        }
      }
    })

    const v$ = useValidate(rules, state)

    return {
      state,
      v$
    }
  },

  data () {
    return {
      event: null,
      eventFaqs: [],
      showAddForm: false
    }
  },

  methods: {
    onClick () {
      this.showAddForm = true
    },

    onEdit (id) {
    },

    getEventFaqsData () {
      this.getEventFaqs(this.$route.params.id).then((res) => {
        this.eventFaqs = res
      })
    },

    onSubmit () {
      this.v$.$validate()
      if (!this.v$.$error) {
        this.createEventFaq({
          event_id: this.$route.params.id,
          question: this.state.eventFaqData.question,
          answer: this.state.eventFaqData.answer
        }).then(() => {
          this.$message({
            message: 'Faq addedd successfully',
            type: 'success'
          })

          this.showAddForm = false
          this.getEventFaqsData()
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      }
    },

    onClose () {
      this.showAddForm = false
    },

    onDelete (id) {
      this.$confirm('Are you sure you want to delete?', 'Accept', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(() => {
        this.deleteEventFaq(id).then(() => {
          this.$message({
            message: 'Faq deleted successfully',
            type: 'success'
          })

          this.getEventFaqsData()
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      })
    }
  },

  mixins: [eventMixin],

  name: 'EventFAQ'
}
</script>

<style lang="scss">
.c-event-faq {
  .error {
    color: darkred;
  }
}
</style>
