<template>
  <Page
    :title="'FAQ'"
    :showButton="false"
    class="c-admin-faq"
  >
    <template #content>
      <PageFrom
        @page:created="onPageCreated"
        :pageValue="pageValue"
      />
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page'
import PageFrom from './PageForm'

import pageMixin from '@/mixins/page'

export default {
  components: {
    PageFrom,
    Page
  },

  created () {
    const slug = this.$route.meta.slug

    this.getPage({
      slug: slug
    }).then((res) => {
      this.pageValue = res
    })
  },

  data () {
    return {
      pageValue: null
    }
  },

  methods: {
    onPageCreated () {
      this.$message({
        message: 'FAQ created successfully',
        type: 'success'
      })
    }
  },

  mixins: [pageMixin],

  name: 'FAQ'
}
</script>
