import { mapActions, mapGetters } from 'vuex'

const blogs = {
  computed: {
    ...mapGetters(
      'search',
      {
        searchData: 'searchData'
      }
    )
  },

  methods: {
    ...mapActions(
      'search',
      [
        'searchUser'
      ]
    )
  }
}

export default blogs
