<template>
  <div class="c-family-detail-form">
    <el-form
      ref="form"
      :model="userData"
      v-loading="loading"
      label-width="150px"
    >
      <el-form-item label="Family Type">
        <el-radio v-model="userData.family_type" :label="'Single'">Single</el-radio>
        <el-radio v-model="userData.family_type" :label="'Joint'">Joint</el-radio>
        <el-radio v-model="userData.family_type" :label="'Nuclear'">Nuclear</el-radio>
      </el-form-item>

      <el-form-item label="Family Members">
        <el-select
          placeholder="Please select Family Members"
          v-model="userData.family_number"
        >
          <el-option value="null" label="Please Select Family Member">Please Select Family Member</el-option>
          <el-option label="2" value="2"></el-option>
          <el-option label="3" value="3"></el-option>
          <el-option label="4" value="4"></el-option>
          <el-option label="5" value="5"></el-option>
          <el-option label="6" value="6"></el-option>
          <el-option label="7" value="7"></el-option>
          <el-option label="8" value="8"></el-option>
          <el-option label="8 +" value="8 +"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Current Residence">
        <el-radio v-model="userData.country_living" :label="1">Nepal</el-radio>
        <el-radio v-model="userData.country_living" :label="2">Foreign</el-radio>
      </el-form-item>

      <el-form-item label="Country" v-if="userData.country_living === 2">
        <el-select
          v-model="userData.country"
          placeholder="Select Country"
          class="form-control"
          filterable
        >
          <el-option
            v-for="country in countryOption"
            :key="country.value"
            :label="country.label"
            :value="country.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          @click="onSubmit"
          type="primary"
          round
        >
          {{ userData.id ? 'Update' : 'Submit' }}
        </el-button>
        <el-button @click="onClose" round>Cancel</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import userMixin from '@/mixins/user'
const { getData } = require('country-list')

export default {
  data () {
    return {
      loading: false,
      userData: {
        family_status: '',
        family_type: '',
        family_number: '',
        country_living: 1,
        country: ''
      }
    }
  },

  computed: {
    countryOption () {
      return getData().map(country => {
        return {
          label: country.name,
          value: country.code
        }
      })
    }
  },

  methods: {
    onClose () {
      this.$emit('form:close')
    },

    onSubmit () {
      if (this.userData.id) {
        this.loading = true
        this.updateFamilyDetail(Object.assign(this.userData, { user_id: this.createdUser ? this.createdUser.id : this.user.id }))
          .then(() => {
            this.$message({
              message: 'Family details data updated successfully.',
              type: 'success'
            })

            if (this.user && this.user.id) {
              this.getMe()
            }

            this.loading = false

            this.$emit('user:create')
          })
          .catch(() => {
            this.loading = false

            this.$message({
              message: 'Oops! Something went wrong.',
              type: 'warning'
            })
          })
      } else {
        this.loading = true
        this.createFamilyDetails(Object.assign(this.userData, { user_id: this.createdUser ? this.createdUser.id : this.user.id }))
          .then(() => {
            if (this.user && this.user.id) {
              this.getMe()
            }

            this.loading = false
            this.$emit('user:create')
          })
          .catch(() => {
            this.loading = false
            this.$message({
              message: 'Oops! Something went wrong.',
              type: 'warning'
            })
          })
      }
    }
  },

  mixins: [userMixin],

  mounted () {
    if (this.familyDetail) {
      this.userData = this.familyDetail
    }
  },

  name: 'FamilyDetailForm',

  props: {
    familyDetail: {
      required: false
    }
  }
}
</script>
<style lang="scss" scoped>
.c-family-detail-form {
  .el-select {
    width: 100%;
  }
}
</style>
