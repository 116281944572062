<template>
  <div class="c-register">
    <Header />
    <Breadcrumb :title="'Contact'" :link="'contact'" />
    <div class="custom-page">
    <Contact />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Contact from '@/views/user/_components/Contact'
import Breadcrumb from '@/views/user/_components/Breadcrumb'
import Footer from '@/components/Footer'

export default {
  components: {
    Header,
    Footer,
    Contact,
    Breadcrumb
  },
  data () {
    return {
      username: null,
      password: null
    }
  },
  name: 'UserLogin'
}
</script>
<style>
.el-pages{
  margin: 0;
  padding: 40px 0 0 0;
}
</style>
