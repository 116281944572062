<template>
  <div>
    <div class="profile-ul">
      <div class="container">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8" :md="8" :lg="8">
            <div class="profile-ul-pro-box">
              <div class="profile-ui-image">
                <a
                  @click="openSelectProfilePopup"
                >
                  <div class="var-line-icon var-line-icon-main-profile">
                    <div class="user-online">
                      <div class="user-online-show" title="Online"></div>
                    </div>
                  </div>
                  <img
                    :src="imageBaseUrl + userData.image" alt="" v-if="userData && userData.image"
                  >
                  <img src="@/assets/images/unknown1.png" alt="" v-else>
                <input
                  @change="onImageChange"
                  :disabled="!isLoggedInUser"
                  ref="imageUpload"
                  type="file"
                  v-show="false"
                />
                </a>
                <div class="upload-profile" v-if="isLoggedInUser">
                  <a @click="openSelectProfilePopup">
                    <i class="el-icon-camera-solid" title="Upload/Change Profile"></i>
                  </a>
                </div>
              </div>
              <div class="profile-ui-name">
                {{ isLoggedInUser ? userData.name : userData.username ? userData.username : userData.name }}

                <span class="icon-profiles-edit" v-if="isLoggedInUser">
                    <a @click="onShowChangeNamePopup"><i class="fa fa-edit"></i></a>
                  </span>
              </div>
              <div class="profile-ui-location">
                {{ personalRecord ? personalRecord.current_address : '' }} -
                {{ personalRecord && personalRecord.country ? getCountryName(personalRecord.country) : '' }}<br>
              </div>
              <div class="profile-ui-gender" v-if="isLoggedInUser">
                <div class="profile-left-text">
                  Edit Login Email
                  <span class="icon-profiles-edit">
                    <a @click="onShowChangeEmailPopup"><i class="fa fa-edit"></i></a>
                  </span>
                </div>
                <div class="profile-left-text" v-if="isLoggedInUser">
                  {{ personalRecord && personalRecord.gender ? 'Male' : 'Female' }} seeking {{ personalRecord && personalRecord.gender ? 'Female' : 'Male' }} {{ personalRecord && personalRecord.seeking_age ? personalRecord.seeking_age  : '20-30' }}
                  <span class="icon-profiles-edit">
                    <a @click="onShowSeekingAgePopup"><i class="fa fa-edit"></i></a>
                  </span>
                </div>
                <div class="test" v-if="isLoggedInUser">
                  within {{ personalRecord && personalRecord.seeking_distance ? personalRecord.seeking_distance : 10 }} miles of {{ personalRecord && personalRecord.city_id ? getCityById(personalRecord.city_id) ? getCityById(personalRecord.city_id).name : 'Kathmandu' : 'Kathmandu' }}
                  <span class="icon-profiles-edit">
                    <a @click="onShowSeekingDistancePopup"><i class="fa fa-edit"></i>
                    </a>
                  </span>
                </div>
              </div>
              <div class="profile-ui-like-count">
                <img src="@/assets/images/heart-active.png" alt=""> <span>{{ totalLikes ? totalLikes : 0 }}</span>
              </div>
              <div class="profile-ui-lcs-btn">
                <el-button
                  @click="onLikeClick(userData)"
                  v-if="user && (userData.id !== user.id)"
                  class="lcs-button"
                >
                  <i class="fa fa-heart"></i>
                  Like
                </el-button>
                <el-button
                  @click="onCommentClick"
                  class="lcs-button"
                  v-if="userData.id !== user.id"
                >
                  <i class="fa fa-comment"></i>
                  Comment
                </el-button>
                <el-button
                  @click="onChatClick(userData.id)"
                  class="lcs-button"
                  v-if="userData.id !== user.id"
                >
                  <i class="fa fa-comments"></i>
                  Chat</el-button>
              </div>
              <div class="profile-ui-premium">
                <router-link :to="{ name: 'newpackage' }">
                  <el-button class="pck-button"><i class="fa fa-certificate"></i> Get Premium</el-button>
                </router-link>
              </div>
            </div>
            <div class="profile-gallery">
              <div class="profile-gal-heading">
                <div class="profile-gal-heading">
                  <i class="el-icon-camera-solid"></i>
                  {{ gallery ? gallery.length : 0 }} Upload Photos
                </div>
              </div>
              <div class="profile-gal-box">
                <el-row :gutter="15">
                  <el-col
                    :xs="12"
                    :sm="8"
                    :md="8"
                    :lg="8"
                    :key="index"
                    v-for="(data, index) in gallery"
                  >
                    <div class="galbox">
                      <img
                        :src="data.image ? imageBasePath + data.image : ''"
                        alt=""
                        style="max-height: 6rem"
                      >
                      <ul class="icon">
                        <li>
                          <a @click="openImagePopup(data)"><i class="fa fa-plus"></i>
                          </a>
                        </li>
                        <li>
                          <a @click="onDeleteGallery(data)" v-if="isLoggedInUser"><i class="el-icon-delete-solid"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
                <div class="gal-add-photo">
                  <el-button
                    @click="$refs.galleryUpload.click()"
                    class="el-button el-button--default add-button"
                    v-if="isLoggedInUser"
                  >
                    <i class="el-icon-plus"></i>
                    Add Photo
                  </el-button>
                  <input
                    @change="onGalleryUpload"
                    :disabled="!isLoggedInUser"
                    ref="galleryUpload"
                    type="file"
                    v-show="false"
                  />
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="10" :md="10" :lg="10">
            <div class="info-box">
              <div class="infobox-heading">
                Username
                <span class="infoplus">
                  <a
                    @click="openAddUsernamePopup"
                    v-if="userData.id === user.id"
                  >
                    <i class="fa fa-plus"></i>
                  </a>
                </span>
              </div>
              <div class="info-list">
                <el-row :gutter="20">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24">
                    <div class="info-list-split">
                      <div class="info-list-text">
                        {{ username ? username : 'No Username added' }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="info-box">
              <div class="infobox-heading">
                Summary
                <span class="infoplus">
                  <a
                    @click="openAddSummaryPopup"
                    v-if="userData.id === user.id"
                  >
                    <i class="fa fa-plus"></i>
                  </a>
                </span>
              </div>
              <div class="info-list">
                <el-row :gutter="20">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24">
                    <div class="info-list-split">
                      <div class="info-list-text" v-if="!showAllSummary">
                        {{  summaryData ? summaryData.substring(0, 120) + (summaryData.length > 60 ? '...' : '') : 'No Data'  }}<br>
                        <a
                          @click="onShowAllSummary"
                          style="color: #da298e; text-decoration: underline"
                          v-if="summaryData.length > 60"
                        >
                          Show more
                        </a>
                      </div>
                      <div class="info-list-text" v-if="showAllSummary">
                        {{  summaryData ? summaryData : 'No Data'  }}<br>
                        <a
                          @click="hideAllSummary"
                          style="color: #da298e; text-decoration: underline"
                        >
                          Show less
                        </a>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="info-box">
              <div class="infobox-heading">
                About Me
                <span class="infoplus">
                  <a
                    @click="openPersonalRecordPopup"
                    v-if="userData.id === user.id"
                  >
                    <i class="fa fa-plus"></i>
                  </a>
                </span>
              </div>
              <div class="info-list">
                <el-row :gutter="20">
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Nationality:</span>
                      </div>
                      <div class="info-list-text">
                        {{  personalRecord && personalRecord.nationality ? personalRecord.nationality : '' }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Height:</span>
                      </div>
                      <div class="info-list-text">
                        {{  personalRecord && personalRecord.height !== null ? personalRecord.height + '\'' : '' }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>City:</span>
                      </div>
                      <div class="info-list-text">
                        {{  personalRecord && personalRecord.current_address ? personalRecord.current_address : '' }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Religion:</span>
                      </div>
                      <div class="info-list-text">
                        {{ personalRecord && personalRecord.religion_id ? getReligionById(personalRecord.religion_id) ? getReligionById(personalRecord.religion_id).name : '' : ''}}
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Date of Birth:</span>
                      </div>
                      <div class="info-list-text">
                        {{  personalRecord && personalRecord.dob ? personalRecord.dob : '' }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Cast:</span>
                      </div>
                      <div class="info-list-text">
                        {{  personalRecord && personalRecord.cast_id ? getCastById(personalRecord.cast_id) ? getCastById(personalRecord.cast_id).name : '' : '' }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="info-box">
              <div class="infobox-heading">
                Apperance & Life Style
                <span class="infoplus" v-if="userData.id === user.id">
                  <a @click="openAppearanceAndLifeStylePopup">
                    <i class="fa fa-plus"></i>
                  </a>
                </span>
              </div>
              <div class="info-list">
                <el-row :gutter="20">
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Body Type:</span>
                      </div>
                      <div class="info-list-text">{{  physicalAppearanceAndLifeStyle ? physicalAppearanceAndLifeStyle.body_type : '' }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Skin Type:</span>
                      </div>
                      <div class="info-list-text">
                        {{  physicalAppearanceAndLifeStyle ? physicalAppearanceAndLifeStyle.skin_type : '' }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Food:</span>
                      </div>
                      <div class="info-list-text">
                        {{  physicalAppearanceAndLifeStyle ? physicalAppearanceAndLifeStyle.food : '' }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Smoke:</span>
                      </div>
                      <div class="info-list-text">
                        {{  physicalAppearanceAndLifeStyle ? physicalAppearanceAndLifeStyle.smoking : '' }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Drinks:</span>
                      </div>
                      <div class="info-list-text">
                        {{  physicalAppearanceAndLifeStyle ? physicalAppearanceAndLifeStyle.drinking : '' }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="info-box">
              <div class="infobox-heading">
                Education and Profession
                <span class="infoplus" v-if="isLoggedInUser">
                  <a @click="openEducationAndProfession">
                    <i class="fa fa-plus"></i>
                  </a>
                </span>
              </div>
              <div class="info-list">
                <el-row :gutter="20">
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Education Level:</span>
                      </div>
                      <div class="info-list-text">
                        {{ personalRecord ? personalRecord.educational_level : '' }}
<!--                        {{ this.getEducationLevelById(personalRecord ? personalRecord.educational_level_id : '') ? this.getEducationLevelById(personalRecord ? personalRecord.educational_level_id : '').name : '' }}-->
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Education Faculty:</span>
                      </div>
                      <div class="info-list-text">
                        {{ personalRecord ? personalRecord.educational_field : '' }}
<!--                        {{ getEducationFieldById(personalRecord ? personalRecord.educational_field_id : '') ? getEducationFieldById(personalRecord ? personalRecord.educational_field_id : '').name : '' }}-->
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="info-box">
              <div class="infobox-heading">
                Family Details
                <span class="infoplus" v-if="isLoggedInUser">
                  <a @click="openFamilyDetail">
                    <i class="fa fa-plus"></i>
                  </a>
                </span>
              </div>
              <div class="info-list">
                <el-row :gutter="20">
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Family Size:</span>
                      </div>
                      <div class="info-list-text">
                        {{ familyDetails ? familyDetails.family_number : '' }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Family Type:</span>
                      </div>
                      <div class="info-list-text">
                        {{ familyDetails ? familyDetails.family_type : '' }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="12" :lg="12">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Country:</span>
                      </div>
                      <div class="info-list-text">
                        {{ familyDetails ? familyDetails.country_living === 1 ? 'Nepal' : 'Foreign' : '' }}
                      </div>
                    </div>
                  </el-col>

                  <el-col :xs="12" :sm="12" :md="12" :lg="12" v-if="familyDetails && familyDetails.country_living === 2">
                    <div class="info-list-split">
                      <div class="info-list-icon">
                        <span>Country:</span>
                      </div>
                      <div class="info-list-text">
                        {{ familyDetails ? getCountryName(familyDetails.country) : '' }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="info-box">
              <div class="infobox-heading">
                Interest & Sports
                <span class="infoplus" v-if="isLoggedInUser">
                  <a @click="openAddInterest">
                    <i class="fa fa-plus" v-show="!showAddInterest"></i>
                    <i class="fa fa-minus" v-show="showAddInterest"></i>
                  </a>
                </span>
              </div>
              <div class="info-list">
                <el-row :gutter="20">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24">
                  <div class="user-interest">
                    <el-alert
                      title="No interests and sports."
                      type="warning"
                      center
                      show-icon
                      :closable="false"
                      v-if="userData.interests && !userData.interests.length"
                    />
                    <button
                      :key="index"
                      class="in-btn"
                      type="button"
                      v-for="(interest, index) in userData.interests"
                    >
                      {{ interest.interest }}
                      <a @click="onDeleteInterest(interest)" class="delete-btn"><i class="el-icon-circle-close"></i></a>
                    </button>

                  </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="info-box">
              <div class="infobox-heading">
                Comments
              </div>
              <div class="info-list">
                <el-row :gutter="20">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24">
                    <div class="text">
                      <div class="comment-wrapper">
                        <el-alert
                          type="error"
                          :closable="false"
                          v-if="comments && comments.length < 1"
                        >
                          No Comments
                        </el-alert>
                        <div
                          :key="index"
                          v-for="(comment, index) in comments"
                        >
                          <div class="comment-list">
                            <div class="comment-list-img">
                              <img
                                :src="comment.from_user.image ? imageBaseUrl + comment.from_user.image : require('@/assets/images/unknown1.png')"
                                alt=""
                              >
                            </div>
                            <div class="comment-list-content-area">
                              <div class="comment-list-content">
                                <div class="uname">
                                  {{ comment.from_user ? comment.from_user.name : '' }}
                                </div>
                                <p>
                                  {{ comment.comments }}
                                </p>
                              </div>
                              <p class="likes">
                            <span
                              class="comment-link"
                            >
                              <a @click="onReplyClick(index)">Reply</a>
                            </span>
                              </p>
                            </div>
                          </div>
                          <div
                            :key="index1"
                            class="comment-reply"
                            v-for="(childComment, index1) in comment.child_comments"
                          >
                            <div class="comment-reply-img">
                              <img
                                :src="childComment.from_user.image ? imageBaseUrl + childComment.from_user.image : require('@/assets/images/unknown1.png')"
                                alt=""
                              >
                            </div>
                            <div class="comment-reply-content-box">
                              <div class="comment-reply-content">
                                <div class="uname">
                                  {{ childComment.from_user ? childComment.from_user.name : '' }}
                                </div>
                                <p>
                                  {{  childComment.comments }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="comment-form"
                            v-if="showReplyForm && showReplyForm[index]"
                          >
                            <div class="comment-form-img">
                              <img
                                :src="user.image ? imageBaseUrl + user.image : require('@/assets/images/unknown1.png')"
                                alt=""
                              >
                            </div>
                            <div class="comment-form-content">
                              <el-form ref="form">
                                <el-input
                                  placeholder="Write a comment..."
                                  v-model="childComment"
                                >
                                </el-input>
                              </el-form>
                            </div>
                            <div>
                              <el-button
                                @click="onChildCommentSubmit(comment)"
                                type="success"
                              >
                                Send
                              </el-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="2" :sm="2" :md="3" :lg="3">
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="6" :md="6" :lg="6">
            <div class="front-ui-connected" v-if="userData.id === user.id">
              <div class="ui-header">
                <div class="tag">
                  Your Profile Completed
                </div>
              </div>
              <div class="hidden-scroller">
                <div class="front-ui-conn-list-pro">

                  <el-popover
                    placement="top-start"
                    :title="unComplitedDetails && unComplitedDetails.length ? 'Complete following section' : 'Completed'"
                    :width="300"
                    trigger="hover"
                    :content="unComplitedDetails && unComplitedDetails.length ? unComplitedDetails : ''"
                  >
                    <template #reference>
                      <el-progress :text-inside="true" :stroke-width="26" :percentage="totalProfileComplete" />
                    </template>
                  </el-popover>
                </div>
              </div>
            </div>
            <div class="front-ui-connected" v-if="userData.id === user.id">
              <div class="ui-header">
                <div class="tag">
                  Your Profile Is Visible
                </div>
              </div>
              <div class="hidden-scroller">
                <div class="front-ui-conn-list-pro">
                  <div class="checklist">
                      <span class="radio-button">
                      <input
                        @click="onWhoCanSeeClick($event)"
                        type="radio"
                        name="who_can_see"
                        :value="1"
                        v-model="whoCanSee"
                      >
                    </span>
                    Anyone Can See You
                  </div>
                  <div class="checklist">
                     <span class="radio-button">
                      <input
                        @click="onWhoCanSeeClick($event)"
                        type="radio"
                        name="who_can_see"
                        :value="0"
                        v-model="whoCanSee"
                      >
                    </span>
                    No one can see
                  </div>
                </div>
              </div>
            </div>
            <div class="front-ui-connected" v-if="userData.id === user.id">
              <div class="ui-header">
                <div class="tag"> People Connected With</div>
              </div>
              <template v-if="connectToUsers.length > 0">
                <div
                  :key="index"
                  class="front-ui-conn-list"
                  v-for="(user, index) in connectToUsers"
                >
                  <div class="left-col">
                    <div class="var-line-icon var-line-icon-new">
                      <div class="user-online">
                        <div
                          :class="[user.is_logged_in ? 'user-online-show' : 'user-offline-show']"
                          :title="user.is_logged_in ? 'Online' : 'Offline'"
                        ></div>
                      </div>
                    </div>
                    <img
                      :src="user.image ? imageBasePath + user.image : require('@/assets/images/default-user.png')"
                      alt=""
                    >
                  </div>
                  <div class="right-col">
                    <div class="title connection-wants-title">
                      <router-link :to="{ name: 'user-profile', params: { id: user.id }}">{{ user.name }}</router-link>
                    </div>
                    <div class="ui-bar-side">
                      <el-button class="pending" v-if="user.pivot && user.pivot.status === 0">
                        <i class="el-icon-loading"></i>
                        Pending
                      </el-button>
                      <el-button class="rejected" v-else-if="user.pivot && user.pivot.status === 2">
                        <i class="el-icon-close"></i>
                        Rejected
                      </el-button>
                      <el-button class="connected" v-else-if="user.pivot && user.pivot.status === 1">
                        <i class="el-icon-check"></i>
                        Connected
                      </el-button>
                    </div>
                  </div>
                </div>
              </template>
              <div
                class="front-ui-conn-list-notfound"
                v-else
              >
                No Connection.
                <div class="noviews">
                  <i class="eye-icon fa fa-link"></i>
                </div>
              </div>
            </div>
            <div class="front-ui-connected" v-if="userData.id === user.id">
              <div class="ui-header">
                <div class="tag">
                  People Who Wants To Connect
                </div>
              </div>
              <template v-if="connectFromUsers.length > 0">
                <div class="hidden-scroller">
                  <div
                    :key="index"
                    class="front-ui-conn-list"
                    v-for="(user, index) in connectFromUsers"
                  >
                    <div class="left-col">
                      <div class="var-line-icon var-line-icon-new">
                        <div class="user-online">
                          <div
                            :class="[user.is_logged_in ? 'user-online-show' : 'user-offline-show']"
                            :title="user.is_logged_in ? 'Online' : 'Offline'"
                          ></div>
                        </div>
                      </div>
                      <img
                        :src="user.image ? imageBasePath + user.image : require('@/assets/images/default-user.png')"
                        alt=""
                      >
                    </div>
                    <div class="right-col">
                      <div class="title connection-wants-title">
                        <router-link :to="{ name: 'user-profile', params: { id: user.id }}">{{ user.name }}</router-link>
                        <span class="accepted-smv" v-if="user.pivot.status === 1">Accepted</span>
                        <span class="rejected-smv" v-if="user.pivot.status === 2">Rejected</span>
                      </div>
                      <div class="ui-bar-side">
                        <el-button
                          @click="onClickAcceptUser(user)"
                          class="ui-bar-button"
                          v-if="user.pivot.status !== 1"
                        >
                          <i class="el-icon-check"></i>
                          Accept
                        </el-button>
                        <el-button
                          @click="onClickRejectUser(user)"
                          class="ui-bar-button"
                          v-if="user.pivot.status !== 2"
                        >
                          <i class="el-icon-close"></i>
                          Reject
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div
                class="front-ui-conn-list-notfound"
                v-else
              >
                No New Request.
                <div class="noviews">
                  <i class="eye-icon fa fa-link"></i>
                </div>
              </div>
            </div>
            <div class="front-ui-connected" v-if="userData.id !== user.id">
              <div class="ui-header">
                <div class="tag">
                  People You May Know
                </div>
              </div>
              <div class="hidden-scroller">
                <div
                  :key="index"
                  class="front-ui-conn-list"
                  v-for="(user, index) in youMayKnowUsers"
                >
                  <div class="left-col">
                    <img
                      :src="user.image ? imageBasePath + user.image : require('@/assets/images/default-user.png')"
                      alt=""
                    >
                  </div>
                  <div class="right-col">
                    <div class="title connection-wants-title">
                      <router-link :to="{ name: 'user-profile', params: { id: user.id }}">{{ user.name }}</router-link>
                    </div>
                    <div class="ui-bar-side">
                      <el-button
                        @click="onViewUser(user)"
                        class="ui-bar-button"
                      >
                        <i class="el-icon-view"></i>
                        View
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="front-ui-connected" v-if="isLoggedInUser">
              <div class="ui-header">
                <div class="tag">
                  Who's Viewed Me
                </div>
              </div>
              <div class="hidden-scroller">
                <div
                  class="front-ui-conn-list-notfound"
                  v-if="viewedUsers && !viewedUsers.length"
                >
                  No New Views.
                  <div class="noviews">
                    <i class="eye-icon fa fa-eye"></i>
                  </div>
                  <div class="subscribe-view">
                    <router-link :to="{ name: 'newpackage' }">
                      Subscribe to see views
                      <i class="eye-icon el-icon-right"></i>
                    </router-link>
                  </div>
                </div>
                <div
                  :key="index"
                  class="front-ui-conn-list"
                  v-for="(user, index) in viewedUsers"
                >
                  <div v-if="index < 3">
                    <div class="left-col">
                      <div class="var-line-icon var-line-icon-new">
                        <div class="user-online">
                          <div
                            :class="[user.is_logged_in ? 'user-online-show' : 'user-offline-show']"
                            :title="user.is_logged_in ? 'Online' : 'Offline'"
                          ></div>
                        </div>
                      </div>
                      <img
                        :src="user.image ? imageBasePath + user.image : require('@/assets/images/default-user.png')"
                        alt="">
                    </div>
                    <div class="right-col">
                      <div class="title connection-wants-title">
                        <a @click="onViewUser(user)" class="">{{ user.name }}</a>
                      </div>
                      <div class="ui-bar-side">
                        <el-button
                          @click="onViewUser(user)"
                          class="ui-bar-button"
                        >
                          <i class="el-icon-view"></i>
                          View
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="front-ui-conn-list-notfound"
                  v-if="viewedUsers && viewedUsers.length"
                >
                  <div class="subscribe-view">
                    <router-link :to="{ name: 'newpackage' }">
                      Subscribe to see views
                      <i class="eye-icon el-icon-right"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <AboutPopup
      @action:confirm="onAboutSubmit"
      @popup:close="closeAboutPopup"
      :showPopup="showAboutPopup"
      :title="'Add About'"
      v-if="showAboutPopup"
    >
      <template #content>
        <el-form>
          <el-form-item label="About">
            <el-input
              type="textarea"
              rows="4"
              v-model="aboutData"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
    </AboutPopup>
    <SummaryPopup
      @action:confirm="onSummarySubmit"
      @popup:close="closeSummaryPopup"
      :showPopup="showSummaryPopup"
      :title="'Add Summary'"
      v-if="showSummaryPopup"
    >
      <template #content>
        <el-form>
          <el-form-item label="Summary">
            <el-input
              type="textarea"
              rows="4"
              v-model="summaryData"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
    </SummaryPopup>

    <UsernamePopup
      @action:confirm="onSubmitUsername"
      @popup:close="closeUsernamePopup"
      :showPopup="showUsernamePopup"
      :title="'Add Username'"
      v-if="showUsernamePopup"
    >
      <template #content>
        <el-form>
          <el-form-item label="Username">
            <el-input
              type="text"
              v-model="username"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
    </UsernamePopup>

    <CommentPopup
      @action:confirm="onCommentSubmit"
      @popup:close="closeCommentPopup"
      :showPopup="showCommentPopup"
      :title="'Comment'"
      v-if="showCommentPopup"
    >
      <template #content>
        <el-form>
          <el-form-item label="Write Comment">
            <el-input
              type="textarea"
              rows="4"
              v-model="commentData"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
    </CommentPopup>

    <InterestPopup
      @action:confirm="onAddUserInterest"
      @popup:close="closeInterestPopup"
      :showPopup="showAddInterest"
      :title="'Interest And Sports'"
      v-if="showAddInterest"
    >
      <template #content>
        <el-form>
          <el-form-item label="Interest And Sports">
            <el-select
              v-model="userInterest"
              placeholder="Select interest and sports"
            >
              <el-option value="Basketball">Basketball</el-option>
              <el-option value="Football">Football</el-option>
              <el-option value="Cricket">Cricket</el-option>
              <el-option value="Hockey">Hockey</el-option>
              <el-option value="Volleyball">Volleyball</el-option>
              <el-option value="Blogging">Blogging</el-option>
              <el-option value="Dancing">Dancing</el-option>
              <el-option value="Singing">Singing</el-option>
              <el-option value="Cooking">Cooking</el-option>
              <el-option value="Travelling">Travelling</el-option>
              <el-option value="Watching Movies">Watching Movies</el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Or Add of you own">
            <el-input
              type="text"
              v-model="userInterest"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
    </InterestPopup>

    <NameChangePopup
      @action:confirm="onChangeNameSubmit"
      @popup:close="closeChangeNamePopup"
      :showPopup="showChangeNamePopup"
      :title="'Your current name ' + '`' + this.user.name + '`'"
      v-if="showChangeNamePopup"
    >
      <template #content>
        <el-form>
          <el-form-item label="Enter new name">
            <el-input
              type="text"
              v-model="nameData"
            ></el-input>
            <span
              class="c-user-form__error"
              v-if="nameError"
              style="color: darkred"
            >
              {{ nameError }}
            </span>
          </el-form-item>
        </el-form>
      </template>
    </NameChangePopup>

    <EmailChangePopup
      @action:confirm="onChangeEmailSubmit"
      @popup:close="closeChangeEmailPopup"
      :showPopup="showChangeEmailPopup"
      :title="'Your current email ' + '`' + this.user.email + '`'"
      v-if="showChangeEmailPopup"
    >
      <template #content>
        <el-form>
          <el-form-item label="Enter new email to update">
            <el-input
              type="email"
              v-model="state.emailData"
            ></el-input>
            <span
              class="c-user-form__error"
              v-if="v$.emailData.$error"
              style="color: darkred"
            >
              {{ v$.emailData.$errors[0].$message }}
            </span>
          </el-form-item>
        </el-form>
      </template>
    </EmailChangePopup>

    <SeekingAgePopup
      @action:confirm="onSeekingAgeSubmit"
      @popup:close="closeSeekingAgePopup"
      :showPopup="showSeekingAgePopup"
      :title="'Change Seeking age'"
      v-if="showSeekingAgePopup"
    >
      <template #content>
        <el-form>
          <el-form-item label="Enter age group in 24-34 format">
            <el-input
              type="text"
              v-model="seekingAgeData"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
    </SeekingAgePopup>
    <SeekingDistancePopup
      @action:confirm="onSeekingDistanceSubmit"
      @popup:close="closeSeekingDistancePopup"
      :showPopup="showSeekingDistancePopup"
      :title="'Change Seeking Distance'"
      v-if="showSeekingDistancePopup"
    >
      <template #content>
        <el-form>
          <el-form-item label="Enter Seeking Distance">
            <el-input
              type="number"
              v-model="seekingDistanceData"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
    </SeekingDistancePopup>
    <ImagePopup
      @popup:close="closeImagePopup"
      :showPopup="showImagePopup"
      :showAction="false"
      :title="'Gallery'"
      v-if="showImagePopup"
    >
      <template #content>
        <img :src="imageData ? imageBasePath + imageData : ''" style="width: 100%">

        <el-button @click="makeGalleryProfile()" type="primary" size="small" v-if="userData.id === user.id">Make profile</el-button>
      </template>
    </ImagePopup>
    <SelectProfilePopup
      @popup:close="hideSelectProfilePopup"
      :showPopup="showSelectProfilePopup"
      :showAction="false"
      :title="'Update Profile Picture'"
      v-if="showSelectProfilePopup"
    >
      <template #content>
        <div>
          <el-button
            @click="$refs.imageUpload.click()"
            v-if="isLoggedInUser"
            size="small"
          >
            <i class="el-icon-plus"></i>
            Upload Photo
          </el-button>
        </div>
        <el-alert title="No Image uploaded yet" type="warning" :closable="false" v-if="gallery && !gallery.length">
        </el-alert>
        <div class="select-profile-picture">
<!--          static image display-->
          <div class="profile-gal-box">
            <el-row :gutter="10">
              <el-col
                :xs="12"
                :sm="8"
                :md="8"
                :lg="8"
                :key="index"
                :alt="data.image"
                v-for="(data, index) in gallery"
              >
                <div class="gal-box">
                  <img
                    :src="data ? imageBasePath + data.image : ''"
                    alt="">
                  <ul class="icon">
                    <li>
                      <a @click="makeGalleryProfile(data.id)"><i class="fa fa-plus"></i></a>
                    </li>
                    <li>
                      <a @click="onDeleteGallery(data)"><i class="el-icon-delete-solid"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </template>
    </SelectProfilePopup>
    <PersonalRecordPopup
      @popup:close="closePersonalRecordPopup"
      :showPopup="showPersonalRecordPopup"
      :title="'Personal Record'"
      :showAction="false"
    >
      <template #content>
        <el-form>
          <el-form-item>
            <PersonalRecord
              @data:update="onPersonalRecordUpdate"
              @data:create="onPersonalRecordUpdate"
              @form:close="closePersonalRecordPopup"
              :value="personalRecord"
            />
          </el-form-item>
        </el-form>
      </template>
    </PersonalRecordPopup>
    <AppearanceAndLifeStylePopup
      @popup:close="closeAppearanceAndLifeStylePopup"
      :showPopup="showAppearanceAndLifeStylePopup"
      :title="'Appearance And Life Style'"
      :showAction="false"
    >
      <template #content>
        <el-form>
          <el-form-item>
            <AppearanceAndLifeStyle
              @data:update="onPhysicalAppAndLifeStyleUpdate"
              @data:create="onPhysicalAppAndLifeStyleUpdate"
              @form:close="closeAppearanceAndLifeStylePopup"
              :value="physicalAppearanceAndLifeStyle"
            />
          </el-form-item>
        </el-form>
      </template>
    </AppearanceAndLifeStylePopup>
    <EducationAndProfessionPopup
      @popup:close="closeEducationAndProfession"
      :showPopup="showEducationAndProfession"
      :title="'Education And Profession'"
      :showAction="false"
    >
      <template #content>
        <el-form>
          <el-form-item>
            <EducationAndProfession
              @data:update="onEducationAndProfessionUpdate"
              @data:create="onEducationAndProfessionUpdate"
              @form:close="closeEducationAndProfession"
              :value="personalRecord"
            />
          </el-form-item>
        </el-form>
      </template>
    </EducationAndProfessionPopup>
    <FamilyDetailPopup
      @popup:close="closeFamilyDetail"
      :showPopup="showFamilyDetailPopup"
      :title="'Family Detail'"
      :showAction="false"
    >
      <template #content>
        <el-form>
          <el-form-item>
            <FamilyDetail
              @user:update="onFamilyDetailUpdate"
              @user:create="onFamilyDetailUpdate"
              @form:close="closeFamilyDetail"
              :familyDetail="familyDetails"
            />
          </el-form-item>
        </el-form>
      </template>
    </FamilyDetailPopup>
    <div class="chat-container" v-if="showChat">
      <div class="chatcard chatcard-bordered">
        <div class="chatcard-header">
          <h4 class="chatcard-title">
            <img
              :src="toUser && toUser.image ? imageBasePath + toUser.image : 'https://img.icons8.com/color/36/000000/administrator-male.png'"
              class="avatar"
              alt="..."
            >
            <div class="text-chat">
              {{ toUser && toUser.username ? toUser.username : toUser.name }}

              <a @click="onVideoChatClick(toUser)">
                <i class="el-icon-video-camera"></i>
              </a>
            </div>
          </h4> <a @click="closeChat" class="btn btn-xs btn-secondary" data-abc="true">X</a>
        </div>
        <div class="chat-content">
          <div class="ps-container ps-theme-default ps-active-y" id="chat-content" ref="chatContainer" style="overflow-y: scroll !important; height:280px !important;">
            <div
              :key="index"
              v-for="(message, index) in allMessages"
            >
              <div
                class="media media-chat"
                v-if="message.to_user_id === user.id"
              >
                <img
                  :src="toUser && toUser.image ? imageBasePath + toUser.image : 'https://img.icons8.com/color/36/000000/administrator-male.png'"
                  class="avatar"
                  alt="..."
                >
                <div class="media-body">
                  <p v-html="message.message"></p>
                  <p class="meta"><time datetime="2018">{{ getTime(message.created_at) }}</time></p>
                </div>
              </div>
              <div
                class="media media-chat media-chat-reverse"
                v-else-if="message.type === 1"
              >
                <div class="media-body">
                  <p v-html="message.message"></p>
                  <p class="meta"><time datetime="2018">{{ getTime(message.created_at) }}</time></p>
                </div>
              </div>
            </div>
            <div class="ps-scrollbar-x-rail" style="left: 0px; bottom: 0px;">
              <div class="ps-scrollbar-x" tabindex="0" style="left: 0px; width: 0px;"></div>
            </div>
            <div class="ps-scrollbar-y-rail" style="top: 0px; height: 0px; right: 2px;">
              <div class="ps-scrollbar-y" tabindex="0" style="top: 0px; height: 2px;"></div>
            </div>
          </div>
          <div class="publisher bt-1 border-light">
            <img
              :src="user && user.image ? imageBasePath + user.image : 'https://img.icons8.com/color/36/000000/administrator-male.png'"
              class="avatar avatar-xs"
              alt="..."
            >
            <textarea
              @keyup.enter="onSendMessage"
              class="publisher-input"
              type="text"
              placeholder="Write something"
              v-model="message"
            >
            </textarea>
            <a @click="openEmojiPicker">
              <i class="fa fa-smile"></i>
            </a>
            <a
              @click="onSendMessage"
              class="publisher-btn text-info"
              data-abc="true"
            >
              send
            </a>
          </div>

          <Picker
            :data="emojiIndex"
            :showPreview="false"
            :showSkinTones="false"
            @select="onSelectEmoji"
            v-if="showEmojiPicker"
            :style="{ position: 'absolute', bottom: '20px', right: '100px' }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pusher from 'pusher-js'
import { computed, reactive } from 'vue'
import { email, helpers, required } from '@vuelidate/validators'
import useValidate from '@vuelidate/core'

import Popup from '@/components/Popup'
import AppearanceAndLifeStyle from '@/views/user/_components/PhysicalAppAndLifeStyleForm'
import EducationAndProfession from '@/views/user/_components/EducationAndProfessionForm'
import PersonalRecord from '@/views/user/_components/PersonalRecordForm'
import FamilyDetail from '@/views/user/_components/FamilyDetailForm'

import userMixin from '@/mixins/user'
import messageMixin from '@/mixins/messages'
import configurationMixin from '@/mixins/configuration'
import likeCommentMixin from '@/mixins/like_comment'
import galleryMixin from '@/mixins/gallery'

import data from 'emoji-mart-vue-fast/data/all.json'
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast/src'

const { getName } = require('country-list')

const emojiIndex = new EmojiIndex(data)

export default {
  components: {
    AppearanceAndLifeStylePopup: Popup,
    EducationAndProfessionPopup: Popup,
    FamilyDetailPopup: Popup,
    PersonalRecordPopup: Popup,
    InterestPopup: Popup,
    AboutPopup: Popup,
    SummaryPopup: Popup,
    UsernamePopup: Popup,
    CommentPopup: Popup,
    ImagePopup: Popup,
    EmailChangePopup: Popup,
    NameChangePopup: Popup,
    SeekingAgePopup: Popup,
    SeekingDistancePopup: Popup,
    SelectProfilePopup: Popup,
    AppearanceAndLifeStyle,
    EducationAndProfession,
    PersonalRecord,
    FamilyDetail,
    Picker
  },

  created () {
    this.getEducationLevels()
    this.getEducationFields()
    this.getCities()
    this.getUsers()
    this.getTotalLikes(this.userData ? this.userData.id : this.user.id)
    this.getTotalComments(this.userData ? this.userData.id : this.user.id)
    this.getComments(this.userData ? this.userData.id : this.user.id)
    this.getGallery(this.userData ? this.userData.id : this.user.id)
  },

  computed: {
    educationAndProfession () {
      return this.userData && this.userData.education_and_professionals
    },

    familyDetails () {
      return this.userData && this.userData.family_detail
    },

    physicalAppearanceAndLifeStyle () {
      return this.userData && this.userData.physical_app_life_style
    },

    personalRecord () {
      return this.userData && this.userData.personal_record
    },

    imageBaseUrl () {
      return process.env.VUE_APP_API_IMAGE_PATH
    },

    isLoggedInUser () {
      return this.user && this.userData.id === this.user.id
    },

    connectFromUsers () {
      const blockedUserIds = this.user && this.user.blocked_users.map(val => {
        return val.id
      })

      return this.userData && this.userData.connect_from_users.filter(value => {
        return !blockedUserIds.includes(value.id) && value.profile_visible === 1
      })
    },

    connectToUsers () {
      const blockedUserIds = this.user && this.user.blocked_users.map(val => {
        return val.id
      })

      return this.userData && this.userData.connect_to_users.filter(value => {
        return !blockedUserIds.includes(value.id) && value.profile_visible === 1
      })
    },

    imageBasePath () {
      return process.env.VUE_APP_API_IMAGE_PATH
    },

    viewedUsers () {
      const blockedUserIds = this.user && this.user.blocked_users.map(val => {
        return val.id
      })

      return this.userData && this.userData.viewed_users.filter(value => {
        return !blockedUserIds.includes(value.id) && value.profile_visible === 1
      })
    },

    youMayKnowUsers () {
      return this.users ? this.users.slice(0, 5) : null
    }
  },

  data () {
    return {
      allMessages: [],
      showEmojiPicker: false,
      emojiIndex: emojiIndex,
      aboutData: '',
      summaryData: '',
      commentData: '',
      emailData: '',
      nameData: '',
      nameError: '',
      seekingAgeData: '',
      seekingDistanceData: '',
      childComment: '',
      showAppearanceAndLifeStylePopup: false,
      showEducationAndProfession: false,
      showFamilyDetailPopup: false,
      showChangeNamePopup: false,
      showPersonalRecordPopup: false,
      showAboutPopup: false,
      showImagePopup: false,
      showSummaryPopup: false,
      showCommentPopup: false,
      showChangeEmailPopup: false,
      showSeekingAgePopup: false,
      showSeekingDistancePopup: false,
      showUsernamePopup: false,
      activeStepBar: 0,
      message: '',
      imageData: '',
      tab: 1,
      fits: ['fill'],
      profileImage: '',
      showChat: false,
      showReplyForm: [],
      toUser: null,
      unComplitedDetails: [],
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      whoCanSee: '',
      totalProfileComplete: 0,
      username: '',
      userInterest: '',
      showAllSummary: false,
      showAddInterest: false,
      showSelectProfilePopup: false
    }
  },

  setup () {
    const state = reactive({
      emailData: '',
      nameData: ''
    })

    const rules = computed(() => {
      return {
        emailData: { required: helpers.withMessage('Email field is required', required), email },
        nameData: { required: helpers.withMessage('Name field is required', required) }
      }
    })

    const v$ = useValidate(rules, state)

    return {
      state,
      v$
    }
  },

  methods: {
    onSelectEmoji (emoji) {
      this.message += emoji.native

      this.showEmojiPicker = !this.showEmojiPicker
    },

    openEmojiPicker () {
      this.showEmojiPicker = !this.showEmojiPicker
    },

    getCountryName (code) {
      return getName(code)
    },

    profileCompletePercentage () {
      let total = 0

      if (this.personalRecord) {
        total += 20
      } else {
        if (!this.unComplitedDetails.find(notCompletedRecord => { return notCompletedRecord === 'personalRecord' })) {
          this.unComplitedDetails.push('personalRecord')
        }
      }

      if (this.physicalAppearanceAndLifeStyle) {
        total += 20
      } else {
        if (!this.unComplitedDetails.find(notCompletedRecord => { return notCompletedRecord === 'physicalAppearanceAndLifeStyle' })) {
          this.unComplitedDetails.push('physicalAppearanceAndLifeStyle')
        }
      }

      if (this.personalRecord && (this.personalRecord.educational_level || this.personalRecord.educational_field)) {
        total += 20
      } else {
        if (!this.unComplitedDetails.find(notCompletedRecord => { return notCompletedRecord === 'Education' })) {
          this.unComplitedDetails.push('Education')
        }
      }

      if (this.familyDetails) {
        total += 20
      } else {
        if (!this.unComplitedDetails.find(notCompletedRecord => { return notCompletedRecord === 'Family Detail' })) {
          this.unComplitedDetails.push('Family Detail')
        }
      }

      const index = this.unComplitedDetails.indexOf('SummaryData')
      if (index > -1) {
        this.unComplitedDetails.splice(index, 1)
      }

      if (this.summaryData && this.summaryData.length) {
        total += 20
      } else {
        if (!this.unComplitedDetails.find(notCompletedRecord => { return notCompletedRecord === 'SummaryData' })) {
          this.unComplitedDetails.push('SummaryData')
        }
      }

      this.totalProfileComplete = total
    },

    closeChangeEmailPopup () {
      this.showChangeEmailPopup = false
    },

    closeChangeNamePopup () {
      this.showChangeNamePopup = false
    },

    closeUsernamePopup () {
      this.showUsernamePopup = false
    },

    onShowAllSummary () {
      this.showAllSummary = true
    },

    hideAllSummary () {
      this.showAllSummary = false
    },

    openSelectProfilePopup () {
      if (this.user.id === this.userData.id) {
        this.showSelectProfilePopup = true
      }
    },

    hideSelectProfilePopup () {
      this.showSelectProfilePopup = false
    },

    makeGalleryProfile (imageId) {
      this.makeProfile({
        user_id: this.user.id,
        gallery_id: imageId || this.selectedImageId
      }).then(() => {
        this.$message({
          message: 'Gallery updated to profile successfully.',
          type: 'success'
        })

        this.$emit('user:update')
        this.showImagePopup = false
        this.showSelectProfilePopup = false
      })
    },

    onChangeEmailSubmit () {
      this.v$.$validate()
      if (!this.v$.$error) {
        this.changeEmail({
          user_id: this.user.id,
          email: this.state.emailData
        }).then(() => {
          this.$message({
            message: 'User email updated successfully.',
            type: 'success'
          })

          this.state.emailData = ''
          this.showChangeEmailPopup = false

          this.$emit('user:update')
          this.logout()
          this.$router.push({ name: 'user-login' })
        }).catch((error) => {
          this.$message({
            message: error.response.data.error ? 'Invalid data' : error.response.data.message,
            type: 'warning'
          })

          this.state.emailData = ''
          this.showChangeEmailPopup = false
        })
      }
    },

    onChangeNameSubmit () {
      if (!this.nameData) {
        this.nameError = 'Name field is required'

        return
      }

      this.changeName({
        user_id: this.user.id,
        name: this.nameData
      }).then(() => {
        this.$message({
          message: 'User name updated successfully.',
          type: 'success'
        })

        this.nameData = ''
        this.showChangeNamePopup = false

        this.$emit('user:update')
      }).catch((error) => {
        this.$message({
          message: error.response.data.error ? 'Invalid data' : error.response.data.message,
          type: 'warning'
        })

        this.nameData = ''
        this.showChangeNamePopup = false
      })
    },

    onVideoChatClick (user) {
      this.$router.push({ name: 'video-chat', params: { id: user.id } })
    },

    onShowChangeEmailPopup () {
      this.showChangeEmailPopup = true
    },

    onShowChangeNamePopup () {
      this.showChangeNamePopup = true
    },

    closeSeekingAgePopup () {
      this.showSeekingAgePopup = false
    },

    closeInterestPopup () {
      this.showAddInterest = false
    },

    onSeekingAgeSubmit () {
      this.changeSeekingAge({
        user_id: this.user.id,
        seeking_age: this.seekingAgeData
      }).then(() => {
        this.$message({
          message: 'User seeking age updated successfully.',
          type: 'success'
        })

        this.showSeekingAgePopup = false

        this.seekingAgeData = ''

        this.$emit('user:update')
      }).catch(() => {
        this.$message({
          message: 'Ops, Something went wrong.',
          type: 'warning'
        })
      })
    },

    onShowSeekingAgePopup () {
      if (!this.personalRecord) {
        this.$message({
          message: 'Please about me data first to add seeking age.',
          type: 'warning'
        })

        return
      }

      this.showSeekingAgePopup = true
    },

    closeSeekingDistancePopup () {
      this.showSeekingDistancePopup = false
    },

    onSeekingDistanceSubmit () {
      this.changeSeekingDistance({
        user_id: this.user.id,
        seeking_distance: this.seekingDistanceData
      }).then(() => {
        this.$message({
          message: 'User email updated successfully.',
          type: 'success'
        })

        this.showSeekingDistancePopup = false

        this.seekingDistanceData = ''

        this.$emit('user:update')
      }).catch(() => {
        this.$message({
          message: 'Ops, Something went wrong.',
          type: 'warning'
        })
      })
    },

    onShowSeekingDistancePopup () {
      if (!this.personalRecord) {
        this.$message({
          message: 'Please about me data first to add seeking distance.',
          type: 'warning'
        })

        return
      }

      this.showSeekingDistancePopup = true
    },

    openAddInterest () {
      if (!this.personalRecord) {
        this.$message({
          message: 'Please about me data first to add interest and sports.',
          type: 'warning'
        })

        return
      }

      this.showAddInterest = !this.showAddInterest
    },

    onAddUserInterest () {
      this.addUserInterest({
        user_id: this.user.id,
        interest: this.userInterest
      }).then(() => {
        this.$message({
          message: 'User interest added successfully.',
          type: 'success'
        })

        this.userInterest = ''
        this.showAddInterest = false
        this.$emit('user:update')
      })
    },

    getGender (gender) {
      if (gender === 1) {
        return 'Male'
      }

      if (gender === 0) {
        return 'Female'
      }

      return ''
    },

    closeAboutPopup () {
      this.showAboutPopup = false
    },

    closeSummaryPopup () {
      this.showSummaryPopup = false
    },

    closeAppearanceAndLifeStylePopup () {
      this.showAppearanceAndLifeStylePopup = false
    },

    closeEducationAndProfession () {
      this.showEducationAndProfession = false
    },

    closeFamilyDetail () {
      this.showFamilyDetailPopup = false
    },

    closePersonalRecordPopup () {
      this.showPersonalRecordPopup = false
    },

    closeImagePopup () {
      this.showImagePopup = false
      this.imageData = ''
      this.selectedImageId = ''
    },

    closeCommentPopup () {
      this.showCommentPopup = false
    },

    onViewUser (user) {
      this.$router.push({ name: 'user-profile', params: { id: user.id } })
    },

    openImagePopup (imageData) {
      this.imageData = imageData.image
      this.selectedImageId = imageData.id

      this.showImagePopup = true
    },

    openAddAboutPopup () {
      if (!this.personalRecord) {
        this.$message({
          message: 'Please add personal record data first to add About data.',
          type: 'warning'
        })

        return
      }

      this.showAboutPopup = true
    },

    openAddSummaryPopup () {
      if (!this.personalRecord) {
        this.$message({
          message: 'Please add personal record data first to add Summary data.',
          type: 'warning'
        })

        return
      }

      this.showSummaryPopup = true
    },

    openAddUsernamePopup () {
      this.showUsernamePopup = true
    },

    openEducationAndProfession () {
      if (!this.personalRecord) {
        this.$message({
          message: 'Please add about me data first to add education and profession.',
          type: 'warning'
        })

        return
      }

      this.showEducationAndProfession = true
    },

    openFamilyDetail () {
      this.showFamilyDetailPopup = true
    },

    openPersonalRecordPopup () {
      this.showPersonalRecordPopup = true
    },

    openAppearanceAndLifeStylePopup () {
      this.showAppearanceAndLifeStylePopup = true
    },

    onImageChange (e) {
      const files = e.target.files || e.dataTransfer.files

      if (files.length) {
        const image = files[0]

        this.updateProfileImage(Object.assign({ image: image, user_id: this.user.id })).then(() => {
          this.$emit('user:update')

          this.$emit('imageUpdated')

          this.showSelectProfilePopup = false

          this.getGallery(this.userData ? this.userData.id : this.user.id)
        })
      }
    },

    onGalleryUpload (e) {
      const files = e.target.files || e.dataTransfer.files

      if (files.length) {
        const image = files[0]

        this.createGallery(Object.assign({ image: image, user_id: this.user.id })).then(() => {
          this.$message({
            message: 'Gallery added successfully.',
            type: 'success'
          })

          this.getGallery(this.userData ? this.userData.id : this.user.id)
        })
      }
    },

    onWhoCanSeeClick (e) {
      this.updateProfileVisible({
        profile_visible: e.target.value,
        user_id: this.user.id
      }).then(() => {
        this.$message({
          message: 'Profile Visible updated successfully.',
          type: 'success'
        })

        this.$emit('user:update')
      })
    },

    onCoverImageChange (e) {
      const files = e.target.files || e.dataTransfer.files

      if (files.length) {
        const image = files[0]

        this.updateCoverImage(Object.assign({ cover_image: image, user_id: this.user.id })).then(() => {
          this.$emit('user:update')

          this.$emit('imageUpdated')
        })
      }
    },

    onAboutSubmit () {
      this.createUserAbout(Object.assign(this.aboutData, { user_id: this.user.id })).then(() => {
        this.showAboutPopup = false

        this.$emit('user:update')

        this.$notify({
          message: 'About data added successfully.',
          type: 'success'
        })
      })
    },

    onSubmitUsername () {
      this.storeUsername({ user_id: this.user.id, username: this.username }).then(() => {
        this.showUsernamePopup = false

        this.$emit('user:update')

        this.$notify({
          message: 'Username added successfully.',
          type: 'success'
        })
      })
    },

    onSummarySubmit () {
      this.createUserSummary(Object.assign(this.summaryData, { user_id: this.user.id })).then(() => {
        this.showSummaryPopup = false

        this.$emit('user:update')

        this.profileCompletePercentage()

        this.$notify({
          message: 'Summary added successfully.',
          type: 'success'
        })
      })
    },

    onCommentSubmit () {
      this.createComment({
        from_user_id: this.user.id,
        to_user_id: this.userData.id,
        comment: this.commentData
      }).then(() => {
        this.$message({
          message: 'User comment added successfully.',
          type: 'success'
        })

        this.getComments(this.userData ? this.userData.id : this.user.id)

        this.commentData = ''
        this.showCommentPopup = false
      }).catch(() => {
        this.$message({
          message: 'Ops! Something went wrong.',
          type: 'warning'
        })

        this.commentData = ''

        this.showCommentPopup = false
      })
    },

    onCreate () {
      this.activeStepBar++
      this.tab++
    },

    onReplyClick (key) {
      this.showReplyForm.forEach((replyInput, key) => {
        if (replyInput) {
          this.showReplyForm[key] = false
        }
      })

      this.showReplyForm[key] = true
    },

    onChildCommentSubmit (comment) {
      this.createComment({
        from_user_id: this.user.id,
        to_user_id: comment.to_user_id,
        comment: this.childComment,
        parent_id: comment.id
      }).then(() => {
        this.$message({
          message: 'Comment replied successfully.',
          type: 'success'
        })

        this.childComment = ''

        this.getComments(this.userData ? this.userData.id : this.user.id)
      })
    },

    onPhysicalAppAndLifeStyleUpdate () {
      this.showAppearanceAndLifeStylePopup = false
      this.$emit('user:update')

      this.profileCompletePercentage()
    },

    onFamilyDetailUpdate () {
      this.showFamilyDetailPopup = false
      this.$emit('user:update')

      this.profileCompletePercentage()
    },

    onPersonalRecordUpdate () {
      this.showPersonalRecordPopup = false
      this.$emit('user:update')

      this.profileCompletePercentage()
    },

    onEducationAndProfessionUpdate () {
      this.showEducationAndProfession = false

      this.$emit('user:update')

      this.profileCompletePercentage()
    },

    onCommentClick () {
      this.showCommentPopup = true
    },

    subscribe () {
      const pusher = new Pusher('4b1c61858ff8ed28adc6', {
        cluster: 'ap2',
        encrypted: false
      })
      pusher.subscribe('chat')
      pusher.bind('my-event', data => {
        this.allMessages.push(data.message)

        var container = this.$refs.chatContainer

        container.scrollTop = container.scrollHeight + 150
      })
    },

    onDeleteInterest (interest) {
      this.$confirm(`Are you sure you want to delete ${interest.interest}?`, 'Accept', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(() => {
        this.deleteUserInterest(interest.id).then(() => {
          this.$message({
            message: 'User Interest Deleted Successfully.',
            type: 'success'
          })

          this.getMe()
          this.$emit('user:update')
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      })
    },

    onDeleteGallery (gallery) {
      this.$confirm('Are you sure you want to delete', 'Accept', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(() => {
        this.deleteUserGallery(gallery.id).then(() => {
          this.$message({
            message: 'User Gallery Deleted Successfully.',
            type: 'success'
          })

          this.getGallery(this.userData ? this.userData.id : this.user.id)
          this.$emit('user:update')
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      })
    },

    onClickAcceptUser (user) {
      this.$confirm(`Are you sure you want to accept ${user.name}?`, 'Accept', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(() => {
        this.acceptUser({
          from_id: user.pivot.from_id,
          to_id: user.pivot.to_id
        }).then(() => {
          this.$message({
            message: 'User Connection Accepted',
            type: 'success'
          })

          this.getMe()
          this.$emit('user:update')
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      })
    },

    onClickRejectUser (user) {
      this.$confirm(`Are you sure you want reject ${user.name}?`, 'Reject', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.rejectUser({
          from_id: user.pivot.from_id,
          to_id: user.pivot.to_id
        }).then(() => {
          this.$message({
            message: 'User Connection Rejected',
            type: 'success'
          })

          this.getMe()
          this.$emit('user:update')
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      })
    },

    onLikeClick (user) {
      this.$confirm(`Are you sure you want to like ${user.name}?`, 'Accept', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(() => {
        this.createLike({
          from_user_id: this.user.id,
          to_user_id: user.id
        }).then(() => {
          this.$message({
            message: 'User Liked Successfully',
            type: 'success'
          })

          this.getTotalLikes(this.userData ? this.userData.id : this.user.id)
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      })
    },

    onChatClick (toUserId) {
      this.showChat = true

      this.subscribe()

      this.getUser(toUserId).then((res) => {
        this.toUser = res
      })

      this.getAllMessages(toUserId)
    },

    getAllMessages (toUserId) {
      this.getMessages({
        from_user_id: this.user.id,
        to_user_id: Number(toUserId)
      }).then((res) => {
        this.allMessages = res
      })
    },

    onSendMessage () {
      this.createMessage({
        from_user_id: this.user.id,
        to_user_id: this.toUser.id,
        message: this.message
      }).then(() => {
        this.getAllMessages(this.toUser.id)
        var container = this.$refs.chatContainer

        container.scrollTop = container.scrollHeight + 150

        this.message = ''
      })
    },

    getTime (dateTime) {
      const today = new Date(dateTime)
      return today.getHours() + ':' + today.getMinutes()
    },

    closeChat () {
      this.showChat = false
    }
  },

  mixins: [configurationMixin, likeCommentMixin, galleryMixin, userMixin, messageMixin],

  mounted () {
    if (this.userData && this.userData.personal_record && this.userData.personal_record.about) {
      this.aboutData = this.userData.personal_record.about
    }

    if (this.userData && this.userData.personal_record && this.userData.personal_record.summary) {
      this.summaryData = this.userData.personal_record.summary
    }

    if (this.userData) {
      this.whoCanSee = this.userData.profile_visible ? this.userData.profile_visible : this.user.profile_visible

      this.profileCompletePercentage()
    }

    this.username = this.userData ? this.userData.username : this.user.name

    if (this.userData.id !== this.user.id) {
      this.updateUserViewedCount({
        user_id: this.userData.id,
        from_user_id: this.user.id
      })
    }
  },

  name: 'UserProfile',

  props: {
    userData: {
      required: true
    }
  }
}
</script>
<style lang="scss">
.interest-add {
  display: flex;
}
.profile-ul{
  margin: 0;
  padding: 40px 0;
  background: #f3f2ef;
}

.profile-ul-pro-box{
  background: #fff;
  border-radius: 5px;
  padding: 40px 20px 25px 20px;
  text-align: center;
}

.profile-ui-image{
  width: 220px;
  margin: 0 auto;
}

.profile-ui-image img{
  width:100%;
  border-radius: 50%;
  width: 220px;
  height: 220px;
  border: 1px solid #ccc;
  transform: scale(1);
  transition: .3s ease-in-out;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile-ui-name{
  margin: 0;
  padding: 25px 0 0 0;
  font-size: 24px;
  color: #000;
  font-weight: 700;
}

.profile-ui-location{
  margin: 0;
  padding: 3px 0 0 0;
  font-size: 15px;
  color: #000;
  font-weight: 400;
}

.profile-ui-gender{
  margin: 0;
  padding: 3px 0 0 0;
  font-size: 15px;
  color: #000;
  font-weight: 400;
}

.profile-ui-like-count{
  margin: 0 auto;
  padding: 20px 0 0 0;
  font-size: 20px;
  color: #000;
  line-height: 28px;
  width: 60px;
  display: flex;
  font-weight: 400;
}

.profile-ui-like-count img{
  width: 28px;
  margin-right: 8px;
}

.profile-ui-lcs-btn{
  margin: 20px 0 0 0;
  padding: 0;
}

.profile-ui-lcs-btn .lcs-button{
  margin: 0 5px 0 0 !important;
  padding: 0 12px;
  text-align: center;
  border: 1px solid #003993;
  color: #003993;
  border-radius: 20px;
  background: #ffffff;
  font-size: 15px;
  font-weight: 600;
  min-height: 35px !important;
  transition: all 0.3s ease-in 0s;
}

.profile-ui-lcs-btn .lcs-button:hover{
  background:#003993;
  color: #fff;
  border: 1px solid #003993;
  transition: all 0.3s ease-in 0s;
}

.profile-ui-lcs-btn .lcs-button:focus{
  background:#003993;
  color: #fff;
  border: 1px solid #003993;
  transition: all 0.3s ease-in 0s;
}

.profile-ui-premium{
  margin: 25px 0 0 0;
  padding: 0;
}

.profile-ui-premium .pck-button{
  margin: 0 5px 0 0 !important;
  padding: 0 25px;
  text-align: center;
  border: 1px solid #dc143c;
  color: #fff;
  border-radius: 35px;
  background: #dc143c;
  font-size: 18px;
  font-weight: 500;
  min-height: 45px !important;
  transition: all 0.3s ease-in 0s;
}

.profile-ui-premium .pck-button:hover{
  background:#dc143c;
  color: #fff;
  border: 1px solid #dc143c;
  transition: all 0.3s ease-in 0s;
}

.profile-gallery{
  background: #fff;
  border-radius: 5px;
  padding: 30px 20px 25px 20px;
  margin: 20px 0 0 0;
  text-align: center;
}

.profile-gal-heading{
  margin: 0;
  padding: 0 0 5px 0;
  font-size: 22px;
  text-align: center;
}

.galbox{
  background: #0a212f;
  font-family: 'Source Sans Pro', sans-serif;
  text-align: center;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  margin: 15px 0 0px 0;
}

.galbox img{
  width: 100%;
  height: auto;
  transition: all .5s ease 0.1s;

}
.galbox:hover img{
  opacity: 0.25;
  filter: blur(3px);
  transform: scale(1.1);

}

.galbox .icon{
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  top: 45%;
  left:50%;
  width: 100%;
  transition: all .5s ease-in-out;
}
.galbox:hover .icon{ opacity: 1; }
.galbox .icon li{
  margin: 0 10px;
  display: inline-block;
}

.galbox .icon li a{
  color: #fff;
  font-size: 14px;
  display: block;
  position: relative;
  transition: all .5s ease 0s;
}

.galbox .icon li a:hover{
  text-shadow: 0 0 7px rgba(0,0,0,0.9);
}

.gal-add-photo{
  margin: 0;
  padding: 20px 0 0 0;
}

.gal-add-photo .add-button{
  margin: 0 5px 0 0 !important;
  padding: 0 15px;
  text-align: center;
  border: 1px solid #003993;
  color: #fff;
  border-radius: 20px;
  background: #003993;
  font-size: 12px;
  font-weight: 600;
  min-height: 34px !important;
  transition: all 0.3s ease-in 0s;
}

.gal-add-photo .add-button:hover{
  background:#003993;
  color: #fff;
  border: 1px solid #003993;
  transition: all 0.3s ease-in 0s;
}

.info-box{
  margin: 0 0 20px 0;
  padding:20px;
  background: #fff;
  border-radius: 5px;
}

.infobox-heading{
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
}

.infoplus{
  float: right;
}

.info-list{
  padding: 25px 0 0 0;
}

.info-list-split{
  padding:8px 0 5px 0;
  display: flex;
}

.info-list-icon{
  padding: 0;
 /* width: 40px;*/
/*  text-align: center;*/
}

.info-list-icon span{
  padding: 0;
  font-weight: 600;
}

.info-list-text{
  padding:0px 0 0 5px;
 /* font-weight: 600;*/
}

.front-ui-conn-list {
  margin: 0 0 2px 0;
  padding: 12px 15px;
  clear: both;
  display: flex;
  background: #fff;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list:hover{
/*  box-shadow: 0px 10px 5px rgb(191 182 182);
  -moz-box-shadow: 0px 5px 5px rgba(0,0,0,0.6);
  -webkit-box-shadow: 0px 4px 5px rgb(151 149 149);
  -o-box-shadow: 0px 10px 5px rgba(0,0,0,0.6);*/
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .left-col {
  margin: 0 15px  0 0;
  padding: 0;
  width: 55 px;
  float: left;
}

.front-ui-conn-list .right-col {
  margin: 0;
  padding: 0;
  float: left;
}

.front-ui-conn-list .left-col img {
  margin: 0;
  padding: 0;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.front-ui-conn-list .title a {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.front-ui-conn-list .ui-bar-side{
  margin: 0;
  padding: 5px 0 0 0;
}

.front-ui-conn-list .ui-bar-button{
  margin: 0 5px 0 0 !important;
  padding: 0 10px;
  text-align: center;
  border: 1px solid #237eeb;
  color: #237eeb;
  border-radius: 20px;
  background: #ffffff;
  font-size: 12px;
  font-weight: 600;
  min-height: 28px !important;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .ui-bar-button:hover{
  background:#237eeb;
  color: #fff;
  border: 1px solid #237eeb;
  transition: all 0.3s ease-in 0s;
}
.connect-button {
  margin-top: 1rem;
}
.about-item {
  color: orangered;
}
.el-breadcrumb {
  padding: 30px 0;
}

.profile-progress {
  margin-top: 1rem;
  color: darkred;
}

.select-profile-picture {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 1rem;

  img {
    cursor: pointer;
    width: 33%;
    padding: 5px;
  }
}

.icons-gal{
  margin-top: -28px;
  margin-left: 20px;
  color: #fff;
}

.icons-gal i{
  cursor: pointer;
}

.user-profile {
  margin: 0;
  padding: 30px 0 30px 0;
  background: #f3f2ef;
}

.background-profile {
  padding: 0;

}

.box-card-profile {
  padding: 0;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  background: #FFFFFF;
  margin-bottom: 20px;
}

.box-card-profile i{
  font-size:16px;
}

.background-profile img {
  margin: 0;
  padding: 0;
  width: 100%;
  background: #fff;
}

.edit-background {
  margin: 0 15px 0 0;
  padding: 0;
  float: right;
  position: relative;
  top: -70px;
  z-index: 99 !important;
}

.el-icon-camera-solid {
  margin: 0;
  padding: 8px;
  background: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 20px;
  text-align: center;
}

.profile-userpic {
  margin: 0 15px 0 20px;
  padding: 20px 0 30px 0;
  position: relative;
  z-index: 999;
}

.profile-userpic img {
  float: none;
  margin: 0 auto;
  width: 190px;
  height: 190px;
/*  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;*/
  border: 5px solid #fff;
}

.box-card-profile .profile-name {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.box-card-profile .profile-post {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
}

.contact-info {
  margin: 0;
  padding: 3px 0 20px 0;
  font-size: 15px !important;
  line-height: 22px;
}

.contact-info a {
  color: #030848;
  font-weight: 600;
}

.edit-details {
  text-align: right;
}

.edit-details .el-icon-edit {
  font-size: 25px;
}

.profile-viewed {
  margin: 0;
  padding: 0 0 20px 0 !important;
}

.profile-viewed span {
  font-size: 16px !important;
  font-weight: 600;
}

.viewed-list {
  margin: 0;
  padding: 10px 0 5px 0;
  clear: both;
  display: flex;
  border-bottom: 1px solid #ccc;
}

.viewed-list .left-col {
  margin: 0 15px 0 0;
  padding: 0;
  width: 55px;
  float: left;
}

.viewed-list .left-col img {
  margin: 0;
  padding: 0;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.viewed-list .right-col {
  margin: 0;
  padding: 0;
  float: left;
}

.viewed-list .title {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 15px;
}

.connection-wants-title {
  display: flex;
  justify-content: space-between;
}

.viewed-list .title a {
  margin: 0;
}

.viewed-list .title a:hover {
  color: #F56C6C;
}

.viewed-list .description {
  margin: 0;
  padding: 5px 0 0 0;
  font-size: 11px;
  line-height: 16px;
}

.el-card__header {
  padding: 15px 20px 8px 20px !important;
}

.el-card {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 20px;
}

.el-card__body p{
  font-weight: 600;
  line-height: 25px;
}

.add-info {
  float: right;
}

.add-info .icons {
  font-weight: 600;
  color: #fff !important;
  font-size: 16px;
}

.add-info .icons:hover {
  font-weight: 600;
  color: #F56C6C;
}

.top-gap {
  padding-top: 30px;
}

label {
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
}

.tp-gap {
  padding-top: 30px;
}

.el-input__inner {
  margin: 5px 0 12px 0 !important;
  border: 1px solid #ccc !important;
  height: 38px;
}

.profile-notice{ font-weight: bold; font-size: 15px; color:#f00;}

.c-register {
  &__form {
    padding: 1rem 10px;
  }

  &__tabs {
    margin-bottom: 1rem;
  }
}
.chat-container{
  width: 320px;
  font-family: 'Open Sans', sans-serif;
  position: fixed;
  bottom: 0;
  right: 40px;
  min-height: 300px;
  z-index: 99999;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.chatcard-bordered {
  border: 1px solid #ebebeb;
}

.chatcard {
  border: 0;
  border-radius: 0px;
  margin-bottom: 5px;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  -webkit-transition: .5s;
  transition: .5s;
}

.padding {
  padding: 3rem !important;
}
.chatcard-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
  background: #003895;
}

.chatcard-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px 10px;
  background-color: transparent;
  border-bottom: 1px solid rgba(77, 82, 89, 0.07)
}

.chatcard-header .chatcard-title {
  margin:4px 0;
  padding: 0;
  border: none;
  color:#fff;
}

h4.chatcard-title {
  font-size: 17px;
  color:#fff;
}

.chatcard-title strong{
  color: #fff;
}

.chatcard-header>*:last-child {
  margin-right: 0;
}

.chatcard-header>* {
  margin-left: 8px;
  margin-right: 8px;
}

.btn-secondary {
  color: #4d5259 !important;
  background-color: #003895;
  border-color: #e4e7ea;
  color: #fff !important;
  font-size: 15px;
}

.btn-xs {
  font-size: 15px;
  padding: 2px 8px;
  line-height: 18px;
  text-decoration: none;
}

.btn-xs:hover {
  color: #fff !important;
}

.chatcard-title {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(77, 82, 89, 0.07)
}

.text-chat{
  padding-left: 45px;
  margin-top: -35px;
  font-size: 16px;
}

.ps-container {
  position: relative;
}

.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}

.media-chat {
  padding-right: 64px;
  margin-bottom: 0;
  background: #fff;
}

.media {
  padding: 0 12px;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.media .avatar {
  flex-shrink: 0;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  background-color: #f5f6f7;
  color: #8b95a5;
  text-transform: uppercase;
}

.media-chat .media-body {
  -webkit-box-flex: initial;
  flex: initial;
  display: table;
}

.media-body {
  min-width: 0;
}

.media-chat .media-body p {
  position: relative;
  padding: 6px 8px;
  margin: 4px 0;
  background-color: #f5f6f7;
  border-radius: 3px;
  font-weight:400;
  color: #716d6d;
}

.media>* {
  margin: 0 8px;
}

.media-chat .media-body p.meta {
  background-color: transparent !important;
  padding: 0 !important;
  opacity: 0.8;
  color: #000 !important;
}

.media-meta-day {
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 0;
  color: #8b95a5;
  opacity: .8;
  font-weight: 400;
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.media-meta-day::before {
  margin-right: 16px;
}

.media-meta-day::before,
.media-meta-day::after {
  content: '';
  -webkit-box-flex: 1;
  flex: 1 1;
  border-top: 1px solid #ebebeb;
}

.media-meta-day::after {
  content: '';
  -webkit-box-flex: 1;
  flex: 1 1;
  border-top: 1px solid #ebebeb;
}

.media-meta-day::after {
  margin-left: 16px;
}

.media-chat.media-chat-reverse {
  padding-right: 12px;
  padding-left: 64px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.media-chat {
  padding-right: 64px;
  margin-bottom: 0;
}

.media {
  padding: 5px 12px;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.media-chat.media-chat-reverse .media-body p {
  float: right;
  clear: right;
  background-color: #48b0f7;
  color: #fff;
  font-size: 15px;
  border-radius: 20px;
  padding: 10px 15px;
}

.media-chat .media-body p {
  position: relative;
  padding: 10px 15px !important;
  margin: 4px 0;
  background-color: #f5f6f7;
  border-radius: 3px;
  font-size: 15px;
  border-radius: 20px;

}

.border-light {
  border-color: #f1f2f3 !important;
}

.bt-1 {
  border-top: 1px solid #ebebeb !important;
}

.publisher {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 12px 20px;
  background-color: #fff;
}

.publisher>*:first-child {
  margin-left: 0;
}

.publisher>* {
  margin: 0 8px;
}

.publisher-input {
  -webkit-box-flex: 1;
  flex-grow: 1;
  border: none;
  outline: none !important;
  background-color: #ccc;
  border-radius: 10Px;
  padding: 5PX;
}

.publisher-btn {
  background-color: transparent;
  border: none;
  color: #8b95a5;
  font-size: 16px;
  cursor: pointer;
  overflow: -moz-hidden-unscrollable;
  -webkit-transition: .2s linear;
  transition: .2s linear;
}

.file-group {
  position: relative;
  overflow: hidden;
}

.publisher-btn {
  background-color: transparent;
  border: none;
  color: #cac7c7;
  font-size: 16px;
  cursor: pointer;
  overflow: -moz-hidden-unscrollable;
  -webkit-transition: .2s linear;
  transition: .2s linear;
}

.file-group input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 20px;
}

.text-info {
  color: #fff !important;
  margin: 0;
  padding: 8px 10px;
  background: #003993 !important;
  border-radius: 20px;
}

.btn-seperator{
  padding-top: 20px;
}
.el-button.round-btn {
  border-radius: 20px !important;
  padding: 5px 10px !important;
  font-size: 13px !important;
  min-height: 36px !important;
}

.contact-info .normal-flag{
  margin-left: -12px;
}

.round-btn{
  border-radius: 20px !important;
  padding: 5px 15px !important;
  font-size: 13px !important;
  min-height: 36px !important;
}

.round-btn:hover{
  background: #F56C6C !important;
  border: 1px solid #F56C6C !important;
  transition: .1s;
}

.comment-wrapper{
  margin:0;
  padding: 0;
  height:280px;
  overflow-x: hidden;
}

.comment-list{
  margin: 0 0 5px 0;
  padding: 0;
  display: flex;
}

.comment-list-img{
  margin: 0;
  padding: 0;
  float: left;
  width: 45px;
}

.comment-list-img img{
  margin: 0;
  padding: 0;
  width: 40px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.comment-list-content-area{
  margin: 0;
  padding:0;
}

.comment-list-content{
  margin: 0;
  padding: 10px 10px;
  background: #f0f2f5;
  border-radius: 3px;
}

.comment-list-content .uname{
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
}

.comment-list-content p{
  margin: 0;
  padding: 0;
  line-height: 18px;
  font-size: 13px;
  font-weight: normal;
}

.comment-reply{
  margin:0;
  padding: 5px 10px;
  margin-left:40px;
  display: flex;
}

.comment-reply-img{
  margin: 0;
  padding: 0;
  float: left;
  width: 50px;
}

.comment-reply-img img{
  margin: 0;
  padding: 0;
  width: 40px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.comment-reply-content-box {
  margin: 0;
  padding: 0;
}

.comment-reply-content{
  margin: 0;
  padding: 10px 10px;
  background: #f0f2f5;
  border-radius: 3px;
}

.comment-reply-content .uname{
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
}

.comment-reply-content p{
  margin: 0;
  padding: 0;
  line-height: 18px;
  font-size: 13px;
  font-weight: normal;
}

.comment-like{
  margin:0;
  padding: 0;
}

.likes{
  margin: 0;
  padding: 3px 0 3px 10px;
  color: #084db9;
}

.likes a:hover{
  text-decoration: underline;
}

.comment-link{
  padding-left: 10px;
  color: #084db9;
}

.comment-link a:hover{
  text-decoration: underline;
}

.comment-form-display{
  margin:0;
  padding: 0;
}

.comment-form{
  align-items: center;
  margin:0;
  padding: 5px 10px;
  margin-left:40px;
  display: flex;
}

.comment-form-img{
  margin: 0;
  padding: 0;
  float: left;
  width: 50px;
}

.comment-form-img img{
  margin: 0;
  padding: 0;
  width: 40px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.comment-form-content{
  margin: 0;
  padding: 0px 5px;
  background: #f0f2f5;
  border-radius: 3px;
  width: 100%;
  margin-right: 0.2rem;
}

.comment-form-content .uname{
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
}

.comment-form-content p{
  margin: 0;
  padding: 0;
  line-height: 18px;
  font-size: 13px;
  font-weight: normal;
}

.comment-form-content .el-input__inner{
  background: #f0f2f5 !important;
  border:none !important;
}

.likescore{
  margin: 0;
  padding: 15px 0 0 0;
  font-weight: 600;
  color: #084db9;
}

.commentscore{
  padding: 0 0px 0 20px;
  margin: 0;
}

.front-ui-conn-list-pro{
  margin: 0 0 2px 0;
  padding: 12px 15px;
  background: #fff;
  transition: all 0.3s ease-in 0s;
}
.front-ui-conn-list-pro .checklist{
  margin: 5px 0;
  padding: 0 0 8px 0;
  font-size: 16px;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list-pro .checklist .radio-button{
  float: right;
}

.front-ui-conn-list .accepted-smv{
  padding: 2px 3px;
  border: 1px solid #0da749;
  color: #fff;
  border-radius: 20px;
  background: #0da749;
  font-size: 9px;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .rejected-smv{
  padding: 2px 3px;
  border: 1px solid #f00;
  color: #fff;
  border-radius: 20px;
  background: #f00;
  font-size: 9px;
  transition: all 0.3s ease-in 0s;
}

.user-interest{
  margin: 0;
  padding: 0;
}

.user-interest .in-btn {
    margin: 10px 10px 0 0;
    padding: 9px 12px 9px 12px;
    color: #000;
    background: #003993;
    border-radius: 5px;
    border: 1px solid #003993;
    background: none;
    transition: all 0.3s ease-in 0s;
    font-weight: 600;
}

.user-interest .in-btn:hover{
  color: #003993;
  border: 1px solid #003993;
  transition: all 0.3s ease-in 0s;
}

.delete-btn .el-icon-circle-close{
  position: absolute;
  margin-top:-30px;
  font-size:20px;
}

.delete-btn .el-icon-circle-close:hover{
  color: #003993;
}

.front-ui-conn-list-notfound {
  margin: 0 0 2px 0;
  padding: 20px 15px;
  background: #fff;
  transition: all 0.3s ease-in 0s;
  text-align: center;
  font-size: 16px;
}

.noviews{
  padding: 0;
  margin: 0;
}

.noviews .eye-icon{
  padding: 15px;
  margin: 20px 0 15px 0;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  font-size: 20px !important;
  border: 1px solid #bfbebe;
  line-height: 30px;
}

.noviews .eye-icon:hover{
  border: 1px solid #003993;
  color: #003993;
}

.subscribe-view{
  padding: 5px 0 20px 0;
  font-size:16px;
  color: #237eeb;
}

.subscribe-view a{
  padding: 0;
  font-size: 16px;
  color: #003993;
}

.profile-left-text{
  margin: 0;
  padding: 10px 0 10px 0;
}

.icon-profiles-edit{
  float: right;
}

.upload-profile{
  margin: 0 auto;
  margin-top:-20px;
}

.upload-profile .el-icon-camera-solid{
  font-size: 25px;
  text-align: center;
  padding: 7px !important;
  background: #f3f2ef !important;
  width: 40px !important;
  height: 40px !important;
  position: relative;
}

.gal-box{
  text-align: center;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  margin: 5px 0 0px 0;
}

.gal-box img {
  transform: scale(1);
  transition: .3s ease-in-out;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 140px;
  border-radius: 15px;
}

.gal-box:hover img{
  filter: blur(3px);
  transform: scale(1.1);

}

.gal-box .icon{
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  top: 70%;
  left:50%;
  width: 100%;
  transition: all .5s ease-in-out;
}

.gal-box:hover .icon{ opacity: 1; }
.gal-box .icon li{
  margin: 0 10px;
  display: inline-block;
}

.gal-box .icon li a{
  color: #fff;
  background: #003993;
  font-size: 14px;
  display: block;
  position: relative;
  transition: all .5s ease 0s;
  width: 28px;
  height: 28px;
  border-radius:50%;
  line-height: 28px;
}

.gal-box .icon li a:hover{
  text-shadow: 0 0 7px rgba(0,0,0,0.9);
}

</style>
