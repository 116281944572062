import Frontend from '@/views/user/Frontend.vue'
import Contact from '@/views/user/Contact'
import UserDashboard from '@/views/user/UserDashboard'
import NextUserDashboard from '@/views/user/NextUserDashboard'
import PrivacyPolicy from '@/views/user/PrivacyPolicy'
import TermsAndConditions from '@/views/user/TermsAndConditions'
import AboutUs from '@/views/user/AboutUs'
import FAQ from '@/views/user/FAQ'
import UserList from '@/views/user/UserList'
import Search from '@/views/user/Search'
import Feedback from '@/views/user/Feedback'
import Landing from '@/views/user/Landing'
import Newprofile from '@/views/user/Newprofile'
import Newpackage from '@/views/user/Newpackage'
import Newpayment from '@/views/user/Newpayment'
import Mutualsearch from '@/views/user/Mutualsearch'
import Reversesearch from '@/views/user/Reversesearch'
import Savedsearch from '@/views/user/Savedsearch'
import Newsearch from '@/views/user/Newsearch'
import Notification from '@/views/user/Notification'
import Customsearch from '@/views/user/Customsearch'
import Views from '@/views/user/Views'
import Likes from '@/views/user/Likes'
import Matches from '@/views/user/Matches'
import Matchevents from '@/views/user/Matchevents'
import Safetycenter from '@/views/user/Safetycenter'
import Datingincovid from '@/views/user/Datingincovid'
import Safetytips from '@/views/user/Safetytips'
import Additionalresources from '@/views/user/Additionalresources'
import Setting from '@/views/user/Setting'
import EventDetail from '@/views/user/EventDetail'
import Managesubscription from '@/views/user/Managesubscription'
import Emailnotification from '@/views/user/Emailnotification'
import Blockedprofiles from '@/views/user/Blockedprofiles'
import Removedprofiles from '@/views/user/Removedprofiles'
import Mobilepushnotification from '@/views/user/Mobilepushnotification'
import Sitenotification from '@/views/user/Sitenotification'
import Verifyaccount from '@/views/user/Verifyaccount'
import Browsebyprofile from '@/views/user/Browsebyprofile'
import Profilegal from '@/views/user/Profilegal'
import VideoChat from '@/views/user/VideoChat'

const routes = [
  {
    component: Frontend,
    meta: {
      frontend: true
    },
    name: 'frontend',
    path: '/'
  },
  {
    component: Contact,
    meta: {
      frontend: true
    },
    name: 'contact',
    path: '/contact'
  },
  {
    component: PrivacyPolicy,
    meta: {
      frontend: true
    },
    name: 'privacy-policy',
    path: '/privacy-policy'
  },
  {
    component: AboutUs,
    meta: {
      frontend: true
    },
    name: 'about-us',
    path: '/about-us'
  },
  {
    component: Likes,
    meta: {
      frontend: true
    },
    name: 'likes',
    path: '/likes'
  },
  {
    component: Views,
    meta: {
      frontend: true
    },
    name: 'views',
    path: '/views'
  },
  {
    component: Feedback,
    meta: {
      frontend: true
    },
    name: 'Feedback',
    path: '/feedback'
  },
  {
    component: Notification,
    meta: {
      frontend: true
    },
    name: 'notification',
    path: '/notification'
  },
  {
    component: Landing,
    meta: {
      frontend: true
    },
    name: 'landing',
    path: '/landing'
  },
  {
    component: Newprofile,
    meta: {
      frontend: true
    },
    name: 'newprofile',
    path: '/newprofile'
  },
  {
    component: Newpackage,
    meta: {
      frontend: true
    },
    name: 'newpackage',
    path: '/package'
  },
  {
    component: Newpayment,
    meta: {
      frontend: true
    },
    name: 'newpayment',
    path: '/newpayment'
  },
  {
    component: Mutualsearch,
    meta: {
      frontend: true
    },
    name: 'mutual-search',
    path: '/mutualsearch'
  },
  {
    component: Reversesearch,
    meta: {
      frontend: true
    },
    name: 'reverse-search',
    path: '/reversesearch'
  },
  {
    component: Savedsearch,
    meta: {
      frontend: true
    },
    name: 'saved-search',
    path: '/savedsearch'
  },
  {
    component: Customsearch,
    meta: {
      frontend: true
    },
    name: 'custom_search',
    path: '/custom_search'
  },
  {
    component: Newsearch,
    meta: {
      frontend: true
    },
    name: 'newsearch',
    path: '/newsearch'
  },
  {
    component: FAQ,
    meta: {
      frontend: true
    },
    name: 'faq',
    path: '/faq'
  },
  {
    component: TermsAndConditions,
    meta: {
      frontend: true
    },
    name: 'terms-conditions',
    path: '/terms-conditions'
  },
  {
    component: UserDashboard,
    meta: {
      frontend: true
    },
    name: 'user-dashboard',
    path: '/user-dashboard'
  },
  {
    component: NextUserDashboard,
    meta: {
      frontend: true
    },
    name: 'user-profile',
    path: '/user-profile/:id'
  },
  {
    component: UserList,
    meta: {
      frontend: true
    },
    name: 'user-list',
    path: '/user-list'
  },
  {
    component: Search,
    meta: {
      frontend: true
    },
    name: 'search',
    path: '/search'
  },
  {
    component: Matches,
    meta: {
      frontend: true
    },
    name: 'matches',
    path: '/matches'
  },
  {
    component: Safetycenter,
    meta: {
      frontend: true
    },
    name: 'safetycenter',
    path: '/safety-center'
  },
  {
    component: Matchevents,
    meta: {
      frontend: true
    },
    name: 'matchevents',
    path: '/matchevents'
  },
  {
    component: Datingincovid,
    meta: {
      frontend: true
    },
    name: 'datingincovid',
    path: '/datingincovid'
  },
  {
    component: Safetytips,
    meta: {
      frontend: true
    },
    name: 'safetytips',
    path: '/safety-tips'
  },
  {
    component: Additionalresources,
    meta: {
      frontend: true
    },
    name: 'additionalresources',
    path: '/additional-resources'
  },
  {
    component: Setting,
    meta: {
      frontend: true
    },
    name: 'setting',
    path: '/setting'
  },
  {
    component: EventDetail,
    meta: {
      frontend: true
    },
    name: 'event-detail',
    path: '/event_detail/:id'
  },
  {
    component: Managesubscription,
    meta: {
      frontend: true
    },
    name: 'manage-subscription',
    path: '/manage-subscription'
  },
  {
    component: Emailnotification,
    meta: {
      frontend: true
    },
    name: 'email-notification',
    path: '/email-notification'
  },
  {
    component: Blockedprofiles,
    meta: {
      frontend: true
    },
    name: 'blocked-profiles',
    path: '/blocked-profiles'
  },
  {
    component: Removedprofiles,
    meta: {
      frontend: true
    },
    name: 'removed-profiles',
    path: '/removed-profiles'
  },
  {
    component: Mobilepushnotification,
    meta: {
      frontend: true
    },
    name: 'mobile-push-notification',
    path: '/mobile-push-notification'
  },
  {
    component: Sitenotification,
    meta: {
      frontend: true
    },
    name: 'site-notification',
    path: '/site-notification'
  },
  {
    component: Verifyaccount,
    meta: {
      frontend: true
    },
    name: 'verify-account',
    path: '/verify-account'
  },
  {
    component: Browsebyprofile,
    meta: {
      frontend: true
    },
    name: 'browse-by-profile',
    path: '/browse-by-profile/:value/:id'
  },
  {
    component: Profilegal,
    meta: {
      frontend: true
    },
    name: 'profile-gal',
    path: '/profile-gal'
  },
  {
    component: VideoChat,
    meta: {
      frontend: true
    },
    name: 'video-chat',
    path: '/video-chat/:id'
  }
]

export default routes
