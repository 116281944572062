// Vuex mutations are very similar to events: each mutation has a string type and a handler.
const UPDATE_BLOG = (state, blog) => {
  state.blog = blog
}

const UPDATE_BLOGS = (state, blogs) => {
  state.blogs = blogs
}

export default {
  UPDATE_BLOG,
  UPDATE_BLOGS
}
