<template>
  <Header/>

  <div v-loading="loading" v-if="loading"  :element-loading-text="'Loading...'" style="height: 100%"></div>
  <UserProfile
    @user:update="getMeData"
    :userData="userData"
    v-if="userData"
  />

  <Footer/>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import UserProfile from './UserProfile'

import userMixin from '@/mixins/user'

export default {
  beforeRouteEnter (to, from, next) {
    const user = localStorage.getItem('user')

    if (!user) {
      next('/user_login')
    } else {
      next()
    }
  },
  components: {
    Header,
    Footer,
    UserProfile
  },

  created () {
    const userId = this.$route.params.id

    if (userId) {
      this.loading = true
      this.getUser(userId).then(user => {
        this.userData = user
        this.loading = false
      })
    } else {
      this.getMeData()
    }
  },

  computed: {
    userId () {
      return this.$route.params.id
    }
  },

  data () {
    return {
      userData: null,
      loading: false
    }
  },

  methods: {
    getMeData () {
      this.loading = true
      this.getMe().then((user) => {
        this.userData = user

        this.loading = false
      })
    }
  },

  mixins: [userMixin],

  watch: {
    $route (to, from) {
      const userId = to.params.id

      if (userId) {
        this.getUser(userId).then(user => {
          this.userData = user
        })
      }
    }
  },

  name: 'UserDashboard'
}
</script>
