<template>
  <div>
    <app-navbar/>
    <el-row :gutter="15" class="body-bg">
      <el-col :xs="24" :sm="6" :md="8" :lg="6">
        <app-sidebar/>
      </el-col>
      <el-col :xs="24" :sm="18" :md="16" :lg="18">
          <app-main/>
      </el-col>
    </el-row>
    <Footer class="c-app__footer"/>
  </div>
</template>

<script>
import AppMain from './AppMain.vue'
import AppNavbar from './AppNavbar.vue'
import AppSidebar from './AppSidebar/index.vue'
import Footer from './Footer.vue'

export default {
  name: 'Layout',

  components: {
    AppMain,
    AppNavbar,
    AppSidebar,
    Footer
  }
}
</script>

<style lang="scss">
.body-bg{
  background: #F3F3F3;
  min-height: 100vh;
}
.c-app {
  display: grid;
  grid-template-areas: "navbar navbar"
      "sidebar body";
  grid-template-columns: 20rem auto;

  &__navbar {
    grid-area: navbar;
  }

  &__sidebar {
    grid-area: sidebar;
  }

  &__body {
    grid-area: body;
    padding: 1rem;
  }
}
</style>
