import UserVerified from '@/components/UserVerified'
import ForgetPassword from '@/views/user/ForgetPassword'

const Register = () => import('@/views/user/Register.vue')
const ReportUser = () => import('@/views/user/ReportUser.vue')
const Chat = () => import('@/views/user/Chat.vue')
const UserLogin = () => import('@/views/user/UserLogin.vue')
const FacebookLogin = () => import('@/views/user/FacebookLogin.vue')

const routes = [
  {
    component: Register,
    meta: {
      frontend: true
    },
    name: 'register',
    path: '/register'
  },
  {
    component: UserLogin,
    meta: {
      frontend: true
    },
    name: 'user-login',
    path: '/user_login'
  },
  {
    component: UserVerified,
    meta: {
      frontend: true
    },
    name: 'user-verified',
    path: '/user/verified/:status'
  },
  {
    component: ForgetPassword,
    meta: {
      frontend: true
    },
    name: 'forget-password',
    path: '/forget_password'
  },
  {
    component: Chat,
    meta: {
      frontend: true
    },
    name: 'chat',
    path: '/chat/:id'
  },
  {
    component: ReportUser,
    meta: {
      frontend: true
    },
    name: 'report-user',
    path: '/report_user'
  },
  {
    component: FacebookLogin,
    meta: {
      frontend: true
    },
    name: 'facebook-login',
    path: '/facebook_login'
  }
]

export default routes
