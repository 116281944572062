import api from '@/api/configuration'
import advertisementApi from '@/api/advertisement'
import notificationApi from '@/api/notification'
import educationApi from '@/api/education'
import occupationApi from '@/api/occupation'

const getCities = ({ commit }) => {
  return new Promise((resolve, reject) => {
    api.getCities()
      .then((response) => {
        commit(
          'UPDATE_CITIES',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getAdvertisements = ({ commit }) => {
  return new Promise((resolve, reject) => {
    advertisementApi.getAdvertisements()
      .then((response) => {
        commit(
          'UPDATE_ADVERTISEMENTS',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getNotifications = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    notificationApi.toNotification(id)
      .then((response) => {
        commit(
          'UPDATE_NOTIFICATIONS',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const seenNotification = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    notificationApi.seenNotification(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getAdvertisement = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    advertisementApi.getAdvertisement(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createAdvertisement = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    advertisementApi.createAdvertisement(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateAdvertisement = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    advertisementApi.updateAdvertisement(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deleteAdvertisement = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    advertisementApi.deleteAdvertisement(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createCity = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createCity(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateCity = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateCity(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deleteCity = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.deleteCity(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getCasts = ({ commit }) => {
  return new Promise((resolve, reject) => {
    api.getCasts()
      .then((response) => {
        commit(
          'UPDATE_CASTS',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createCast = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createCast(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateCast = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateCast(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deleteCast = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.deleteCast(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getEducations = ({ commit }) => {
  return new Promise((resolve, reject) => {
    educationApi.getEducations()
      .then((response) => {
        commit(
          'UPDATE_EDUCATIONS',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createEducation = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    educationApi.createEducation(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateEducation = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    educationApi.updateEducation(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deleteEducation = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    educationApi.deleteEducation(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getOccupations = ({ commit }) => {
  return new Promise((resolve, reject) => {
    occupationApi.getOccupations()
      .then((response) => {
        commit(
          'UPDATE_OCCUPATIONS',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createOccupation = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    occupationApi.createOccupation(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateOccupation = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    occupationApi.updateOccupation(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deleteOccupation = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    occupationApi.deleteOccupation(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getReligions = ({ commit }) => {
  return new Promise((resolve, reject) => {
    api.getReligions()
      .then((response) => {
        commit(
          'UPDATE_RELIGIONS',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createReligion = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createReligion(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateReligion = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.updateReligion(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deleteReligion = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.deleteReligion(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default {
  getAdvertisement,
  createAdvertisement,
  getAdvertisements,
  getCities,
  createCity,
  updateCity,
  deleteCity,
  getCasts,
  createCast,
  updateCast,
  deleteCast,
  getReligions,
  createReligion,
  deleteReligion,
  updateReligion,
  deleteAdvertisement,
  updateAdvertisement,
  getEducations,
  createEducation,
  updateEducation,
  deleteEducation,
  getOccupations,
  createOccupation,
  updateOccupation,
  deleteOccupation,

  getNotifications,
  seenNotification
}
