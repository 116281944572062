import request from '@/utils/request'

const getMessages = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/messages',
      method: 'get',
      params: {
        from_user_id: data.from_user_id,
        to_user_id: data.to_user_id
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const fromMessages = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/from-messages/' + data.id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const seenMessage = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/seen-message/' + data.id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createMessage = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/store-message',
      method: 'post',
      data: {
        from_user_id: data.from_user_id,
        to_user_id: Number(data.to_user_id),
        message: data.message,
        type: data.type ? data.type : 1,
        link: data.link ? data.link : ''
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

export default {
  getMessages,
  createMessage,
  fromMessages,
  seenMessage
}
