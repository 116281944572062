<template>
  <Header />
  <Breadcrumb :title="'Search'" :link="'search'" />
  <div class="search-ul-bg">
    <div class="container">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="6" :lg="6">
              <div class="profile-user">
                <div class="profile-box">
                  <div class="var-online-part">
                    <div class="var-online">
                      <div class="var-icon">
                        <img src="@/assets/images/verified-icon.png" alt="">
                      </div>
                      <div class="var-line-icon">
                        <div class="user-online">
                          <div class="user-offline-show" title="Online"></div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <figure>
                    <a href=""><img alt="" border="0" class="img-responsive" src="@/assets/images/image3.jpg"></a>
                  </figure>
                  <a href="">
                    <div class="main-profile">
                      <div class="profile-info-name">Sarita Thapa</div>
                      <div class="profile-info">
                        <div class="profile-info-left">34 - Bhairahawa, Nepal</div>
                        <div class="profile-info-right">
                          <p class="cam-icon"> 3 <i class="fa fa-camera"></i></p>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="profile-ux-btn">
                  <div class="profile-ux-btn-left">
                    <a href=""><img src="@/assets/images/comment.png" alt=""></a>
                  </div>
                  <div class="profile-ux-btn-mid">
                    <a href=""><img src="@/assets/images/connect.png" alt=""></a>
                  </div>
                  <div class="profile-ux-btn-right">
                    <a href=""><img src="@/assets/images/heart.png" alt=""></a>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6">
              <div class="profile-user">
                <div class="profile-box">
                  <div class="var-online-part">
                    <div class="var-online">
                      <div class="var-icon">
                        <img src="@/assets/images/verified-icon.png" alt="">
                      </div>
                      <div class="var-line-icon">
                        <div class="user-online">
                          <div class="user-offline-show" title="Online"></div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <figure>
                    <a href=""><img alt="" border="0" class="img-responsive" src="@/assets/images/mahesh.jpg"></a>
                  </figure>
                  <a href="">
                    <div class="main-profile">
                      <div class="profile-info-name">Mahesh Shahi</div>
                      <div class="profile-info">
                        <div class="profile-info-left">34 - Bhairahawa, Nepal</div>
                        <div class="profile-info-right">
                          <p class="cam-icon"> 3 <i class="fa fa-camera"></i></p>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="profile-ux-btn">
                  <div class="profile-ux-btn-left">
                    <a href=""><img src="@/assets/images/comment-active.png" alt=""></a>
                  </div>
                  <div class="profile-ux-btn-mid">
                    <a href=""><img src="@/assets/images/connect.png" alt=""></a>
                  </div>
                  <div class="profile-ux-btn-right">
                    <a href=""><img src="@/assets/images/heart-active.png" alt=""></a>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6">
              <div class="profile-user">
                <div class="profile-box">
                  <div class="var-online-part">
                    <div class="var-online">
                      <div class="var-icon">
                        <img src="@/assets/images/verified-icon.png" alt="">
                      </div>
                      <div class="var-line-icon">
                        <div class="user-online">
                          <div class="user-offline-show" title="Online"></div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <figure>
                    <a href=""><img alt="" border="0" class="img-responsive" src="@/assets/images/image2.jpg"></a>
                  </figure>
                  <a href="">
                    <div class="main-profile">
                      <div class="profile-info-name">Sarita Thapa</div>
                      <div class="profile-info">
                        <div class="profile-info-left">34 - Bhairahawa, Nepal</div>
                        <div class="profile-info-right">
                          <p class="cam-icon"> 3 <i class="fa fa-camera"></i></p>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="profile-ux-btn">
                  <div class="profile-ux-btn-left">
                    <a href=""><img src="@/assets/images/comment.png" alt=""></a>
                  </div>
                  <div class="profile-ux-btn-mid">
                    <a href=""><img src="@/assets/images/connect.png" alt=""></a>
                  </div>
                  <div class="profile-ux-btn-right">
                    <a href=""><img src="@/assets/images/heart-active.png" alt=""></a>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6">
              <div class="profile-user">
                <div class="profile-box">
                  <div class="var-online-part">
                    <div class="var-online">
                      <div class="var-icon">
                        <img src="@/assets/images/verified-icon.png" alt="">
                      </div>
                      <div class="var-line-icon">
                        <div class="user-online">
                          <div class="user-offline-show" title="Online"></div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <figure>
                    <a href=""><img alt="" border="0" class="img-responsive" src="@/assets/images/image3.jpg"></a>
                  </figure>
                  <a href="">
                    <div class="main-profile">
                      <div class="profile-info-name">Sarita Thapa</div>
                      <div class="profile-info">
                        <div class="profile-info-left">34 - Bhairahawa, Nepal</div>
                        <div class="profile-info-right">
                          <p class="cam-icon"> 3 <i class="fa fa-camera"></i></p>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="profile-ux-btn">
                  <div class="profile-ux-btn-left">
                    <a href=""><img src="@/assets/images/comment.png" alt=""></a>
                  </div>
                  <div class="profile-ux-btn-mid">
                    <a href=""><img src="@/assets/images/connect.png" alt=""></a>
                  </div>
                  <div class="profile-ux-btn-right">
                    <a href=""><img src="@/assets/images/heart-active.png" alt=""></a>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6">
              <div class="profile-user">
                <div class="profile-box">
                  <div class="var-online-part">
                    <div class="var-online">
                      <div class="var-icon">
                        <img src="@/assets/images/verified-icon.png" alt="">
                      </div>
                      <div class="var-line-icon">
                        <div class="user-online">
                          <div class="user-offline-show" title="Online"></div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <figure>
                    <a href=""><img alt="" border="0" class="img-responsive" src="@/assets/images/image3.jpg"></a>
                  </figure>
                  <a href="">
                    <div class="main-profile">
                      <div class="profile-info-name">Sarita Thapa</div>
                      <div class="profile-info">
                        <div class="profile-info-left">34 - Bhairahawa, Nepal</div>
                        <div class="profile-info-right">
                          <p class="cam-icon"> 3 <i class="fa fa-camera"></i></p>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="profile-ux-btn">
                  <div class="profile-ux-btn-left">
                    <a href=""><img src="@/assets/images/comment.png" alt=""></a>
                  </div>
                  <div class="profile-ux-btn-mid">
                    <a href=""><img src="@/assets/images/connect.png" alt=""></a>
                  </div>
                  <div class="profile-ux-btn-right">
                    <a href=""><img src="@/assets/images/heart-active.png" alt=""></a>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6">
              <div class="profile-user">
                <div class="profile-box">
                  <div class="var-online-part">
                    <div class="var-online">
                      <div class="var-icon">
                        <img src="@/assets/images/verified-icon.png" alt="">
                      </div>
                      <div class="var-line-icon">
                        <div class="user-online">
                          <div class="user-offline-show" title="Online"></div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <figure>
                    <a href=""><img alt="" border="0" class="img-responsive" src="@/assets/images/image3.jpg"></a>
                  </figure>
                  <a href="">
                    <div class="main-profile">
                      <div class="profile-info-name">Sarita Thapa</div>
                      <div class="profile-info">
                        <div class="profile-info-left">34 - Bhairahawa, Nepal</div>
                        <div class="profile-info-right">
                          <p class="cam-icon"> 3 <i class="fa fa-camera"></i></p>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="profile-ux-btn">
                  <div class="profile-ux-btn-left">
                    <a href=""><img src="@/assets/images/comment.png" alt=""></a>
                  </div>
                  <div class="profile-ux-btn-mid">
                    <a href=""><img src="@/assets/images/connect.png" alt=""></a>
                  </div>
                  <div class="profile-ux-btn-right">
                    <a href=""><img src="@/assets/images/heart-active.png" alt=""></a>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6">
              <div class="profile-user">
                <div class="profile-box">
                  <div class="var-online-part">
                    <div class="var-online">
                      <div class="var-icon">
                        <img src="@/assets/images/verified-icon.png" alt="">
                      </div>
                      <div class="var-line-icon">
                        <div class="user-online">
                          <div class="user-offline-show" title="Online"></div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <figure>
                    <a href=""><img alt="" border="0" class="img-responsive" src="@/assets/images/image3.jpg"></a>
                  </figure>
                  <a href="">
                    <div class="main-profile">
                      <div class="profile-info-name">Sarita Thapa</div>
                      <div class="profile-info">
                        <div class="profile-info-left">34 - Bhairahawa, Nepal</div>
                        <div class="profile-info-right">
                          <p class="cam-icon"> 3 <i class="fa fa-camera"></i></p>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="profile-ux-btn">
                  <div class="profile-ux-btn-left">
                    <a href=""><img src="@/assets/images/comment.png" alt=""></a>
                  </div>
                  <div class="profile-ux-btn-right">
                    <a href=""><img src="@/assets/images/heart-active.png" alt=""></a>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6">
              <div class="profile-user">
                <div class="profile-box">
                  <div class="var-online-part">
                    <div class="var-online">
                      <div class="var-icon">
                        <img src="@/assets/images/verified-icon.png" alt="">
                      </div>
                      <div class="var-line-icon">
                        <div class="user-online">
                          <div class="user-offline-show" title="Online"></div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <figure>
                    <a href=""><img alt="" border="0" class="img-responsive" src="@/assets/images/image3.jpg"></a>
                  </figure>
                  <a href="">
                    <div class="main-profile">
                      <div class="profile-info-name">Sarita Thapa</div>
                      <div class="profile-info">
                        <div class="profile-info-left">34 - Bhairahawa, Nepal</div>
                        <div class="profile-info-right">
                          <p class="cam-icon"> 3 <i class="fa fa-camera"></i></p>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="profile-ux-btn">
                  <div class="profile-ux-btn-left">
                    <a href=""><img src="@/assets/images/comment.png" alt=""></a>
                  </div>
                  <div class="profile-ux-btn-right">
                    <a href=""><img src="@/assets/images/heart-active.png" alt=""></a>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Breadcrumb from '@/views/user/_components/Breadcrumb'

import pageMixin from '@/mixins/page'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  created () {
    this.getPage({
      slug: 'faq'
    }).then((res) => {
      this.value = res
    })
  },

  data () {
    return {
      value: null,
      activeStepBar: 0,
      tab: 1
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    }
  },

  mixins: [pageMixin],

  name: 'Register'
}
</script>
<style lang="scss">
.custom-page{
  padding: 20px 0;
  background: #f3f2ef;
}

.custom-page .card-header span{
  font-size: 18px;
}

.c-register {
  &__form {
    padding: 1rem 10px;
  }

  &__tabs {
    margin-bottom: 1rem;
  }
}

</style>
