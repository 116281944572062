import api from '@/api/message'

const getMessages = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.getMessages(data)
      .then((response) => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const fromMessages = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.fromMessages(data)
      .then((response) => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const seenMessage = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.seenMessage(data)
      .then((response) => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createMessage = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createMessage(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default {
  getMessages,
  createMessage,
  fromMessages,
  seenMessage
}
