<template>
  <div class="footer-top">
    <div class="container">
      <el-row :gutter="20">
        <el-col :span="24" :sm="6" :md="6" :lg="6">
          <div class="block">
            <h4>By Religion</h4>
            <ul>
              <li
                :key="index"
                v-for="(religion, index) in religionData"
              >
                <a @click="onReligionClick(religion)">
                  {{ religion.name }}
                </a>
              </li>
              <li>
                <router-link :to="{ name: 'search' }">More</router-link>
              </li>
            </ul>
          </div>
        </el-col>
        <el-col :span="24" :sm="6" :md="6" :lg="6">
          <div class="block">
            <h4>By Cast</h4>
            <ul>
              <li
                :key="index"
                v-for="(cast, index) in castsData"
              >
                <a @click="onCastClick(cast)">
                  {{ cast.name }}
                </a>
              </li>
              <li>
                <router-link :to="{ name: 'search' }">More</router-link>
              </li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6">
          <div class="block">
            <h4>Information</h4>
            <ul>
              <li>
                <router-link :to="{ name: 'about-us' }">About Us</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'contact' }">Contact Us</router-link>
              </li>
              <li v-if="!user">
                <router-link :to="{ name: 'register' }">Register Free</router-link>
              </li>
              <li v-if="!user">
                <router-link :to="{ name: 'user-login' }">User Login</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'terms-conditions' }">Terms & Conditions</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'faq' }">FAQ</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'privacy-policy' }">Privacy Policy</router-link>
              </li>
              <li v-if="user">
                <router-link :to="{ name: 'report-user' }">Report User</router-link>
              </li>
              <li v-if="user">
                <router-link :to="{ name: 'Feedback' }">Feedback</router-link>
              </li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6">
          <div class="block">
            <h4>Contact Details</h4>
            <div class="contact-info">
            <p><i class="icons el-icon-location-outline"></i> Lazimpat, Kathmandu Nepal</p>
            <p><i class="icons el-icon-phone"></i> +977-9843000000</p>
            <p><i class="icons el-icon-message"></i> <a href="mailto:info@merojodi.com"> info@merojodi.com</a></p>
            <p><i class="icons el-icon-link"></i> <a href="http://www.merojodi.com" target="_blank">www.merojodi.com</a></p>
          </div>
            <div class="connect-with">
              <a href="https://www.facebook.com/Mero-Jodi-106922884964186" target="_blank"><img src="@/assets/images/facebook.png" width="36" alt=""></a>
                <a href="https://www.instagram.com/merojodinepal/" target="_blank"><img src="@/assets/images/instagram.png" width="36" alt=""></a>
              <a href="https://twitter.com/merojodinepal" target="_blank"><img src="@/assets/images/twitter.png" width="36" alt=""></a>
            </div>
    </div>
    </el-col>
    </el-row>
  </div>
  </div>
  <div id="topToBottom"><a @click="goToTop"><i class="el-icon-arrow-up"></i></a></div>
  <div class="footer-main">
    <p>© 2021 Merojodi. All rights reserved. Powered by <a href="http://siddhitechnepal.com/" target="_blank">Siddhi
      Tech Nepal</a></p>
  </div>
</template>

<script>
import configurationMixin from '@/mixins/configuration'
import searchMixin from '@/mixins/search'
import userMixin from '@/mixins/user'

export default {
  created () {
    this.getReligions()
    this.getCasts()
  },

  computed: {
    castsData () {
      return this.casts && this.casts.slice(0, 6)
    },

    religionData () {
      return this.religions && this.religions.slice(0, 6)
    }
  },

  methods: {
    onReligionClick (religion) {
      this.$router.push({ name: 'browse-by-profile', params: { value: 'Religion', id: religion.id } })

      window.scrollTo(0, 0)
    },

    onCastClick (cast) {
      this.$router.push({ name: 'browse-by-profile', params: { value: 'Cast', id: cast.id } })

      window.scrollTo(0, 0)
    },

    goToTop () {
      window.scrollTo(0, 0)
    }
  },

  name: 'Footer',

  mixins: [configurationMixin, searchMixin, userMixin]
}
</script>

<style>
.footer-top {
  background: #070f6a;
 /* background-image: linear-gradient(166deg, #f22876 0%, #942dd9 100%);*/
  margin: 0;
  padding: 30px 0 50px 0;
  box-shadow: -4px -8px 5px -2px rgb(128 128 128 / 61%);
  -webkit-box-shadow: -4px -5px 5px -2px rgb(214 212 212 / 61%);
  -moz-box-shadow: -4px -8px 5px -2px rgba(128,128,128,0.61);
}

.footer-top .block {
  margin: 0;
}

.footer-top .block h4 {
  margin: 0;
  padding: 20px 0 0 0;
  font-size: 22px;
  color: #fff;
}

.footer-top .block ul {
  padding: 20px 0 0 0;
}

.footer-top .block li {
  padding: 2px 0 0 0;
  line-height: 26px;
  color: #fff;
}

.footer-top .contact-info {
  padding: 15px 0 0 0;
  color: #fff;
}

.footer-top .contact-info p{
  padding: 0;
  color: #fff;
  font-size: 15px;
  line-height: 30px;
}

.footer-top .contact-info p a{
  padding: 0;
  color: #fff;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
}

.footer-main {
  background: #030848;
  padding: 15px 0;
}

.footer-main p {
  text-align: center;
  line-height: 30px;
  color: #efeaea;
  font-size: 13px;
}

.footer-main p a {
  text-align: center;
  line-height: 30px;
  color: #fff;
  font-weight: 800;
  font-size: 14px;

}

.footer-main p a:hover {
  color: #e9e9e9;
}

.connect-with{
 margin: 0;
  padding: 30px 0 0 0;
}

.connect-with a{
  margin: 0 8px 0 0;
  padding:0;
}

#topToBottom {
  animation: topToBottom ease 2s;
  -webkit-animation: topToBottom ease 2s;
  -moz-animation: topToBottom ease 2s;
  -o-animation: topToBottom ease 2s;
  -ms-animation: topToBottom ease 2s;
  position: absolute;
  right: 20px;
  color: #fff;
  z-index: 9999;
}

#topToBottom .el-icon-arrow-up{
  background: #003895;
  color: #fff;
  padding: 10px;
  font-weight: bold;
  font-size: 20px;
}

@keyframes topToBottom {
  0% {
    margin-top: -5%;
  }
  100% {
    margin-top: 0%;
  }
}

@-moz-keyframes topToBottom {
  0% {
    margin-top: -5%;
  }
  100% {
    margin-top: 0%;
  }
}

@-webkit-keyframes topToBottom {
  0% {
    margin-top: -5%;
  }
  100% {
    margin-top: 0%;
  }
}

@-o-keyframes topToBottom {
  0% {
    margin-top: -5%;
  }
  100% {
    margin-top: 0%;
  }
}

@-ms-keyframes topToBottom {
  0% {
    margin-top: -5%;
  }
  100% {
    margin-top: 0%;
  }
}

.custom-page .el-card__body p {
  font-weight: 400 !important;
  line-height: 24px !important;
  font-size: 16px !important;
  padding: 8px 0 0 0 !important;
}

.custom-page .el-card__body h1{
  font-size: 24px !important;

}

.custom-page .el-card__body h2{
  font-size: 22px !important;

}

.custom-page .el-card__body h3{
  font-size: 18px !important;

}

.custom-page .el-card__body h4 {
  font-size: 16px !important;
}

</style>
