import request from '@/utils/request'

const createCity = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/city',
      method: 'post',
      data: {
        name: data
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getCities = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/cities',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateCity = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/city/' + data.id,
      method: 'post',
      data: {
        name: data.name
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deleteCity = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/city/' + data.id,
      method: 'delete'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createCast = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/cast',
      method: 'post',
      data: {
        name: data
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getCasts = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/casts',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateCast = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/cast/' + data.id,
      method: 'post',
      data: {
        name: data.name
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deleteCast = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/cast/' + data.id,
      method: 'delete'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createReligion = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/religion',
      method: 'post',
      data: {
        name: data
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getReligions = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/religions',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateReligion = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/religion/' + data.id,
      method: 'post',
      data: {
        name: data.name
      }
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deleteReligion = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/religion/' + data.id,
      method: 'delete'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

export default {
  createCity,
  getCities,
  updateCity,
  deleteCity,

  createCast,
  getCasts,
  updateCast,
  deleteCast,

  createReligion,
  getReligions,
  updateReligion,
  deleteReligion
}
