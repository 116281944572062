import { mapActions, mapGetters } from 'vuex'

const banners = {
  computed: {
    ...mapGetters(
      'banner',
      {
        banners: 'banners'
      }
    )
  },

  methods: {
    ...mapActions(
      'banner',
      [
        'getBanner',
        'getBanners',
        'createBanner',
        'deleteBanner',
        'updateBanner'
      ]
    )
  }
}

export default banners
