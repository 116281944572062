import api from '@/api/feedback'

const getFeedbacks = ({ commit }) => {
  return new Promise((resolve, reject) => {
    api.getFeedbacks()
      .then((response) => {
        commit(
          'UPDATE_FEEDBACKS',
          response.data
        )

        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createFeedback = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createFeedback(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deleteFeedback = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    api.deleteFeedback(id)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default {
  getFeedbacks,
  createFeedback,
  deleteFeedback
}
