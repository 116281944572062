import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  created_user: null,
  education_fields: [],
  education_levels: [],
  user: null,
  admin: null,
  users: []
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state
}
