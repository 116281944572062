<template>
  <Header />
  <div class="profile-gal">
  <div class="container">
    <el-row :gutter="40" >
          <el-col :xs="24" :sm="24" :md="24" :lg="24">
            <div class="content-box-gal">
              <div class="title-gal">Update profile picture</div>
                <div class="profile-gal-box">
                  <el-row :gutter="10">
                    <el-col :xs="12" :sm="4" :md="4" :lg="4">
                      <div class="gal-box">
                        <img src="@/assets/images/image2.jpg" alt="">
                        <ul class="icon">
                          <li>
                            <a><i class="fa fa-plus"></i></a>
                          </li>
                          <li>
                            <a><i class="el-icon-delete-solid"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </el-col>
                    <el-col :xs="12" :sm="4" :md="4" :lg="4">
                      <div class="gal-box">
                        <img src="@/assets/images/image3.jpg" alt="">
                        <ul class="icon">
                          <li>
                            <a><i class="fa fa-plus"></i></a>
                          </li>
                          <li>
                            <a><i class="el-icon-delete-solid"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </el-col>
                    <el-col :xs="12" :sm="4" :md="4" :lg="4">
                      <div class="gal-box">
                        <img src="@/assets/images/mahesh.jpg" alt="">
                        <ul class="icon">
                          <li>
                            <a><i class="fa fa-plus"></i></a>
                          </li>
                          <li>
                            <a><i class="el-icon-delete-solid"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </el-col>
                    <el-col :xs="12" :sm="4" :md="4" :lg="4">
                      <div class="gal-box">
                        <img src="@/assets/images/image2.jpg" alt="">
                        <ul class="icon">
                          <li>
                            <a><i class="fa fa-plus"></i></a>
                          </li>
                          <li>
                            <a><i class="el-icon-delete-solid"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </el-col>
                    <el-col :xs="12" :sm="4" :md="4" :lg="4">
                      <div class="gal-box">
                        <img src="@/assets/images/image2.jpg" alt="">
                        <ul class="icon">
                          <li>
                            <a><i class="fa fa-plus"></i></a>
                          </li>
                          <li>
                            <a><i class="el-icon-delete-solid"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </el-col>
                    <el-col :xs="12" :sm="4" :md="4" :lg="4">
                      <div class="gal-box">
                        <img src="@/assets/images/image2.jpg" alt="">
                        <ul class="icon">
                          <li>
                            <a><i class="fa fa-plus"></i></a>
                          </li>
                          <li>
                            <a><i class="el-icon-delete-solid"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </el-col>
                  </el-row>
            </div>
            </div>
          </el-col>
        </el-row>
  </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import pageMixin from '@/mixins/page'

export default {
  components: {
    Header,
    Footer
  },

  created () {
    this.getPage({
      slug: 'safety_center'
    }).then((res) => {
      this.value = res
    })
  },

  data () {
    return {
      value: null,
      activeStepBar: 0,
      tab: 1
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    }
  },

  mixins: [pageMixin],
  name: 'Register'
}
</script>
<style scoped>
.profile-gal{
  padding: 20px 0 0 0;
  background: #f3f2ef;
}
.content-box-gal{
  background: #fff;
  padding: 30px;
}

.title-gal{
  text-align: center;
  font-size: 20px;
  padding-bottom: 20px;
}

.gal-box{
  background: #ccc;
  text-align: center;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  margin: 15px 0 0px 0;
}

.gal-box img {
  transform: scale(1);
  transition: .3s ease-in-out;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 140px;
}

.gal-box:hover img{
  filter: blur(3px);
  transform: scale(1.1);

}

.gal-box .icon{
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  top: 45%;
  left:50%;
  width: 100%;
  transition: all .5s ease-in-out;
}

.gal-box:hover .icon{ opacity: 1; }
.gal-box .icon li{
  margin: 0 10px;
  display: inline-block;
}

.gal-box .icon li a{
  color: #fff;
  background: #003993;
  font-size: 14px;
  display: block;
  position: relative;
  transition: all .5s ease 0s;
  width: 28px;
  height: 28px;
  border-radius:50%;
  line-height: 28px;
}

.gal-box .icon li a:hover{
  text-shadow: 0 0 7px rgba(0,0,0,0.9);
}

</style>
