import request from '@/utils/request'

const getEvents = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/events',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getEventList = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/eventList',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getEvent = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/admin-event/' + id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createEvent = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('image', data.image)
    formData.set('title', data.title)
    formData.set('description', data.description)
    formData.set('date', data.date)
    formData.set('time', data.time)
    formData.set('city', data.city)
    formData.set('price', data.price)
    formData.set('status', data.status)
    formData.set('banner_image', data.banner_image)
    formData.set('know_before_go', data.know_before_go)

    return request({
      url: '/event',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateEvent = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('image', data.image)
    formData.set('title', data.title)
    formData.set('description', data.description)
    formData.set('date', data.date)
    formData.set('time', data.time)
    formData.set('city', data.city)
    formData.set('price', data.price)
    formData.set('banner_image', data.banner_image)
    formData.set('know_before_go', data.know_before_go)

    return request({
      url: '/admin-event/' + data.id,
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deleteEvent = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/admin-event/delete/' + id,
      method: 'post'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getEventFaqById = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/admin-event/faq/' + id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateEventTotalVisit = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: 'admin-event/updateTotalCount/' + id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getEventFaqs = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/admin-event/faqs/' + id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createEventFaq = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('event_id', data.event_id)
    formData.set('question', data.question)
    formData.set('answer', data.answer)

    return request({
      url: '/admin-event/faq/store',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateEventFaq = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('id', data.id)
    formData.set('event_id', data.event_id)
    formData.set('question', data.question)
    formData.set('answer', data.answer)

    return request({
      url: '/admin-event/faq/update',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deleteEventFaq = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/admin-event/faq/delete/' + id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

export default {
  getEvent,
  getEventList,
  getEvents,
  createEvent,
  deleteEvent,
  updateEvent,
  getEventFaqById,
  getEventFaqs,
  updateEventFaq,
  createEventFaq,
  deleteEventFaq,
  updateEventTotalVisit
}
