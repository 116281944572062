import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  event: null,
  events: []
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state
}
