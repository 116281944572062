<template>
  <div class="app-navbar">
    <div class="navbar-block">
      <router-link
        :to="{ name: 'admin-dashboard' }"
        class="navbar-brand"
      >
        Mero Jodi Admin
      </router-link>
    </div>
    <div class="navbar-block">
      <el-dropdown
        placement="bottom"
        trigger="click"
        class="navbar-dropdown"
      >
        <div class="navbar-dropdown-trigger">
          <el-badge
            :max="99"
            class="user-message-badge"
          >
            <img
              class="icon-ui"
              :src="adminImage ? imageBasePath + adminImage : admin && admin.image ? imageBasePath + admin.image : require('@/assets/images/logo.jpg')"
              alt=""
            >
            <input
              @change="onImageChange"
              ref="imageUpload"
              type="file"
              v-show="false"
            />
            <span
              class="user-name"
              v-if="admin"
            > Hi, {{ admin.name }}
            </span>
            <i class="el-icon-caret-bottom" />
          </el-badge>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="dashboard">
              Dashboard
            </el-dropdown-item>
            <el-dropdown-item
              @click="onPictureUpdate"
              command="updatePassword"
            >
              Update Picture
            </el-dropdown-item>
            <el-dropdown-item
              @click="onLogout"
              command="signOut"
            >
              LogOut
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixins/user'

export default {
  computed: {
    imageBasePath () {
      return process.env.VUE_APP_API_IMAGE_PATH
    }
  },

  data () {
    return {
      adminImage: null
    }
  },

  methods: {
    onPictureUpdate () {
      this.$refs.imageUpload.click()
    },

    onImageChange (e) {
      const files = e.target.files || e.dataTransfer.files

      if (files.length) {
        const image = files[0]

        this.updateProfileImage(Object.assign({ image: image, user_id: this.admin.id })).then((data) => {
          this.adminImage = data.image
        })
      }
    },

    onLogout () {
      this.logout().then(() => {
        localStorage.removeItem('admin')
        localStorage.removeItem('ADMIN_TOKEN')
        this.$router.push({ name: 'admin-login' })
      })
    }
  },

  mixins: [userMixin],

  name: 'AppNavbar'
}
</script>
<style>
.app-navbar{
  background-color: #376BB2 !important;
  height: 55px !important;
}

.app-navbar .el-dropdown {
  margin-top: -4px;
}

.user-message-badge img{
  padding: 4px;
  text-align: center;
  font-size: 18px !important;
  margin-right: 5px;
  border-radius: 50%;
  width: 45px;
  height: 53px;
  background: #fff;
  color: #003895;
}

</style>
