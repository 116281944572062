<template>
  <Header />
  <Breadcrumb :title="'Mobile Push Notification'" :link="'mobile-push-notification'" />
  <div class="information-ul">
    <div class="container">
      <el-row :gutter="40" >
        <el-col :xs="24" :sm="8" :md="8" :lg="8">
          <div class="sidebar-bg">
            <div class="page-links">
              <ul>
                <li><a href="/#/blocked-profiles" class="active"> Blocked Profiles</a></li>
                <li><a href="/#/removed-profiles"> Removed Profiles</a></li>
                <li><a href="/#/manage-subscription"> Manage Subscriptions</a></li>
                <li><a href="/#/email-notification"> Email Notifications</a></li>
                <li><a href="/#/mobile-push-notification"> Mobile Push Notification</a></li>
                <li><a href="/#/site-notification"> Site Notification</a></li>
                <li><a href="/#/verify-account"> Verify your Account</a></li>
              </ul>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="14" :md="16" :lg="16">
          <div class="page-title">Mobile Push Notification</div>
          <div class="other-notifications">
            <div class="main-notification">
              <div class="notify-title">Top Picks
                <span class="switch-button"> <el-switch ></el-switch></span>
              </div>
              <div class="notify-text">
                Be notify when your top picks are ready to view
              </div>
            </div>
            <div class="main-notification">
              <div class="notify-title">Likes
                <span class="switch-button"> <el-switch ></el-switch></span>
              </div>
              <div class="notify-text">
                Find out when someone likes you in real time
              </div>
            </div>
            <div class="main-notification">
              <div class="notify-title">Super Likes
                <span class="switch-button"> <el-switch ></el-switch></span>
              </div>
              <div class="notify-text">
                Get Updated when someone send you a super likes
              </div>
            </div>
            <div class="main-notification">
              <div class="notify-title">Interest Summery
                <span class="switch-button"> <el-switch ></el-switch></span>
              </div>
              <div class="notify-text">
                Get weekly reminder of members who shows interest in you.s
              </div>
            </div>
            <div class="main-notification">
              <div class="notify-title">Tips and Announcement
                <span class="switch-button"> <el-switch ></el-switch></span>
              </div>
              <div class="notify-text">
                Be an inside with success tips, featre announcements and more
              </div>
            </div>
            <div class="main-notification">
              <div class="notify-title">Match Offesr
                <span class="switch-button"> <el-switch ></el-switch></span>
              </div>
              <div class="notify-text">
                Get discount to subscriptions, features and more
              </div>
            </div>
            <div class="main-notification">
              <div class="notify-title">Match Eventss
                <span class="switch-button"> <el-switch ></el-switch></span>
              </div>
              <div class="notify-text">
                Find out about upcoming single events in your areass
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import pageMixin from '@/mixins/page'
import Breadcrumb from '@/views/user/_components/Breadcrumb'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  created () {
    this.getPage({
      slug: 'likes'
    }).then((res) => {
      this.value = res
    })
  },

  data () {
    return {
      value: null,
      activeStepBar: 0,
      tab: 1
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    }
  },

  mixins: [pageMixin],
  name: 'Register'
}
</script>
<style scoped>
.information-ul {
  margin: 0;
  padding: 40px 0;
  background: #fff;
  border-top: 1px solid #ccc;
}
.information-ul .page-title {
  font-size: 24px !important;
  padding-top: 10px;
}

.information-ul p{
  padding: 10px 0;
  line-height: 24px;
}

.sidebar-bg{
  background: #fff;
  padding-bottom: 40px;
}

.page-links{
  margin: 0;
  padding:10px 0 0 0;
}

.page-links ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-links li{
  margin: 0 5px;
  padding: 20px 0 20px 20px;
  list-style: none;
  font-size: 17px;
  border-bottom: 1px solid #ccc;
}

.page-links li a{
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all 0.3s ease-in 0s;
}

.page-links li a:hover{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}

.page-links li a.active{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}

.other-notifications{
  padding: 10px 0 30px 0 ;
}

.main-notification{
  margin: 0;
  padding: 20px 0 20px 0;
}

.notify-title{
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.notify-text{
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 15px;
}

.switch-button{
  float: right;
}

</style>
