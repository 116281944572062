<template>
  <Header />
  <Breadcrumb :title="'Terms & Conditions'" :link="'terms-conditionss'" />
  <div class="custom-page">
    <div class="container">
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>Terms And Conditions</span>
          </div>
        </template>
        <div v-for="o in 1" :key="o" class="text item">
          <div v-html="value.description" v-if="value"></div>
        </div>
      </el-card>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Breadcrumb from '@/views/user/_components/Breadcrumb'

import pageMixin from '@/mixins/page'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  created () {
    this.getPage({
      slug: 'terms_conditions'
    }).then((res) => {
      this.value = res
    })
  },

  data () {
    return {
      value: null,
      activeStepBar: 0,
      tab: 1
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    }
  },

  mixins: [pageMixin],

  name: 'TermsAndConditions'
}
</script>
<style lang="scss">
.custom-page{
  padding: 20px 0;
  background: #f3f2ef;
}

.custom-page .card-header span{
  font-size: 18px;
}

.c-register {
  &__form {
    padding: 1rem 10px;
  }

  &__tabs {
    margin-bottom: 1rem;
  }
}
</style>
