<template>
  <div class="footer">
  © Copyright 2022 Merojodi. All Rights Reserved.
  </div>
</template>
<style>
.footer {
  padding: 20px 0;
  position:relative;
  bottom:0;
  width:100%;
  background:#dbdbdb;
  text-align: center;
  z-index: 9;
}
</style>
