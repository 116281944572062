<template>
  <Header />
  <Breadcrumb :title="'Likes'" :link="'likes'" />
  <div class="profile-ul">
    <div class="container">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="16" :md="18" :lg="18">
          <el-tabs v-model="activeName" class="likes-area">
            <el-tab-pane class="tabs" label="Likes You" name="first">
              <div
                class="boost-info"
                v-if="likedUsers && !likedUsers.length"
              >
                <div class="icon-boost">
                  <i class="fa fa-eye"></i>
                </div>
                <div class="boost-title"> No Likes yet</div>
                <div class="boost-content">See what's out there, ceck out some search results just or you</div>
                <div class="boost-btn-area"> <router-link :to="{ name: 'search' }" class="boost-btn"> Keep Seaching <i class="el-icon-right"></i> </router-link></div>
              </div>
              <el-row :gutter="20" class="spacing">
                <el-col
                  :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :key="index"
                  v-for="(user, index) in likedUsers"
                >
                  <div class="profile-user">
                    <div class="profile-box">
                      <div class="var-online-part">
                        <div class="var-online">
                          <div class="var-icon">
                            <img src="@/assets/images/verified-icon.png" alt="">
                          </div>
                          <div class="var-line-icon">
                            <div class="user-online">
                              <div
                                class="user-offline-show"
                                :title="user.is_logged_in ? 'Online' : 'Offline'"
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <figure>
                        <a @click="onUserView(user.id)">
                          <img
                            :src="user.image ? imageBasePath + user.image : require('@/assets/images/default-user.png')"
                            alt=""
                            border="0"
                            class="img-responsive"
                            style="max-height: 14.3rem;"
                          >
                        </a>
                      </figure>
                      <a @click="onUserView(user.id)">
                        <div class="main-profile">
                          <div class="profile-info-name">{{ user.username ? user.username : user.name }}</div>
                          <div class="profile-info">
                            <div class="profile-info-left">
                              {{ user.personal_record && user.personal_record.age ? user.personal_record.age : '25'}} -
                              {{ user.personal_record && user.personal_record.city_id ? getCityById(user.personal_record.city_id) ? getCityById(user.personal_record.city_id).name : '' : '' }},
                              {{ user.personal_record && user.personal_record.country ? getName(user.personal_record.country) : getName('NP') }}
                            </div>
                            <div class="profile-info-right">
                              <p class="cam-icon">
                                {{ user.gallery_count && user.gallery_count.length ? user.gallery_count.length : 0 }}
                                <i class="fa fa-camera"></i>
                              </p>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="profile-ux-btn">
                      <div class="profile-ux-btn-left">
                        <a @click="onCommentClick(user.id)">
                          <img src="@/assets/images/comment.png" alt="">
                        </a>
                      </div>
                      <div class="profile-ux-btn-mid">
                        <a
                          @click="onConnectUser(user)"
                          v-if="!checkIfUserExist(user.connect_from_users)"
                        >
                          <img src="@/assets/images/connect.png" alt="" title="Connect">
                        </a>
                        <a v-else-if="checkIfUserPending(user.connect_from_users)">
                          <img src="@/assets/images/connect-pending.png" alt="" title="Pending">
                        </a>
                        <a v-else-if="checkIfUserRejected(user.connect_from_users)">
                          <img src="@/assets/images/connect-rejected.png" alt="" title="Pending">
                        </a>
                        <a v-else>
                          <img src="@/assets/images/connect-active.png" alt="" title="Pending">
                        </a>
                      </div>
                      <div class="profile-ux-btn-right">
                        <a
                          class="linke-btn"
                          v-if="checkIfUserLiked(user.liked_users)"
                        >
                          <img src="@/assets/images/heart-active.png" alt="">
                        </a>
                        <a
                          @click="onLikeClick(user)"
                          class="linke-btn"
                          v-else>
                          <img src="@/assets/images/heart.png" alt=""></a>
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-tab-pane>

            <el-tab-pane label="You Like" name="second">
              <div class="boost-info"
                   v-if="likesUsers && !likesUsers.length"
              >
                <div class="icon-boost">
                  <i class="fa fa-eye"></i>
                </div>
                <div class="boost-title">You have not liked yet.</div>
                <div class="boost-content">See what's out there, ceck out some search results just or you</div>
                <div class="boost-btn-area">
                  <router-link :to="{ name: 'search' }" class="boost-btn"> Keep Seaching <i class="el-icon-right"></i>
                  </router-link>
                </div>
              </div>
              <el-row :gutter="20" class="spacing">
                <el-col
                  :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :key="index"
                  v-for="(user, index) in likesUsers"
                >
                  <div class="profile-user">
                    <div class="profile-box">
                      <div class="var-online-part">
                        <div class="var-online">
                          <div class="var-icon">
                            <img src="@/assets/images/verified-icon.png" alt="">
                          </div>
                          <div class="var-line-icon">
                            <div class="user-online">
                              <div class="user-offline-show" title="Online"></div>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <figure>
                        <a @click="onUserView(user.id)">
                          <img
                            :src="user.image ? imageBasePath + user.image : require('@/assets/images/default-user.png')"
                            alt=""
                            border="0"
                            class="img-responsive"
                            style="max-height: 14.3rem;"
                          >
                        </a>
                      </figure>
                      <a @click="onUserView(user.id)">
                        <div class="main-profile">
                          <div class="profile-info-name">{{ user.username ? user.username : user.name }}</div>
                          <div class="profile-info">
                            <div class="profile-info-left">
                              {{ user.personal_record && user.personal_record.age ? user.personal_record.age : '25'}} -
                              {{ user.personal_record && user.personal_record.city_id ? getCityById(user.personal_record.city_id) ? getCityById(user.personal_record.city_id).name : '' : '' }},
                              {{ user.personal_record && user.personal_record.country ? getName(user.personal_record.country) : getName('NP') }}
                            </div>
                            <div class="profile-info-right">
                              <p class="cam-icon">
                                {{ user.gallery_count && user.gallery_count.length ? user.gallery_count.length : 0 }}
                                <i class="fa fa-camera"></i>
                              </p>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="profile-ux-btn">
                      <div class="profile-ux-btn-left">
                        <a @click="onCommentClick(user.id)">
                          <img src="@/assets/images/comment.png" alt="">
                        </a>
                      </div>
                      <div class="profile-ux-btn-mid">
                        <a
                          @click="onConnectUser(user)"
                          v-if="!checkIfUserExist(user.connect_from_users)"
                        >
                          <img src="@/assets/images/connect.png" alt="" title="Connect">
                        </a>
                        <a v-else-if="checkIfUserPending(user.connect_from_users)">
                          <img src="@/assets/images/connect-pending.png" alt="" title="Pending">
                        </a>
                        <a v-else-if="checkIfUserRejected(user.connect_from_users)">
                          <img src="@/assets/images/connect-rejected.png" alt="" title="Rejected">
                        </a>
                        <a v-else>
                          <img src="@/assets/images/connect-active.png" alt="" title="Connected">
                        </a>
                      </div>
                      <div class="profile-ux-btn-right">
                        <a
                          class="linke-btn"
                          v-if="checkIfUserLiked(user.liked_users)"
                        >
                          <img src="@/assets/images/heart-active.png" alt="">
                        </a>
                        <a
                          @click="onLikeClick(user)"
                          class="linke-btn"
                          v-else>
                          <img src="@/assets/images/heart.png" alt=""></a>
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24">
            <div class="boost-info">
             <div class="icon-boost">
               <i class="fa fa-bolt"></i>
            </div>
              <div class="boost-title"> Boost for more likes</div>
              <div class="boost-content">Boost your profile & receive 4x more views. Then sit back and watch the likes roll in!</div>
             <div class="boost-btn-area"><router-link :to="{ name: 'newpackage' }" class="boost-btn">Boost me <i class="el-icon-right"></i> </router-link></div>
            </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6">
          <div class="front-ui-connected">
            <div class="ui-header">
              <div class="tag">Boost Your Profile</div>
            </div>
            <div class="boost-profile">
             <div class="boost-icon">
               <div class="bicon">
              <i class="fa fa-bolt"></i>
               </div>
             </div>
              <div class="boost-text">
                <div class="title">Boost</div>
                <p>Be the top profile for one hour to be presented more</p>
                <div class="boost-me"><router-link :to="{ name: 'newpackage' }" class="boost-btn">Boost me <i class="el-icon-right"></i> </router-link></div>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="ui-header">
              <div class="tag"> You're their type</div>
            </div>
            <div
              :key="index"
              class="front-ui-conn-list"
              v-for="(user, index) in youMayKnowUsers"
            >
              <div class="left-col">
                <img
                  :src="user.image ? imageBasePath + user.image : require('@/assets/images/default-user.png')"
                  alt=""
                >
              </div>
              <div class="right-col">
                <div class="title connection-wants-title">
                  <router-link :to="{ name: 'user-profile', params: { id: user.id }}">{{ user.username ? user.username : user.name }}</router-link>
                </div>
                <div class="ui-bar-side">
                  <el-button
                    @click="onViewUser(user)"
                    class="ui-bar-button"
                  >
                    <i class="el-icon-view"></i>
                    View
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Breadcrumb from '@/views/user/_components/Breadcrumb'

import configurationMixin from '@/mixins/configuration'
import likeCommentMixin from '@/mixins/like_comment'
import pageMixin from '@/mixins/page'
import userMixin from '@/mixins/user'

const { getName } = require('country-list')

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  created () {
    this.getPage({
      slug: 'likes'
    }).then((res) => {
      this.value = res
    })

    this.getUsers()
    this.getMeData()
  },

  computed: {
    likedUsers () {
      return this.userData && this.userData.liked_users
    },

    likesUsers () {
      return this.userData && this.userData.likes_users
    },

    imageBasePath () {
      return process.env.VUE_APP_API_IMAGE_PATH
    },

    youMayKnowUsers () {
      return this.users ? this.users.slice(0, 5) : null
    }
  },

  data () {
    return {
      activeName: 'first',
      value: null,
      activeStepBar: 0,
      tab: 1,
      userData: ''
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    },

    getMeData () {
      this.getMe().then((user) => {
        this.userData = user
      })
    },

    onViewUser (user) {
      this.$router.push({ name: 'user-profile', params: { id: user.id } })
    },

    onCommentClick (commentToUserId) {
      this.onUserView(commentToUserId)
    },

    onCommentSubmit () {
      this.createComment({
        from_user_id: this.user.id,
        to_user_id: this.commentToUserId,
        comment: this.commentData
      }).then(() => {
        this.$message({
          message: 'User comment added successfully.',
          type: 'success'
        })

        this.commentData = ''
        this.commentToUserId = ''
        this.showCommentPopup = false
      }).catch(() => {
        this.$message({
          message: 'Ops! Something went wrong.',
          type: 'warning'
        })

        this.commentData = ''
        this.commentToUserId = ''

        this.showCommentPopup = false
      })
    },

    onLikeClick (user) {
      this.$confirm(`Are you sure you want to like ${user.name}?`, 'Accept', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(() => {
        this.createLike({
          from_user_id: this.user.id,
          to_user_id: user.id
        }).then(() => {
          this.$message({
            message: 'User Liked Successfully',
            type: 'success'
          })

          this.$emit('user:update')
          this.getUsers()
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      })
    },

    checkIfUserConnected (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return existingUser && existingUser.pivot.status === 1
      }

      return false
    },

    checkIfUserExist (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return !!existingUser
      }

      return false
    },

    checkIfUserPending (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return existingUser && existingUser.pivot.status === 0
      }

      return false
    },

    checkIfUserRejected (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return existingUser && existingUser.pivot.status === 2
      }

      return false
    },

    checkIfUserLiked (users) {
      console.log(users)
      if (this.user) {
        const isLiked = users && users.find(user => {
          return user.id === this.user.id
        })

        return !!isLiked
      }

      return false
    },

    onConnectUser (user) {
      if (!this.user) {
        this.$router.push({ name: 'user-login' })
        return
      }

      this.$confirm(`Are you sure you want to connect ${user.name}?`, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.connectUser({
          from_id: this.user ? this.user.id : null,
          to_id: user.id
        }).then(() => {
          this.getUsers()
          this.getMe()

          this.$message({
            type: 'success',
            message: 'User connected successfully.'
          })

          this.$router.push({ name: 'user-profile', params: { id: user.id } })
        })
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: 'Opps, Something went wrong.'
        })
      })
    },

    getName (code) {
      return code ? getName(code) : 'Nepal'
    },

    onUserView (id) {
      this.$router.push({ name: 'user-profile', params: { id: id } })
    }
  },

  mixins: [configurationMixin, likeCommentMixin, pageMixin, userMixin],

  name: 'Register'
}
</script>
<style scoped>
.likes-area{
}
.boost-profile{
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
}

.likes-area .el-tabs__item{
  font-size: 24px !important;
}

.boost-profile .title{
  padding: 0px 0 5px 0;
  font-size: 16px;
  font-weight: 500;
}

.boost-icon{
  margin: 0 8px 0 0;
  padding: 0;
  float: left;
  width: 30%;
}

.boost-icon .bicon{
  padding: 15px;
  margin: 0;
  border-radius: 50%;
  width: 65px;
  height: 64px;
  font-size: 28px !important;
  border: 1px solid #bfbebe;
  line-height: 40px;
  text-align: center;
  transition: all 0.3s ease-in 0s;
}
.boost-icon .bicon:hover{
  border: 1px solid #003993;
  color: #003993;
  transition: all 0.3s ease-in 0s;
}
.boost-title{
  margin: 0;
  padding: 20px 0 0 0;
  font-size: 26px;
  font-weight: 500;
}

.boost-text{
  margin: 0;
  padding: 0;
  float: right;
  width: 70%;
}

.boost-me{
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 15px;
}

.boost-me a{
  margin: 0;
  padding: 0;
  font-size: 15px;
  color: #003993;
  font-weight: 500;
  border:none;
  background: none;
}

.boost-info{
  padding: 60px 0 60px 0;
  text-align: center;
}

.boost-content{
  margin: 0 auto;
  padding: 15px 0;
  width: 45%;
  line-height: 26px;
  font-size: 18px;
}

.icon-boost{
  padding: 15px;
  margin: 0 auto;
  border-radius: 50%;
  width:100px;
  height:100px;
  font-size: 35px !important;
  border: 3px solid #dc143c;
  line-height: 70px;
  font-weight: 500;
  color:#dc143c ;
}

.boost-btn-area{
  margin-top: 40px;
}

.boost-btn{
  margin: 20px 0 !important;
  padding: 15px 50px;
  text-align: center;
  border: 1px solid #003993;
  color: #fff;
  border-radius: 30px;
  background: #003993;
  font-size: 18px;
  font-weight: 600;
  min-height: 28px !important;
  transition: all 0.3s ease-in 0s;
}

.spacing{
  padding-top: 30px;
}

</style>
