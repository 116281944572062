// Vuex mutations are very similar to events: each mutation has a string type and a handler.
const UPDATE_CITY = (state, city) => {
  state.city = city
}

const UPDATE_CITIES = (state, cities) => {
  state.cities = cities
}

const UPDATE_RELIGION = (state, religion) => {
  state.religion = religion
}

const UPDATE_RELIGIONS = (state, religions) => {
  state.religions = religions
}

const UPDATE_CAST = (state, cast) => {
  state.cast = cast
}

const UPDATE_CASTS = (state, casts) => {
  state.casts = casts
}

const UPDATE_EDUCATION = (state, education) => {
  state.education = education
}

const UPDATE_EDUCATIONS = (state, educations) => {
  state.educations = educations
}

const UPDATE_OCCUPATION = (state, occupation) => {
  state.occupation = occupation
}

const UPDATE_OCCUPATIONS = (state, occupations) => {
  state.occupations = occupations
}

const UPDATE_ADVERTISEMENTS = (state, advertisements) => {
  state.advertisements = advertisements
}

const UPDATE_NOTIFICATIONS = (state, notifications) => {
  state.notifications = notifications
}

export default {
  UPDATE_ADVERTISEMENTS,
  UPDATE_CITY,
  UPDATE_CITIES,
  UPDATE_RELIGION,
  UPDATE_RELIGIONS,
  UPDATE_CAST,
  UPDATE_CASTS,
  UPDATE_EDUCATION,
  UPDATE_EDUCATIONS,
  UPDATE_OCCUPATION,
  UPDATE_OCCUPATIONS,
  UPDATE_NOTIFICATIONS
}
