import request from '@/utils/request'

const getBlogs = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/blogs',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getBlog = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/blog/' + id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createBlog = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('image', data.image)
    formData.set('title', data.title)
    formData.set('slug', data.slug)
    formData.set('description', data.description)
    formData.set('parent_id', data.parent_id)
    formData.set('meta_title', data.meta_title)
    formData.set('meta_description', data.meta_description)
    formData.set('meta_keyword', data.meta_keyword)

    return request({
      url: '/blog/add',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateBlog = (data) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()

    formData.set('image', data.image)
    formData.set('title', data.title)
    formData.set('slug', data.slug)
    formData.set('description', data.description)
    if (data.parent_id) {
      formData.set('parent_id', data.parent_id)
    }
    formData.set('meta_title', data.meta_title)
    formData.set('meta_description', data.meta_description)
    formData.set('meta_keyword', data.meta_keyword)

    return request({
      url: '/blog/' + data.id,
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deleteBlog = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/blog/delete/' + id,
      method: 'post'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

export default {
  getBlog,
  getBlogs,
  createBlog,
  updateBlog,
  deleteBlog
}
