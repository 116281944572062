import api from '@/api/search'

const searchUser = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.searchUser(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default {
  searchUser
}
