import { createStore as createVuexStore } from 'vuex'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

import user from './user/index'
import blog from './blog/index'
import search from './search/index'
import feedback from './feedback/index'
import configuration from './configuration/index'
import banner from './banner/index'
import page from './page/index'
import message from './message/index'
import likeComment from './like_comment/index'
import gallery from './gallery/index'
import event from './event/index'

const modules = {
  blog,
  user,
  search,
  feedback,
  configuration,
  banner,
  page,
  message,
  likeComment,
  gallery,
  event
}

export const createStore = () => createVuexStore({
  actions,
  getters,
  modules,
  mutations
})

const store = createStore()

export default store
