<template>
  <div class="c-page">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="24">
        <el-card class="box-card">
          <div class="c-page__toolbar">
            <h2>{{ title }}</h2>
            <el-button
              @click="onClick"
              :type="ctaClass"
              round
              v-if="showButton"
            >{{ ctaLabel }}
            </el-button>
          </div>
          <slot name="content"></slot>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  methods: {
    onClick () {
      this.$emit('cta:click')
    }
  },

  name: 'Page',

  props: {
    ctaClass: {
      default: 'primary',
      type: String
    },

    ctaLabel: {
      default: 'Add',
      type: String
    },

    title: {
      type: String,
      required: true
    },

    showButton: {
      default: true,
      type: Boolean
    }
  }
}
</script>

<style lang="scss">
.c-page {
  padding: 0 1rem;
  &__toolbar {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: space-between;

  }
}
</style>
