<template>
  <Page
    @cta:click="onClick"
    :title="'Add Blog'"
    :ctaLabel="'Back'"
    :cta-class="'warning'"
  >
    <template #content>
      <Form />
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page'
import Form from './Form'

import blogMixin from '@/mixins/blog'

export default {
  components: {
    Form,
    Page
  },

  created () {
    this.getBlogs()
  },

  methods: {
    onClick () {
      this.$router.push({ name: 'admin-blogs' })
    }
  },

  mixins: [blogMixin],

  name: 'AddBlog'
}
</script>
