<template>
  <Header />
  <Breadcrumb :title="'Safety Center'" :link="'safety-cnter'" />
  <div class="information-ul">
  <div class="container">
    <el-row :gutter="40" >
          <el-col :xs="24" :sm="8" :md="8" :lg="8">
            <div class="sidebar-bg">
           <div class="page-links">
             <ul>
               <li><a href="/#/safety-center" class="active"> Safety Center</a></li>
               <li><a href="/#/datingincovid"> Dating in the time of Corona Virus</a></li>
               <li><a href="/#/feedback"> Report a Users</a></li>
               <li><a href="/#/safety-tips"> View our Safety Tips</a></li>
               <li><a href="/#/additional-resources"> View additional resources</a></li>
             </ul>
           </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="14" :md="16" :lg="16">
            <div class="page-title">{{  value ? value.title : '' }}</div>
            <div v-html="value.description" v-if="value"></div>
          </el-col>
        </el-row>
  </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import pageMixin from '@/mixins/page'
import Breadcrumb from '@/views/user/_components/Breadcrumb'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  created () {
    this.getPage({
      slug: 'safety_center'
    }).then((res) => {
      this.value = res
    })
  },

  data () {
    return {
      value: null,
      activeStepBar: 0,
      tab: 1
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    }
  },

  mixins: [pageMixin],
  name: 'Register'
}
</script>
<style scoped>
.information-ul {
  margin: 0;
  padding: 40px 0;
  background: #f3f2ef;
}
.information-ul .page-title {
  font-size: 24px !important;
  padding-top: 10px;
}

.information-ul p{
  padding: 10px 0;
  line-height: 24px;
}

.sidebar-bg{
  background: #fff;
  padding-bottom: 40px;
}

.page-links{
  margin: 0;
  padding:10px 0 0 0;
}

.page-links ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-links li{
  margin: 0 5px;
  padding: 20px 0 20px 20px;
  list-style: none;
  font-size: 17px;
  border-bottom: 1px solid #ccc;
}

.page-links li a{
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all 0.3s ease-in 0s;
}

.page-links li a:hover{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}

.page-links li a.active{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}

</style>
