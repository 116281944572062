<template>
  <div
    class="front-ul-bg"
    v-if="isLoggedIn"
  >
    <div class="container">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="12" :md="18" :lg="18">
          <el-row
            :gutter="20"
            v-if="users && users.length"
          >
            <el-col
              :xs="24" :sm="12" :md="8" :lg="8"
              :key="index"
              v-for="(user, index) in usersList"
            >
              <div class="profile-user">
                <div class="profile-box">
                  <div class="var-online-part">
                    <div class="var-online">
                      <div class="var-icon">
                        <img src="@/assets/images/verified-icon.png" alt="">
                      </div>
                      <div class="var-line-icon">
                        <div class="user-online">
                          <div
                            :class="[user.is_logged_in ? 'user-online-show' : 'user-offline-show']"
                            :title="user.is_logged_in ? 'Online' : 'Offline'"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <figure>
                    <a @click="onUserView(user.id)">
                      <img
                        :src="user.image ? imageBasePath + user.image : require('@/assets/images/default-user.png')"
                        alt=""
                        border="0"
                        class="img-responsive"
                        style="max-height: 14.3rem;"
                      >
                    </a>
                  </figure>
                  <a @click="onUserView(user.id)">
                    <div class="main-profile">
                      <div class="profile-info-name">{{ user.username ? user.username : user.name }}</div>
                      <div class="profile-info">
                        <div class="profile-info-left">
                          {{ user.personal_record && user.personal_record.age ? user.personal_record.age : '25'}} -
                          {{ user.personal_record && user.personal_record.city_id ? getCityById(user.id) ? getCityById(user.personal_record.city_id).name : '' : '' }},
                          {{ user.personal_record && user.personal_record.country ? getName(user.personal_record.country) : getName('NP') }}
                        </div>
                        <div class="profile-info-right">
                          <p class="cam-icon">
                            {{ user.gallery_count && user.gallery_count.length ? user.gallery_count.length : 0 }}
                            <i class="fa fa-camera"></i>
                          </p>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="profile-ux-btn">
                  <div class="profile-ux-btn-left">
                    <a @click="onCommentClick(user.id)">
                      <img src="@/assets/images/comment.png" alt="">
                    </a>
                  </div>
                  <div class="profile-ux-btn-mid">
                    <a
                      @click="onConnectUser(user)"
                      v-if="!checkIfUserExist(user.connect_from_users)"
                    >
                      <img src="@/assets/images/connect.png" alt="" title="Connect">
                    </a>
                    <a v-else-if="checkIfUserPending(user.connect_from_users)">
                      <img src="@/assets/images/connect-pending.png" alt="" title="Pending">
                    </a>
                    <a v-else-if="checkIfUserRejected(user.connect_from_users)">
                      <img src="@/assets/images/connect-rejected.png" alt="" title="Pending">
                    </a>
                    <a v-else>
                      <img src="@/assets/images/connect-active.png" alt="" title="Pending">
                    </a>
                  </div>
                  <div class="profile-ux-btn-right">
                    <a
                      class="linke-btn"
                      v-if="checkIfUserLiked(user.liked_users)"
                    >
                      <img src="@/assets/images/heart-active.png" alt="">
                    </a>
                    <a
                      @click="onLikeClick(user)"
                      class="linke-btn"
                      v-else>
                      <img src="@/assets/images/heart.png" alt=""></a>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6">
          <div class="front-ui-connected">
            <div class="ui-header">
              <div class="tag"> People Connected With</div>
            </div>
            <template v-if="connectToUsers.length > 0">
              <div
                :key="index"
                class="front-ui-conn-list"
                v-for="(user, index) in connectToUsers"
              >
                <div class="left-col">
                  <img
                    :src="user.image ? imageBasePath + user.image : require('@/assets/images/default-user.png')"
                    alt=""
                  >
                </div>
                <div class="right-col">
                  <div class="title connection-wants-title">
                    <router-link :to="{ name: 'user-profile', params: { id: user.id }}">{{ user.username ? user.username : user.name }}</router-link>
                  </div>
                  <div class="ui-bar-side">
                    <el-button class="pending" v-if="user.pivot && user.pivot.status === 0">
                      <i class="el-icon-loading"></i>
                      Pending
                    </el-button>
                    <el-button class="rejected" v-else-if="user.pivot && user.pivot.status === 2">
                      <i class="el-icon-close"></i>
                      Rejected
                    </el-button>
                    <el-button class="connected" v-else-if="user.pivot && user.pivot.status === 1">
                      <i class="el-icon-check"></i>
                      Connected
                    </el-button>
                  </div>
                </div>
              </div>
            </template>
            <div
              class="front-ui-conn-list-notfound"
              v-else
            >
              No Request.
              <div class="noviews">
                <i class="eye-icon fa fa-link"></i>
              </div>
            </div>
          </div>
          <div class="front-ui-connected">
            <div class="ui-header">
              <div class="tag">
                People Who Wants To Connect
              </div>
            </div>
            <template v-if="connectFromUsers.length > 0">
              <div class="hidden-scroller">
                <div
                  :key="index"
                  class="front-ui-conn-list"
                  v-for="(user, index) in connectFromUsers"
                >
                  <div class="left-col">
                    <img
                      :src="user.image ? imageBasePath + user.image : require('@/assets/images/default-user.png')"
                      alt=""
                    >
                  </div>
                  <div class="right-col">
                    <div class="title connection-wants-title">
                      <router-link :to="{ name: 'user-profile', params: { id: user.id }}">{{ user.username ? user.username : user.name }}</router-link>
                      <span class="accepted-smv" v-if="user.pivot.status === 1">Accepted</span>
                      <span class="rejected-smv" v-if="user.pivot.status === 2">Rejected</span>
                    </div>
                    <div class="ui-bar-side">
                      <el-button
                        @click="onClickAcceptUser(user)"
                        class="ui-bar-button"
                        v-if="user.pivot.status !== 1"
                      >
                        <i class="el-icon-check"></i>
                        Accept
                      </el-button>
                      <el-button
                        @click="onClickRejectUser(user)"
                        class="ui-bar-button"
                        v-if="user.pivot.status !== 2"
                      >
                        <i class="el-icon-close"></i>
                        Reject
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div
              class="front-ui-conn-list-notfound"
              v-else
            >
              No new Request.
              <div class="noviews">
                <i class="eye-icon fa fa-link"></i>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import 'intl-tel-input/build/css/intlTelInput.css'
import 'intl-tel-input/build/js/intlTelInput.js'
import intlTelInput from 'intl-tel-input'

import userMixins from '@/mixins/user'
import advertisementMixin from '@/mixins/advertisement'
import configurationMixin from '@/mixins/configuration'
import bannerMixin from '@/mixins/banner'
import likeCommentMixin from '@/mixins/like_comment'
import feedbackMixin from '@/mixins/feedback'

import { computed, reactive } from 'vue'
import { email, helpers, numeric, required } from '@vuelidate/validators'
import useValidate from '@vuelidate/core'

const { getName } = require('country-list')

export default {
  created () {
    this.loading = true
    this.getUsers().then(() => {
      this.loading = false
    })
    this.getAdvertisements()
    this.getBanners()
    this.getCities()
  },

  computed: {
    middleAdvertisementsData () {
      return this.advertisements && this.advertisements.slice(0, 4).filter(advertisement => {
        return advertisement.status === 1 && advertisement.position === 2
      })
    },

    bottomAdvertisementsData () {
      return this.advertisements && this.advertisements.slice(0, 4).filter(advertisement => {
        return advertisement.status === 1 && advertisement.position === 3
      })
    },

    bannersData () {
      return this.banners && this.banners.slice(0, 4).filter(banner => {
        return banner.status === 1
      })
    },

    usersList () {
      if (this.user && this.user.id) {
        const verifiedUsers = this.users && this.users.filter(user => {
          return user.id !== this.user.id && (user.status === 1 || user.email_verified_at !== null)
        })

        return verifiedUsers
      }

      const verifiedUsers = this.users && this.users.filter(user => {
        return user.status === 1 || user.email_verified_at !== null
      })

      return verifiedUsers.slice(0, 12)
    },

    loggedInUser () {
      return this.user ? this.user : null
    },

    latestVerifiedUsers () {
      const selectedUsers = this.users.slice(0, 4).map(user => {
        return user
      })

      return selectedUsers.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at)
      })
    },

    connectFromUsers () {
      return this.user && this.user.connect_from_users
    },

    connectToUsers () {
      return this.user && this.user.connect_to_users
    },

    imageBasePath () {
      return process.env.VUE_APP_API_IMAGE_PATH
    }
  },

  data () {
    return {
      commentData: '',
      commentToUserId: '',
      loading: false,
      showChat: false,
      showCommentPopup: false
    }
  },

  setup () {
    const state = reactive({
      loading: false,
      userData: {
        name: '',
        email: '',
        phone: '',
        password: ''
      }
    })

    const feedbackState = reactive({
      loading: false,
      feedback: {
        name: '',
        email: '',
        phone: '',
        address: '',
        message: ''
      }
    })

    const rules = computed(() => {
      return {
        userData: {
          name: { required: helpers.withMessage('Name field is required', required) },
          email: { required: helpers.withMessage('Email field is required', required), email },
          phone: { required: helpers.withMessage('Phone field is required', required), numeric },
          password: { required: helpers.withMessage('Password field is required', required) }
        }
      }
    })

    const feedbackRules = computed(() => {
      return {
        feedback: {
          name: { required: helpers.withMessage('Name field is required', required) },
          email: { required: helpers.withMessage('Email field is required', required), email },
          phone: { required: helpers.withMessage('Phone field is required', required), numeric },
          address: { required: helpers.withMessage('Address field is required', required) },
          message: { required: helpers.withMessage('Message field is required', required) }
        }
      }
    })

    const v$ = useValidate(rules, state)
    const f$ = useValidate(feedbackRules, feedbackState)

    return {
      state,
      feedbackState,
      v$,
      f$
    }
  },

  methods: {
    onCommentClick (commentToUserId) {
      this.showCommentPopup = true
      this.commentToUserId = commentToUserId
    },

    onCommentSubmit () {
      this.createComment({
        from_user_id: this.user.id,
        to_user_id: this.commentToUserId,
        comment: this.commentData
      }).then(() => {
        this.$message({
          message: 'User comment added successfully.',
          type: 'success'
        })

        this.commentData = ''
        this.commentToUserId = ''
        this.showCommentPopup = false
      }).catch(() => {
        this.$message({
          message: 'Ops! Something went wrong.',
          type: 'warning'
        })

        this.commentData = ''
        this.commentToUserId = ''

        this.showCommentPopup = false
      })
    },

    closeCommentPopup () {
      this.showCommentPopup = false
    },

    closeChat () {
      this.showChat = false
    },

    getName (code) {
      return code ? getName(code) : 'Nepal'
    },

    onFeedbackSubmit () {
      this.f$.$validate()

      if (!this.f$.$error) {
        this.feedbackState.loading = true

        this.createFeedback(this.feedbackState.feedback).then((res) => {
          this.$message({
            message: 'Feedback submitted successfully.',
            type: 'success'
          })

          this.feedbackState.loading = false

          this.feedbackState.feedback = {
            name: '',
            email: '',
            phone: '',
            address: '',
            message: ''
          }
        }).catch((error) => {
          this.feedbackState.loading = false

          this.$message({
            message: error.response.data.error ? 'Invalid data' : error.response.data.message,
            type: 'warning'
          })
        })
      }
    },

    onLikeClick (user) {
      this.$confirm(`Are you sure you want to like ${user.name}?`, 'Accept', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(() => {
        this.createLike({
          from_user_id: this.user.id,
          to_user_id: user.id
        }).then(() => {
          this.$message({
            message: 'User Liked Successfully',
            type: 'success'
          })

          this.$emit('user:update')
          this.getUsers()
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      })
    },

    onClickAcceptUser (user) {
      this.$confirm(`Are you sure you want to accept ${user.name}?`, 'Accept', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(() => {
        this.acceptUser({
          from_id: user.pivot.from_id,
          to_id: user.pivot.to_id
        }).then(() => {
          this.$message({
            message: 'User Connection Accepted',
            type: 'success'
          })

          this.$router.push({ name: 'user-profile', params: { id: user.pivot.from_id } })
          this.$emit('user:update')
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      })
    },

    onClickRejectUser (user) {
      this.$confirm(`Are you sure you want reject ${user.name}?`, 'Reject', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.rejectUser({
          from_id: user.pivot.from_id,
          to_id: user.pivot.to_id
        }).then(() => {
          this.$message({
            message: 'User Connection Rejected',
            type: 'success'
          })

          this.$router.push({ name: 'user-profile', params: { id: user.pivot.from_id } })
          this.$emit('user:update')
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Opps, Something went wrong.'
          })
        })
      })
    },

    onUserView (id) {
      this.$router.push({ name: 'user-profile', params: { id: id } })
    },

    onConnectUser (user) {
      if (!this.user) {
        this.$router.push({ name: 'user-login' })
        return
      }

      this.$confirm(`Are you sure you want to connect ${user.name}?`, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.connectUser({
          from_id: this.user ? this.user.id : null,
          to_id: user.id
        }).then(() => {
          this.getUsers()
          this.getMe()

          this.$message({
            type: 'success',
            message: 'User connected successfully.'
          })

          this.$router.push({ name: 'user-profile', params: { id: user.id } })
        })
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: 'Opps, Something went wrong.'
        })
      })
    },

    onSubmit () {
      this.v$.$validate()
      if (!this.v$.$error) {
        this.state.loading = true

        this.createUserInformation(this.state.userData).then((res) => {
          this.$message({
            message: 'User Information created successfully.Please check email to verify and complete profile further.',
            type: 'success'
          })

          this.state.loading = false

          this.$router.push({ name: 'user-login' })
        }).catch((error) => {
          this.state.loading = false

          this.$message({
            message: error.response.data.error ? 'Invalid data' : error.response.data.message,
            type: 'warning'
          })
        })
      }
    },

    onFacebookClick () {
      window.location.href = process.env.VUE_APP_API_BASE_URL + '/auth/facebook'
    },

    onGoogleClick () {
      window.location.href = process.env.VUE_APP_API_BASE_URL + '/auth/google'
    },

    checkIfUserConnected (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return existingUser && existingUser.pivot.status === 1
      }

      return false
    },

    checkIfUserExist (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return !!existingUser
      }

      return false
    },

    checkIfUserPending (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return existingUser && existingUser.pivot.status === 0
      }

      return false
    },

    checkIfUserRejected (users) {
      if (this.user) {
        const existingUser = users && users.find(user => {
          return user.pivot.from_id === this.user.id
        })

        return existingUser && existingUser.pivot.status === 2
      }

      return false
    },

    checkIfUserLiked (users) {
      if (this.user) {
        const isLiked = users && users.find(user => {
          return user.from_user_id === this.user.id
        })

        return !!isLiked
      }

      return false
    }
  },

  mixins: [advertisementMixin, configurationMixin, feedbackMixin, likeCommentMixin, bannerMixin, userMixins],

  mounted () {
    if (!this.isLoggedIn) {
      const input = document.querySelector('#telephone')
      intlTelInput(input, {
        preferredCountries: ['np', 'us'],
        separateDialCode: true
      })
    }
  },

  name: 'Body'
}
</script>
<style lang="scss">
.c-body {
  &__connect {
    margin-top: 0.5rem;
  }

  &__error {
    color: darkred;
  }

  &__phone {
    width: 100%;

    .iti .iti--separate-dial-code {
      width: 100% !important;
    }
  }
}
.el-carousel__container{
  height: 110px !important;
  overflow: hidden;
}

.home-banner{
  margin: 0;
  padding: 0;
  width: 100%;
  background: url('~@/assets/images/app-bg.jpg') no-repeat;
  background-position: 100% 0;
  background-size: cover;
  min-height: 485px;
  background-attachment: fixed;
}

.home-banner .title{
  margin-top: 28%;
  padding: 0;
  font-size:40px;
  color: #fff;
  font-weight: 800;

}

.home-banner .Subtitle{
  margin: 0;
  padding: 0 0 20px 0;
  font-size:40px;
  color: #fff;
  font-weight: 800;
}

.home-banner .frontregister{
  margin: 0;
  padding: 20px;
  background: #fff;
  min-height: 485px;
}

.home-banner .frontregister .el-select {
  width: 100%;
}

.home-banner .frontregister p{
  padding: 10px 0 0px 0;
}

.home-banner .frontregister .heading{
  margin: 0;
  padding: 0px;
  font-size: 18px;
}

.home-banner .frontregister .btns{
  padding: 12px 25px !important;
  font-size: 16px !important;
  margin-top:5px;
}

.home-banner .frontregister .logwith{
  padding: 15px 0;
  color: #003895;
  font-size: 15px;
  font-weight: 500;
}

.el-items{
  position: relative !important;
  z-index: 9999 !important;
  top: 40%;
  text-align: center;
}

.el-carousel__item h3 {
  color: #fff;
  font-size: 55px;
  margin: 0;
  text-shadow: 2px 2px #000;
}

.el-carousel__item h4 {
  color: #fff;
  font-size: 28px;
  line-height: 40px;
  margin: 0;
  text-shadow: 2px 2px #000;
  font-weight: 600;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

/*
.el-carousel__item img{
  width: 100%;
  position: absolute !important;
  z-index: 9 !important;
}
*/

.el-carousel__arrow i {
  cursor: pointer;
  font-size: 25px;
  color: #FFFFFF;
  font-weight: 700;
}

@media screen and (min-width: 200px) and (max-width:520px) {
  .el-items {
    top: 10%;
  }

  .el-carousel__container{
    height: 180px !important;
    overflow: hidden;
  }

  .el-carousel__item h3 {
    color: #fff;
    font-size: 35px;
    margin: 0;
    text-shadow: 2px 2px #000;
  }

  .el-carousel__item h4 {
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    text-shadow: 2px 2px #000;
    font-weight: 600;
  }
}

.support-section{
  margin:0;
  padding:50px 0 80px 0;
  background: #fff;
  min-height: 320px;
}

.support-title {
  text-align: center;
}

.support-title h2 {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 30px;
  text-align: center;
  color: #000a93;
}

.support-block{
  margin: 0;
  padding: 30px 0 0 0;
}

.support-block .box{
  margin: 0;
  padding: 20px;
  /* background: #e7eaee;*/
  text-align: center;
}

.support-block .box .title{
  margin: 0;
  padding: 15px 0 5px 0;
  font-size: 22px;
  font-weight: 600;
}

.support-block .icons{
  margin: 0;
  padding: 0;
  font-size: 58px;
  font-weight: 600;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  line-height: 109px;
  color: #ffffff;
  background-image: linear-gradient(
      166deg, #f22876 0%, #942dd9 100%);
}

.support-block .box li{
  margin: 0;
  padding:0;
  line-height: 20px;
}

.member-section {
  margin: 0;
  padding: 70px 0;
  background: #ecf1f5;
}

.member-title {
  text-align: center;
}

.member-title h2 {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 30px;
  text-align: center;
  color: #000a93;
}

.member-title h6 {
  margin: 0;
  padding: 10px 0 40px 0;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}

.member-block {
  margin: 20px 0 0 0;
  padding: 0 0 20px 0;
  background: #fff;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
  text-align: center;
}

.member-block h3 {
  margin: 0;
  padding: 10px 0 0px 0;
  font-size: 16px;
  text-align: center;
  color: #dc143c;
}

.member-block h3 a {
  font-size: 16px;
  text-align: center;
  color: #dc143c;
}

.member-box{
  background: #484d81;
  font-family: 'Raleway', sans-serif;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
  max-height: 300px;
  overflow: hidden;
}

.member-box:hover{
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.member-box img{
  width: 100%;
  height: auto;
  transform: translate3d(-15px, 0, 0) scale(1.12);
  transition: all 0.35s ease 0s;
}

.member-box:hover img{
  opacity: 0.3;
  transform: translate3d(0, 0, 0) scale(1);
}

.member-box .box-content{
  color: #fff;
  text-align: left;
  padding: 0 10px;
  opacity: 0;
  position: absolute;
  bottom: 30px;
  left: -100%;
  transition: all 0.35s ease 0s;
}
.member-box:hover .box-content{
  opacity: 1;
  left: 10px;
}

.member-box .title{
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  padding: 0 0 10px 0;
  color: #fff;
  text-align: left;
  line-height: 28px;
}

.member-box .post{
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
  text-transform: capitalize;
  letter-spacing: 1px;
  display: block;
}

.member-box:hover .icon{
  opacity: 1;
  transform: scale(1);
}

@keyframes animate-hover{
  0%{ box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3); }
  50%{ box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.3); }
  100%{ box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3); }
}

.readmore-link {
  padding: 40px 0 0 0;
  text-align: center;
  font-size: 16px;
}

.readmore-link .el-button{
  font-size: 15px;
}

.parallax-app {
  background-attachment: fixed;
  background-image: url('~@/assets/images/app-bg.jpg');
  background-size: cover;
  min-height: 500px;
  background-repeat: repeat-y;
  background-position: 50% 0px;
  margin: 0px;
  padding: 50px 0px;
}
.app-content-box{
  margin: 300px auto 0;
  padding: 60px;
  background: #ecf1f5;
  border-radius: 50px 50px 0 0;
  width: 970px;
  min-height: 300px;
  position: relative;
  bottom: -100px;
}

.app-content-title{
  margin: 0;
  padding: 0 0 10px 0;
  font-size: 30px;
  text-align: left;
  color: #003893;
  font-weight: 600;
}

.app-content-box p {
  margin: 0;
  padding: 12px 0;
  font-size: 16px;
  color: #000000;
  text-align: left;
  line-height: 26px;
}

.app-content-box .icons {
  margin: 20px 15px 0 0;
  padding: 0px 0;
}

.app-content-box .icons img{
  margin: 0px 15px 0 0;
  padding: 0px 0;
}

.app-content-box .mob-img{
  margin: 0 auto;
  width: 290px;
  padding: 0;
  text-align: center;
}

.advertise-section {
  margin: 0;
  padding: 70px 0;
  background: #ecf1f5;
}

.advertise-title {
  text-align: center;

}

.advertise-title h2 {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 30px;
  text-align: center;
  color: #000a93;
}

.advertise-title h5 {
  margin: 0;
  padding: 10px 0 40px 0;
  font-size: 20px;
  text-align: center;
}

.advertise-block {
  margin: 20px 0 0 0;
  padding: 0 0 20px 0;
  background: #fff;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
  text-align: center;
}

.advertise-block h3 {
  margin: 0;
  padding: 10px 0 0px 0;
  font-size: 16px;
  text-align: center;
  color: #dc143c;
}

.advertise-block h3 a {
  font-size: 16px;
  text-align: center;
  color: #dc143c;
}
.card-title{
  font-size: 20px;
  font-weight: 500;
}

.vp-bg{
  margin: 0;
  padding: 40px 0 40px 0;
  background: #f3f2ef;
}

.vp-profile-list{
  padding:5px 10px 25px 10px;
  overflow: hidden;
  border-radius: 5px;
  background: #FFFFFF;
  margin-bottom: 20px;
}

.vp-userpic-list{
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 999;
}

.vp-userpic-list .el-button{
  margin: 0 5px 0 0;
  padding:5px 8px !important;
  min-height: 30px !important;
  border-radius: 2px !important;
  font-size: 12px !important;
}

.vp-profile-list .card-header{
  font-weight: 500;
  font-size: 50px !important;
}

.vp-profile-list .online{
  width: 12px;
  height: 12px;
  background: #008000;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  top: -30px;
  left: 38px;
  border: 5px solid #ffffff;
  padding: 8px;
}

.vp-profile-list .offline{
  width: 12px;
  height: 12px;
  background: #c4c2c2;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  top: -30px;
  left: 38px;
  border: 5px solid #ffffff;
  padding: 8px;
}

.vp-head .el-card__body{
  padding: 8px 20px !important;;
}

.browse-more{
  text-align: center;
}

.browse-more .el-button {
  font-size: 15px;
}

.vp-userpic-list img {
  float: none;
  margin: 0 auto;
  width: 115px;
  height: 115px;
  -webkit-border-radius: 50%!important;
  -moz-border-radius: 50%!important;
  border-radius: 50%!important;
  /*filter: blur(1.75px);
  -webkit-filter: blur(1.75px);*/
}

.vp-userpic-list .blur-img{
  filter: blur(4.75px) !important;
  -webkit-filter: blur(4.75px) !important;
}

.vp-name{
  margin-top: -8px;
  padding: 5px 0 10px 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}

.vp-age-height{
  margin: 0;
  padding: 5px 0 0 0;
  font-size:16px;
  line-height: 20px;
  font-weight: 500;
}

.vp-post{
  margin: 0;
  padding: 3px 0 0 0;
  font-size: 14px;
  line-height: 20px;
}

.vp-info{
  margin: 0;
  padding: 3px 0 20px 0;
  font-size: 14px;
  line-height: 22px;
}

.vp-info a{
  color: #030848;
  font-weight: 600;
}

.el-card__header {
  padding: 15px 20px 15px 20px !important;
}

.el-card{
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 20px;
}

.add-info{
  float: right;
}

.add-info .icons{
  font-weight: 600;
  color: #000;
}

.add-info .icons:hover{
  font-weight: 600;
  color: #F56C6C;
}

.top-gap{
  padding-top: 30px;
}

label{
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
}

.tp-gap{
  padding-top: 30px;
}

.el-input__inner{
  margin: 5px 0 5px 0 !important;
  border: 2px solid #ccc !important;
  width: 100%;
}

.text-crousel {
  position: absolute;
  z-index: 99999;
  bottom: -50px;
  font-size: 50px;
  text-align: center;
}

.home-slider{
  margin: 0;
  padding: 0;
}

.country-flags{
  margin-top:0px;
  position: relative;
  right: -15px;
}

.verify-top{
  margin: 0;
  padding: 0;
  height:48px;
}

.verify-left{
  margin: 0;
  padding: 0;
}

.verify-right{
  margin: 0;
  padding: 0;
  float: right;
}

.verified-tag {
  position: absolute;
  left: -85px;
  top: 18px;
  font-size: 12px;
  text-align: center;
  line-height: 12px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  transform: rotate(
      -45deg
  );
  -webkit-transform: rotate(
      -45deg
  );
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  background: #10a64a;
  padding: 6px 5px 7px;
  margin: 0;
  min-width: 185px;
  z-index: 9;
}

.verified-tag span{
  padding-left: 22px;
  font-weight: 600;
}

.vp-profile-list .online {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  margin-top: -8px;
  left: 38px;
  border: 5px solid #fff;
  padding: 8px;
  background: green;
}

.el-input .iti {
  width: 100% !important;
}

.ads-banner{
  padding: 20px 0;
}

.front-ul-bg{
  margin: 0;
  padding: 40px 0;
  background: #f3f2ef;
  overflow: hidden;
}

.front-profile{
  margin: 0;
  padding: 0px 0 20px 0;
}

.front-ul-box{
  margin: 0;
  padding: 0;
  background:#fff;
  transition: all 0.3s ease-in 0s;
}

.front-ul-box:hover{
  box-shadow:  0px 10px 5px rgba(0,0,0,0.6);
  -moz-box-shadow:  0px 10px 5px rgba(0,0,0,0.6);
  -webkit-box-shadow: 0px 10px 5px rgba(0,0,0,0.6);
  -o-box-shadow: 0px 10px 5px rgba(0,0,0,0.6);
  transition: all 0.3s ease-in 0s;
}

.front-ul-block{
  font-family: 'Raleway', sans-serif;
  overflow: hidden;
  position: relative;
}

.front-ul-block:before,
.front-ul-block:after{
  content: '';
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  height: 50%;
  width: 100%;
  opacity: 0.8;
  position: absolute;
  top: -50%;
  left: 0;
  z-index: 1;
  transition: all 0.3s ease-in 0s;
}

.front-ul-block:after{
  top: auto;
  bottom: -50%;
}

.front-ul-block:hover:before{ top: 0; }
.front-ul-block:hover:after{ bottom: 0; }
.front-ul-block img{
  width: 100%;
  height: auto;
}

.front-ul-block .box-content{
  color: #fff;
  text-align: center;
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 40%;
  left: 0;
  z-index: 2;
  transition: all 0.3s ease-out 0.1s;
}

.front-ul-block:hover .box-content{
  opacity: 1;
  top: 50%;
}

.front-ul-block .title{
  font-size: 17px;
  font-weight: 700;
  margin: 0;
  line-height: 22px;
}

.front-ul-block .post{
  color: rgba(255,255,255,0.8);
  font-size: 13px;
  font-weight: 500;
  display: block;
}

.front-ul-block .icon{
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  bottom: 40%;
  z-index: 2;
  transition: all 0.3s ease-out 0.1s;
}

.front-ul-block:hover .icon{
  opacity: 1;
  bottom: 20%;
}

.front-ul-block .icon li{
  margin: 0 3px;
  display: inline-block;
}

.front-ul-block .icon li a{
  color: #444;
  background-color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 36px;
  height: 38px;
  width: 35px;
  border-radius: 10px;
  display: block;
  transition: all 0.3s ease 0s;
}

.front-ul-block .icon li a:hover{
  color: #fff;
  background: #12c2e9;
}

.front-ui-likes-btn{
  margin-top:-20px;
  padding: 0;
  text-align: center;
  z-index: 999;
  position: relative;
}

.front-ui-likes-btn img{
  width: 32px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.front-ui-likes-btn .linke-btn{
  margin: 0;
  padding: 8px 6px;
  background: #fff;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 22px;
  color: #fff;
  border-radius: 50%;
  line-height: 40px;
  box-shadow:  0px 10px 5px rgba(0,0,0,0.6);
  -moz-box-shadow:  0px 10px 5px rgba(0,0,0,0.6);
  -webkit-box-shadow: 0px 10px 5px rgba(0,0,0,0.6);
  -o-box-shadow: 0px 10px 5px rgba(0,0,0,0.6);
}

.front-ui-details{
  margin: 0;
  padding:20px 0;
  text-align: center;
}

.front-ui-name{
  margin: 0;
  padding:0;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  line-height: 24px;
}

.front-ui-adds{
  margin: 0;
  padding:0;
  font-size: 13px;
  color: #000;
}

.front-ui-cl-btn{
  margin:0;
  padding: 20px 0 20px 0;
  text-align: center;

}

.front-ui-cl-btn i{
  font-weight: 600;
}

.front-ui-cl-btn .view-btn{
  margin:0 5px 0 0 !important;
  padding: 0 15px;
  text-align: center;
  border: 1px solid #000;
  color:#000;
  border-radius: 20px;
  background:#ffffff;
  font-size: 12px;
  font-weight: 600;
  min-height: 35px;
  transition: all 0.3s ease 0s;
}

.front-ui-cl-btn .view-btn:hover{
  border: 1px solid #237eeb;
  color:#fff;
  background:#237eeb;
  transition: all 0.3s ease 0s;
}

.front-ui-cl-btn .connect-btn{
  margin:0 !important;
  padding: 0 15px;
  text-align: center;
  border: 1px solid #000;
  color:#000;
  border-radius: 20px;
  background:#ffffff;
  font-size: 12px;
  font-weight: 600;
  min-height: 35px;
  transition: all 0.3s ease 0s;
}

.front-ui-cl-btn .connected-btn{
  margin:0 !important;
  padding: 0 15px;
  text-align: center;
  border: 1px solid #0da749;
  color:#fff;
  border-radius: 20px;
  background:#0da749;
  font-size: 12px;
  font-weight: 600;
  min-height: 35px;
  transition: all 0.3s ease 0s;
}

.front-ui-cl-btn .connected-btn:hover{
  color:#fff;
  background:#0da749;
  border: 1px solid #0da749;
  transition: all 0.3s ease 0s;
}

.front-ui-cl-btn .pending-btn{
  margin:0 !important;
  padding: 0 15px;
  text-align: center;
  border: 1px solid #ff5f18;
  color:#fff;
  border-radius: 20px;
  background:#ff5f18;
  font-size: 12px;
  font-weight: 600;
  min-height: 35px;
  transition: all 0.3s ease 0s;
}

.front-ui-cl-btn .pending-btn:hover{
  color:#fff;
  background:#ff5f18;
  border: 1px solid #ff5f18;
  transition: all 0.3s ease 0s;
}

.front-ui-cl-btn .rejected-btn{
  margin:0 !important;
  padding: 0 15px;
  text-align: center;
  border: 1px solid #f00;
  color:#fff;
  border-radius: 20px;
  background:#f00;
  font-size: 12px;
  font-weight: 600;
  min-height: 35px;
  transition: all 0.3s ease 0s;
}

.front-ui-cl-btn .rejected-btn:hover{
  color:#fff;
  background:#f00;
  border: 1px solid #f00;
  transition: all 0.3s ease 0s;
}

.front-ui-cl-btn .connect-btn:hover{
  border: 1px solid #237eeb;
  color:#fff;
  background:#237eeb;
  transition: all 0.3s ease 0s;
}

.var-online{
  top: 5px;
  padding: 10px;
  position: absolute;
  z-index: 999;
  width:100%;
}

.var-online-part{
  margin:0;
  padding: 0;
}

.var-icon{
  margin: 0;
  padding: 0;
  float: left;
}

.var-line-icon{
  margin: 0;
  padding: 0;
  float: right;
}

.user-online{
  float: right;
}

.user-online-show {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 4px solid #fff;
  padding: 7px;
  background: green;
}

.user-offline-show {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 4px solid #fff;
  padding: 7px;
  background:#c4c2c2;
}

.front-ui-connected{
  margin: 0 0 20px 0;
  padding: 0;
}

.front-ui-connected .ui-header{
  margin: 0 0 1px 0;
  padding:12px 0px;
  background: #fff;
  font-size: 16px;
}

.front-ui-connected .ui-header .tag {
  border-left:4px solid #fa6342;
  padding:4px 20px;
}

.front-ui-conn-list {
  margin: 0 0 2px 0;
  padding: 12px 15px;
  clear: both;
  display: flex;
  background: #fff;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list:hover{
  box-shadow:  0px 10px 5px rgba(0,0,0,0.6);
  -moz-box-shadow:  0px 10px 5px rgba(0,0,0,0.6);
  -webkit-box-shadow: 0px 10px 5px rgba(0,0,0,0.6);
  -o-box-shadow: 0px 10px 5px rgba(0,0,0,0.6);
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .left-col {
  margin: 0 15px  0 0;
  padding: 0;
  width: 55 px;
  float: left;
}

.front-ui-conn-list .right-col {
  margin: 0;
  padding: 0;
  float: left;
}

.front-ui-conn-list .left-col img {
  margin: 0;
  padding: 0;
  width: 55px;
  height: 55px;
}

.front-ui-conn-list .title a {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.front-ui-conn-list .ui-bar-side{
  margin: 0;
  padding: 5px 0 0 0;
}

.front-ui-conn-list .ui-bar-button{
  margin: 0 5px 0 0 !important;
  padding: 0 10px;
  text-align: center;
  border: 1px solid #237eeb;
  color: #237eeb;
  border-radius: 20px;
  background: #ffffff;
  font-size: 12px;
  font-weight: 600;
  min-height: 28px !important;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .ui-bar-button:hover{
  background:#237eeb;
  color: #fff;
  border: 1px solid #237eeb;
  transition: all 0.3s ease-in 0s;
}

.feedback-section{
  margin: 0;
  padding: 40px 0;
  background: #f3f2ef;
}

.feed-text{
  margin: 0;
  padding: 20px 0 0 0;
  background: #f3f2ef;
}

.feed-text h3{
  margin: 0;
  padding: 20px 0;
  text-align: right;
  font-size: 24px;
}

.feed-text p{
  margin: 0;
  padding: 20px 0;
  text-align: right;
  font-size: 18px;
}

.feedback-section .ui-feed-btn {
  margin: 20px 0 0 0 !important;
  padding: 0 15px;
  text-align: center;
  border: 1px solid #237eeb;
  color: #fff;
  border-radius: 20px;
  background: #237eeb;
  font-size: 12px;
  font-weight: 600;
  min-height: 35px;
}

.front-ui-conn-list .connected{
  margin: 0 5px 0 0 !important;
  padding: 0 10px;
  text-align: center;
  border: 1px solid #237eeb;
  color: #237eeb;
  border-radius: 20px;
  background: #fff;
  font-size: 12px;
  font-weight: 600;
  min-height: 28px !important;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .connected:hover{
  background:#237eeb;
  color: #fff;
  border: 1px solid #237eeb;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .pending{
  margin: 0 5px 0 0 !important;
  padding: 0 10px;
  text-align: center;
  border: 1px solid #e66f0e;
  color: #ff5f18;
  border-radius: 20px;
  background: #ffffff;
  font-size: 12px;
  font-weight: 600;
  min-height: 28px !important;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .pending:hover{
  background:#e66f0e;
  color: #fff;
  border: 1px solid #e66f0e;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .rejected{
  margin: 0 5px 0 0 !important;
  padding: 0 10px;
  text-align: center;
  border: 1px solid #f00;
  color: #f00;
  border-radius: 20px;
  background: #ffffff;
  font-size: 12px;
  font-weight: 600;
  min-height: 28px !important;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .rejected:hover{
  background:#f00;
  color: #fff;
  border: 1px solid #f00;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .accepted-smv{
  padding: 2px 3px;
  border: 1px solid #0da749;
  color: #fff;
  border-radius: 20px;
  background: #0da749;
  font-size: 9px;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .rejected-smv{
  padding: 2px 3px;
  border: 1px solid #f00;
  color: #fff;
  border-radius: 20px;
  background: #f00;
  font-size: 9px;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .accepted-smv{
  padding: 2px 3px;
  border: 1px solid #0da749;
  color: #fff;
  border-radius: 20px;
  background: #0da749;
  font-size: 9px;
  transition: all 0.3s ease-in 0s;
}

.front-ui-conn-list .rejected-smv{
  padding: 2px 3px;
  border: 1px solid #f00;
  color: #fff;
  border-radius: 20px;
  background: #f00;
  font-size: 9px;
  transition: all 0.3s ease-in 0s;
}

</style>
