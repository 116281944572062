import { mapActions, mapGetters } from 'vuex'

const notifications = {
  computed: {
    ...mapGetters(
      'configuration',
      {
        notifications: 'notifications'
      }
    )
  },

  methods: {
    ...mapActions(
      'configuration',
      [
        'getNotifications',
        'seenNotification'
      ]
    )
  }
}

export default notifications
