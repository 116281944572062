import request from '@/utils/request'

const getOccupations = () =>
  new Promise((resolve, reject) => {
    return request({
      url: '/occupations',
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getOccupation = (id) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/admin-occupation/' + id,
      method: 'get'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createOccupation = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/occupation',
      method: 'post',
      data: data
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateOccupation = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/admin-occupation/' + data.id,
      method: 'post',
      data: data
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deleteOccupation = (data) =>
  new Promise((resolve, reject) => {
    return request({
      url: '/admin-occupation/delete/' + data.id,
      method: 'post'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

export default {
  getOccupation,
  getOccupations,
  createOccupation,
  deleteOccupation,
  updateOccupation
}
