<template>
  <div class="page">
    <Header />
    <Body />
    <Footer />
  </div>
</template>

<script>
import Body from '@/components/Body'
import Footer from '@/components/Footer'
import Header from '@/components/Header'

export default {
  created () {
    const admin = localStorage.getItem('admin')

    if (admin) {
      localStorage.removeItem('admin')
    }
  },

  components: {
    Body,
    Footer,
    Header
  }
}
</script>
