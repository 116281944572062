import api from '@/api/page'

const getPage = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.getPage(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createPage = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    api.createPage(data)
      .then((response) => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default {
  getPage,
  createPage
}
