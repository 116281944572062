<template>
  <Header />
  <Breadcrumb :title="'Manage Subscription'" :link="'setting'" />
  <div class="information-ul">
    <div class="container">
      <el-row :gutter="40" >
        <el-col :xs="24" :sm="8" :md="8" :lg="8">
          <div class="sidebar-bg">
            <div class="page-links">
              <ul>
                <li><a href="/#/blocked-profiles"> Blocked Profiles</a></li>
                <li><a href="/#/manage-subscription" class="active"> Manage Subscriptions</a></li>
                <li><a href="/#/email-notification"> Email Notifications</a></li>
                <li><a href="/#/site-notification"> Site Notification</a></li>
                <li><a href="/#/verify-account"> Verify your Account</a></li>
              </ul>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="14" :md="16" :lg="16">
          <div class="page-title">Manage Subscription</div>
          <div class="content-bg">
            <div class="title-small">Choose a Premium Plan to see our flexible payment Option </div>
            <div class="package-price-option">
              <div class="deals">Best Deal</div>
              <div class="package-pricing-list">
                <el-row :gutter="20">
                  <el-col :xs="8" :sm="8" :md="8" :lg="8">
                    <el-radio v-model="radio" label="1" class="radio-btn"><span class="pack-date">12 Months</span></el-radio>
                  </el-col>
                  <el-col :xs="8" :sm="8" :md="8" :lg="8">
                    <div class="packages--price"><span class="pack-old-price">$22.99</span> <span class="pack-new-price">$17.29</span></div>
                    <div class="pack-time">Per Month</div>
                  </el-col>
                  <el-col :xs="8" :sm="8" :md="8" :lg="8">
                    <div class="saving-per"> Save 73%</div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="package-price-option">
              <div class="mostpopular">Most Popular</div>
              <div class="package-pricing-list">
                <el-row :gutter="20">
                  <el-col :xs="8" :sm="8" :md="8" :lg="8">
                    <el-radio v-model="radio" label="1" class="radio-btn"><span class="pack-date">6 Months</span></el-radio>
                  </el-col>
                  <el-col :xs="8" :sm="8" :md="8" :lg="8">
                    <div class="packages--price"><span class="pack-old-price">$24.99</span> <span class="pack-new-price">$18.74</span></div>
                    <div class="pack-time">Per Month</div>
                  </el-col>
                  <el-col :xs="8" :sm="8" :md="8" :lg="8">
                    <div class="saving-per"> Save 70%</div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="package-price-option">
              <div class="package-pricing-list">
                <el-row :gutter="20">
                  <el-col :xs="8" :sm="8" :md="8" :lg="8">
                    <el-radio v-model="radio" label="1" class="radio-btn"><span class="pack-date">3 Months</span></el-radio>
                  </el-col>
                  <el-col :xs="8" :sm="8" :md="8" :lg="8">
                    <div class="packages--price"><span class="pack-old-price">$37.99</span> <span class="pack-new-price">$28.49</span></div>
                    <div class="pack-time">Per Month</div>
                  </el-col>
                  <el-col :xs="8" :sm="8" :md="8" :lg="8">
                    <div class="saving-per"> Save 55%</div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="package-btn">
              <el-button class="pack-btn">Continue</el-button>
            </div>
            <div class="package-plan-box">
              <div class="package-plan-title">Premium plans included these great features </div>
              <ul>
                <li><i class="el-icon-check"></i>  See who likes you </li>
                <li><i class="el-icon-check"></i> Anyone can reply to you </li>
                <li><i class="el-icon-check"></i> View read receipts </li>
                <li><i class="el-icon-check"></i> Get a monthly Boost </li>
                <li><i class="el-icon-check"></i> Enjoy Unlimited Rewards </li>
                <li><i class="el-icon-check"></i> See who's viewed you </li>
                <li><span class="includes">+Everything included instandard </span> </li>
              </ul>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import pageMixin from '@/mixins/page'
import Breadcrumb from '@/views/user/_components/Breadcrumb'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  created () {
    this.getPage({
      slug: 'likes'
    }).then((res) => {
      this.value = res
    })
  },

  data () {
    return {
      value: null,
      activeStepBar: 0,
      tab: 1
    }
  },

  methods: {
    onCreate () {
      this.activeStepBar++
      this.tab++
    }
  },

  mixins: [pageMixin],
  name: 'Register'
}
</script>
<style scoped>
.information-ul {
  margin: 0;
  padding: 40px 0;
  background: #fff;
  border-top: 1px solid #ccc;
}
.information-ul .page-title {
  font-size: 24px !important;
  padding-top: 10px;
}

.information-ul p{
  padding: 10px 0;
  line-height: 24px;
}

.sidebar-bg{
  background: #fff;
  padding-bottom: 40px;
}

.page-links{
  margin: 0;
  padding:10px 0 0 0;
}

.page-links ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-links li{
  margin: 0 5px;
  padding: 20px 0 20px 20px;
  list-style: none;
  font-size: 17px;
  border-bottom: 1px solid #ccc;
}

.page-links li a{
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all 0.3s ease-in 0s;
}

.page-links li a:hover{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}

.page-links li a.active{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}

</style>
