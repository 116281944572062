const UPDATE_EDUCATION_FIELDS = (state, educationFields) => {
  state.education_fields = educationFields
}

const UPDATE_EDUCATION_LEVELS = (state, educationLevels) => {
  state.education_levels = educationLevels
}

const UPDATE_CREATED_USER = (state, user) => {
  state.created_user = user
}

const UPDATE_ADMIN = (state, admin) => {
  state.admin = admin
}

const UPDATE_USER = (state, user) => {
  state.user = user
}

const UPDATE_USERS = (state, users) => {
  state.users = users
}

export default {
  UPDATE_ADMIN,
  UPDATE_EDUCATION_FIELDS,
  UPDATE_EDUCATION_LEVELS,
  UPDATE_CREATED_USER,
  UPDATE_USER,
  UPDATE_USERS
}
