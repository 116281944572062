import { createRouter, createWebHashHistory } from 'vue-router'

import home from './user/home'
import adminUser from './admin/user'
import adminIndex from './admin/index'
import user from './user/user'
import configuration from './admin/configuration'

const routes = [].concat(adminIndex, adminUser, home, user, configuration)

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const user = localStorage.getItem('admin')

  if (to.name !== 'admin-login' && !user && !to.meta.frontend) next({ name: 'frontend' })
  else next()
})

export default router
