<template>
  <SettingPage>
    <template #content>
      <el-col :xs="24" :sm="14" :md="16" :lg="16">
        <div class="page-title">Blocked Profiles</div>
        <p>Once you block someone, that person can no longer see things you post on your profile,
          invite you to events or groups, start a conversation with you, or add you as a friend.</p>
        <div class="block-profile-form">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="Block Users">
              <el-input  placeholder="Type a Name" class="input-forms"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary">Block</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="blocked-profile-list">
          <ul>
            <li>Ram Prasad Dhakal  <a href="">Unblock</a></li>
            <li>Raghu Yadav <a href="">Unblock</a></li>
            <li>Rajiv Dangol  <a href="">Unblock</a></li>
          </ul>
        </div>
        <div class="not-blocking">
          You are not blocking any profile
        </div>
      </el-col>
    </template>
  </SettingPage>
</template>

<script>
import SettingPage from '@/components/SettingPage'

export default {
  components: {
    SettingPage
  },

  data () {
    return {
    }
  },

  methods: {
  },

  name: 'Setting'
}
</script>
<style scoped>
.information-ul {
  margin: 0;
  padding: 40px 0;
  background: #fff;
  border-top: 1px solid #ccc;
}
.information-ul .page-title {
  font-size: 24px !important;
  padding-top: 10px;
}

.information-ul p{
  padding: 10px 0;
  line-height: 24px;
}

.sidebar-bg{
  background: #fff;
  padding-bottom: 40px;
}

.page-links{
  margin: 0;
  padding:10px 0 0 0;
}

.page-links ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-links li{
  margin: 0 5px;
  padding: 20px 0 20px 20px;
  list-style: none;
  font-size: 17px;
  border-bottom: 1px solid #ccc;
}

.page-links li a{
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all 0.3s ease-in 0s;
}

.page-links li a:hover{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}

.page-links li a.active{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}
.block-profile-form{
  padding: 30px 0 5px 0;
}

.block-profile-form button{
  margin-top: 5px;
}

.blocked-profile-list{
  margin: 0;
  padding: 0;
}

.blocked-profile-list ul{
  margin: 0;
  padding: 0 0 0 108px;
}

.blocked-profile-list li{
  margin: 0;
  padding: 0px 0 15px 0;
  font-size: 15px;
  list-style-type: disc !important;
}

.blocked-profile-list li a{
  margin: 0;
  padding:0;
  color: #237eeb;
}

.not-blocking{
  margin: 30px 0 0 0;
  padding: 20px;
  font-size: 18px;
  color: #237eeb;
  background: #f6f8f9;
  border-radius: 5px;
}
</style>
